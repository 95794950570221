import { PreloadedState, configureStore } from "@reduxjs/toolkit";
import { getClient, getSdk } from "context/graphql/client";
import { GraphQLError } from "graphql";
import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { queueMiddleware } from "./queueMiddleware";
import { reducers } from "./reducers";
import { menuInit } from "./slices/menu.store";

export const setupStore = (
  preloadedState?: PreloadedState<RootState>,
  silenceMiddlewareWarnings?: boolean
) => {
  // Expand options as necessary.
  // Undefined object should result in default options
  // Undefined properties should result in default for that/those option(s)
  const middlewareOptions = silenceMiddlewareWarnings
    ? {
        immutableCheck: false,
        serializableCheck: false,
      }
    : undefined;
  const store = configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        ...middlewareOptions,
        thunk: {
          extraArgument: {
            sdk: getSdk(),
            client: getClient(),
          },
        },
      }).prepend(queueMiddleware),
  });
  storeInit(store);
  return store;
};

const storeInit = (store: AppStore) => {
  store.dispatch(menuInit());
};

let rootStore: AppStore;
export const getStore = (
  preloadedState?: PreloadedState<RootState>,
  silenceMiddlewareWarnings?: boolean
): AppStore => {
  if (!rootStore) {
    rootStore = setupStore(preloadedState, silenceMiddlewareWarnings);
    return rootStore;
  }
  return rootStore;
};
export const forceSetupNewStore = (
  preloadedState?: PreloadedState<RootState>,
  silenceMiddlewareWarnings?: boolean
): AppStore => {
  rootStore = setupStore(preloadedState, silenceMiddlewareWarnings);
  return rootStore;
};

export const store = getStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppAsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string | readonly GraphQLError[];
  pendingMeta: {
    queued?: boolean;
  };
  extra: {
    sdk: ReturnType<typeof getSdk>;
    client: ReturnType<typeof getClient>;
  };
};
