import Container from "@mui/material/Container";
import { FC } from "react";

import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { MeterReadingComponent } from "./MeterReading";

export const MeterTab: FC = () => {
  const { meters: jobMeters } = useAppSelector(selectPageSelectedJob);
  const meters = jobMeters?.slice() || [];

  return (
    <Container data-testid="MeterTab">
      {meters.map((meter) => (
        <div key={meter?.name}>
          <MeterReadingComponent meter={meter} />
        </div>
      ))}
    </Container>
  );
};
