import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Container, List, ListItem, ListItemText } from "@mui/material";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import Transition from "components/Transition";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import {
  selectEquipmentSpecificationsLoading,
  selectEquipmentSpecificationsOpen,
  setEquipmentSpecificationsOpen,
} from "store/slices/visit.store";

export const EquipmentSpecificationsDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);
  const open = useAppSelector(selectEquipmentSpecificationsOpen);
  const loading = useAppSelector(selectEquipmentSpecificationsLoading);
  const [specifications] = useState(job.equipment?.specifications ?? []);

  const handleClose = useCallback(() => {
    dispatch(setEquipmentSpecificationsOpen({ open: false }));
  }, [dispatch]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      data-testid="EquipmentSpecificationsDialog"
    >
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="EquipmentSpecificationsDialog-CloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            <FormattedMessage id="visit.equipmentSpecifications" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container>
        <List>
          {specifications.map((s, index) => (
            <ListItem key={`${s.id}-${index}`} divider={true}>
              {/* <ListItemText primary={s.partNumber} secondary={`${s.description} - ${s.location}`} /> */}
              <ListItemText
                primary={s.partNumber}
                secondary={
                  <>
                    <Typography component="p">{`${s.description}`}</Typography>
                    {s.location && <Typography component="p">{`${s.location}`}</Typography>}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Container>
    </Dialog>
  );
};
