import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import {
  GetCustomerQueryVariables,
  GetCustomersQueryVariables,
  GetEquipmentQueryVariables,
  WorkNoteArgsInputType,
} from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./createJob.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getCreateJobOptions: createAppAsyncThunk(
    "createJob/getCreateJobOptions",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCreateJobOptions);
      if (errors) return rejectWithValue(errors);
      if (!data?.createJobOptions) return rejectWithValue("something went wrong");
      const { createJobOptions } = data;
      const { categories, symptoms } = createJobOptions || {};
      if (categories && symptoms) return { categories, symptoms };
      else return rejectWithValue("Something went wrong");
    }
  ),
  getCustomer: createAppAsyncThunk(
    "createJob/getCustomer",
    async (variables: GetCustomerQueryVariables, { rejectWithValue, getState, extra: { sdk } }) => {
      const { createJob } = getState();
      let existingCustomer = createJob.customers.find((c) => c.id === variables.id);
      if (existingCustomer) return existingCustomer;
      const { data, errors } = await graphqlRequest(sdk.getCustomer, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.customer) return rejectWithValue("something went wrong");
      return data.customer;
    }
  ),
  getCustomers: createAppAsyncThunk(
    "createJob/getCustomers",
    async (variables: GetCustomersQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getCustomers, { variables });
      if (errors) return rejectWithValue(errors);
      if (!data?.customers) return rejectWithValue("something went wrong");
      return data.customers;
    }
  ),
  getEquipment: createAppAsyncThunk(
    "createJob/getEquipment",
    async (variables: GetEquipmentQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEquipment, { variables });
      if (errors) return rejectWithValue(errors);
      if (!data?.equipment) return rejectWithValue("something went wrong");
      return data.equipment;
    }
  ),
  getEquipmentContractStatus: createAppAsyncThunk(
    "createJob/getEquipmentContractStatus",
    async (_, { rejectWithValue, getState, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getEquipmentContractStatus);
      if (errors) return rejectWithValue(errors);
      if (!data?.equipmentContractStatus) return rejectWithValue("something went wrong");
      const { equipmentContractStatus } = data;
      if (equipmentContractStatus) {
        const state = getState();
        if (
          equipmentContractStatus.customerId &&
          !state.createJob.customers.find((c) => c.id === equipmentContractStatus.customerId)
        ) {
          const { data: customerData, errors: customerErrors } = await graphqlRequest(
            sdk.getCustomer,
            {
              variables: {
                id: equipmentContractStatus.customerId,
              },
            }
          );
          if (customerErrors) return rejectWithValue(customerErrors);
          if (!customerData?.customer) return rejectWithValue("something went wrong");
          const { customer } = customerData;
          if (customer) return { equipmentContractStatus, customer };
          else return rejectWithValue("Something went wrong");
        }
        return { equipmentContractStatus };
      } else return rejectWithValue("Something went wrong");
    }
  ),
  getAlertNotes: createAppAsyncThunk(
    "createJob/getAlertNotes",
    async (variables: WorkNoteArgsInputType, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getWorkNotes, {
        variables: {
          workNoteArgs: variables,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.workNotes) return rejectWithValue("something went wrong");
      return data.workNotes;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getCreateJobOptions.pending, (state) => {
    state.initLoading = true;
    return state;
  });
  builder.addCase(asyncQueries.getCreateJobOptions.rejected, (state) => {
    state.initLoading = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getCreateJobOptions.fulfilled,
    (state, { payload: { categories, symptoms } }) => {
      state.initLoading = false;
      state.categories = [...categories];
      state.symptoms = [...symptoms];

      return state;
    }
  );
  builder.addCase(asyncQueries.getCustomer.pending, (state) => {
    state.customersLoading = true;
  });
  builder.addCase(asyncQueries.getCustomer.rejected, (state) => {
    state.customersLoading = false;
  });
  builder.addCase(asyncQueries.getCustomer.fulfilled, (state, { payload: customer }) => {
    state.customersLoading = false;
    const existingCustomer = state.customers.find((c) => c.id === customer.id);
    if (!existingCustomer) state.customers = [...state.customers, customer];
    return state;
  });
  builder.addCase(asyncQueries.getCustomers.pending, (state) => {
    state.customersLoading = true;
  });
  builder.addCase(asyncQueries.getCustomers.rejected, (state) => {
    state.customersLoading = false;
  });
  builder.addCase(asyncQueries.getCustomers.fulfilled, (state, { payload: customers }) => {
    state.customersLoading = false;
    state.customers = [...customers];
    return state;
  });
  builder.addCase(asyncQueries.getAlertNotes.fulfilled, (state, { payload: workNotes }) => {
    state.alertNotes = [...workNotes.filter((n) => n.isAlert)];
    return state;
  });
  builder.addCase(asyncQueries.getEquipment.pending, (state) => {
    state.equipmentsLoading = true;
  });
  builder.addCase(asyncQueries.getEquipment.rejected, (state, { payload: errors }) => {
    state.equipmentsLoading = false;
  });
  builder.addCase(asyncQueries.getEquipment.fulfilled, (state, { payload: equipment }) => {
    state.equipmentsLoading = false;
    state.equipments = [...equipment];
    return state;
  });
  builder.addCase(
    asyncQueries.getEquipmentContractStatus.fulfilled,
    (state, { payload: { equipmentContractStatus, customer } }) => {
      if (customer) state.customers = [...state.customers, customer];
      if (!state.formValues.equipment?.customerId && equipmentContractStatus.customerId) {
        state.formValues.customer =
          state.customers.find((c) => c.id === equipmentContractStatus.customerId) ?? null;
      }
      state.selectedContract = equipmentContractStatus;
      return state;
    }
  );
};
