import { FC } from "react";
import { styled } from "@mui/material/styles";
import Chip, { ChipProps } from "@mui/material/Chip";

export const StyledChipStyles = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));

export const StyledChip: FC<ChipProps> = (props) => {
  return (
    <StyledChipStyles {...props} size="small">
      {props.children}
    </StyledChipStyles>
  );
};
