import { useState, useEffect, useRef } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useIosInstallPrompt from "hooks/install/useIosInstallPrompt";
import useWebInstallPrompt from "hooks/install/useWebInstallPrompt";

import { ReactComponent as Logo } from "assets/a2h.svg";
import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";

const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const cardRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const resizePlaceholder = () => {
      setHeight(cardRef?.current?.clientHeight ?? 0);
    };

    resizePlaceholder();

    window.addEventListener("resize", resizePlaceholder);
    return () => window.removeEventListener("resize", resizePlaceholder);
  }, [cardRef?.current?.clientHeight, setHeight]);

  return iosInstallPrompt || webInstallPrompt ? (
    <>
      <Card ref={cardRef} style={{ position: "fixed", zIndex: 1200, width: "100%" }}>
        <CardContent style={{ paddingBottom: 16 }}>
          {iosInstallPrompt && (
            <Grid container direction="row" wrap="nowrap" spacing={2}>
              <Grid item alignSelf="center">
                <CloseIcon onClick={handleIOSInstallDeclined} />
              </Grid>
              <Grid item>
                <Typography fontWeight={700} gutterBottom>
                  Install to Home Screen
                </Typography>
                <Typography>
                  Just tap{" "}
                  <Logo style={{ width: 20, height: 20 }} title="icon" /> and
                  then 'Add to Home Screen'
                </Typography>
              </Grid>
            </Grid>
          )}
          {webInstallPrompt && (
            <Grid
              container
              direction="row"
              wrap="nowrap"
              spacing={1}
              alignItems="center"
              justifyItems="center"
            >
              <Grid item alignSelf="center">
                <CloseIcon onClick={handleWebInstallDeclined} />
              </Grid>
              <Grid item>
                <Typography fontWeight={700} gutterBottom variant="body2">
                  Install
                </Typography>
                <Typography variant="body2">
                  This website has app functionality. Install it to your home
                  screen.
                </Typography>
              </Grid>
              <Grid item alignSelf="center">
                <StyledButtonPrimaryMini
                  onClick={handleWebInstallAccepted}
                  size="small"
                >
                  Install
                </StyledButtonPrimaryMini>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      {/* placeholder for fixed toolbar */}
      <div style={{ height: height }}></div>
    </>
  ) : null;
};

export default InstallPWA;
