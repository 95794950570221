import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton as MuiSkeleton,
  Typography,
} from "@mui/material";

export const JobDetailsSkeleton = () => {
  const skeletonIcon = <MuiSkeleton variant="rectangular" width={24} height={24} />;

  return (
    <Card elevation={1}>
      <CardHeader
        className="e2e-jobDetailHeader"
        data-testid="JobDetailSkeleton"
        title={<MuiSkeleton width="100%" />}
        titleTypographyProps={{ variant: "h6" }}
        subheader={<MuiSkeleton width="100%" />}
      />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <MuiSkeleton width={60} height={18} variant="rectangular" />
          </Grid>

          <Grid item>
            <>
              <MuiSkeleton width="100%" />
              <MuiSkeleton width="70%" />
            </>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              <MuiSkeleton width="25%" />
            </Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>{skeletonIcon}</ListItemIcon>
                <ListItemText primary={<MuiSkeleton width="60%" />} />
              </ListItem>
              <ListItem>
                <ListItemIcon>{skeletonIcon}</ListItemIcon>
                <ListItemText primary={<MuiSkeleton width="100%" />} />
              </ListItem>
              <ListItem>
                <ListItemIcon>{skeletonIcon}</ListItemIcon>
                <ListItemText primary={<MuiSkeleton width="80%" />} />
              </ListItem>
              <ListItem>
                <ListItemIcon>{skeletonIcon}</ListItemIcon>
                <ListItemText primary={<MuiSkeleton width="80%" />} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
