import { publicUrl } from "env";

export const consentVersion = {
  basic: {
    version: 1,
    required: true,
  },
  tracking: {
    version: 1,
    required: false,
  },
};

export type ConsentObject = {
  required: boolean;
  version: number;
  text: string;
};
export const getBasicConsent = async (lang: string): Promise<ConsentObject> => {
  let text = await fetch(
    `${publicUrl()}/tos/basic/${consentVersion.basic.version}/${lang}.txt`
  ).then((t) => t.text());
  const consent = {
    required: consentVersion.basic.required,
    version: consentVersion.basic.version,
    text: text,
  };
  return consent;
};
export const getTrackingConsent = async (lang: string): Promise<ConsentObject> => {
  let text = await fetch(
    `${publicUrl()}/tos/basic/${consentVersion.tracking.version}/${lang}.txt`
  ).then((t) => t.text());
  const consent = {
    required: consentVersion.tracking.required,
    version: consentVersion.tracking.version,
    text: text,
  };
  return consent;
};
