import { Build as BuildIcon } from "@mui/icons-material";
import { Grid, List, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { isEmpty } from "helpers";
import { ServiceJob } from "operations/schema/schema";
import { Visit } from "./Visit";

interface VisitListProps {
  visits: ServiceJob[] | undefined;
}

export const VisitList: FC<VisitListProps> = (props) => {
  const { visits = [] } = props;

  if (!isEmpty(visits)) {
    return (
      <>
        {visits.map((visit) => {
          return (
            <List dense key={visit.id}>
              <Visit job={visit} toLink="/history" />
            </List>
          );
        })}
      </>
    );
  } else {
    return (
      <Grid container direction="column" alignItems="center" mt={2.5}>
        <Grid item>
          <BuildIcon color="secondary" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography color="secondary">
            <FormattedMessage id="visit.noVisitsFound" />
          </Typography>
        </Grid>
      </Grid>
    );
  }
};
