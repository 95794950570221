import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";

export type CalendarViewTypes = "day" | "week" | "month";
export const calendarKeys = ["day", "week", "month"] as const;

export interface State {
  view: CalendarViewTypes;
  hideWeekends: boolean;
  selectedDate: string | null;
}

interface Actions extends SliceCaseReducers<State> {
  setView: (state: State, action: PayloadAction<{ view: CalendarViewTypes }>) => State;
  setHideWeekends: (state: State, action: PayloadAction<{ hideWeekends: boolean }>) => State;
  setCalendarSelectedDate: (state: State, action: PayloadAction<{ date: string | null }>) => State;
}

//interface Selectors {}

export const initialState: State = {
  view: "day",
  hideWeekends: false,
  selectedDate: null,
};

const actions: Actions = {
  setView(state, { payload: { view } }) {
    state.view = view;
    return state;
  },
  setHideWeekends(state, { payload: { hideWeekends } }) {
    state.hideWeekends = hideWeekends;
    return state;
  },
  setCalendarSelectedDate(state, { payload: { date } }) {
    state.selectedDate = date;
    return state;
  },
};

//const selectors: Selectors = {};

const storeBase = createSlice<State, Actions>({
  name: "calendar",
  initialState,
  reducers: actions,
});

export default storeBase.reducer;
export const { setView, setHideWeekends, setCalendarSelectedDate } = storeBase.actions;
//export const {} = selectors;
