import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import { store } from "store";

import ServiceWorkerWrapper from "serviceWorkerWrapper";
import "style.css";
import theme from "theme";

import ErrorBoundary from "components/ErrorBoundary";
import SettingsProvider from "context/SettingsProvider";
import SnackbarProvider from "context/snackbar";

import Router from "pages";

const root = createRoot(document.getElementById("root") as Element);
root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StoreProvider store={store}>
          <ErrorBoundary>
            <SettingsProvider>
              <SnackbarProvider>
                <CssBaseline />
                <Router />
                <ServiceWorkerWrapper />
              </SnackbarProvider>
            </SettingsProvider>
          </ErrorBoundary>
        </StoreProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// We are calling serviceWorker.register from <ServiceWorkerWrapper /> component
//serviceWorkerRegistration.register();
