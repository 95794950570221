import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";

import { getQueue, QueueEntry } from "context/graphql/queue";
import { store } from "store";
import { selectJob } from "store/slices/jobs.store";

import { QueueItem } from "./QueueItem";

interface QueueItemProps {
  queueKey: string;
}

export const QueueHeader = (props: QueueItemProps) => {
  const { queueKey } = props;
  const [open, setOpen] = useState(false);
  const [queue, setQueue] = useState<QueueEntry[]>([]);

  const jobId: string | undefined = queueKey !== "other" ? queueKey : undefined;
  let externalId;
  if (jobId) {
    externalId = selectJob(store.getState(), jobId)?.externalId || jobId;
  }

  const onAbort = (q: any) => {
    getQueue(queueKey).then((q) => setQueue(q));
  };
  useEffect(() => {
    if (open) getQueue(queueKey).then((q) => setQueue(q));
  }, [queueKey, open]);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText primary={queueKey === "other" ? "Other" : `Job: ${externalId}`} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {queue.map((o, i) => (
            <QueueItem key={`${queueKey}-${i}`} queueEntry={o} onAbort={() => onAbort(o)} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
