import { FC } from "react";

import { JobStatus } from "operations/schema/schema";

import { AlertNotesDialog } from "components/AlertNotesDialog";
import { JobDetails } from "pages/jobs/jobDetails/JobDetails";

import { useEffectOnce } from "helpers/useEffectOnce";
import { useAppDispatch, useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { acceptJob } from "store/slices/jobs.store";
import { JobDetailsActions } from "./JobDetailsActions";

export const JobDetailsPage: FC = () => {
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectPageSelectedJob);

  useEffectOnce(() => {
    if (job.status === JobStatus.Assigned) {
      dispatch(acceptJob({ jobId: job.id }));
    }
  });

  return (
    <>
      <JobDetails />
      <JobDetailsActions />
      <AlertNotesDialog />
    </>
  );
};
