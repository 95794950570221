import { MenuItem } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import { useIntl } from "react-intl";

export type FilterDateSelectProps = {
  value: string | null;
  onChange: (value: any) => any;
  options: DateFilterType[];
};

export type DateFilterType = {
  label: string;
  value: string;
  class: string;
};

export const FilterDateSelect = (props: FilterDateSelectProps) => {
  const { value, onChange, options } = props;
  const intl = useIntl();

  return (
    <>
      <StyledTextField
        select
        label={intl.formatMessage({ id: "filter.date" })}
        data-testid="Filter-Date-Select"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        defaultValue={options[0].value}
      >
        {options.map((e, i) => {
          return (
            <MenuItem key={i} value={e.value} className={e.class}>
              {e.label}
            </MenuItem>
          );
        })}
      </StyledTextField>
    </>
  );
};
