import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { getIntl } from "context/SettingsProvider";

import { getSnackbarMessage, isValidSnackbarKey } from "context/snackbar/getSnackbarMessage";
import { SnackbarMessage, SnackbarMessageKey } from "context/snackbar/SnackbarMessages";
import { RootState } from "store";

export interface State {
  messages: SnackbarMessage[];
}

interface Actions extends SliceCaseReducers<State> {
  addSnackbarMessage: (
    state: State,
    action: PayloadAction<{ key: string; argument?: string }>
  ) => State;
  removeSnackbarMessage: (
    state: State,
    action: PayloadAction<{ key: SnackbarMessageKey | undefined }>
  ) => State;
}

interface Selectors {
  selectSnackbarMessages: (state: RootState) => SnackbarMessage[];
  selectSnackbarMessage: (state: RootState) => SnackbarMessage | undefined;
}

export const initialState: State = {
  messages: [],
};

const actions: Actions = {
  addSnackbarMessage: (state, { payload: { key, argument } }) => {
    if (!isValidSnackbarKey(key)) return state;
    const intl = getIntl();
    argument = argument === undefined ? "" : argument;
    let message = getSnackbarMessage(key, argument, intl);
    state.messages = message ? [...state.messages, message] : state.messages;
    return state;
  },
  removeSnackbarMessage: (state, { payload: { key } }) => {
    if (state.messages.length !== 0 && state.messages[0].key === key) {
      const [_ignore, ...rest] = state.messages; // eslint-disable-line
      state.messages = [...rest];
    }
    return state;
  },
};

const selectors: Selectors = {
  selectSnackbarMessage: ({ snackbar }) => {
    return snackbar.messages.length > 0 ? snackbar.messages[0] : undefined;
  },
  selectSnackbarMessages: ({ snackbar }) => {
    return snackbar.messages;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "snackbar",
  initialState,
  reducers: actions,
});

export default storeBase.reducer;
export const { addSnackbarMessage, removeSnackbarMessage } = storeBase.actions;
export const { selectSnackbarMessage, selectSnackbarMessages } = selectors;
