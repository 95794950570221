import CheckIcon from "@mui/icons-material/Check";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { IntlShape, useIntl } from "react-intl";

import { ListItemButton } from "@mui/material";
import { MenuItem } from "models/MenuItem";
import { useAppDispatch, useAppSelector } from "store";
import {
  CalendarViewTypes,
  calendarKeys,
  setHideWeekends,
  setView,
} from "store/slices/calendar.store";

const calendarViewOptionText = (intl: IntlShape, key: CalendarViewTypes): string => {
  if (key === "day") return intl.formatMessage({ id: "calendar.day" });
  if (key === "week") return intl.formatMessage({ id: "calendar.week" });
  if (key === "month") return intl.formatMessage({ id: "calendar.month" });
  throw Error("invalid key");
};

export const CalendarViewList = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { view: selectedView, hideWeekends } = useAppSelector((s) => s.calendar);

  const viewList: MenuItem[] = [
    {
      text: intl.formatMessage({ id: "calendar.view" }),
      childItems: [
        ...calendarKeys.map((view) => ({
          text: calendarViewOptionText(intl, view),
          selected: selectedView === view,
          onClick: () => {
            dispatch(
              setView({
                view: view,
              })
            );
          },
          testId: `view-${view}`,
        })),
      ],
    },
    {
      text: intl.formatMessage({ id: "calendar.weekends" }),
      childItems: [
        {
          text: intl.formatMessage({ id: "calendar.show" }),
          selected: !hideWeekends,
          onClick: () => {
            dispatch(setHideWeekends({ hideWeekends: false }));
          },
          testId: `weekendShown`,
        },
        {
          text: intl.formatMessage({ id: "calendar.hide" }),
          selected: hideWeekends,
          onClick: () => {
            dispatch(setHideWeekends({ hideWeekends: true }));
          },
          testId: `weekendHide`,
        },
      ],
    },
  ];

  return (
    <div data-testid="CalendarViewList">
      {viewList.map(({ text, childItems }) => (
        <div key={text + "listItem"}>
          <ListItem dense>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            />
          </ListItem>
          <List disablePadding>
            {childItems?.map(
              ({ text: subListText, testId, selected, onClick, className }: MenuItem) => (
                <ListItemButton
                  key={subListText + "item"}
                  dense
                  onClick={onClick}
                  className={className}
                  data-testid={`CalendarViewList-Button${testId ? `-${testId}` : ""}`}
                >
                  {selected && <CheckIcon color="primary" />}
                  <ListItemText
                    primary={subListText}
                    primaryTypographyProps={{
                      style: { marginLeft: selected ? "16px" : "40px" },
                    }}
                  />
                </ListItemButton>
              )
            )}
          </List>
        </div>
      ))}
    </div>
  );
};
