import { GraphQLClient } from "graphql-request";
import { GraphQLClientRequestHeaders } from "graphql-request/build/cjs/types";
import gql from "graphql-tag";
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: { input: string; output: string };
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: { input: string; output: string };
  Decimal: { input: number; output: number };
  Long: { input: number; output: number };
  Short: { input: number; output: number };
};

export type ActionType = {
  __typename?: "ActionType";
  /** The code of the action. */
  code?: Maybe<Scalars["String"]["output"]>;
  /** The id of the action. */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the action. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The sort id of the action. */
  sortId?: Maybe<Scalars["String"]["output"]>;
  /** The sub action codes of the action. */
  subActionCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The type id of the action. */
  typeId?: Maybe<Scalars["Int"]["output"]>;
};

export type AddressType = {
  __typename?: "AddressType";
  /** Address1 */
  address1?: Maybe<Scalars["String"]["output"]>;
  /** Address2 */
  address2?: Maybe<Scalars["String"]["output"]>;
  /** Address3 */
  address3?: Maybe<Scalars["String"]["output"]>;
  /** Service Region for Evatic */
  areaCode?: Maybe<Scalars["String"]["output"]>;
  /** City */
  city?: Maybe<Scalars["String"]["output"]>;
  /** Country */
  country?: Maybe<Scalars["String"]["output"]>;
  /** Name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** PostalCode */
  postalCode?: Maybe<Scalars["String"]["output"]>;
  /** PostalPlace */
  postalPlace?: Maybe<Scalars["String"]["output"]>;
};

export type AsolviMobileMutation = {
  __typename?: "AsolviMobileMutation";
  addChecklist?: Maybe<ChecklistType>;
  addUserConsent: Array<UserConsentType>;
  addWorkNote?: Maybe<WorkNoteType>;
  assignTask?: Maybe<Scalars["Boolean"]["output"]>;
  auth0Login?: Maybe<LoginResult>;
  changeJobEquipment?: Maybe<Scalars["Boolean"]["output"]>;
  completeVisitWithParts?: Maybe<ServiceJob>;
  createServiceJob?: Maybe<CreateJobResult>;
  feedback?: Maybe<Scalars["Boolean"]["output"]>;
  login?: Maybe<LoginResult>;
  logout?: Maybe<LogoutResult>;
  preLogin?: Maybe<PreLoginResult>;
  rejectJobAssignment?: Maybe<Scalars["Boolean"]["output"]>;
  startVisitTravel?: Maybe<ServiceJob>;
  startVisitWork?: Maybe<ServiceJob>;
  updateContact?: Maybe<Scalars["Boolean"]["output"]>;
  updateETA?: Maybe<ServiceJob>;
  updateEngineerMetadata?: Maybe<Scalars["Boolean"]["output"]>;
  updateEquipment?: Maybe<Scalars["Boolean"]["output"]>;
  updateJobStatus?: Maybe<ServiceJob>;
  updateMachineCustomProperties?: Maybe<ServiceJob>;
  updateMeters?: Maybe<Scalars["Boolean"]["output"]>;
  updatePlannedDate?: Maybe<Scalars["Boolean"]["output"]>;
  updateTransitParts?: Maybe<Scalars["Boolean"]["output"]>;
  updateVisitInformation?: Maybe<ServiceJob>;
  updateVisitTravel?: Maybe<ServiceJob>;
  userSettings: UserSettings;
};

export type AsolviMobileMutationAddChecklistArgs = {
  checklistCode: Scalars["String"]["input"];
  jobId: Scalars["String"]["input"];
};

export type AsolviMobileMutationAddUserConsentArgs = {
  userConsents?: InputMaybe<Array<UserConsentInputType>>;
};

export type AsolviMobileMutationAddWorkNoteArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  workNote: WorkNoteInputType;
};

export type AsolviMobileMutationAssignTaskArgs = {
  engineerId: Scalars["String"]["input"];
  jobId: Scalars["String"]["input"];
  scheduleStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AsolviMobileMutationAuth0LoginArgs = {
  accessToken: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  product: Product;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  url: Scalars["String"]["input"];
};

export type AsolviMobileMutationChangeJobEquipmentArgs = {
  equipmentId: Scalars["String"]["input"];
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationCompleteVisitWithPartsArgs = {
  extras: Array<ExtraInputType>;
  files: Array<FileInputType>;
  followUp: FollowUpInputType;
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  newWorkUnitId?: InputMaybe<Scalars["Int"]["input"]>;
  parts: Array<PartInputType>;
  visit: JobVisitInput;
  workTasks?: InputMaybe<Array<WorkTaskInputType>>;
};

export type AsolviMobileMutationCreateServiceJobArgs = {
  files?: InputMaybe<Array<FileInputType>>;
  jobDetails?: InputMaybe<JobInputType>;
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationFeedbackArgs = {
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  rate?: InputMaybe<Scalars["Int"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type AsolviMobileMutationLoginArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  product: Product;
  url: Scalars["String"]["input"];
};

export type AsolviMobileMutationPreLoginArgs = {
  email: Scalars["String"]["input"];
  product: Product;
  url: Scalars["String"]["input"];
};

export type AsolviMobileMutationRejectJobAssignmentArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  rejectReason: Scalars["String"]["input"];
};

export type AsolviMobileMutationStartVisitTravelArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  startTime: Scalars["DateTime"]["input"];
};

export type AsolviMobileMutationStartVisitWorkArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  startTime: Scalars["DateTime"]["input"];
  travelStopTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AsolviMobileMutationUpdateContactArgs = {
  contact: ContactInputType;
  customerId: Scalars["String"]["input"];
  equipmentId: Scalars["String"]["input"];
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationUpdateEtaArgs = {
  eta: Scalars["DateTime"]["input"];
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationUpdateEngineerMetadataArgs = {
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationUpdateEquipmentArgs = {
  equipment: EquipmentInputType;
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
};

export type AsolviMobileMutationUpdateJobStatusArgs = {
  jobId: Scalars["String"]["input"];
  status: JobStatus;
};

export type AsolviMobileMutationUpdateMachineCustomPropertiesArgs = {
  jobId: Scalars["String"]["input"];
  properties?: InputMaybe<Array<PropertyValueInputType>>;
};

export type AsolviMobileMutationUpdateMetersArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  meters?: InputMaybe<Array<MeterReadingInputType>>;
};

export type AsolviMobileMutationUpdatePlannedDateArgs = {
  jobId: Scalars["String"]["input"];
  times?: InputMaybe<TimesInputType>;
};

export type AsolviMobileMutationUpdateTransitPartsArgs = {
  metadata?: InputMaybe<MetadataInputType>;
  partIds: Array<Scalars["String"]["input"]>;
};

export type AsolviMobileMutationUpdateVisitInformationArgs = {
  jobId: Scalars["String"]["input"];
  miles?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AsolviMobileMutationUpdateVisitTravelArgs = {
  jobId: Scalars["String"]["input"];
  metadata?: InputMaybe<MetadataInputType>;
  miles?: InputMaybe<Scalars["Int"]["input"]>;
  travelTimes?: InputMaybe<Array<InputMaybe<TimesInputType>>>;
};

export type AsolviMobileMutationUserSettingsArgs = {
  userSettings?: InputMaybe<UserSettingsInputType>;
};

export type AssignedEngineerType = {
  engineerGuid?: InputMaybe<Scalars["String"]["input"]>;
  engineerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CauseType = {
  __typename?: "CauseType";
  /** The code of the Cause. */
  code?: Maybe<Scalars["String"]["output"]>;
  /** The id of the Cause. */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the Cause. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The type id of the cause. */
  typeId?: Maybe<Scalars["Int"]["output"]>;
};

export type ChecklistGroupsType = {
  __typename?: "ChecklistGroupsType";
  code?: Maybe<Scalars["String"]["output"]>;
  company?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
};

export type ChecklistInputType = {
  checklistCode?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isTemplate?: InputMaybe<Scalars["Boolean"]["input"]>;
  machineGuid?: InputMaybe<Scalars["ID"]["input"]>;
  questions?: InputMaybe<Array<ChecklistQuestionInputType>>;
  title: Scalars["String"]["input"];
};

export type ChecklistQuestionInputType = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dataType?: InputMaybe<QuestionDataType>;
  dropDownValues?: InputMaybe<Array<Scalars["String"]["input"]>>;
  expression?: InputMaybe<Scalars["String"]["input"]>;
  expressionParams?: InputMaybe<Array<ExpressionParameterInputType>>;
  isRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRequiredPreStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  minValue?: InputMaybe<Scalars["Decimal"]["input"]>;
  nodeKey?: InputMaybe<Scalars["String"]["input"]>;
  parent?: InputMaybe<Scalars["String"]["input"]>;
  question: Scalars["String"]["input"];
  showComment?: InputMaybe<Scalars["Boolean"]["input"]>;
  showCondition?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ChecklistQuestionType = {
  __typename?: "ChecklistQuestionType";
  answer?: Maybe<Scalars["String"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  dataType: QuestionDataType;
  dropDownValues: Array<Scalars["String"]["output"]>;
  expression?: Maybe<Scalars["String"]["output"]>;
  expressionParams: Array<ExpressionParameterType>;
  isRequired: Scalars["Boolean"]["output"];
  isRequiredPreStart: Scalars["Boolean"]["output"];
  maxValue?: Maybe<Scalars["Decimal"]["output"]>;
  minValue?: Maybe<Scalars["Decimal"]["output"]>;
  nodeKey?: Maybe<Scalars["String"]["output"]>;
  parent?: Maybe<Scalars["String"]["output"]>;
  question: Scalars["String"]["output"];
  showComment: Scalars["Boolean"]["output"];
  showCondition?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ChecklistType = {
  __typename?: "ChecklistType";
  checklistCode?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  isTemplate?: Maybe<Scalars["Boolean"]["output"]>;
  machineGuid?: Maybe<Scalars["ID"]["output"]>;
  questions: Array<ChecklistQuestionType>;
  title: Scalars["String"]["output"];
};

export type ContactInputType = {
  contactNo?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mobile?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactType = {
  __typename?: "ContactType";
  contactNo?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  mobile?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type CoordinatesInputType = {
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CreateJobResult = {
  __typename?: "CreateJobResult";
  /** Determines if the job is provisionally assigned */
  isProvisional: Scalars["Boolean"]["output"];
  /** The jobId of the created job */
  jobId?: Maybe<Scalars["String"]["output"]>;
};

export type CustomPropertyType = {
  __typename?: "CustomPropertyType";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  propertyGuid?: Maybe<Scalars["ID"]["output"]>;
  sourceGuid?: Maybe<Scalars["ID"]["output"]>;
  type: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerContactType = {
  __typename?: "CustomerContactType";
  /** Contact email */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Contact first name */
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** The id of the customer contact. */
  id: Scalars["String"]["output"];
  /** Contact last name */
  lastName?: Maybe<Scalars["String"]["output"]>;
  /** Mobile number */
  mobileNumber?: Maybe<Scalars["String"]["output"]>;
  /** Contact name */
  name?: Maybe<Scalars["String"]["output"]>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerType = {
  __typename?: "CustomerType";
  /** Address */
  address?: Maybe<AddressType>;
  /** Contacts associated with the customer */
  contacts: Array<CustomerContactType>;
  /** Customer Account Number */
  customerNo?: Maybe<Scalars["String"]["output"]>;
  /** The id of the customer. */
  id: Scalars["String"]["output"];
  isOnStop: Scalars["Boolean"]["output"];
  isVIP?: Maybe<Scalars["Boolean"]["output"]>;
  /** Customer name */
  name?: Maybe<Scalars["String"]["output"]>;
  salesRegion?: Maybe<Scalars["String"]["output"]>;
  serviceRegion?: Maybe<Scalars["String"]["output"]>;
};

export type DatabaseCheckType = {
  __typename?: "DatabaseCheckType";
  /** The email of the user. */
  email?: Maybe<Scalars["String"]["output"]>;
  /** Is the database up? */
  isUp: Scalars["Boolean"]["output"];
};

export type Endpoint = {
  __typename?: "Endpoint";
  apiUrl: Scalars["String"]["output"];
  apiVersion: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  product: Product;
};

export type EndpointInputType = {
  apiUrl: Scalars["String"]["input"];
  apiVersion?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  product?: InputMaybe<Product>;
};

export type EngineerSettings = {
  __typename?: "EngineerSettings";
  allowNegativeStock: Scalars["Boolean"]["output"];
  allowSalesPrice: Scalars["Boolean"]["output"];
  canChangeEquipmentOnTicket: Scalars["Boolean"]["output"];
  canChangeServiceLevel: Scalars["Boolean"]["output"];
  canCreateJob: Scalars["Boolean"]["output"];
  canEditCustomEquipmentProps: Scalars["Boolean"]["output"];
  canUseTransitManager: Scalars["Boolean"]["output"];
  isEquipmentMandatory: Scalars["Boolean"]["output"];
  isSupervisor: Scalars["Boolean"]["output"];
  requireEngineerSignature: Scalars["Boolean"]["output"];
  requiredSolutionDescription: Scalars["Boolean"]["output"];
  sendMailToCustomer: Scalars["Boolean"]["output"];
};

export type EngineerType = {
  __typename?: "EngineerType";
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type EquipmentDetailsType = {
  __typename?: "EquipmentDetailsType";
  alarmReceivingCentre?: Maybe<Scalars["String"]["output"]>;
  alarmReceivingCentreDigiNumber?: Maybe<Scalars["String"]["output"]>;
  alarmReceivingCentreReferenceNumber?: Maybe<Scalars["String"]["output"]>;
  authority?: Maybe<Scalars["String"]["output"]>;
  buildStandard?: Maybe<Scalars["String"]["output"]>;
  certificateDate?: Maybe<Scalars["DateTime"]["output"]>;
  certificateNumber?: Maybe<Scalars["String"]["output"]>;
  customProperties?: Maybe<Array<CustomPropertyType>>;
  fireAlarmCategory?: Maybe<Scalars["String"]["output"]>;
  fireAlarmType?: Maybe<Scalars["String"]["output"]>;
  gradeOfNotification?: Maybe<Scalars["String"]["output"]>;
  information?: Maybe<Scalars["String"]["output"]>;
  isRemotelyMonitored?: Maybe<Scalars["Boolean"]["output"]>;
  lastServiceDate?: Maybe<Scalars["String"]["output"]>;
  nextServiceDate?: Maybe<Scalars["String"]["output"]>;
  notificationOption?: Maybe<Scalars["String"]["output"]>;
  remoteIP?: Maybe<Scalars["String"]["output"]>;
  securityGrade?: Maybe<Scalars["String"]["output"]>;
  signalType?: Maybe<Scalars["String"]["output"]>;
  sortGroups?: Maybe<Array<SortGroupValueType>>;
  stuNumber?: Maybe<Scalars["String"]["output"]>;
  systemProtocol?: Maybe<Scalars["String"]["output"]>;
  verificationMethod?: Maybe<Scalars["String"]["output"]>;
};

export type EquipmentInputType = {
  /** The equipment's address row no. field */
  addressRowNo?: InputMaybe<Scalars["Int"]["input"]>;
  /** The equipment's id field */
  id: Scalars["String"]["input"];
  /** The equipment's location field */
  location: Scalars["String"]["input"];
  /** The equipment's model guid field */
  modelGuid?: InputMaybe<Scalars["String"]["input"]>;
  /** The equipment's serial number field */
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type EquipmentType = {
  __typename?: "EquipmentType";
  /** The Address of the equipment */
  address?: Maybe<AddressType>;
  /** The equipment's address row no. field */
  addressRowNo?: Maybe<Scalars["Int"]["output"]>;
  /** The equipment's asset number field */
  assetNumber?: Maybe<Scalars["String"]["output"]>;
  /** The CustomerId of the equipment */
  customerId?: Maybe<Scalars["String"]["output"]>;
  /** The CustomerName of the equipment */
  customerName?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<EquipmentDetailsType>;
  /** The equipment's id field */
  id?: Maybe<Scalars["String"]["output"]>;
  /** VO uses this value to decide if increase is too high and above this value */
  increaseAboveLimit?: Maybe<Scalars["Decimal"]["output"]>;
  /** Limit for meters to decide if increase is too high */
  increaseLimit?: Maybe<Scalars["Decimal"]["output"]>;
  /** The equipment's install date field */
  installDate?: Maybe<Scalars["DateTime"]["output"]>;
  /** The equipment's item Id */
  itemId?: Maybe<Scalars["Int"]["output"]>;
  /** The equipment's location field */
  location?: Maybe<Scalars["String"]["output"]>;
  /** The equipment's model guid field */
  modelGuid?: Maybe<Scalars["String"]["output"]>;
  /** The equipment's name field */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The equipment's serial number field */
  serialNumber?: Maybe<Scalars["String"]["output"]>;
  specifications: Array<SpecificationType>;
  /** Warranty end date for this equipment */
  warrantyEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  /** Warranty service level id for this equipment */
  warrantyServiceLevelId?: Maybe<Scalars["Int"]["output"]>;
  /** Warranty start date for this equipment */
  warrantyStartDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ExpressionParameterInputType = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  key: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["Decimal"]["input"]>;
};

export type ExpressionParameterType = {
  __typename?: "ExpressionParameterType";
  description?: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  value?: Maybe<Scalars["Decimal"]["output"]>;
};

export type ExtraInputType = {
  id: Scalars["String"]["input"];
  isTime: Scalars["Boolean"]["input"];
  quantity: Scalars["Decimal"]["input"];
  servicesCode?: InputMaybe<Scalars["String"]["input"]>;
  startTime: Scalars["DateTime"]["input"];
  stopTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  type: Scalars["String"]["input"];
};

export type ExtraType = {
  __typename?: "ExtraType";
  code: Scalars["String"]["output"];
  company: Scalars["String"]["output"];
  department: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  isTime: Scalars["Boolean"]["output"];
  quantity: Scalars["Decimal"]["output"];
  servicesCode: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type FeatureFlagType = {
  __typename?: "FeatureFlagType";
  /** The value indicating if the user can access the feature or not. */
  isActive: Scalars["Boolean"]["output"];
  /** The name of the feature. */
  name: Scalars["String"]["output"];
};

export type FileInputType = {
  data: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type FileType = {
  __typename?: "FileType";
  /** File data */
  data: Scalars["String"]["output"];
  /** The description of the file */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The id of the file */
  id: Scalars["ID"]["output"];
  /** File size */
  isFullSize?: Maybe<Scalars["Boolean"]["output"]>;
  /** The file name */
  name: Scalars["String"]["output"];
  /** File size */
  size?: Maybe<Scalars["Long"]["output"]>;
};

export type FollowUpInputType = {
  engineerId: Scalars["Int"]["input"];
  followUpChecked: Scalars["Boolean"]["input"];
  followUpPartsChecked: Scalars["Boolean"]["input"];
  symptomCodeId1?: InputMaybe<Scalars["String"]["input"]>;
  symptomCodeId2?: InputMaybe<Scalars["String"]["input"]>;
  symptomCodeId3?: InputMaybe<Scalars["String"]["input"]>;
  symptomDescription: Scalars["String"]["input"];
};

export type JobCategoryType = {
  __typename?: "JobCategoryType";
  /** The id of the category. */
  id?: Maybe<Scalars["Int"]["output"]>;
  /** The name of the category. */
  text?: Maybe<Scalars["String"]["output"]>;
  /** The type id of the category. */
  typeId?: Maybe<Scalars["Int"]["output"]>;
};

export type JobFilterInputType = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  jobId?: InputMaybe<Scalars["String"]["input"]>;
  plannedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  serviceRegion?: InputMaybe<Scalars["String"]["input"]>;
  symptomIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type JobInputType = {
  assignedEngineers?: InputMaybe<Array<AssignedEngineerType>>;
  /** Job's category id */
  categoryId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Job's Customer Id */
  customerId: Scalars["String"]["input"];
  /** Description of problem */
  description: Scalars["String"]["input"];
  /** Id of the Equipment / Machine */
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
  /** Equipments's ItemId */
  itemId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Date the job creation was received */
  receivedDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Job's Symptom code 1 */
  symptomCodeId1: Scalars["String"]["input"];
  /** Job's Symptom code 2 */
  symptomCodeId2?: InputMaybe<Scalars["String"]["input"]>;
  /** Job's Symptom code 3 */
  symptomCodeId3?: InputMaybe<Scalars["String"]["input"]>;
  /** Warranty end date for this equipment */
  warrantyEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Warranty service level id for this equipment */
  warrantyServiceLevelId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Warranty start date for this equipment */
  warrantyStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type JobOptionsType = {
  __typename?: "JobOptionsType";
  categories: Array<JobCategoryType>;
  symptoms: Array<JobSymptomType>;
};

export enum JobStatus {
  Accepted = "Accepted",
  Assigned = "Assigned",
  Completed = "Completed",
  InProgress = "InProgress",
  Planned = "Planned",
  Rejected = "Rejected",
}

export type JobSymptomType = {
  __typename?: "JobSymptomType";
  /** The Code of the symptom. */
  code?: Maybe<Scalars["String"]["output"]>;
  /** The Description of the symptom. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The SortId of the symptom. */
  sortId?: Maybe<Scalars["String"]["output"]>;
  /** The sub symptom codes of the symptom. */
  subSymptomCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The TypeId of the Symptom */
  typeId?: Maybe<Scalars["Int"]["output"]>;
};

export type JobVisitInput = {
  actionId1: Scalars["String"]["input"];
  actionId2?: InputMaybe<Scalars["String"]["input"]>;
  actionId3?: InputMaybe<Scalars["String"]["input"]>;
  categoryId?: InputMaybe<Scalars["Int"]["input"]>;
  causeId: Scalars["String"]["input"];
  checklists?: InputMaybe<Array<ChecklistInputType>>;
  customerSignatureImageData?: InputMaybe<Scalars["String"]["input"]>;
  customerSignerName?: InputMaybe<Scalars["String"]["input"]>;
  engineerSignatureImageData?: InputMaybe<Scalars["String"]["input"]>;
  newServiceLevelId?: InputMaybe<Scalars["String"]["input"]>;
  serviceReportEmail?: InputMaybe<Scalars["String"]["input"]>;
  solutionDescription?: InputMaybe<Scalars["String"]["input"]>;
  typeId?: InputMaybe<Scalars["Int"]["input"]>;
  workTimes?: InputMaybe<Array<TimesInputType>>;
};

export type LoginResult = {
  __typename?: "LoginResult";
  /** The email of the logged in user */
  email: Scalars["String"]["output"];
  /** Token expiry */
  expiry: Scalars["DateTime"]["output"];
  /** The external GUID of the logged in user */
  externalGuid?: Maybe<Scalars["ID"]["output"]>;
  /** The external id of the logged in user */
  externalId: Scalars["String"]["output"];
  /** The full name of the logged in user */
  fullName?: Maybe<Scalars["String"]["output"]>;
  /** The id of the logged in user */
  id: Scalars["String"]["output"];
  /** The user prefered language */
  languageCode?: Maybe<Scalars["String"]["output"]>;
  /** The product the user is assigned to */
  product: Product;
  /** The engineer own stock id */
  stockId?: Maybe<Scalars["String"]["output"]>;
  /** The default supplier stock for the user */
  suppliedStoreId?: Maybe<Scalars["Int"]["output"]>;
  /** JWT */
  token: Scalars["String"]["output"];
  /** User's consents */
  userConsents: Array<UserConsentType>;
};

export type LogoutResult = {
  __typename?: "LogoutResult";
  /** Logout message */
  message: Scalars["String"]["output"];
};

export type ManufacturerType = {
  __typename?: "ManufacturerType";
  id: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
};

export type MetadataInputType = {
  coordinates?: InputMaybe<CoordinatesInputType>;
  timestamp?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MeterReadingInputType = {
  currentReading?: InputMaybe<Scalars["Int"]["input"]>;
  currentReadingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  typeId: Scalars["String"]["input"];
};

export type MeterReadingType = {
  __typename?: "MeterReadingType";
  /** Reading */
  reading?: Maybe<Scalars["Int"]["output"]>;
  /** Reading date */
  readingDate: Scalars["DateTimeOffset"]["output"];
};

export type MeterType = {
  __typename?: "MeterType";
  currentReading?: Maybe<MeterReadingType>;
  /** MeterReading name */
  name?: Maybe<Scalars["String"]["output"]>;
  previousReading?: Maybe<MeterReadingType>;
  /** Meter sequence order */
  sequence?: Maybe<Scalars["Short"]["output"]>;
  /** Meter type id */
  typeId?: Maybe<Scalars["String"]["output"]>;
  /** For Evatic meters validation, usual increase number per day */
  unitsPerDay?: Maybe<Scalars["Decimal"]["output"]>;
};

export type PartInputType = {
  id: Scalars["String"]["input"];
  isNonStock?: InputMaybe<Scalars["Boolean"]["input"]>;
  partNumber: Scalars["String"]["input"];
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  requestStockId?: InputMaybe<Scalars["Int"]["input"]>;
  returnStockId?: InputMaybe<Scalars["String"]["input"]>;
  salesPrice?: InputMaybe<Scalars["Decimal"]["input"]>;
  salesPriceChangedOnClient?: InputMaybe<Scalars["Boolean"]["input"]>;
  status?: InputMaybe<PartStatus>;
  stockId: Scalars["String"]["input"];
  stockStore?: InputMaybe<StockStore>;
};

export type PartQueryInputType = {
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  stockStore?: InputMaybe<StockStore>;
};

export enum PartStatus {
  Requested = "REQUESTED",
  RequestedReturned = "REQUESTED_RETURNED",
  RequestedUsed = "REQUESTED_USED",
  Used = "USED",
}

export type PartType = {
  __typename?: "PartType";
  /** The barcode number of the part */
  barcode?: Maybe<Scalars["String"]["output"]>;
  /** The description of the part */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The id of the Part */
  id?: Maybe<Scalars["String"]["output"]>;
  /** Is part non-stock */
  isNonStock?: Maybe<Scalars["Boolean"]["output"]>;
  /** Location of part */
  location?: Maybe<Scalars["String"]["output"]>;
  manufacturerId?: Maybe<Scalars["Int"]["output"]>;
  /** The part number */
  partNumber?: Maybe<Scalars["String"]["output"]>;
  /** Quantity in stock */
  quantity?: Maybe<Scalars["Decimal"]["output"]>;
  /** The id of the stock for requesting the part */
  requestStockId?: Maybe<Scalars["Int"]["output"]>;
  /** The id of the stock for returning the part */
  returnStockId?: Maybe<Scalars["String"]["output"]>;
  /** Sales Price */
  salesPrice?: Maybe<Scalars["Decimal"]["output"]>;
  /** Sales price changed by client */
  salesPriceChangedOnClient?: Maybe<Scalars["Boolean"]["output"]>;
  /** Evatic Sort Group 1 code */
  sortGroup1?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Sort Group 2 code */
  sortGroup2?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Sort Group 3 code */
  sortGroup3?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Sort Group 4 code */
  sortGroup4?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Sort Group 5 code */
  sortGroup5?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<PartStatus>;
  /** Stock id */
  stockId?: Maybe<Scalars["String"]["output"]>;
  /** Stock name */
  stockName?: Maybe<Scalars["String"]["output"]>;
  stockStore?: Maybe<StockStore>;
  storeLabel?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Supplier Guid */
  supplierId?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Supplier name */
  supplierName?: Maybe<Scalars["String"]["output"]>;
  /** Evatic Supplier no */
  supplierNo?: Maybe<Scalars["String"]["output"]>;
};

export type PlannerFilterInputType = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  engineerIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  jobId?: InputMaybe<Scalars["String"]["input"]>;
  plannerNode?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  responseDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  serviceRegion?: InputMaybe<Scalars["String"]["input"]>;
  symptomIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  toDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PlannerNodesType = {
  __typename?: "PlannerNodesType";
  isVirtualNode?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  nodeKey?: Maybe<Scalars["String"]["output"]>;
  nodeParent?: Maybe<Scalars["String"]["output"]>;
  nodeText?: Maybe<Scalars["String"]["output"]>;
};

export type PreLoginResult = {
  __typename?: "PreLoginResult";
  /** Audience for this user auth0 login */
  audience?: Maybe<Scalars["String"]["output"]>;
  /** ClientId for this user auth0 login */
  clientId?: Maybe<Scalars["String"]["output"]>;
  /** Domain for this user auth0 login */
  domain?: Maybe<Scalars["String"]["output"]>;
  /** The email of the user */
  email: Scalars["String"]["output"];
  /** The product the user is assigned to */
  product?: Maybe<Product>;
  /** Url for this user auth0 login */
  url?: Maybe<Scalars["String"]["output"]>;
};

export enum Product {
  Evatic = "EVATIC",
  Mock = "MOCK",
  VantageOnline = "VANTAGE_ONLINE",
  AsolviProtect = "ASOLVI_PROTECT",
}

export type PropertyValueInputType = {
  code: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  propertyGuid?: InputMaybe<Scalars["String"]["input"]>;
  sourceGuid?: InputMaybe<Scalars["String"]["input"]>;
  value: Scalars["String"]["input"];
};

export type Query = {
  __typename?: "Query";
  checklistGroups: Array<ChecklistGroupsType>;
  contacts: Array<ContactType>;
  createJobOptions?: Maybe<JobOptionsType>;
  customer?: Maybe<CustomerType>;
  customers: Array<CustomerType>;
  databaseCheck?: Maybe<DatabaseCheckType>;
  engineerHistory: Array<ServiceJob>;
  engineerSettings: EngineerSettings;
  engineers: Array<EngineerType>;
  equipment: Array<EquipmentType>;
  equipmentContractStatus?: Maybe<ContractStatus>;
  extras: Array<ExtraType>;
  featureFlags: Array<FeatureFlagType>;
  files: Array<FileType>;
  job?: Maybe<ServiceJob>;
  jobActions: Array<ActionType>;
  jobCategories: Array<JobCategoryType>;
  jobCauses: Array<CauseType>;
  jobVisits: Array<ServiceJob>;
  jobVisitsHistory: Array<ServiceJob>;
  jobs: Array<ServiceJob>;
  jobsPlanner: Array<ServiceJob>;
  manufacturers: Array<ManufacturerType>;
  partSortGroups: SortGroupsType;
  parts: Array<PartType>;
  plannerNodes: Array<PlannerNodesType>;
  preOrderedParts: Array<PartType>;
  publicFeatureFlags: Array<FeatureFlagType>;
  relatedJobs: Array<ServiceJob>;
  requestableParts: Array<PartType>;
  serviceHistory: Array<ServiceHistory>;
  serviceLevels: Array<ServiceLevelType>;
  stockEnquiry: Array<PartType>;
  stockList: Array<Stock>;
  suggestedParts: Array<Scalars["String"]["output"]>;
  suppliers: Array<SupplierType>;
  symptoms: Array<JobSymptomType>;
  transitParts: Array<PartType>;
  userEndpoints: UserEndpointResult;
  userSettings: UserSettings;
  workNotes: Array<WorkNoteType>;
};

export type QueryChecklistGroupsArgs = {
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryContactsArgs = {
  customerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomersArgs = {
  nameFilter?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryEngineerHistoryArgs = {
  engineerId?: InputMaybe<Scalars["ID"]["input"]>;
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  specificDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryEngineerSettingsArgs = {
  engineerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryEquipmentArgs = {
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryEquipmentContractStatusArgs = {
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFilesArgs = {
  jobId: Scalars["ID"]["input"];
  max?: Scalars["Int"]["input"];
};

export type QueryJobArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryJobActionsArgs = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryJobCausesArgs = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryJobVisitsArgs = {
  jobId: Scalars["ID"]["input"];
  max?: Scalars["Int"]["input"];
};

export type QueryJobVisitsHistoryArgs = {
  jobId: Scalars["ID"]["input"];
  max?: Scalars["Int"]["input"];
};

export type QueryJobsArgs = {
  engineerId?: InputMaybe<Scalars["ID"]["input"]>;
  jobFilter?: InputMaybe<JobFilterInputType>;
  max?: Scalars["Int"]["input"];
};

export type QueryJobsPlannerArgs = {
  engineerId?: InputMaybe<Scalars["String"]["input"]>;
  plannerFilter?: InputMaybe<PlannerFilterInputType>;
  to?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryPartsArgs = {
  locations: Array<PartQueryInputType>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPreOrderedPartsArgs = {
  count: Scalars["Int"]["input"];
  jobId: Scalars["String"]["input"];
};

export type QueryRelatedJobsArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  equipmentId?: InputMaybe<Scalars["ID"]["input"]>;
  jobId?: InputMaybe<Scalars["ID"]["input"]>;
  max?: Scalars["Int"]["input"];
};

export type QueryRequestablePartsArgs = {
  locations: Array<PartQueryInputType>;
};

export type QueryServiceHistoryArgs = {
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  specificDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryServiceLevelsArgs = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryStockEnquiryArgs = {
  hideOutOfStock?: InputMaybe<Scalars["Boolean"]["input"]>;
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySuggestedPartsArgs = {
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTransitPartsArgs = {
  location: PartQueryInputType;
};

export type QueryUserEndpointsArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryWorkNotesArgs = {
  workNoteArgs: WorkNoteArgsInputType;
};

export enum QuestionDataType {
  Boolean = "Boolean",
  ConditionalGroup = "ConditionalGroup",
  Date = "Date",
  DateTime = "DateTime",
  Decimal = "Decimal",
  DropDown = "DropDown",
  Expression = "Expression",
  Group = "Group",
  Image = "Image",
  Int32 = "Int32",
  Signature = "Signature",
  String = "String",
  TimeOfDay = "TimeOfDay",
}

export type ServiceHistory = {
  __typename?: "ServiceHistory";
  actionCodes: Array<Scalars["String"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  causeName?: Maybe<Scalars["String"]["output"]>;
  completedDate: Scalars["String"]["output"];
  customerName: Scalars["String"]["output"];
  engineerName: Scalars["String"]["output"];
  externalId?: Maybe<Scalars["String"]["output"]>;
  isLegacy?: Maybe<Scalars["Boolean"]["output"]>;
  lastStatusName?: Maybe<Scalars["String"]["output"]>;
  loggedDate: Scalars["DateTimeOffset"]["output"];
  parts?: Maybe<Scalars["String"]["output"]>;
  partsStatusName?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  serviceJobId?: Maybe<Scalars["String"]["output"]>;
  symptoms: Array<Scalars["String"]["output"]>;
  workEnd?: Maybe<Scalars["DateTime"]["output"]>;
  workUnitId?: Maybe<Scalars["Int"]["output"]>;
};

export type ServiceJob = {
  __typename?: "ServiceJob";
  /** The action taken to solve the service job */
  actions: Array<ActionType>;
  /** Can engineer travel */
  canTravel: Scalars["Boolean"]["output"];
  /** The category of service job */
  category?: Maybe<Scalars["String"]["output"]>;
  /** Work unit's category id */
  categoryId?: Maybe<Scalars["Int"]["output"]>;
  /** The actual cause of the service job */
  cause?: Maybe<CauseType>;
  /** Work unit's charge band */
  chargeBand?: Maybe<Scalars["String"]["output"]>;
  checklists: Array<ChecklistType>;
  /** The date the service job was marked as completed */
  completedDate?: Maybe<Scalars["DateTime"]["output"]>;
  contractId?: Maybe<Scalars["Int"]["output"]>;
  /** The customer the service job is for */
  customer?: Maybe<CustomerType>;
  /** Image data of the customer's signature */
  customerSignatureImageData?: Maybe<Scalars["String"]["output"]>;
  /** The name of the customer signing off the visit */
  customerSignerName?: Maybe<Scalars["String"]["output"]>;
  /** The id of engineer */
  engineerId?: Maybe<Scalars["String"]["output"]>;
  /** The name of engineer */
  engineerName?: Maybe<Scalars["String"]["output"]>;
  /** Image data of the engineer's signature */
  engineerSignatureImageData?: Maybe<Scalars["String"]["output"]>;
  equipment?: Maybe<EquipmentType>;
  /** Id in source system this service job is from */
  externalId: Scalars["String"]["output"];
  /** The id of the job. */
  id: Scalars["String"]["output"];
  /** Work unit logged date */
  loggedDate?: Maybe<Scalars["DateTime"]["output"]>;
  meters: Array<MeterType>;
  /** The planned date of the service job */
  plannedDate?: Maybe<TimesType>;
  preOrderedParts: Array<PartType>;
  preferredContact?: Maybe<CustomerContactType>;
  /** The priority of the service job */
  priority?: Maybe<Scalars["String"]["output"]>;
  product?: Maybe<Product>;
  /** Should report meter reading */
  requireMeterReading?: Maybe<Scalars["Boolean"]["output"]>;
  /** The response target date of the service job */
  responseDate?: Maybe<Scalars["DateTime"]["output"]>;
  /** Work unit's service level */
  serviceLevel?: Maybe<Scalars["String"]["output"]>;
  /** Engineer notes on the solution for the service job */
  solutionDescription?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<JobStatus>;
  /** Service job symptom description */
  symptomDescription?: Maybe<Scalars["String"]["output"]>;
  symptoms: Array<Scalars["String"]["output"]>;
  tasks: Array<WorkTaskType>;
  /** Miles/KM the engineer traveled */
  totalMileage?: Maybe<Scalars["Int"]["output"]>;
  /** The Estimated Time of Arrival to the job location */
  travelETA?: Maybe<Scalars["DateTime"]["output"]>;
  /** Travel times list with start and end dates */
  travelTimes: Array<TimesType>;
  /** Work unit' type id */
  typeId?: Maybe<Scalars["Int"]["output"]>;
  usedParts: Array<PartType>;
  /** Work times list with start and end dates */
  workTimes: Array<TimesType>;
};

export type ServiceLevelType = {
  __typename?: "ServiceLevelType";
  /** The id of the Service Level. */
  id?: Maybe<Scalars["String"]["output"]>;
  /** The name of the Service Level. */
  name?: Maybe<Scalars["String"]["output"]>;
};

export type SortGroupType = {
  __typename?: "SortGroupType";
  code?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  sortId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SortGroupValueType = {
  __typename?: "SortGroupValueType";
  description: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SortGroupsType = {
  __typename?: "SortGroupsType";
  /** Sort Group 1 */
  sortGroup1?: Maybe<SortGroupType>;
  /** Sort Group 1 Values */
  sortGroup1Values?: Maybe<Array<SortGroupType>>;
  /** Sort Group 2 */
  sortGroup2?: Maybe<SortGroupType>;
  /** Sort Group 2 Values */
  sortGroup2Values?: Maybe<Array<SortGroupType>>;
  /** Sort Group 3 */
  sortGroup3?: Maybe<SortGroupType>;
  /** Sort Group 3 Values */
  sortGroup3Values?: Maybe<Array<SortGroupType>>;
  /** Sort Group 4 */
  sortGroup4?: Maybe<SortGroupType>;
  /** Sort Group 4 Values */
  sortGroup4Values?: Maybe<Array<SortGroupType>>;
  /** Sort Group 5 */
  sortGroup5?: Maybe<SortGroupType>;
  /** Sort Group 5 Values */
  sortGroup5Values?: Maybe<Array<SortGroupType>>;
};

export type SpecificationType = {
  __typename?: "SpecificationType";
  description: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  location?: Maybe<Scalars["String"]["output"]>;
  partNumber: Scalars["String"]["output"];
};

export type Stock = {
  __typename?: "Stock";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export enum StockStore {
  Customer = "CUSTOMER",
  Engineer = "ENGINEER",
  Locational = "LOCATIONAL",
  Nonstock = "NONSTOCK",
  Other = "OTHER",
}

export type SupplierType = {
  __typename?: "SupplierType";
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  supplierNo: Scalars["String"]["output"];
};

export type TimesInputType = {
  /** Times object start time */
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Times object stop time */
  stopTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TimesType = {
  __typename?: "TimesType";
  /** Times object start time */
  startTime?: Maybe<Scalars["DateTime"]["output"]>;
  /** Times object stop time */
  stopTime?: Maybe<Scalars["DateTime"]["output"]>;
};

export type UserConsentInputType = {
  accepted: Scalars["Boolean"]["input"];
  type: UserConsentTypeEnum;
  version: Scalars["Int"]["input"];
};

export type UserConsentType = {
  __typename?: "UserConsentType";
  accepted: Scalars["Boolean"]["output"];
  type: UserConsentTypeEnum;
  version: Scalars["Int"]["output"];
};

export enum UserConsentTypeEnum {
  Basic = "Basic",
  Tracking = "Tracking",
}

export type UserEndpointResult = {
  __typename?: "UserEndpointResult";
  email: Scalars["String"]["output"];
  lastEndpoint?: Maybe<Endpoint>;
  useMultipleEndpoints: Scalars["Boolean"]["output"];
  userEndpoints: Array<Endpoint>;
};

export type UserSettings = {
  __typename?: "UserSettings";
  email: Scalars["String"]["output"];
  lastEndpoint?: Maybe<Endpoint>;
  useMultipleEndpoints: Scalars["Boolean"]["output"];
  userEndpoints: Array<Endpoint>;
};

export type UserSettingsInputType = {
  lastEndpoint?: InputMaybe<EndpointInputType>;
  useMultipleEndpoints?: InputMaybe<Scalars["Boolean"]["input"]>;
  userEndpoints: Array<EndpointInputType>;
};

export type WorkNoteArgsInputType = {
  contractId?: InputMaybe<Scalars["Int"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
  jobId?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkNoteInputType = {
  isAlert: Scalars["Boolean"]["input"];
  loggedBy: Scalars["String"]["input"];
  loggedDate: Scalars["DateTime"]["input"];
  text: Scalars["String"]["input"];
};

export type WorkNoteType = {
  __typename?: "WorkNoteType";
  hideHeader?: Maybe<Scalars["Boolean"]["output"]>;
  isAlert: Scalars["Boolean"]["output"];
  isInternal: Scalars["Boolean"]["output"];
  loggedBy: Scalars["String"]["output"];
  loggedDate: Scalars["DateTime"]["output"];
  text: Scalars["String"]["output"];
  type?: Maybe<WorkNoteTypeEnum>;
};

export enum WorkNoteTypeEnum {
  Contract = "Contract",
  Customer = "Customer",
  Equipment = "Equipment",
  Ticket = "Ticket",
}

export type WorkTaskInputType = {
  /** The work task's completed date field */
  completedDate?: InputMaybe<Scalars["DateTimeOffset"]["input"]>;
  /** The work task's description field */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** The work task's id field */
  id: Scalars["Int"]["input"];
};

export type WorkTaskType = {
  __typename?: "WorkTaskType";
  /** The completed date of the work task. */
  completedDate?: Maybe<Scalars["DateTimeOffset"]["output"]>;
  /** The description of the work task. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The id of the work task. */
  id: Scalars["Int"]["output"];
  /** The name of the work task. */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The sequence of the work task. */
  sequence: Scalars["Int"]["output"];
};

export type ContractStatus = {
  __typename?: "contractStatus";
  contractId?: Maybe<Scalars["Int"]["output"]>;
  customerId?: Maybe<Scalars["String"]["output"]>;
};

export type EndpointFragmentFragment = {
  __typename: "Endpoint";
  id: string;
  apiUrl: string;
  apiVersion: string;
  product: Product;
};

export type JobFragmentFragment = {
  __typename: "ServiceJob";
  status?: JobStatus | null | undefined;
  id: string;
  engineerId?: string | null | undefined;
  engineerName?: string | null | undefined;
  externalId: string;
  responseDate?: string | null | undefined;
  product?: Product | null | undefined;
  symptomDescription?: string | null | undefined;
  symptoms: Array<string>;
  requireMeterReading?: boolean | null | undefined;
  canTravel: boolean;
  category?: string | null | undefined;
  priority?: string | null | undefined;
  loggedDate?: string | null | undefined;
  typeId?: number | null | undefined;
  categoryId?: number | null | undefined;
  contractId?: number | null | undefined;
  completedDate?: string | null | undefined;
  serviceLevel?: string | null | undefined;
  chargeBand?: string | null | undefined;
  travelETA?: string | null | undefined;
  totalMileage?: number | null | undefined;
  solutionDescription?: string | null | undefined;
  customerSignerName?: string | null | undefined;
  plannedDate?:
    | {
        __typename?: "TimesType";
        startTime?: string | null | undefined;
        stopTime?: string | null | undefined;
      }
    | null
    | undefined;
  equipment?:
    | {
        __typename?: "EquipmentType";
        id?: string | null | undefined;
        name?: string | null | undefined;
        location?: string | null | undefined;
        assetNumber?: string | null | undefined;
        serialNumber?: string | null | undefined;
        modelGuid?: string | null | undefined;
        addressRowNo?: number | null | undefined;
        increaseAboveLimit?: number | null | undefined;
        increaseLimit?: number | null | undefined;
        installDate?: string | null | undefined;
        itemId?: number | null | undefined;
        details?:
          | {
              __typename?: "EquipmentDetailsType";
              information?: string | null | undefined;
              nextServiceDate?: string | null | undefined;
              lastServiceDate?: string | null | undefined;
              buildStandard?: string | null | undefined;
              systemProtocol?: string | null | undefined;
              signalType?: string | null | undefined;
              securityGrade?: string | null | undefined;
              gradeOfNotification?: string | null | undefined;
              notificationOption?: string | null | undefined;
              fireAlarmCategory?: string | null | undefined;
              fireAlarmType?: string | null | undefined;
              certificateNumber?: string | null | undefined;
              certificateDate?: string | null | undefined;
              isRemotelyMonitored?: boolean | null | undefined;
              authority?: string | null | undefined;
              verificationMethod?: string | null | undefined;
              alarmReceivingCentre?: string | null | undefined;
              alarmReceivingCentreDigiNumber?: string | null | undefined;
              alarmReceivingCentreReferenceNumber?: string | null | undefined;
              stuNumber?: string | null | undefined;
              remoteIP?: string | null | undefined;
              customProperties?:
                | Array<{
                    __typename?: "CustomPropertyType";
                    code: string;
                    description: string;
                    value?: string | null | undefined;
                    propertyGuid?: string | null | undefined;
                    sourceGuid?: string | null | undefined;
                    type: string;
                  }>
                | null
                | undefined;
              sortGroups?:
                | Array<{
                    __typename?: "SortGroupValueType";
                    description: string;
                    value?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        specifications: Array<{
          __typename?: "SpecificationType";
          id: number;
          description: string;
          location?: string | null | undefined;
          partNumber: string;
        }>;
      }
    | null
    | undefined;
  travelTimes: Array<{
    __typename?: "TimesType";
    startTime?: string | null | undefined;
    stopTime?: string | null | undefined;
  }>;
  usedParts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    description?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    status?: PartStatus | null | undefined;
    stockName?: string | null | undefined;
    stockId?: string | null | undefined;
    manufacturerId?: number | null | undefined;
  }>;
  preOrderedParts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    description?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    status?: PartStatus | null | undefined;
    stockName?: string | null | undefined;
    stockId?: string | null | undefined;
    manufacturerId?: number | null | undefined;
  }>;
  cause?:
    | {
        __typename: "CauseType";
        id?: string | null | undefined;
        name?: string | null | undefined;
        code?: string | null | undefined;
      }
    | null
    | undefined;
  actions: Array<{
    __typename: "ActionType";
    id?: string | null | undefined;
    name?: string | null | undefined;
    code?: string | null | undefined;
    sortId?: string | null | undefined;
  }>;
  workTimes: Array<{
    __typename?: "TimesType";
    startTime?: string | null | undefined;
    stopTime?: string | null | undefined;
  }>;
  customer?:
    | {
        __typename: "CustomerType";
        name?: string | null | undefined;
        id: string;
        isOnStop: boolean;
        salesRegion?: string | null | undefined;
        serviceRegion?: string | null | undefined;
        isVIP?: boolean | null | undefined;
        customerNo?: string | null | undefined;
        address?:
          | {
              __typename?: "AddressType";
              name?: string | null | undefined;
              address1?: string | null | undefined;
              address2?: string | null | undefined;
              address3?: string | null | undefined;
              postalCode?: string | null | undefined;
              postalPlace?: string | null | undefined;
              country?: string | null | undefined;
              city?: string | null | undefined;
            }
          | null
          | undefined;
        contacts: Array<{
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
  preferredContact?:
    | {
        __typename: "CustomerContactType";
        name?: string | null | undefined;
        email?: string | null | undefined;
        phoneNumber?: string | null | undefined;
        mobileNumber?: string | null | undefined;
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
      }
    | null
    | undefined;
  meters: Array<{
    __typename?: "MeterType";
    name?: string | null | undefined;
    typeId?: string | null | undefined;
    unitsPerDay?: number | null | undefined;
    sequence?: number | null | undefined;
    currentReading?:
      | {
          __typename?: "MeterReadingType";
          reading?: number | null | undefined;
          readingDate: string;
        }
      | null
      | undefined;
    previousReading?:
      | {
          __typename?: "MeterReadingType";
          reading?: number | null | undefined;
          readingDate: string;
        }
      | null
      | undefined;
  }>;
  tasks: Array<{
    __typename?: "WorkTaskType";
    id: number;
    name?: string | null | undefined;
    description?: string | null | undefined;
    completedDate?: string | null | undefined;
    sequence: number;
  }>;
  checklists: Array<{
    __typename?: "ChecklistType";
    checklistCode?: string | null | undefined;
    title: string;
    description?: string | null | undefined;
    isTemplate?: boolean | null | undefined;
    machineGuid?: string | null | undefined;
    questions: Array<{
      __typename?: "ChecklistQuestionType";
      nodeKey?: string | null | undefined;
      question: string;
      dataType: QuestionDataType;
      isRequired: boolean;
      isRequiredPreStart: boolean;
      answer?: string | null | undefined;
      minValue?: number | null | undefined;
      maxValue?: number | null | undefined;
      showComment: boolean;
      comment?: string | null | undefined;
      parent?: string | null | undefined;
      showCondition?: boolean | null | undefined;
      dropDownValues: Array<string>;
      expression?: string | null | undefined;
      expressionParams: Array<{
        __typename?: "ExpressionParameterType";
        key: string;
        description?: string | null | undefined;
        value?: number | null | undefined;
      }>;
    }>;
  }>;
};

export type LoginFragmentFragment = {
  __typename: "LoginResult";
  id: string;
  expiry: string;
  token: string;
  email: string;
  product: Product;
  externalId: string;
  languageCode?: string | null | undefined;
  stockId?: string | null | undefined;
  suppliedStoreId?: number | null | undefined;
  externalGuid?: string | null | undefined;
  fullName?: string | null | undefined;
  userConsents: Array<{
    __typename?: "UserConsentType";
    accepted: boolean;
    version: number;
    type: UserConsentTypeEnum;
  }>;
};

export type AddChecklistMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  checklistCode: Scalars["String"]["input"];
}>;

export type AddChecklistMutation = {
  __typename?: "AsolviMobileMutation";
  addChecklist?:
    | {
        __typename?: "ChecklistType";
        checklistCode?: string | null | undefined;
        title: string;
        description?: string | null | undefined;
        isTemplate?: boolean | null | undefined;
        machineGuid?: string | null | undefined;
        questions: Array<{
          __typename?: "ChecklistQuestionType";
          nodeKey?: string | null | undefined;
          question: string;
          dataType: QuestionDataType;
          isRequired: boolean;
          isRequiredPreStart: boolean;
          answer?: string | null | undefined;
          minValue?: number | null | undefined;
          maxValue?: number | null | undefined;
          showComment: boolean;
          comment?: string | null | undefined;
          parent?: string | null | undefined;
          showCondition?: boolean | null | undefined;
          dropDownValues: Array<string>;
          expression?: string | null | undefined;
          expressionParams: Array<{
            __typename?: "ExpressionParameterType";
            key: string;
            description?: string | null | undefined;
            value?: number | null | undefined;
          }>;
        }>;
      }
    | null
    | undefined;
};

export type AddUserConsentMutationVariables = Exact<{
  userConsents: Array<UserConsentInputType> | UserConsentInputType;
}>;

export type AddUserConsentMutation = {
  __typename?: "AsolviMobileMutation";
  addUserConsent: Array<{
    __typename?: "UserConsentType";
    accepted: boolean;
    version: number;
    type: UserConsentTypeEnum;
  }>;
};

export type AddWorkNoteMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  workNote: WorkNoteInputType;
}>;

export type AddWorkNoteMutation = {
  __typename?: "AsolviMobileMutation";
  addWorkNote?:
    | {
        __typename?: "WorkNoteType";
        loggedDate: string;
        loggedBy: string;
        text: string;
        isAlert: boolean;
        isInternal: boolean;
      }
    | null
    | undefined;
};

export type AssignTaskMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  engineerId: Scalars["String"]["input"];
  scheduleStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type AssignTaskMutation = {
  __typename?: "AsolviMobileMutation";
  assignTask?: boolean | null | undefined;
};

export type Auth0LoginMutationVariables = Exact<{
  url: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  accessToken: Scalars["String"]["input"];
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  product: Product;
}>;

export type Auth0LoginMutation = {
  __typename?: "AsolviMobileMutation";
  auth0Login?:
    | {
        __typename: "LoginResult";
        id: string;
        expiry: string;
        token: string;
        email: string;
        product: Product;
        externalId: string;
        languageCode?: string | null | undefined;
        stockId?: string | null | undefined;
        suppliedStoreId?: number | null | undefined;
        externalGuid?: string | null | undefined;
        fullName?: string | null | undefined;
        userConsents: Array<{
          __typename?: "UserConsentType";
          accepted: boolean;
          version: number;
          type: UserConsentTypeEnum;
        }>;
      }
    | null
    | undefined;
};

export type ChangeJobEquipmentMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  equipmentId: Scalars["String"]["input"];
}>;

export type ChangeJobEquipmentMutation = {
  __typename?: "AsolviMobileMutation";
  changeJobEquipment?: boolean | null | undefined;
};

export type CompleteVisitWithPartsMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  visit: JobVisitInput;
  followUp: FollowUpInputType;
  parts: Array<PartInputType> | PartInputType;
  extras: Array<ExtraInputType> | ExtraInputType;
  files: Array<FileInputType> | FileInputType;
  workTasks: Array<WorkTaskInputType> | WorkTaskInputType;
  newWorkUnitId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CompleteVisitWithPartsMutation = {
  __typename?: "AsolviMobileMutation";
  completeVisitWithParts?:
    | { __typename?: "ServiceJob"; id: string; externalId: string }
    | null
    | undefined;
};

export type CreateJobMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobDetails: JobInputType;
  files: Array<FileInputType> | FileInputType;
}>;

export type CreateJobMutation = {
  __typename?: "AsolviMobileMutation";
  createServiceJob?:
    | { __typename?: "CreateJobResult"; jobId?: string | null | undefined }
    | null
    | undefined;
};

export type EditContactMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  customerId: Scalars["String"]["input"];
  equipmentId: Scalars["String"]["input"];
  contact: ContactInputType;
}>;

export type EditContactMutation = {
  __typename?: "AsolviMobileMutation";
  updateContact?: boolean | null | undefined;
};

export type FeedbackMutationVariables = Exact<{
  rate?: InputMaybe<Scalars["Int"]["input"]>;
  feedback?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FeedbackMutation = {
  __typename?: "AsolviMobileMutation";
  feedback?: boolean | null | undefined;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  product: Product;
}>;

export type LoginMutation = {
  __typename?: "AsolviMobileMutation";
  login?:
    | {
        __typename: "LoginResult";
        id: string;
        expiry: string;
        token: string;
        email: string;
        product: Product;
        externalId: string;
        languageCode?: string | null | undefined;
        stockId?: string | null | undefined;
        suppliedStoreId?: number | null | undefined;
        externalGuid?: string | null | undefined;
        fullName?: string | null | undefined;
        userConsents: Array<{
          __typename?: "UserConsentType";
          accepted: boolean;
          version: number;
          type: UserConsentTypeEnum;
        }>;
      }
    | null
    | undefined;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: "AsolviMobileMutation";
  logout?: { __typename?: "LogoutResult"; message: string } | null | undefined;
};

export type PreLoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
  product: Product;
}>;

export type PreLoginMutation = {
  __typename?: "AsolviMobileMutation";
  preLogin?:
    | {
        __typename?: "PreLoginResult";
        email: string;
        url?: string | null | undefined;
        product?: Product | null | undefined;
        clientId?: string | null | undefined;
        domain?: string | null | undefined;
        audience?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RejectJobAssignmentMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  rejectReason: Scalars["String"]["input"];
}>;

export type RejectJobAssignmentMutation = {
  __typename?: "AsolviMobileMutation";
  rejectJobAssignment?: boolean | null | undefined;
};

export type StartVisitWorkMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  startTime: Scalars["DateTime"]["input"];
  travelStopTime?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type StartVisitWorkMutation = {
  __typename?: "AsolviMobileMutation";
  startVisitWork?:
    | {
        __typename: "ServiceJob";
        id: string;
        workTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type UpdateEngineerMetadataMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
}>;

export type UpdateEngineerMetadataMutation = {
  __typename?: "AsolviMobileMutation";
  updateEngineerMetadata?: boolean | null | undefined;
};

export type UpdateEquipmentMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  equipment: EquipmentInputType;
  jobId: Scalars["String"]["input"];
}>;

export type UpdateEquipmentMutation = {
  __typename?: "AsolviMobileMutation";
  updateEquipment?: boolean | null | undefined;
};

export type UpdateMachineCustomPropertiesMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  properties: Array<PropertyValueInputType> | PropertyValueInputType;
}>;

export type UpdateMachineCustomPropertiesMutation = {
  __typename?: "AsolviMobileMutation";
  updateMachineCustomProperties?:
    | {
        __typename: "ServiceJob";
        id: string;
        equipment?:
          | {
              __typename?: "EquipmentType";
              id?: string | null | undefined;
              name?: string | null | undefined;
              location?: string | null | undefined;
              assetNumber?: string | null | undefined;
              serialNumber?: string | null | undefined;
              modelGuid?: string | null | undefined;
              addressRowNo?: number | null | undefined;
              increaseAboveLimit?: number | null | undefined;
              increaseLimit?: number | null | undefined;
              installDate?: string | null | undefined;
              details?:
                | {
                    __typename?: "EquipmentDetailsType";
                    nextServiceDate?: string | null | undefined;
                    lastServiceDate?: string | null | undefined;
                    buildStandard?: string | null | undefined;
                    systemProtocol?: string | null | undefined;
                    signalType?: string | null | undefined;
                    securityGrade?: string | null | undefined;
                    gradeOfNotification?: string | null | undefined;
                    notificationOption?: string | null | undefined;
                    fireAlarmCategory?: string | null | undefined;
                    fireAlarmType?: string | null | undefined;
                    certificateNumber?: string | null | undefined;
                    certificateDate?: string | null | undefined;
                    isRemotelyMonitored?: boolean | null | undefined;
                    authority?: string | null | undefined;
                    verificationMethod?: string | null | undefined;
                    alarmReceivingCentre?: string | null | undefined;
                    alarmReceivingCentreDigiNumber?: string | null | undefined;
                    alarmReceivingCentreReferenceNumber?: string | null | undefined;
                    stuNumber?: string | null | undefined;
                    remoteIP?: string | null | undefined;
                    customProperties?:
                      | Array<{
                          __typename?: "CustomPropertyType";
                          code: string;
                          description: string;
                          value?: string | null | undefined;
                          propertyGuid?: string | null | undefined;
                          sourceGuid?: string | null | undefined;
                          type: string;
                        }>
                      | null
                      | undefined;
                    sortGroups?:
                      | Array<{
                          __typename?: "SortGroupValueType";
                          description: string;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateJobStatusMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  status: JobStatus;
}>;

export type UpdateJobStatusMutation = {
  __typename?: "AsolviMobileMutation";
  updateJobStatus?:
    | { __typename?: "ServiceJob"; id: string; status?: JobStatus | null | undefined }
    | null
    | undefined;
};

export type UpdateMetersMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  meters: Array<MeterReadingInputType> | MeterReadingInputType;
  jobId: Scalars["String"]["input"];
}>;

export type UpdateMetersMutation = {
  __typename?: "AsolviMobileMutation";
  updateMeters?: boolean | null | undefined;
};

export type UpdatePlannedDateMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  times: TimesInputType;
}>;

export type UpdatePlannedDateMutation = {
  __typename?: "AsolviMobileMutation";
  updatePlannedDate?: boolean | null | undefined;
};

export type UpdateTransitPartsMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  partIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type UpdateTransitPartsMutation = {
  __typename?: "AsolviMobileMutation";
  updateTransitParts?: boolean | null | undefined;
};

export type UpdateUserSettingsMutationVariables = Exact<{
  userSettings?: InputMaybe<UserSettingsInputType>;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: "AsolviMobileMutation";
  userSettings: {
    __typename?: "UserSettings";
    email: string;
    useMultipleEndpoints: boolean;
    lastEndpoint?:
      | { __typename: "Endpoint"; id: string; apiUrl: string; apiVersion: string; product: Product }
      | null
      | undefined;
    userEndpoints: Array<{
      __typename: "Endpoint";
      id: string;
      apiUrl: string;
      apiVersion: string;
      product: Product;
    }>;
  };
};

export type UpdateVisitInformationMutationVariables = Exact<{
  jobId: Scalars["String"]["input"];
  miles?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UpdateVisitInformationMutation = {
  __typename?: "AsolviMobileMutation";
  updateVisitInformation?:
    | { __typename?: "ServiceJob"; id: string; totalMileage?: number | null | undefined }
    | null
    | undefined;
};

export type VisitTravelFragmentFragment = {
  __typename: "ServiceJob";
  id: string;
  travelETA?: string | null | undefined;
  totalMileage?: number | null | undefined;
  status?: JobStatus | null | undefined;
  travelTimes: Array<{
    __typename?: "TimesType";
    startTime?: string | null | undefined;
    stopTime?: string | null | undefined;
  }>;
};

export type StartVisitTravelMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  startTime: Scalars["DateTime"]["input"];
}>;

export type StartVisitTravelMutation = {
  __typename?: "AsolviMobileMutation";
  startVisitTravel?:
    | {
        __typename: "ServiceJob";
        id: string;
        travelETA?: string | null | undefined;
        totalMileage?: number | null | undefined;
        status?: JobStatus | null | undefined;
        travelTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type UpdateVisitTravelMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  travelTimes: Array<InputMaybe<TimesInputType>> | InputMaybe<TimesInputType>;
  miles?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type UpdateVisitTravelMutation = {
  __typename?: "AsolviMobileMutation";
  updateVisitTravel?:
    | {
        __typename: "ServiceJob";
        id: string;
        travelETA?: string | null | undefined;
        totalMileage?: number | null | undefined;
        status?: JobStatus | null | undefined;
        travelTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type UpdateEtaMutationVariables = Exact<{
  metadata?: InputMaybe<MetadataInputType>;
  jobId: Scalars["String"]["input"];
  eta: Scalars["DateTime"]["input"];
}>;

export type UpdateEtaMutation = {
  __typename?: "AsolviMobileMutation";
  updateETA?:
    | {
        __typename: "ServiceJob";
        id: string;
        travelETA?: string | null | undefined;
        totalMileage?: number | null | undefined;
        status?: JobStatus | null | undefined;
        travelTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetActionsAndCausesQueryVariables = Exact<{ [key: string]: never }>;

export type GetActionsAndCausesQuery = {
  __typename?: "Query";
  jobActions: Array<{
    __typename?: "ActionType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    sortId?: string | null | undefined;
    subActionCodes?: Array<string> | null | undefined;
    typeId?: number | null | undefined;
  }>;
  jobCauses: Array<{
    __typename?: "CauseType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
};

export type GetAvailablePartsQueryVariables = Exact<{
  locations: Array<PartQueryInputType> | PartQueryInputType;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetAvailablePartsQuery = {
  __typename?: "Query";
  parts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    description?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    isNonStock?: boolean | null | undefined;
    stockName?: string | null | undefined;
    stockId?: string | null | undefined;
    manufacturerId?: number | null | undefined;
    sortGroup1?: string | null | undefined;
    sortGroup2?: string | null | undefined;
    sortGroup3?: string | null | undefined;
    sortGroup4?: string | null | undefined;
    sortGroup5?: string | null | undefined;
    supplierId?: string | null | undefined;
    supplierName?: string | null | undefined;
    supplierNo?: string | null | undefined;
    salesPrice?: number | null | undefined;
  }>;
};

export type GetCachePrefillQueryVariables = Exact<{ [key: string]: never }>;

export type GetCachePrefillQuery = {
  __typename?: "Query";
  extras: Array<{
    __typename?: "ExtraType";
    id: string;
    code: string;
    company: string;
    department: string;
    isTime: boolean;
    quantity: number;
    servicesCode: string;
    type: string;
  }>;
  jobActions: Array<{
    __typename?: "ActionType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    sortId?: string | null | undefined;
    subActionCodes?: Array<string> | null | undefined;
    typeId?: number | null | undefined;
  }>;
  jobCauses: Array<{
    __typename?: "CauseType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
  jobCategories: Array<{
    __typename?: "JobCategoryType";
    id?: number | null | undefined;
    text?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
  symptoms: Array<{
    __typename?: "JobSymptomType";
    code?: string | null | undefined;
    description?: string | null | undefined;
    typeId?: number | null | undefined;
    sortId?: string | null | undefined;
  }>;
  manufacturers: Array<{
    __typename?: "ManufacturerType";
    id: number;
    name?: string | null | undefined;
  }>;
  serviceLevels: Array<{
    __typename?: "ServiceLevelType";
    name?: string | null | undefined;
    id?: string | null | undefined;
  }>;
  suppliers: Array<{
    __typename?: "SupplierType";
    id: string;
    name?: string | null | undefined;
    supplierNo: string;
  }>;
  partSortGroups: {
    __typename?: "SortGroupsType";
    sortGroup1?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup1Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup2?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup2Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup3?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup3Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup4?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup4Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup5?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup5Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetChecklistGroupsQueryVariables = Exact<{
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetChecklistGroupsQuery = {
  __typename?: "Query";
  checklistGroups: Array<{
    __typename?: "ChecklistGroupsType";
    company?: string | null | undefined;
    code?: string | null | undefined;
    description?: string | null | undefined;
  }>;
};

export type GetContactsQueryVariables = Exact<{
  customerId: Scalars["String"]["input"];
}>;

export type GetContactsQuery = {
  __typename?: "Query";
  contacts: Array<{
    __typename?: "ContactType";
    id: string;
    name: string;
    email: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    phone?: string | null | undefined;
    mobile?: string | null | undefined;
    contactNo?: string | null | undefined;
  }>;
};

export type GetCreateJobOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCreateJobOptionsQuery = {
  __typename?: "Query";
  createJobOptions?:
    | {
        __typename?: "JobOptionsType";
        categories: Array<{
          __typename?: "JobCategoryType";
          text?: string | null | undefined;
          typeId?: number | null | undefined;
          id?: number | null | undefined;
        }>;
        symptoms: Array<{
          __typename?: "JobSymptomType";
          code?: string | null | undefined;
          description?: string | null | undefined;
          sortId?: string | null | undefined;
          subSymptomCodes?: Array<string> | null | undefined;
          typeId?: number | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetCustomersQueryVariables = Exact<{
  nameFilter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCustomersQuery = {
  __typename?: "Query";
  customers: Array<{
    __typename?: "CustomerType";
    id: string;
    name?: string | null | undefined;
    isVIP?: boolean | null | undefined;
    isOnStop: boolean;
    serviceRegion?: string | null | undefined;
    salesRegion?: string | null | undefined;
    customerNo?: string | null | undefined;
    address?:
      | {
          __typename?: "AddressType";
          name?: string | null | undefined;
          address1?: string | null | undefined;
          address2?: string | null | undefined;
          address3?: string | null | undefined;
          postalCode?: string | null | undefined;
          postalPlace?: string | null | undefined;
          country?: string | null | undefined;
          city?: string | null | undefined;
        }
      | null
      | undefined;
    contacts: Array<{
      __typename?: "CustomerContactType";
      id: string;
      name?: string | null | undefined;
      phoneNumber?: string | null | undefined;
      mobileNumber?: string | null | undefined;
      email?: string | null | undefined;
    }>;
  }>;
};

export type GetCustomersSimpleQueryVariables = Exact<{
  nameFilter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCustomersSimpleQuery = {
  __typename?: "Query";
  customers: Array<{
    __typename?: "CustomerType";
    id: string;
    name?: string | null | undefined;
    address?:
      | {
          __typename?: "AddressType";
          name?: string | null | undefined;
          address1?: string | null | undefined;
          address2?: string | null | undefined;
          address3?: string | null | undefined;
          postalCode?: string | null | undefined;
          postalPlace?: string | null | undefined;
          country?: string | null | undefined;
          city?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetCustomerQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCustomerQuery = {
  __typename?: "Query";
  customer?:
    | {
        __typename?: "CustomerType";
        id: string;
        name?: string | null | undefined;
        isVIP?: boolean | null | undefined;
        isOnStop: boolean;
        serviceRegion?: string | null | undefined;
        salesRegion?: string | null | undefined;
        customerNo?: string | null | undefined;
        address?:
          | {
              __typename?: "AddressType";
              name?: string | null | undefined;
              address1?: string | null | undefined;
              address2?: string | null | undefined;
              address3?: string | null | undefined;
              postalCode?: string | null | undefined;
              postalPlace?: string | null | undefined;
              country?: string | null | undefined;
              city?: string | null | undefined;
            }
          | null
          | undefined;
        contacts: Array<{
          __typename?: "CustomerContactType";
          id: string;
          name?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          email?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetEngineerSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEngineerSettingsQuery = {
  __typename?: "Query";
  engineerSettings: {
    __typename?: "EngineerSettings";
    canCreateJob: boolean;
    requireEngineerSignature: boolean;
    canUseTransitManager: boolean;
    isSupervisor: boolean;
    isEquipmentMandatory: boolean;
    sendMailToCustomer: boolean;
    canEditCustomEquipmentProps: boolean;
    canChangeEquipmentOnTicket: boolean;
    canChangeServiceLevel: boolean;
    allowNegativeStock: boolean;
    requiredSolutionDescription: boolean;
    allowSalesPrice: boolean;
  };
};

export type GetEngineersQueryVariables = Exact<{ [key: string]: never }>;

export type GetEngineersQuery = {
  __typename?: "Query";
  engineers: Array<{ __typename?: "EngineerType"; id: string; name?: string | null | undefined }>;
};

export type GetEquipmentQueryVariables = Exact<{
  serialNumber?: InputMaybe<Scalars["String"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetEquipmentQuery = {
  __typename?: "Query";
  equipment: Array<{
    __typename?: "EquipmentType";
    id?: string | null | undefined;
    name?: string | null | undefined;
    increaseAboveLimit?: number | null | undefined;
    increaseLimit?: number | null | undefined;
    itemId?: number | null | undefined;
    assetNumber?: string | null | undefined;
    serialNumber?: string | null | undefined;
    location?: string | null | undefined;
    customerId?: string | null | undefined;
    customerName?: string | null | undefined;
    warrantyServiceLevelId?: number | null | undefined;
    warrantyStartDate?: string | null | undefined;
    warrantyEndDate?: string | null | undefined;
    address?:
      | {
          __typename?: "AddressType";
          name?: string | null | undefined;
          address1?: string | null | undefined;
          address2?: string | null | undefined;
          address3?: string | null | undefined;
          postalCode?: string | null | undefined;
          postalPlace?: string | null | undefined;
          country?: string | null | undefined;
          city?: string | null | undefined;
        }
      | null
      | undefined;
    specifications: Array<{
      __typename?: "SpecificationType";
      id: number;
      description: string;
      location?: string | null | undefined;
      partNumber: string;
    }>;
  }>;
};

export type GetEquipmentContractStatusQueryVariables = Exact<{
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetEquipmentContractStatusQuery = {
  __typename?: "Query";
  equipmentContractStatus?:
    | {
        __typename?: "contractStatus";
        customerId?: string | null | undefined;
        contractId?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetExtrasQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtrasQuery = {
  __typename?: "Query";
  extras: Array<{
    __typename?: "ExtraType";
    id: string;
    code: string;
    company: string;
    department: string;
    isTime: boolean;
    quantity: number;
    servicesCode: string;
    type: string;
  }>;
};

export type GetFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeatureFlagsQuery = {
  __typename?: "Query";
  featureFlags: Array<{ __typename?: "FeatureFlagType"; name: string; isActive: boolean }>;
};

export type GetPublicFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublicFeatureFlagsQuery = {
  __typename?: "Query";
  publicFeatureFlags: Array<{ __typename?: "FeatureFlagType"; name: string; isActive: boolean }>;
};

export type GetFilesQueryVariables = Exact<{
  jobId: Scalars["ID"]["input"];
}>;

export type GetFilesQuery = {
  __typename?: "Query";
  files: Array<{
    __typename?: "FileType";
    id: string;
    data: string;
    description?: string | null | undefined;
    name: string;
    size?: number | null | undefined;
  }>;
};

export type GetEngineerHistoryQueryVariables = Exact<{
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  specificDate?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type GetEngineerHistoryQuery = {
  __typename?: "Query";
  engineerHistory: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetServiceHistoryQueryVariables = Exact<{
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
  fromDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  specificDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  customerId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetServiceHistoryQuery = {
  __typename?: "Query";
  serviceHistory: Array<{
    __typename?: "ServiceHistory";
    serviceJobId?: string | null | undefined;
    loggedDate: string;
    completedDate: string;
    workUnitId?: number | null | undefined;
    lastStatusName?: string | null | undefined;
    engineerName: string;
    customerName: string;
    actionCodes: Array<string>;
    symptoms: Array<string>;
    category?: string | null | undefined;
    causeName?: string | null | undefined;
    priority?: string | null | undefined;
    externalId?: string | null | undefined;
    isLegacy?: boolean | null | undefined;
    parts?: string | null | undefined;
  }>;
};

export type GetInitialJobQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetInitialJobQuery = {
  __typename?: "Query";
  job?:
    | {
        __typename: "ServiceJob";
        status?: JobStatus | null | undefined;
        id: string;
        engineerId?: string | null | undefined;
        engineerName?: string | null | undefined;
        externalId: string;
        responseDate?: string | null | undefined;
        product?: Product | null | undefined;
        symptomDescription?: string | null | undefined;
        symptoms: Array<string>;
        requireMeterReading?: boolean | null | undefined;
        canTravel: boolean;
        category?: string | null | undefined;
        priority?: string | null | undefined;
        loggedDate?: string | null | undefined;
        typeId?: number | null | undefined;
        categoryId?: number | null | undefined;
        contractId?: number | null | undefined;
        completedDate?: string | null | undefined;
        serviceLevel?: string | null | undefined;
        chargeBand?: string | null | undefined;
        travelETA?: string | null | undefined;
        totalMileage?: number | null | undefined;
        solutionDescription?: string | null | undefined;
        customerSignerName?: string | null | undefined;
        plannedDate?:
          | {
              __typename?: "TimesType";
              startTime?: string | null | undefined;
              stopTime?: string | null | undefined;
            }
          | null
          | undefined;
        equipment?:
          | {
              __typename?: "EquipmentType";
              id?: string | null | undefined;
              name?: string | null | undefined;
              location?: string | null | undefined;
              assetNumber?: string | null | undefined;
              serialNumber?: string | null | undefined;
              modelGuid?: string | null | undefined;
              addressRowNo?: number | null | undefined;
              increaseAboveLimit?: number | null | undefined;
              increaseLimit?: number | null | undefined;
              installDate?: string | null | undefined;
              itemId?: number | null | undefined;
              details?:
                | {
                    __typename?: "EquipmentDetailsType";
                    information?: string | null | undefined;
                    nextServiceDate?: string | null | undefined;
                    lastServiceDate?: string | null | undefined;
                    buildStandard?: string | null | undefined;
                    systemProtocol?: string | null | undefined;
                    signalType?: string | null | undefined;
                    securityGrade?: string | null | undefined;
                    gradeOfNotification?: string | null | undefined;
                    notificationOption?: string | null | undefined;
                    fireAlarmCategory?: string | null | undefined;
                    fireAlarmType?: string | null | undefined;
                    certificateNumber?: string | null | undefined;
                    certificateDate?: string | null | undefined;
                    isRemotelyMonitored?: boolean | null | undefined;
                    authority?: string | null | undefined;
                    verificationMethod?: string | null | undefined;
                    alarmReceivingCentre?: string | null | undefined;
                    alarmReceivingCentreDigiNumber?: string | null | undefined;
                    alarmReceivingCentreReferenceNumber?: string | null | undefined;
                    stuNumber?: string | null | undefined;
                    remoteIP?: string | null | undefined;
                    customProperties?:
                      | Array<{
                          __typename?: "CustomPropertyType";
                          code: string;
                          description: string;
                          value?: string | null | undefined;
                          propertyGuid?: string | null | undefined;
                          sourceGuid?: string | null | undefined;
                          type: string;
                        }>
                      | null
                      | undefined;
                    sortGroups?:
                      | Array<{
                          __typename?: "SortGroupValueType";
                          description: string;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              specifications: Array<{
                __typename?: "SpecificationType";
                id: number;
                description: string;
                location?: string | null | undefined;
                partNumber: string;
              }>;
            }
          | null
          | undefined;
        travelTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
        usedParts: Array<{
          __typename?: "PartType";
          id?: string | null | undefined;
          description?: string | null | undefined;
          partNumber?: string | null | undefined;
          barcode?: string | null | undefined;
          quantity?: number | null | undefined;
          stockStore?: StockStore | null | undefined;
          status?: PartStatus | null | undefined;
          stockName?: string | null | undefined;
          stockId?: string | null | undefined;
          manufacturerId?: number | null | undefined;
        }>;
        preOrderedParts: Array<{
          __typename?: "PartType";
          id?: string | null | undefined;
          partNumber?: string | null | undefined;
          barcode?: string | null | undefined;
          description?: string | null | undefined;
          quantity?: number | null | undefined;
          stockStore?: StockStore | null | undefined;
          status?: PartStatus | null | undefined;
          stockName?: string | null | undefined;
          stockId?: string | null | undefined;
          manufacturerId?: number | null | undefined;
        }>;
        cause?:
          | {
              __typename: "CauseType";
              id?: string | null | undefined;
              name?: string | null | undefined;
              code?: string | null | undefined;
            }
          | null
          | undefined;
        actions: Array<{
          __typename: "ActionType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
          sortId?: string | null | undefined;
        }>;
        workTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
        customer?:
          | {
              __typename: "CustomerType";
              name?: string | null | undefined;
              id: string;
              isOnStop: boolean;
              salesRegion?: string | null | undefined;
              serviceRegion?: string | null | undefined;
              isVIP?: boolean | null | undefined;
              customerNo?: string | null | undefined;
              address?:
                | {
                    __typename?: "AddressType";
                    name?: string | null | undefined;
                    address1?: string | null | undefined;
                    address2?: string | null | undefined;
                    address3?: string | null | undefined;
                    postalCode?: string | null | undefined;
                    postalPlace?: string | null | undefined;
                    country?: string | null | undefined;
                    city?: string | null | undefined;
                  }
                | null
                | undefined;
              contacts: Array<{
                __typename: "CustomerContactType";
                name?: string | null | undefined;
                email?: string | null | undefined;
                phoneNumber?: string | null | undefined;
                mobileNumber?: string | null | undefined;
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
              }>;
            }
          | null
          | undefined;
        preferredContact?:
          | {
              __typename: "CustomerContactType";
              name?: string | null | undefined;
              email?: string | null | undefined;
              phoneNumber?: string | null | undefined;
              mobileNumber?: string | null | undefined;
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
            }
          | null
          | undefined;
        meters: Array<{
          __typename?: "MeterType";
          name?: string | null | undefined;
          typeId?: string | null | undefined;
          unitsPerDay?: number | null | undefined;
          sequence?: number | null | undefined;
          currentReading?:
            | {
                __typename?: "MeterReadingType";
                reading?: number | null | undefined;
                readingDate: string;
              }
            | null
            | undefined;
          previousReading?:
            | {
                __typename?: "MeterReadingType";
                reading?: number | null | undefined;
                readingDate: string;
              }
            | null
            | undefined;
        }>;
        tasks: Array<{
          __typename?: "WorkTaskType";
          id: number;
          name?: string | null | undefined;
          description?: string | null | undefined;
          completedDate?: string | null | undefined;
          sequence: number;
        }>;
        checklists: Array<{
          __typename?: "ChecklistType";
          checklistCode?: string | null | undefined;
          title: string;
          description?: string | null | undefined;
          isTemplate?: boolean | null | undefined;
          machineGuid?: string | null | undefined;
          questions: Array<{
            __typename?: "ChecklistQuestionType";
            nodeKey?: string | null | undefined;
            question: string;
            dataType: QuestionDataType;
            isRequired: boolean;
            isRequiredPreStart: boolean;
            answer?: string | null | undefined;
            minValue?: number | null | undefined;
            maxValue?: number | null | undefined;
            showComment: boolean;
            comment?: string | null | undefined;
            parent?: string | null | undefined;
            showCondition?: boolean | null | undefined;
            dropDownValues: Array<string>;
            expression?: string | null | undefined;
            expressionParams: Array<{
              __typename?: "ExpressionParameterType";
              key: string;
              description?: string | null | undefined;
              value?: number | null | undefined;
            }>;
          }>;
        }>;
      }
    | null
    | undefined;
  jobActions: Array<{
    __typename?: "ActionType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    sortId?: string | null | undefined;
    subActionCodes?: Array<string> | null | undefined;
    typeId?: number | null | undefined;
  }>;
  jobCauses: Array<{
    __typename?: "CauseType";
    name?: string | null | undefined;
    code?: string | null | undefined;
    id?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
  jobCategories: Array<{
    __typename?: "JobCategoryType";
    id?: number | null | undefined;
    text?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
  symptoms: Array<{
    __typename?: "JobSymptomType";
    code?: string | null | undefined;
    description?: string | null | undefined;
    typeId?: number | null | undefined;
    sortId?: string | null | undefined;
  }>;
};

export type GetJobQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetJobQuery = {
  __typename?: "Query";
  job?:
    | {
        __typename: "ServiceJob";
        status?: JobStatus | null | undefined;
        id: string;
        engineerId?: string | null | undefined;
        engineerName?: string | null | undefined;
        externalId: string;
        responseDate?: string | null | undefined;
        product?: Product | null | undefined;
        symptomDescription?: string | null | undefined;
        symptoms: Array<string>;
        requireMeterReading?: boolean | null | undefined;
        canTravel: boolean;
        category?: string | null | undefined;
        priority?: string | null | undefined;
        loggedDate?: string | null | undefined;
        typeId?: number | null | undefined;
        categoryId?: number | null | undefined;
        contractId?: number | null | undefined;
        completedDate?: string | null | undefined;
        serviceLevel?: string | null | undefined;
        chargeBand?: string | null | undefined;
        travelETA?: string | null | undefined;
        totalMileage?: number | null | undefined;
        solutionDescription?: string | null | undefined;
        customerSignerName?: string | null | undefined;
        plannedDate?:
          | {
              __typename?: "TimesType";
              startTime?: string | null | undefined;
              stopTime?: string | null | undefined;
            }
          | null
          | undefined;
        equipment?:
          | {
              __typename?: "EquipmentType";
              id?: string | null | undefined;
              name?: string | null | undefined;
              location?: string | null | undefined;
              assetNumber?: string | null | undefined;
              serialNumber?: string | null | undefined;
              modelGuid?: string | null | undefined;
              addressRowNo?: number | null | undefined;
              increaseAboveLimit?: number | null | undefined;
              increaseLimit?: number | null | undefined;
              installDate?: string | null | undefined;
              itemId?: number | null | undefined;
              details?:
                | {
                    __typename?: "EquipmentDetailsType";
                    information?: string | null | undefined;
                    nextServiceDate?: string | null | undefined;
                    lastServiceDate?: string | null | undefined;
                    buildStandard?: string | null | undefined;
                    systemProtocol?: string | null | undefined;
                    signalType?: string | null | undefined;
                    securityGrade?: string | null | undefined;
                    gradeOfNotification?: string | null | undefined;
                    notificationOption?: string | null | undefined;
                    fireAlarmCategory?: string | null | undefined;
                    fireAlarmType?: string | null | undefined;
                    certificateNumber?: string | null | undefined;
                    certificateDate?: string | null | undefined;
                    isRemotelyMonitored?: boolean | null | undefined;
                    authority?: string | null | undefined;
                    verificationMethod?: string | null | undefined;
                    alarmReceivingCentre?: string | null | undefined;
                    alarmReceivingCentreDigiNumber?: string | null | undefined;
                    alarmReceivingCentreReferenceNumber?: string | null | undefined;
                    stuNumber?: string | null | undefined;
                    remoteIP?: string | null | undefined;
                    customProperties?:
                      | Array<{
                          __typename?: "CustomPropertyType";
                          code: string;
                          description: string;
                          value?: string | null | undefined;
                          propertyGuid?: string | null | undefined;
                          sourceGuid?: string | null | undefined;
                          type: string;
                        }>
                      | null
                      | undefined;
                    sortGroups?:
                      | Array<{
                          __typename?: "SortGroupValueType";
                          description: string;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              specifications: Array<{
                __typename?: "SpecificationType";
                id: number;
                description: string;
                location?: string | null | undefined;
                partNumber: string;
              }>;
            }
          | null
          | undefined;
        travelTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
        usedParts: Array<{
          __typename?: "PartType";
          id?: string | null | undefined;
          description?: string | null | undefined;
          partNumber?: string | null | undefined;
          barcode?: string | null | undefined;
          quantity?: number | null | undefined;
          stockStore?: StockStore | null | undefined;
          status?: PartStatus | null | undefined;
          stockName?: string | null | undefined;
          stockId?: string | null | undefined;
          manufacturerId?: number | null | undefined;
        }>;
        preOrderedParts: Array<{
          __typename?: "PartType";
          id?: string | null | undefined;
          partNumber?: string | null | undefined;
          barcode?: string | null | undefined;
          description?: string | null | undefined;
          quantity?: number | null | undefined;
          stockStore?: StockStore | null | undefined;
          status?: PartStatus | null | undefined;
          stockName?: string | null | undefined;
          stockId?: string | null | undefined;
          manufacturerId?: number | null | undefined;
        }>;
        cause?:
          | {
              __typename: "CauseType";
              id?: string | null | undefined;
              name?: string | null | undefined;
              code?: string | null | undefined;
            }
          | null
          | undefined;
        actions: Array<{
          __typename: "ActionType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
          sortId?: string | null | undefined;
        }>;
        workTimes: Array<{
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }>;
        customer?:
          | {
              __typename: "CustomerType";
              name?: string | null | undefined;
              id: string;
              isOnStop: boolean;
              salesRegion?: string | null | undefined;
              serviceRegion?: string | null | undefined;
              isVIP?: boolean | null | undefined;
              customerNo?: string | null | undefined;
              address?:
                | {
                    __typename?: "AddressType";
                    name?: string | null | undefined;
                    address1?: string | null | undefined;
                    address2?: string | null | undefined;
                    address3?: string | null | undefined;
                    postalCode?: string | null | undefined;
                    postalPlace?: string | null | undefined;
                    country?: string | null | undefined;
                    city?: string | null | undefined;
                  }
                | null
                | undefined;
              contacts: Array<{
                __typename: "CustomerContactType";
                name?: string | null | undefined;
                email?: string | null | undefined;
                phoneNumber?: string | null | undefined;
                mobileNumber?: string | null | undefined;
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
              }>;
            }
          | null
          | undefined;
        preferredContact?:
          | {
              __typename: "CustomerContactType";
              name?: string | null | undefined;
              email?: string | null | undefined;
              phoneNumber?: string | null | undefined;
              mobileNumber?: string | null | undefined;
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
            }
          | null
          | undefined;
        meters: Array<{
          __typename?: "MeterType";
          name?: string | null | undefined;
          typeId?: string | null | undefined;
          unitsPerDay?: number | null | undefined;
          sequence?: number | null | undefined;
          currentReading?:
            | {
                __typename?: "MeterReadingType";
                reading?: number | null | undefined;
                readingDate: string;
              }
            | null
            | undefined;
          previousReading?:
            | {
                __typename?: "MeterReadingType";
                reading?: number | null | undefined;
                readingDate: string;
              }
            | null
            | undefined;
        }>;
        tasks: Array<{
          __typename?: "WorkTaskType";
          id: number;
          name?: string | null | undefined;
          description?: string | null | undefined;
          completedDate?: string | null | undefined;
          sequence: number;
        }>;
        checklists: Array<{
          __typename?: "ChecklistType";
          checklistCode?: string | null | undefined;
          title: string;
          description?: string | null | undefined;
          isTemplate?: boolean | null | undefined;
          machineGuid?: string | null | undefined;
          questions: Array<{
            __typename?: "ChecklistQuestionType";
            nodeKey?: string | null | undefined;
            question: string;
            dataType: QuestionDataType;
            isRequired: boolean;
            isRequiredPreStart: boolean;
            answer?: string | null | undefined;
            minValue?: number | null | undefined;
            maxValue?: number | null | undefined;
            showComment: boolean;
            comment?: string | null | undefined;
            parent?: string | null | undefined;
            showCondition?: boolean | null | undefined;
            dropDownValues: Array<string>;
            expression?: string | null | undefined;
            expressionParams: Array<{
              __typename?: "ExpressionParameterType";
              key: string;
              description?: string | null | undefined;
              value?: number | null | undefined;
            }>;
          }>;
        }>;
      }
    | null
    | undefined;
};

export type GetJobVisitsQueryVariables = Exact<{
  max: Scalars["Int"]["input"];
  jobId: Scalars["ID"]["input"];
}>;

export type GetJobVisitsQuery = {
  __typename?: "Query";
  jobVisits: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetJobVisitsHistoryQueryVariables = Exact<{
  max: Scalars["Int"]["input"];
  jobId: Scalars["ID"]["input"];
}>;

export type GetJobVisitsHistoryQuery = {
  __typename?: "Query";
  jobVisitsHistory: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetJobsQueryVariables = Exact<{
  max: Scalars["Int"]["input"];
  jobFilter?: InputMaybe<JobFilterInputType>;
}>;

export type GetJobsQuery = {
  __typename?: "Query";
  jobs: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetJobsPlannerQueryVariables = Exact<{
  plannerFilter?: InputMaybe<PlannerFilterInputType>;
}>;

export type GetJobsPlannerQuery = {
  __typename?: "Query";
  jobsPlanner: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetManufacturersQueryVariables = Exact<{ [key: string]: never }>;

export type GetManufacturersQuery = {
  __typename?: "Query";
  manufacturers: Array<{
    __typename?: "ManufacturerType";
    id: number;
    name?: string | null | undefined;
  }>;
};

export type GetPartSortGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPartSortGroupsQuery = {
  __typename?: "Query";
  partSortGroups: {
    __typename?: "SortGroupsType";
    sortGroup1?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup1Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup2?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup2Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup3?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup3Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup4?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup4Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
    sortGroup5?:
      | {
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }
      | null
      | undefined;
    sortGroup5Values?:
      | Array<{
          __typename?: "SortGroupType";
          type?: string | null | undefined;
          sortId?: string | null | undefined;
          code?: string | null | undefined;
          description?: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetPlannerNodesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlannerNodesQuery = {
  __typename?: "Query";
  plannerNodes: Array<{
    __typename?: "PlannerNodesType";
    nodeKey?: string | null | undefined;
    nodeText?: string | null | undefined;
    nodeParent?: string | null | undefined;
    isVirtualNode?: boolean | null | undefined;
    label?: string | null | undefined;
  }>;
};

export type GetRelatedJobsQueryVariables = Exact<{
  max: Scalars["Int"]["input"];
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  equipmentId?: InputMaybe<Scalars["ID"]["input"]>;
  jobId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type GetRelatedJobsQuery = {
  __typename?: "Query";
  relatedJobs: Array<{
    __typename: "ServiceJob";
    status?: JobStatus | null | undefined;
    id: string;
    engineerId?: string | null | undefined;
    engineerName?: string | null | undefined;
    externalId: string;
    responseDate?: string | null | undefined;
    product?: Product | null | undefined;
    symptomDescription?: string | null | undefined;
    symptoms: Array<string>;
    requireMeterReading?: boolean | null | undefined;
    canTravel: boolean;
    category?: string | null | undefined;
    priority?: string | null | undefined;
    loggedDate?: string | null | undefined;
    typeId?: number | null | undefined;
    categoryId?: number | null | undefined;
    contractId?: number | null | undefined;
    completedDate?: string | null | undefined;
    serviceLevel?: string | null | undefined;
    chargeBand?: string | null | undefined;
    travelETA?: string | null | undefined;
    totalMileage?: number | null | undefined;
    solutionDescription?: string | null | undefined;
    customerSignerName?: string | null | undefined;
    plannedDate?:
      | {
          __typename?: "TimesType";
          startTime?: string | null | undefined;
          stopTime?: string | null | undefined;
        }
      | null
      | undefined;
    equipment?:
      | {
          __typename?: "EquipmentType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          location?: string | null | undefined;
          assetNumber?: string | null | undefined;
          serialNumber?: string | null | undefined;
          modelGuid?: string | null | undefined;
          addressRowNo?: number | null | undefined;
          increaseAboveLimit?: number | null | undefined;
          increaseLimit?: number | null | undefined;
          installDate?: string | null | undefined;
          itemId?: number | null | undefined;
          details?:
            | {
                __typename?: "EquipmentDetailsType";
                information?: string | null | undefined;
                nextServiceDate?: string | null | undefined;
                lastServiceDate?: string | null | undefined;
                buildStandard?: string | null | undefined;
                systemProtocol?: string | null | undefined;
                signalType?: string | null | undefined;
                securityGrade?: string | null | undefined;
                gradeOfNotification?: string | null | undefined;
                notificationOption?: string | null | undefined;
                fireAlarmCategory?: string | null | undefined;
                fireAlarmType?: string | null | undefined;
                certificateNumber?: string | null | undefined;
                certificateDate?: string | null | undefined;
                isRemotelyMonitored?: boolean | null | undefined;
                authority?: string | null | undefined;
                verificationMethod?: string | null | undefined;
                alarmReceivingCentre?: string | null | undefined;
                alarmReceivingCentreDigiNumber?: string | null | undefined;
                alarmReceivingCentreReferenceNumber?: string | null | undefined;
                stuNumber?: string | null | undefined;
                remoteIP?: string | null | undefined;
                customProperties?:
                  | Array<{
                      __typename?: "CustomPropertyType";
                      code: string;
                      description: string;
                      value?: string | null | undefined;
                      propertyGuid?: string | null | undefined;
                      sourceGuid?: string | null | undefined;
                      type: string;
                    }>
                  | null
                  | undefined;
                sortGroups?:
                  | Array<{
                      __typename?: "SortGroupValueType";
                      description: string;
                      value?: string | null | undefined;
                    }>
                  | null
                  | undefined;
              }
            | null
            | undefined;
          specifications: Array<{
            __typename?: "SpecificationType";
            id: number;
            description: string;
            location?: string | null | undefined;
            partNumber: string;
          }>;
        }
      | null
      | undefined;
    travelTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    usedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      description?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    preOrderedParts: Array<{
      __typename?: "PartType";
      id?: string | null | undefined;
      partNumber?: string | null | undefined;
      barcode?: string | null | undefined;
      description?: string | null | undefined;
      quantity?: number | null | undefined;
      stockStore?: StockStore | null | undefined;
      status?: PartStatus | null | undefined;
      stockName?: string | null | undefined;
      stockId?: string | null | undefined;
      manufacturerId?: number | null | undefined;
    }>;
    cause?:
      | {
          __typename: "CauseType";
          id?: string | null | undefined;
          name?: string | null | undefined;
          code?: string | null | undefined;
        }
      | null
      | undefined;
    actions: Array<{
      __typename: "ActionType";
      id?: string | null | undefined;
      name?: string | null | undefined;
      code?: string | null | undefined;
      sortId?: string | null | undefined;
    }>;
    workTimes: Array<{
      __typename?: "TimesType";
      startTime?: string | null | undefined;
      stopTime?: string | null | undefined;
    }>;
    customer?:
      | {
          __typename: "CustomerType";
          name?: string | null | undefined;
          id: string;
          isOnStop: boolean;
          salesRegion?: string | null | undefined;
          serviceRegion?: string | null | undefined;
          isVIP?: boolean | null | undefined;
          customerNo?: string | null | undefined;
          address?:
            | {
                __typename?: "AddressType";
                name?: string | null | undefined;
                address1?: string | null | undefined;
                address2?: string | null | undefined;
                address3?: string | null | undefined;
                postalCode?: string | null | undefined;
                postalPlace?: string | null | undefined;
                country?: string | null | undefined;
                city?: string | null | undefined;
              }
            | null
            | undefined;
          contacts: Array<{
            __typename: "CustomerContactType";
            name?: string | null | undefined;
            email?: string | null | undefined;
            phoneNumber?: string | null | undefined;
            mobileNumber?: string | null | undefined;
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
          }>;
        }
      | null
      | undefined;
    preferredContact?:
      | {
          __typename: "CustomerContactType";
          name?: string | null | undefined;
          email?: string | null | undefined;
          phoneNumber?: string | null | undefined;
          mobileNumber?: string | null | undefined;
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
        }
      | null
      | undefined;
    meters: Array<{
      __typename?: "MeterType";
      name?: string | null | undefined;
      typeId?: string | null | undefined;
      unitsPerDay?: number | null | undefined;
      sequence?: number | null | undefined;
      currentReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
      previousReading?:
        | {
            __typename?: "MeterReadingType";
            reading?: number | null | undefined;
            readingDate: string;
          }
        | null
        | undefined;
    }>;
    tasks: Array<{
      __typename?: "WorkTaskType";
      id: number;
      name?: string | null | undefined;
      description?: string | null | undefined;
      completedDate?: string | null | undefined;
      sequence: number;
    }>;
    checklists: Array<{
      __typename?: "ChecklistType";
      checklistCode?: string | null | undefined;
      title: string;
      description?: string | null | undefined;
      isTemplate?: boolean | null | undefined;
      machineGuid?: string | null | undefined;
      questions: Array<{
        __typename?: "ChecklistQuestionType";
        nodeKey?: string | null | undefined;
        question: string;
        dataType: QuestionDataType;
        isRequired: boolean;
        isRequiredPreStart: boolean;
        answer?: string | null | undefined;
        minValue?: number | null | undefined;
        maxValue?: number | null | undefined;
        showComment: boolean;
        comment?: string | null | undefined;
        parent?: string | null | undefined;
        showCondition?: boolean | null | undefined;
        dropDownValues: Array<string>;
        expression?: string | null | undefined;
        expressionParams: Array<{
          __typename?: "ExpressionParameterType";
          key: string;
          description?: string | null | undefined;
          value?: number | null | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type GetRequestablePartsQueryVariables = Exact<{
  locations: Array<PartQueryInputType> | PartQueryInputType;
}>;

export type GetRequestablePartsQuery = {
  __typename?: "Query";
  requestableParts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    description?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    isNonStock?: boolean | null | undefined;
    stockName?: string | null | undefined;
    manufacturerId?: number | null | undefined;
    sortGroup1?: string | null | undefined;
    sortGroup2?: string | null | undefined;
    sortGroup3?: string | null | undefined;
    sortGroup4?: string | null | undefined;
    sortGroup5?: string | null | undefined;
    supplierId?: string | null | undefined;
    supplierName?: string | null | undefined;
    supplierNo?: string | null | undefined;
    salesPrice?: number | null | undefined;
  }>;
};

export type GetPreOrderedPartsQueryVariables = Exact<{
  jobId: Scalars["String"]["input"];
  count: Scalars["Int"]["input"];
}>;

export type GetPreOrderedPartsQuery = {
  __typename?: "Query";
  preOrderedParts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    description?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    isNonStock?: boolean | null | undefined;
    stockName?: string | null | undefined;
    salesPrice?: number | null | undefined;
  }>;
};

export type GetServiceLevelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetServiceLevelsQuery = {
  __typename?: "Query";
  serviceLevels: Array<{
    __typename?: "ServiceLevelType";
    name?: string | null | undefined;
    id?: string | null | undefined;
  }>;
};

export type GetStockEnquiryQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars["String"]["input"]>;
  searchString?: InputMaybe<Scalars["String"]["input"]>;
  hideOutOfStock?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type GetStockEnquiryQuery = {
  __typename?: "Query";
  stockEnquiry: Array<{
    __typename?: "PartType";
    barcode?: string | null | undefined;
    description?: string | null | undefined;
    id?: string | null | undefined;
    isNonStock?: boolean | null | undefined;
    partNumber?: string | null | undefined;
    quantity?: number | null | undefined;
    returnStockId?: string | null | undefined;
    status?: PartStatus | null | undefined;
    stockId?: string | null | undefined;
    stockName?: string | null | undefined;
    stockStore?: StockStore | null | undefined;
    location?: string | null | undefined;
  }>;
};

export type GetStockListQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockListQuery = {
  __typename?: "Query";
  stockList: Array<{ __typename?: "Stock"; id: string; name: string }>;
};

export type GetSuggestedPartsQueryVariables = Exact<{
  equipmentId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetSuggestedPartsQuery = { __typename?: "Query"; suggestedParts: Array<string> };

export type GetSuppliersQueryVariables = Exact<{ [key: string]: never }>;

export type GetSuppliersQuery = {
  __typename?: "Query";
  suppliers: Array<{
    __typename?: "SupplierType";
    id: string;
    name?: string | null | undefined;
    supplierNo: string;
  }>;
};

export type GetSymptomsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSymptomsQuery = {
  __typename?: "Query";
  symptoms: Array<{
    __typename?: "JobSymptomType";
    code?: string | null | undefined;
    description?: string | null | undefined;
    typeId?: number | null | undefined;
    sortId?: string | null | undefined;
  }>;
};

export type GetTransitPartsQueryVariables = Exact<{
  location: PartQueryInputType;
}>;

export type GetTransitPartsQuery = {
  __typename?: "Query";
  transitParts: Array<{
    __typename?: "PartType";
    id?: string | null | undefined;
    description?: string | null | undefined;
    partNumber?: string | null | undefined;
    barcode?: string | null | undefined;
    quantity?: number | null | undefined;
    stockStore?: StockStore | null | undefined;
    isNonStock?: boolean | null | undefined;
    stockName?: string | null | undefined;
    stockId?: string | null | undefined;
    manufacturerId?: number | null | undefined;
  }>;
};

export type GetJobCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetJobCategoriesQuery = {
  __typename?: "Query";
  jobCategories: Array<{
    __typename?: "JobCategoryType";
    id?: number | null | undefined;
    text?: string | null | undefined;
    typeId?: number | null | undefined;
  }>;
};

export type GetUserEndpointsQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type GetUserEndpointsQuery = {
  __typename?: "Query";
  userEndpoints: {
    __typename?: "UserEndpointResult";
    email: string;
    useMultipleEndpoints: boolean;
    lastEndpoint?:
      | { __typename: "Endpoint"; id: string; apiUrl: string; apiVersion: string; product: Product }
      | null
      | undefined;
    userEndpoints: Array<{
      __typename: "Endpoint";
      id: string;
      apiUrl: string;
      apiVersion: string;
      product: Product;
    }>;
  };
};

export type GetUserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserSettingsQuery = {
  __typename?: "Query";
  userSettings: {
    __typename: "UserSettings";
    email: string;
    useMultipleEndpoints: boolean;
    lastEndpoint?:
      | { __typename: "Endpoint"; id: string; apiUrl: string; apiVersion: string; product: Product }
      | null
      | undefined;
    userEndpoints: Array<{
      __typename: "Endpoint";
      id: string;
      apiUrl: string;
      apiVersion: string;
      product: Product;
    }>;
  };
};

export type GetWorkNotesQueryVariables = Exact<{
  workNoteArgs: WorkNoteArgsInputType;
}>;

export type GetWorkNotesQuery = {
  __typename?: "Query";
  workNotes: Array<{
    __typename?: "WorkNoteType";
    loggedDate: string;
    loggedBy: string;
    text: string;
    isAlert: boolean;
    isInternal: boolean;
    type?: WorkNoteTypeEnum | null | undefined;
    hideHeader?: boolean | null | undefined;
  }>;
};

export const EndpointFragmentFragmentDoc = gql`
  fragment EndpointFragment on Endpoint {
    __typename
    id
    apiUrl
    apiVersion
    product
  }
`;
export const JobFragmentFragmentDoc = gql`
  fragment JobFragment on ServiceJob {
    __typename
    status
    id
    engineerId
    engineerName
    externalId
    responseDate
    plannedDate {
      startTime
      stopTime
    }
    product
    symptomDescription
    symptoms
    requireMeterReading
    canTravel
    category
    priority
    loggedDate
    typeId
    categoryId
    contractId
    completedDate
    equipment {
      id
      name
      location
      assetNumber
      serialNumber
      modelGuid
      addressRowNo
      increaseAboveLimit
      increaseLimit
      installDate
      itemId
      details {
        information
        nextServiceDate
        lastServiceDate
        buildStandard
        systemProtocol
        signalType
        securityGrade
        gradeOfNotification
        notificationOption
        fireAlarmCategory
        fireAlarmType
        certificateNumber
        certificateDate
        isRemotelyMonitored
        authority
        verificationMethod
        alarmReceivingCentre
        alarmReceivingCentreDigiNumber
        alarmReceivingCentreReferenceNumber
        stuNumber
        remoteIP
        customProperties {
          code
          description
          value
          propertyGuid
          sourceGuid
          type
        }
        sortGroups {
          description
          value
        }
      }
      specifications {
        id
        description
        location
        partNumber
      }
    }
    serviceLevel
    chargeBand
    travelETA
    travelTimes {
      startTime
      stopTime
    }
    totalMileage
    usedParts {
      id
      description
      partNumber
      barcode
      quantity
      stockStore
      status
      stockName
      stockId
      manufacturerId
    }
    preOrderedParts {
      id
      partNumber
      barcode
      description
      quantity
      stockStore
      status
      stockName
      stockId
      manufacturerId
    }
    cause {
      __typename
      id
      name
      code
    }
    actions {
      __typename
      id
      name
      code
      sortId
    }
    workTimes {
      startTime
      stopTime
    }
    solutionDescription
    customerSignerName
    customer {
      __typename
      name
      id
      isOnStop
      salesRegion
      serviceRegion
      isVIP
      customerNo
      address {
        name
        address1
        address2
        address3
        postalCode
        postalPlace
        country
        city
      }
      contacts {
        __typename
        name
        email
        phoneNumber
        mobileNumber
        id
        firstName
        lastName
      }
    }
    preferredContact {
      __typename
      name
      email
      phoneNumber
      mobileNumber
      id
      firstName
      lastName
    }
    meters {
      name
      typeId
      unitsPerDay
      sequence
      currentReading {
        reading
        readingDate
      }
      previousReading {
        reading
        readingDate
      }
    }
    tasks {
      id
      name
      description
      completedDate
      sequence
    }
    checklists {
      checklistCode
      title
      description
      isTemplate
      machineGuid
      questions {
        nodeKey
        question
        dataType
        isRequired
        isRequiredPreStart
        answer
        minValue
        maxValue
        showComment
        comment
        parent
        showCondition
        dropDownValues
        expression
        expressionParams {
          key
          description
          value
        }
      }
    }
  }
`;
export const LoginFragmentFragmentDoc = gql`
  fragment LoginFragment on LoginResult {
    __typename
    id
    expiry
    token
    email
    product
    externalId
    languageCode
    stockId
    suppliedStoreId
    externalGuid
    fullName
    userConsents {
      accepted
      version
      type
    }
  }
`;
export const VisitTravelFragmentFragmentDoc = gql`
  fragment VisitTravelFragment on ServiceJob {
    __typename
    id
    travelETA
    travelTimes {
      startTime
      stopTime
    }
    totalMileage
    status
  }
`;
export const AddChecklistDocument = gql`
  mutation addChecklist($jobId: String!, $checklistCode: String!) {
    addChecklist(jobId: $jobId, checklistCode: $checklistCode) {
      checklistCode
      title
      description
      isTemplate
      machineGuid
      questions {
        nodeKey
        question
        dataType
        isRequired
        isRequiredPreStart
        answer
        minValue
        maxValue
        showComment
        comment
        parent
        showCondition
        dropDownValues
        expression
        expressionParams {
          key
          description
          value
        }
      }
    }
  }
`;
export const AddUserConsentDocument = gql`
  mutation addUserConsent($userConsents: [UserConsentInputType!]!) {
    addUserConsent(userConsents: $userConsents) {
      accepted
      version
      type
    }
  }
`;
export const AddWorkNoteDocument = gql`
  mutation addWorkNote(
    $metadata: MetadataInputType
    $jobId: String!
    $workNote: WorkNoteInputType!
  ) {
    addWorkNote(metadata: $metadata, jobId: $jobId, workNote: $workNote) {
      loggedDate
      loggedBy
      text
      isAlert
      isInternal
    }
  }
`;
export const AssignTaskDocument = gql`
  mutation assignTask($jobId: String!, $engineerId: String!, $scheduleStartDate: DateTime) {
    assignTask(jobId: $jobId, engineerId: $engineerId, scheduleStartDate: $scheduleStartDate)
  }
`;
export const Auth0LoginDocument = gql`
  mutation auth0Login(
    $url: String!
    $email: String!
    $accessToken: String!
    $refreshToken: String
    $product: Product!
  ) {
    auth0Login(
      url: $url
      email: $email
      accessToken: $accessToken
      refreshToken: $refreshToken
      product: $product
    ) {
      ...LoginFragment
    }
  }
  ${LoginFragmentFragmentDoc}
`;
export const ChangeJobEquipmentDocument = gql`
  mutation changeJobEquipment(
    $metadata: MetadataInputType
    $jobId: String!
    $equipmentId: String!
  ) {
    changeJobEquipment(metadata: $metadata, jobId: $jobId, equipmentId: $equipmentId)
  }
`;
export const CompleteVisitWithPartsDocument = gql`
  mutation completeVisitWithParts(
    $metadata: MetadataInputType
    $jobId: String!
    $visit: JobVisitInput!
    $followUp: FollowUpInputType!
    $parts: [PartInputType!]!
    $extras: [ExtraInputType!]!
    $files: [FileInputType!]!
    $workTasks: [WorkTaskInputType!]!
    $newWorkUnitId: Int
  ) {
    completeVisitWithParts(
      metadata: $metadata
      jobId: $jobId
      visit: $visit
      followUp: $followUp
      parts: $parts
      extras: $extras
      files: $files
      workTasks: $workTasks
      newWorkUnitId: $newWorkUnitId
    ) {
      id
      externalId
    }
  }
`;
export const CreateJobDocument = gql`
  mutation createJob(
    $metadata: MetadataInputType
    $jobDetails: JobInputType!
    $files: [FileInputType!]!
  ) {
    createServiceJob(metadata: $metadata, jobDetails: $jobDetails, files: $files) {
      jobId
    }
  }
`;
export const EditContactDocument = gql`
  mutation editContact(
    $metadata: MetadataInputType
    $jobId: String!
    $customerId: String!
    $equipmentId: String!
    $contact: ContactInputType!
  ) {
    updateContact(
      metadata: $metadata
      jobId: $jobId
      customerId: $customerId
      equipmentId: $equipmentId
      contact: $contact
    )
  }
`;
export const FeedbackDocument = gql`
  mutation feedback($rate: Int, $feedback: String, $version: String) {
    feedback(rate: $rate, feedback: $feedback, version: $version)
  }
`;
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!, $url: String!, $product: Product!) {
    login(email: $email, password: $password, url: $url, product: $product) {
      ...LoginFragment
    }
  }
  ${LoginFragmentFragmentDoc}
`;
export const LogoutDocument = gql`
  mutation logout {
    logout {
      message
    }
  }
`;
export const PreLoginDocument = gql`
  mutation preLogin($email: String!, $url: String!, $product: Product!) {
    preLogin(email: $email, url: $url, product: $product) {
      email
      url
      product
      clientId
      domain
      audience
    }
  }
`;
export const RejectJobAssignmentDocument = gql`
  mutation rejectJobAssignment(
    $metadata: MetadataInputType
    $jobId: String!
    $rejectReason: String!
  ) {
    rejectJobAssignment(metadata: $metadata, jobId: $jobId, rejectReason: $rejectReason)
  }
`;
export const StartVisitWorkDocument = gql`
  mutation startVisitWork(
    $metadata: MetadataInputType
    $jobId: String!
    $startTime: DateTime!
    $travelStopTime: DateTime
  ) {
    startVisitWork(
      metadata: $metadata
      jobId: $jobId
      startTime: $startTime
      travelStopTime: $travelStopTime
    ) {
      __typename
      id
      workTimes {
        startTime
        stopTime
      }
    }
  }
`;
export const UpdateEngineerMetadataDocument = gql`
  mutation updateEngineerMetadata($metadata: MetadataInputType) {
    updateEngineerMetadata(metadata: $metadata)
  }
`;
export const UpdateEquipmentDocument = gql`
  mutation updateEquipment(
    $metadata: MetadataInputType
    $equipment: EquipmentInputType!
    $jobId: String!
  ) {
    updateEquipment(metadata: $metadata, equipment: $equipment, jobId: $jobId)
  }
`;
export const UpdateMachineCustomPropertiesDocument = gql`
  mutation updateMachineCustomProperties($jobId: String!, $properties: [PropertyValueInputType!]!) {
    updateMachineCustomProperties(jobId: $jobId, properties: $properties) {
      __typename
      id
      equipment {
        id
        name
        location
        assetNumber
        serialNumber
        modelGuid
        addressRowNo
        increaseAboveLimit
        increaseLimit
        installDate
        details {
          nextServiceDate
          lastServiceDate
          buildStandard
          systemProtocol
          signalType
          securityGrade
          gradeOfNotification
          notificationOption
          fireAlarmCategory
          fireAlarmType
          certificateNumber
          certificateDate
          isRemotelyMonitored
          authority
          verificationMethod
          alarmReceivingCentre
          alarmReceivingCentreDigiNumber
          alarmReceivingCentreReferenceNumber
          stuNumber
          remoteIP
          customProperties {
            code
            description
            value
            propertyGuid
            sourceGuid
            type
          }
          sortGroups {
            description
            value
          }
        }
      }
    }
  }
`;
export const UpdateJobStatusDocument = gql`
  mutation updateJobStatus($jobId: String!, $status: JobStatus!) {
    updateJobStatus(jobId: $jobId, status: $status) {
      id
      status
    }
  }
`;
export const UpdateMetersDocument = gql`
  mutation updateMeters(
    $metadata: MetadataInputType
    $meters: [MeterReadingInputType!]!
    $jobId: String!
  ) {
    updateMeters(metadata: $metadata, meters: $meters, jobId: $jobId)
  }
`;
export const UpdatePlannedDateDocument = gql`
  mutation updatePlannedDate($jobId: String!, $times: TimesInputType!) {
    updatePlannedDate(jobId: $jobId, times: $times)
  }
`;
export const UpdateTransitPartsDocument = gql`
  mutation updateTransitParts($metadata: MetadataInputType, $partIds: [String!]!) {
    updateTransitParts(metadata: $metadata, partIds: $partIds)
  }
`;
export const UpdateUserSettingsDocument = gql`
  mutation updateUserSettings($userSettings: UserSettingsInputType) {
    userSettings(userSettings: $userSettings) {
      email
      useMultipleEndpoints
      lastEndpoint {
        ...EndpointFragment
      }
      userEndpoints {
        ...EndpointFragment
      }
    }
  }
  ${EndpointFragmentFragmentDoc}
`;
export const UpdateVisitInformationDocument = gql`
  mutation updateVisitInformation($jobId: String!, $miles: Int) {
    updateVisitInformation(jobId: $jobId, miles: $miles) {
      id
      totalMileage
    }
  }
`;
export const StartVisitTravelDocument = gql`
  mutation startVisitTravel($metadata: MetadataInputType, $jobId: String!, $startTime: DateTime!) {
    startVisitTravel(metadata: $metadata, jobId: $jobId, startTime: $startTime) {
      ...VisitTravelFragment
    }
  }
  ${VisitTravelFragmentFragmentDoc}
`;
export const UpdateVisitTravelDocument = gql`
  mutation updateVisitTravel(
    $metadata: MetadataInputType
    $jobId: String!
    $travelTimes: [TimesInputType]!
    $miles: Int
  ) {
    updateVisitTravel(
      metadata: $metadata
      jobId: $jobId
      travelTimes: $travelTimes
      miles: $miles
    ) {
      ...VisitTravelFragment
    }
  }
  ${VisitTravelFragmentFragmentDoc}
`;
export const UpdateEtaDocument = gql`
  mutation updateETA($metadata: MetadataInputType, $jobId: String!, $eta: DateTime!) {
    updateETA(metadata: $metadata, jobId: $jobId, eta: $eta) {
      ...VisitTravelFragment
    }
  }
  ${VisitTravelFragmentFragmentDoc}
`;
export const GetActionsAndCausesDocument = gql`
  query getActionsAndCauses {
    jobActions(max: 300) {
      name
      code
      id
      sortId
      subActionCodes
      typeId
    }
    jobCauses(max: 300) {
      name
      code
      id
      typeId
    }
  }
`;
export const GetAvailablePartsDocument = gql`
  query getAvailableParts($locations: [PartQueryInputType!]!, $searchString: String) {
    parts(locations: $locations, searchString: $searchString) {
      id
      description
      partNumber
      barcode
      quantity
      stockStore
      isNonStock
      stockName
      stockId
      manufacturerId
      sortGroup1
      sortGroup2
      sortGroup3
      sortGroup4
      sortGroup5
      supplierId
      supplierName
      supplierNo
      salesPrice
    }
  }
`;
export const GetCachePrefillDocument = gql`
  query getCachePrefill {
    extras {
      id
      code
      company
      department
      isTime
      quantity
      servicesCode
      type
    }
    jobActions(max: 300) {
      name
      code
      id
      sortId
      subActionCodes
      typeId
    }
    jobCauses(max: 300) {
      name
      code
      id
      typeId
    }
    jobCategories {
      id
      text
      typeId
    }
    symptoms {
      code
      description
      typeId
      sortId
    }
    manufacturers {
      id
      name
    }
    serviceLevels(max: 300) {
      name
      id
    }
    suppliers {
      id
      name
      supplierNo
    }
    partSortGroups {
      sortGroup1 {
        type
        sortId
        code
        description
      }
      sortGroup1Values {
        type
        sortId
        code
        description
      }
      sortGroup2 {
        type
        sortId
        code
        description
      }
      sortGroup2Values {
        type
        sortId
        code
        description
      }
      sortGroup3 {
        type
        sortId
        code
        description
      }
      sortGroup3Values {
        type
        sortId
        code
        description
      }
      sortGroup4 {
        type
        sortId
        code
        description
      }
      sortGroup4Values {
        type
        sortId
        code
        description
      }
      sortGroup5 {
        type
        sortId
        code
        description
      }
      sortGroup5Values {
        type
        sortId
        code
        description
      }
    }
  }
`;
export const GetChecklistGroupsDocument = gql`
  query getChecklistGroups($equipmentId: String) {
    checklistGroups(equipmentId: $equipmentId) {
      company
      code
      description
    }
  }
`;
export const GetContactsDocument = gql`
  query getContacts($customerId: String!) {
    contacts(customerId: $customerId) {
      id
      name
      email
      firstName
      lastName
      phone
      mobile
      contactNo
    }
  }
`;
export const GetCreateJobOptionsDocument = gql`
  query getCreateJobOptions {
    createJobOptions {
      categories {
        text
        typeId
        id
      }
      symptoms {
        code
        description
        sortId
        subSymptomCodes
        typeId
      }
    }
  }
`;
export const GetCustomersDocument = gql`
  query getCustomers($nameFilter: String) {
    customers(nameFilter: $nameFilter) {
      id
      name
      isVIP
      isOnStop
      serviceRegion
      salesRegion
      customerNo
      address {
        name
        address1
        address2
        address3
        postalCode
        postalPlace
        country
        city
      }
      contacts {
        id
        name
        phoneNumber
        mobileNumber
        email
      }
    }
  }
`;
export const GetCustomersSimpleDocument = gql`
  query getCustomersSimple($nameFilter: String) {
    customers(nameFilter: $nameFilter) {
      id
      name
      address {
        name
        address1
        address2
        address3
        postalCode
        postalPlace
        country
        city
      }
    }
  }
`;
export const GetCustomerDocument = gql`
  query getCustomer($id: String, $name: String) {
    customer(id: $id, name: $name) {
      id
      name
      isVIP
      isOnStop
      serviceRegion
      salesRegion
      customerNo
      address {
        name
        address1
        address2
        address3
        postalCode
        postalPlace
        country
        city
      }
      contacts {
        id
        name
        phoneNumber
        mobileNumber
        email
      }
    }
  }
`;
export const GetEngineerSettingsDocument = gql`
  query getEngineerSettings {
    engineerSettings {
      canCreateJob
      requireEngineerSignature
      canUseTransitManager
      isSupervisor
      isEquipmentMandatory
      sendMailToCustomer
      canEditCustomEquipmentProps
      canChangeEquipmentOnTicket
      canChangeServiceLevel
      allowNegativeStock
      requiredSolutionDescription
      allowSalesPrice
    }
  }
`;
export const GetEngineersDocument = gql`
  query getEngineers {
    engineers {
      id
      name
    }
  }
`;
export const GetEquipmentDocument = gql`
  query getEquipment($serialNumber: String, $customerId: String) {
    equipment(serialNumber: $serialNumber, customerId: $customerId) {
      id
      name
      increaseAboveLimit
      increaseLimit
      itemId
      assetNumber
      serialNumber
      location
      customerId
      customerName
      address {
        name
        address1
        address2
        address3
        postalCode
        postalPlace
        country
        city
      }
      warrantyServiceLevelId
      warrantyStartDate
      warrantyEndDate
      specifications {
        id
        description
        location
        partNumber
      }
    }
  }
`;
export const GetEquipmentContractStatusDocument = gql`
  query getEquipmentContractStatus($equipmentId: String) {
    equipmentContractStatus(equipmentId: $equipmentId) {
      customerId
      contractId
    }
  }
`;
export const GetExtrasDocument = gql`
  query getExtras {
    extras {
      id
      code
      company
      department
      isTime
      quantity
      servicesCode
      type
    }
  }
`;
export const GetFeatureFlagsDocument = gql`
  query getFeatureFlags {
    featureFlags {
      name
      isActive
    }
  }
`;
export const GetPublicFeatureFlagsDocument = gql`
  query getPublicFeatureFlags {
    publicFeatureFlags {
      name
      isActive
    }
  }
`;
export const GetFilesDocument = gql`
  query getFiles($jobId: ID!) {
    files(jobId: $jobId) {
      id
      data
      description
      name
      size
    }
  }
`;
export const GetEngineerHistoryDocument = gql`
  query getEngineerHistory($from: DateTime, $specificDate: DateTime) {
    engineerHistory(from: $from, specificDate: $specificDate) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetServiceHistoryDocument = gql`
  query getServiceHistory(
    $equipmentId: String
    $fromDate: DateTime
    $specificDate: DateTime
    $customerId: String
  ) {
    serviceHistory(
      equipmentId: $equipmentId
      fromDate: $fromDate
      specificDate: $specificDate
      customerId: $customerId
    ) {
      serviceJobId
      loggedDate
      completedDate
      workUnitId
      lastStatusName
      engineerName
      customerName
      actionCodes
      symptoms
      category
      causeName
      priority
      externalId
      isLegacy
      parts
    }
  }
`;
export const GetInitialJobDocument = gql`
  query getInitialJob($id: ID!) {
    job(id: $id) {
      ...JobFragment
    }
    jobActions(max: 300) {
      name
      code
      id
      sortId
      subActionCodes
      typeId
    }
    jobCauses(max: 300) {
      name
      code
      id
      typeId
    }
    jobCategories {
      id
      text
      typeId
    }
    symptoms {
      code
      description
      typeId
      sortId
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetJobDocument = gql`
  query getJob($id: ID!) {
    job(id: $id) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetJobVisitsDocument = gql`
  query getJobVisits($max: Int!, $jobId: ID!) {
    jobVisits(max: $max, jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetJobVisitsHistoryDocument = gql`
  query getJobVisitsHistory($max: Int!, $jobId: ID!) {
    jobVisitsHistory(max: $max, jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetJobsDocument = gql`
  query getJobs($max: Int!, $jobFilter: JobFilterInputType) {
    jobs(max: $max, jobFilter: $jobFilter) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetJobsPlannerDocument = gql`
  query getJobsPlanner($plannerFilter: PlannerFilterInputType) {
    jobsPlanner(plannerFilter: $plannerFilter) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetManufacturersDocument = gql`
  query getManufacturers {
    manufacturers {
      id
      name
    }
  }
`;
export const GetPartSortGroupsDocument = gql`
  query getPartSortGroups {
    partSortGroups {
      sortGroup1 {
        type
        sortId
        code
        description
      }
      sortGroup1Values {
        type
        sortId
        code
        description
      }
      sortGroup2 {
        type
        sortId
        code
        description
      }
      sortGroup2Values {
        type
        sortId
        code
        description
      }
      sortGroup3 {
        type
        sortId
        code
        description
      }
      sortGroup3Values {
        type
        sortId
        code
        description
      }
      sortGroup4 {
        type
        sortId
        code
        description
      }
      sortGroup4Values {
        type
        sortId
        code
        description
      }
      sortGroup5 {
        type
        sortId
        code
        description
      }
      sortGroup5Values {
        type
        sortId
        code
        description
      }
    }
  }
`;
export const GetPlannerNodesDocument = gql`
  query getPlannerNodes {
    plannerNodes {
      nodeKey
      nodeText
      nodeParent
      isVirtualNode
      label
    }
  }
`;
export const GetRelatedJobsDocument = gql`
  query getRelatedJobs($max: Int!, $customerId: ID, $equipmentId: ID, $jobId: ID) {
    relatedJobs(max: $max, customerId: $customerId, equipmentId: $equipmentId, jobId: $jobId) {
      ...JobFragment
    }
  }
  ${JobFragmentFragmentDoc}
`;
export const GetRequestablePartsDocument = gql`
  query getRequestableParts($locations: [PartQueryInputType!]!) {
    requestableParts(locations: $locations) {
      id
      description
      partNumber
      barcode
      quantity
      stockStore
      isNonStock
      stockName
      manufacturerId
      sortGroup1
      sortGroup2
      sortGroup3
      sortGroup4
      sortGroup5
      supplierId
      supplierName
      supplierNo
      salesPrice
    }
  }
`;
export const GetPreOrderedPartsDocument = gql`
  query getPreOrderedParts($jobId: String!, $count: Int!) {
    preOrderedParts(jobId: $jobId, count: $count) {
      id
      description
      partNumber
      barcode
      quantity
      stockStore
      isNonStock
      stockName
      salesPrice
    }
  }
`;
export const GetServiceLevelsDocument = gql`
  query getServiceLevels {
    serviceLevels(max: 300) {
      name
      id
    }
  }
`;
export const GetStockEnquiryDocument = gql`
  query getStockEnquiry($locationId: String, $searchString: String, $hideOutOfStock: Boolean) {
    stockEnquiry(
      locationId: $locationId
      searchString: $searchString
      hideOutOfStock: $hideOutOfStock
    ) {
      barcode
      description
      id
      isNonStock
      partNumber
      quantity
      returnStockId
      status
      stockId
      stockName
      stockStore
      location
    }
  }
`;
export const GetStockListDocument = gql`
  query getStockList {
    stockList {
      id
      name
    }
  }
`;
export const GetSuggestedPartsDocument = gql`
  query getSuggestedParts($equipmentId: String) {
    suggestedParts(equipmentId: $equipmentId)
  }
`;
export const GetSuppliersDocument = gql`
  query getSuppliers {
    suppliers {
      id
      name
      supplierNo
    }
  }
`;
export const GetSymptomsDocument = gql`
  query getSymptoms {
    symptoms {
      code
      description
      typeId
      sortId
    }
  }
`;
export const GetTransitPartsDocument = gql`
  query getTransitParts($location: PartQueryInputType!) {
    transitParts(location: $location) {
      id
      description
      partNumber
      barcode
      quantity
      stockStore
      isNonStock
      stockName
      stockId
      manufacturerId
    }
  }
`;
export const GetJobCategoriesDocument = gql`
  query getJobCategories {
    jobCategories {
      id
      text
      typeId
    }
  }
`;
export const GetUserEndpointsDocument = gql`
  query getUserEndpoints($email: String!) {
    userEndpoints(email: $email) {
      email
      useMultipleEndpoints
      lastEndpoint {
        ...EndpointFragment
      }
      userEndpoints {
        ...EndpointFragment
      }
    }
  }
  ${EndpointFragmentFragmentDoc}
`;
export const GetUserSettingsDocument = gql`
  query getUserSettings {
    userSettings {
      __typename
      email
      useMultipleEndpoints
      lastEndpoint {
        ...EndpointFragment
      }
      userEndpoints {
        ...EndpointFragment
      }
    }
  }
  ${EndpointFragmentFragmentDoc}
`;
export const GetWorkNotesDocument = gql`
  query getWorkNotes($workNoteArgs: WorkNoteArgsInputType!) {
    workNotes(workNoteArgs: $workNoteArgs) {
      loggedDate
      loggedBy
      text
      isAlert
      isInternal
      type
      hideHeader
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    addChecklist(
      variables: AddChecklistMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<AddChecklistMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddChecklistMutation>(AddChecklistDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addChecklist",
        "mutation"
      );
    },
    addUserConsent(
      variables: AddUserConsentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<AddUserConsentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddUserConsentMutation>(AddUserConsentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addUserConsent",
        "mutation"
      );
    },
    addWorkNote(
      variables: AddWorkNoteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<AddWorkNoteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AddWorkNoteMutation>(AddWorkNoteDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "addWorkNote",
        "mutation"
      );
    },
    assignTask(
      variables: AssignTaskMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<AssignTaskMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AssignTaskMutation>(AssignTaskDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "assignTask",
        "mutation"
      );
    },
    auth0Login(
      variables: Auth0LoginMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<Auth0LoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<Auth0LoginMutation>(Auth0LoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "auth0Login",
        "mutation"
      );
    },
    changeJobEquipment(
      variables: ChangeJobEquipmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ChangeJobEquipmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChangeJobEquipmentMutation>(ChangeJobEquipmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "changeJobEquipment",
        "mutation"
      );
    },
    completeVisitWithParts(
      variables: CompleteVisitWithPartsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CompleteVisitWithPartsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CompleteVisitWithPartsMutation>(
            CompleteVisitWithPartsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "completeVisitWithParts",
        "mutation"
      );
    },
    createJob(
      variables: CreateJobMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CreateJobMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateJobMutation>(CreateJobDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createJob",
        "mutation"
      );
    },
    editContact(
      variables: EditContactMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EditContactMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EditContactMutation>(EditContactDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "editContact",
        "mutation"
      );
    },
    feedback(
      variables?: FeedbackMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<FeedbackMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FeedbackMutation>(FeedbackDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "feedback",
        "mutation"
      );
    },
    login(
      variables: LoginMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<LoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginMutation>(LoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "login",
        "mutation"
      );
    },
    logout(
      variables?: LogoutMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<LogoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LogoutMutation>(LogoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "logout",
        "mutation"
      );
    },
    preLogin(
      variables: PreLoginMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<PreLoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PreLoginMutation>(PreLoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "preLogin",
        "mutation"
      );
    },
    rejectJobAssignment(
      variables: RejectJobAssignmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<RejectJobAssignmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RejectJobAssignmentMutation>(RejectJobAssignmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "rejectJobAssignment",
        "mutation"
      );
    },
    startVisitWork(
      variables: StartVisitWorkMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<StartVisitWorkMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartVisitWorkMutation>(StartVisitWorkDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "startVisitWork",
        "mutation"
      );
    },
    updateEngineerMetadata(
      variables?: UpdateEngineerMetadataMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateEngineerMetadataMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateEngineerMetadataMutation>(
            UpdateEngineerMetadataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateEngineerMetadata",
        "mutation"
      );
    },
    updateEquipment(
      variables: UpdateEquipmentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateEquipmentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateEquipmentMutation>(UpdateEquipmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateEquipment",
        "mutation"
      );
    },
    updateMachineCustomProperties(
      variables: UpdateMachineCustomPropertiesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateMachineCustomPropertiesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMachineCustomPropertiesMutation>(
            UpdateMachineCustomPropertiesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateMachineCustomProperties",
        "mutation"
      );
    },
    updateJobStatus(
      variables: UpdateJobStatusMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateJobStatusMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateJobStatusMutation>(UpdateJobStatusDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateJobStatus",
        "mutation"
      );
    },
    updateMeters(
      variables: UpdateMetersMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateMetersMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMetersMutation>(UpdateMetersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateMeters",
        "mutation"
      );
    },
    updatePlannedDate(
      variables: UpdatePlannedDateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdatePlannedDateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePlannedDateMutation>(UpdatePlannedDateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updatePlannedDate",
        "mutation"
      );
    },
    updateTransitParts(
      variables: UpdateTransitPartsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateTransitPartsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTransitPartsMutation>(UpdateTransitPartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateTransitParts",
        "mutation"
      );
    },
    updateUserSettings(
      variables?: UpdateUserSettingsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateUserSettingsMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserSettingsMutation>(UpdateUserSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateUserSettings",
        "mutation"
      );
    },
    updateVisitInformation(
      variables: UpdateVisitInformationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateVisitInformationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVisitInformationMutation>(
            UpdateVisitInformationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "updateVisitInformation",
        "mutation"
      );
    },
    startVisitTravel(
      variables: StartVisitTravelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<StartVisitTravelMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StartVisitTravelMutation>(StartVisitTravelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "startVisitTravel",
        "mutation"
      );
    },
    updateVisitTravel(
      variables: UpdateVisitTravelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateVisitTravelMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateVisitTravelMutation>(UpdateVisitTravelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateVisitTravel",
        "mutation"
      );
    },
    updateETA(
      variables: UpdateEtaMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<UpdateEtaMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateEtaMutation>(UpdateEtaDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateETA",
        "mutation"
      );
    },
    getActionsAndCauses(
      variables?: GetActionsAndCausesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetActionsAndCausesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetActionsAndCausesQuery>(GetActionsAndCausesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getActionsAndCauses",
        "query"
      );
    },
    getAvailableParts(
      variables: GetAvailablePartsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetAvailablePartsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAvailablePartsQuery>(GetAvailablePartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getAvailableParts",
        "query"
      );
    },
    getCachePrefill(
      variables?: GetCachePrefillQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCachePrefillQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCachePrefillQuery>(GetCachePrefillDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCachePrefill",
        "query"
      );
    },
    getChecklistGroups(
      variables?: GetChecklistGroupsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetChecklistGroupsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetChecklistGroupsQuery>(GetChecklistGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getChecklistGroups",
        "query"
      );
    },
    getContacts(
      variables: GetContactsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetContactsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetContactsQuery>(GetContactsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getContacts",
        "query"
      );
    },
    getCreateJobOptions(
      variables?: GetCreateJobOptionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCreateJobOptionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCreateJobOptionsQuery>(GetCreateJobOptionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCreateJobOptions",
        "query"
      );
    },
    getCustomers(
      variables?: GetCustomersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCustomersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCustomersQuery>(GetCustomersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCustomers",
        "query"
      );
    },
    getCustomersSimple(
      variables?: GetCustomersSimpleQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCustomersSimpleQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCustomersSimpleQuery>(GetCustomersSimpleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCustomersSimple",
        "query"
      );
    },
    getCustomer(
      variables?: GetCustomerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetCustomerQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetCustomerQuery>(GetCustomerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getCustomer",
        "query"
      );
    },
    getEngineerSettings(
      variables?: GetEngineerSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEngineerSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEngineerSettingsQuery>(GetEngineerSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEngineerSettings",
        "query"
      );
    },
    getEngineers(
      variables?: GetEngineersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEngineersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEngineersQuery>(GetEngineersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEngineers",
        "query"
      );
    },
    getEquipment(
      variables?: GetEquipmentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEquipmentQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEquipmentQuery>(GetEquipmentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEquipment",
        "query"
      );
    },
    getEquipmentContractStatus(
      variables?: GetEquipmentContractStatusQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEquipmentContractStatusQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEquipmentContractStatusQuery>(
            GetEquipmentContractStatusDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "getEquipmentContractStatus",
        "query"
      );
    },
    getExtras(
      variables?: GetExtrasQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetExtrasQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetExtrasQuery>(GetExtrasDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getExtras",
        "query"
      );
    },
    getFeatureFlags(
      variables?: GetFeatureFlagsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetFeatureFlagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetFeatureFlagsQuery>(GetFeatureFlagsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getFeatureFlags",
        "query"
      );
    },
    getPublicFeatureFlags(
      variables?: GetPublicFeatureFlagsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPublicFeatureFlagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPublicFeatureFlagsQuery>(GetPublicFeatureFlagsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPublicFeatureFlags",
        "query"
      );
    },
    getFiles(
      variables: GetFilesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetFilesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetFilesQuery>(GetFilesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getFiles",
        "query"
      );
    },
    getEngineerHistory(
      variables?: GetEngineerHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetEngineerHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetEngineerHistoryQuery>(GetEngineerHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getEngineerHistory",
        "query"
      );
    },
    getServiceHistory(
      variables?: GetServiceHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetServiceHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetServiceHistoryQuery>(GetServiceHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getServiceHistory",
        "query"
      );
    },
    getInitialJob(
      variables: GetInitialJobQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetInitialJobQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetInitialJobQuery>(GetInitialJobDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getInitialJob",
        "query"
      );
    },
    getJob(
      variables: GetJobQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobQuery>(GetJobDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJob",
        "query"
      );
    },
    getJobVisits(
      variables: GetJobVisitsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobVisitsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobVisitsQuery>(GetJobVisitsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJobVisits",
        "query"
      );
    },
    getJobVisitsHistory(
      variables: GetJobVisitsHistoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobVisitsHistoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobVisitsHistoryQuery>(GetJobVisitsHistoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJobVisitsHistory",
        "query"
      );
    },
    getJobs(
      variables: GetJobsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobsQuery>(GetJobsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJobs",
        "query"
      );
    },
    getJobsPlanner(
      variables?: GetJobsPlannerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobsPlannerQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobsPlannerQuery>(GetJobsPlannerDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJobsPlanner",
        "query"
      );
    },
    getManufacturers(
      variables?: GetManufacturersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetManufacturersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetManufacturersQuery>(GetManufacturersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getManufacturers",
        "query"
      );
    },
    getPartSortGroups(
      variables?: GetPartSortGroupsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPartSortGroupsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPartSortGroupsQuery>(GetPartSortGroupsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPartSortGroups",
        "query"
      );
    },
    getPlannerNodes(
      variables?: GetPlannerNodesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPlannerNodesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPlannerNodesQuery>(GetPlannerNodesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPlannerNodes",
        "query"
      );
    },
    getRelatedJobs(
      variables: GetRelatedJobsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetRelatedJobsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRelatedJobsQuery>(GetRelatedJobsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getRelatedJobs",
        "query"
      );
    },
    getRequestableParts(
      variables: GetRequestablePartsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetRequestablePartsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetRequestablePartsQuery>(GetRequestablePartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getRequestableParts",
        "query"
      );
    },
    getPreOrderedParts(
      variables: GetPreOrderedPartsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetPreOrderedPartsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetPreOrderedPartsQuery>(GetPreOrderedPartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getPreOrderedParts",
        "query"
      );
    },
    getServiceLevels(
      variables?: GetServiceLevelsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetServiceLevelsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetServiceLevelsQuery>(GetServiceLevelsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getServiceLevels",
        "query"
      );
    },
    getStockEnquiry(
      variables?: GetStockEnquiryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetStockEnquiryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetStockEnquiryQuery>(GetStockEnquiryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getStockEnquiry",
        "query"
      );
    },
    getStockList(
      variables?: GetStockListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetStockListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetStockListQuery>(GetStockListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getStockList",
        "query"
      );
    },
    getSuggestedParts(
      variables?: GetSuggestedPartsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSuggestedPartsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSuggestedPartsQuery>(GetSuggestedPartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getSuggestedParts",
        "query"
      );
    },
    getSuppliers(
      variables?: GetSuppliersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSuppliersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSuppliersQuery>(GetSuppliersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getSuppliers",
        "query"
      );
    },
    getSymptoms(
      variables?: GetSymptomsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetSymptomsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetSymptomsQuery>(GetSymptomsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getSymptoms",
        "query"
      );
    },
    getTransitParts(
      variables: GetTransitPartsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetTransitPartsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTransitPartsQuery>(GetTransitPartsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getTransitParts",
        "query"
      );
    },
    getJobCategories(
      variables?: GetJobCategoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetJobCategoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetJobCategoriesQuery>(GetJobCategoriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getJobCategories",
        "query"
      );
    },
    getUserEndpoints(
      variables: GetUserEndpointsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserEndpointsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserEndpointsQuery>(GetUserEndpointsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getUserEndpoints",
        "query"
      );
    },
    getUserSettings(
      variables?: GetUserSettingsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetUserSettingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserSettingsQuery>(GetUserSettingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getUserSettings",
        "query"
      );
    },
    getWorkNotes(
      variables: GetWorkNotesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<GetWorkNotesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetWorkNotesQuery>(GetWorkNotesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getWorkNotes",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
