import { abortAll } from "context/graphql/client";
import { backendUrl } from "env";
import { RefreshTokenResponse, Token } from "models/authToken";
import { getStore } from "store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { selectAuth, setAuth } from "store/slices/user.store";

export const refreshToken = async (): Promise<Token | undefined> => {
  const store = getStore();
  const dispatch = store.dispatch;
  const authVar = selectAuth(store.getState());
  const token = authVar.token;
  try {
    if (!token) return;
    const auth = await fetch(`${backendUrl()}/refresh`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = (await res.json()) as RefreshTokenResponse;
      if (!res.ok || !data.auth) {
        const error = new Error(data?.message || res.statusText || "Error");
        return Promise.reject(error);
      }
      return data.auth;
    });
    dispatch(
      setAuth({
        ...authVar,
        token: auth?.token,
        expiry: new Date(auth?.expiry).toISOString(),
      })
    );
    localStorage.setItem("authState", JSON.stringify(auth));
    dispatch(addSnackbarMessage({ key: "TokenRefresh-success" }));
    return auth.token;
  } catch (e) {
    abortAll("TOKEN_EXPIRED");
    dispatch(
      setAuth({
        ...authVar,
        isLoggedIn: false,
        message: "Your session has expired, please log in again",
      })
    );
    dispatch(addSnackbarMessage({ key: "TokenRefresh-failed" }));
  }
};
