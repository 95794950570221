import { Product } from "operations/schema/schema";

export const isProductEvatic = (product: Product | string | undefined | null): boolean =>
  product ? (product as Product) === Product.Evatic : false;

export const isProductVantage = (product: Product | string | undefined | null): boolean =>
  product
    ? (product as Product) === Product.VantageOnline ||
      (product as Product) === Product.AsolviProtect
    : false;
