import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "store";

import { isEmpty } from "helpers";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { isEqual } from "lodash";
import { CustomerSimple } from "models/CustomerSimple";
import { Job, Jobs } from "models/Job";
import { defaultJobFilter, JobFilter, JobFilterKeys } from "models/JobFilter";
import { EquipmentType, JobStatus, TimesType } from "operations/schema/schema";
import { asyncMutations, mutationBuilder } from "./jobs.mutations";
import { asyncQueries, queryBuilder } from "./jobs.queries";

// Interface for state
export interface State {
  jobs: Jobs;
  lastLoaded: string | null;
  jobFilter: JobFilter;
  loadingJob?: boolean;
  loadingJobs?: boolean;
  loadingRelatedJobs?: boolean;
  newJobIds: string[];
  hideNewJobNotification: boolean;
  filterOptions: {
    customers: CustomerSimple[];
    loadingCustomers: boolean;
  };
  changeJobEquipment: {
    equipments: EquipmentType[];
    loading: boolean;
    loadingEquipments: boolean;
  };
  rejectJob: {
    open: boolean;
    loading: boolean;
    text: string;
  };
  updateEquipment: {
    open: boolean;
    loading: boolean;
  };
  editContact: {
    open: boolean;
    loading: boolean;
  };
  updatePlannedDate: {
    open: boolean;
    loading: boolean;
  };
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  getJobsOnQueued: (state: State, action: Action) => State;
  handleCloseRejectJob: (state: State, action: Action) => State;
  handleCloseUpdateEquipment: (state: State, action: Action) => State;
  handleCloseUpdatePlannedDate: (state: State, action: Action) => State;
  resetJobFilter: (state: State, action: Action) => State;
  resetJobStatus: (state: State, action: PayloadAction<{ jobId: string }>) => State;
  setJobFilter: (
    state: State,
    action: PayloadAction<{ jobFilter: JobFilter; hideNewJobNotification: boolean }>
  ) => State;
  setJobStatus: (
    state: State,
    action: PayloadAction<{ jobId: string; status: JobStatus }>
  ) => State;
  setPlannedDate: (
    state: State,
    action: PayloadAction<{ jobId: string; times: TimesType }>
  ) => State;
  setRejectJobOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setRejectJobText: (state: State, action: PayloadAction<{ text: string }>) => State;
  setUpdateEquipmentOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setEditContactOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setUpdatePlannedDateOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  clearNewJobIds: (state: State, action: Action) => State;
  filterNewJobIds: (state: State, action: PayloadAction<{ jobId: string }>) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  selectJobLoaded: (state: RootState) => boolean;
  selectJob: (state: RootState, id: string | undefined) => Job | undefined;
  selectJobs: (state: RootState) => Job[];
  selectIncompleteJobs: (state: RootState) => Job[];
  selectLoadingJobs: (state: RootState) => boolean;
  selectRejectJobOpen: (state: RootState) => boolean;
  selectRejectJobLoading: (state: RootState) => boolean;
  selectRejectJobText: (state: RootState) => string;
  selectUpdateEquipmentOpen: (state: RootState) => boolean;
  selectUpdateEquipmentLoading: (state: RootState) => boolean;
  selectUpdatePlannedDateOpen: (state: RootState) => boolean;
  selectUpdatePlannedDateLoading: (state: RootState) => boolean;
  selectJobFilter: (state: RootState) => JobFilter;
  selectJobFilterCount: (state: RootState) => number;
  selectEditContactOpen: (state: RootState) => boolean;
  selectEditContactLoading: (state: RootState) => boolean;
  selectNewJobIds: (state: RootState) => string[];
}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  jobs: {},
  lastLoaded: null,
  newJobIds: [],
  hideNewJobNotification: false,
  jobFilter: {
    ...defaultJobFilter,
  },
  filterOptions: {
    customers: [],
    loadingCustomers: false,
  },
  changeJobEquipment: {
    equipments: [],
    loading: false,
    loadingEquipments: false,
  },
  rejectJob: {
    open: false,
    loading: false,
    text: "",
  },
  updateEquipment: {
    open: false,
    loading: false,
  },
  editContact: {
    open: false,
    loading: false,
  },
  updatePlannedDate: {
    open: false,
    loading: false,
  },
};
/** Example state
 * feedback: false
 */

// Definition of actual actions
const actions: Actions = {
  getJobsOnQueued(state) {
    state.loadingJobs = false;
    return state;
  },
  setRejectJobOpen: (state, { payload: { open } }) => {
    state.rejectJob.open = open;
    return state;
  },
  setRejectJobText: (state, { payload: { text } }) => {
    state.rejectJob.text = text;
    return state;
  },
  handleCloseRejectJob: (state) => {
    state.rejectJob.text = "";
    state.rejectJob.open = false;
    return state;
  },
  setUpdateEquipmentOpen: (state, { payload: { open } }) => {
    state.updateEquipment.open = open;
    return state;
  },
  handleCloseUpdateEquipment: (state) => {
    state.updateEquipment.open = false;
    return state;
  },
  setUpdatePlannedDateOpen: (state, { payload: { open } }) => {
    state.updatePlannedDate.open = open;
    return state;
  },
  handleCloseUpdatePlannedDate: (state) => {
    state.updatePlannedDate.open = false;
    return state;
  },
  setPlannedDate: (state, { payload: { jobId, times } }) => {
    state.jobs[jobId].plannedDate = times;
    return state;
  },
  setJobFilter(state, { payload: { jobFilter, hideNewJobNotification } }) {
    state.jobFilter = jobFilter;
    state.hideNewJobNotification = hideNewJobNotification;
    return state;
  },
  resetJobFilter(state) {
    state.jobFilter = { ...defaultJobFilter };
    state.hideNewJobNotification = true;
    return state;
  },
  setJobStatus: (state, { payload: { jobId, status } }) => {
    if (!state.jobs[jobId] || state.jobs[jobId].status === status) return state;
    state.jobs[jobId].previousStatus = state.jobs[jobId].status || undefined;
    state.jobs[jobId].status = status;
    return state;
  },
  resetJobStatus: (state, { payload: { jobId } }) => {
    if (!state.jobs[jobId] || !state.jobs[jobId].previousStatus) return state;
    state.jobs[jobId].status = state.jobs[jobId].previousStatus;
    state.jobs[jobId].previousStatus = undefined;
    return state;
  },
  setEditContactOpen: (state, { payload: { open } }) => {
    state.editContact.open = open;
    return state;
  },
  clearNewJobIds: (state) => {
    state.newJobIds = [];
    return state;
  },
  filterNewJobIds: (state, { payload: { jobId } }) => {
    state.newJobIds = state.newJobIds.filter((id) => id !== jobId);
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  selectJobLoaded({ jobs: { jobs }, root: { selectedJobId } }) {
    if (selectedJobId && jobs[selectedJobId]) {
      return true;
    }
    return false;
  },
  selectJob({ jobs }, id) {
    return id ? jobs.jobs[id] : undefined;
  },
  selectJobs({ jobs }) {
    return jobsDictionaryToArray(jobs.jobs);
  },
  selectIncompleteJobs({ jobs }) {
    const jobList = jobsDictionaryToArray(jobs.jobs).filter(
      (j) => j.status !== JobStatus.Completed && j.status !== JobStatus.Rejected
    );
    return jobList;
  },
  selectLoadingJobs({ jobs: { loadingJobs } }) {
    return !!loadingJobs;
  },
  selectRejectJobOpen: ({ jobs: { rejectJob } }) => {
    return rejectJob.open;
  },
  selectRejectJobLoading: ({ jobs: { rejectJob } }) => {
    return rejectJob.loading;
  },
  selectRejectJobText: ({ jobs: { rejectJob } }) => {
    return rejectJob.text;
  },
  selectUpdateEquipmentOpen: ({ jobs: { updateEquipment } }) => {
    return updateEquipment.open;
  },
  selectUpdateEquipmentLoading: ({ jobs: { updateEquipment } }) => {
    return updateEquipment.loading;
  },
  selectUpdatePlannedDateOpen: ({ jobs: { updatePlannedDate } }) => {
    return updatePlannedDate.open;
  },
  selectUpdatePlannedDateLoading: ({ jobs: { updatePlannedDate } }) => {
    return updatePlannedDate.loading;
  },
  selectJobFilter({ jobs: { jobFilter } }) {
    return jobFilter;
  },
  selectJobFilterCount: ({ jobs: { jobFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of JobFilterKeys) {
      if (isEqual(defaultJobFilter[k], jobFilter[k]) || isEmpty(jobFilter[k])) continue;
      count++;
    }
    return count;
  },
  selectEditContactOpen: ({ jobs: { editContact } }) => {
    return editContact.open;
  },
  selectEditContactLoading: ({ jobs: { editContact } }) => {
    return editContact.loading;
  },
  selectNewJobIds: ({ jobs }) => {
    return jobs.newJobIds;
  },
};
/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

// * job: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "jobs",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;
export const {
  handleCloseRejectJob,
  handleCloseUpdateEquipment,
  handleCloseUpdatePlannedDate,
  resetJobFilter,
  resetJobStatus,
  setJobFilter,
  setJobStatus,
  setPlannedDate,
  setRejectJobOpen,
  setRejectJobText,
  setUpdateEquipmentOpen,
  setEditContactOpen,
  setUpdatePlannedDateOpen,
  clearNewJobIds,
  filterNewJobIds,
} = storeBase.actions;
export const {
  selectJobLoaded,
  selectJob,
  selectJobs,
  selectIncompleteJobs,
  selectLoadingJobs,
  selectRejectJobLoading,
  selectRejectJobOpen,
  selectRejectJobText,
  selectUpdateEquipmentLoading,
  selectUpdateEquipmentOpen,
  selectUpdatePlannedDateLoading,
  selectUpdatePlannedDateOpen,
  selectJobFilter,
  selectJobFilterCount,
  selectEditContactLoading,
  selectEditContactOpen,
  selectNewJobIds,
} = selectors;
export const {
  getJob,
  getJobs,
  getCustomers,
  getFiles,
  getNotes,
  getRelatedJobs,
  getVisits,
  getEquipment,
} = asyncQueries;

export const {
  rejectJob,
  acceptJob,
  updateEquipment,
  updatePlannedDate,
  changeJobEquipment,
  editContact,
} = asyncMutations;
