import { RequestMiddleware, Variables } from "graphql-request/build/esm/types";
import { isEqual } from "lodash";
import { getQueue, getQueueOpen, pushToQueue } from "./queue";

type RequestExtendedInit<V extends Variables = Variables> = RequestInit & {
  url: string;
  operationName?: string;
  variables?: V;
};

export const queueMiddleware: RequestMiddleware = async (request) => {
  // If open let through
  if (getQueueOpen()) return request;
  // Create abort controller, and set signal
  let abort = new AbortController();
  request.signal = abort.signal;

  // Queue request if not query or a duplicate
  let reason = await queueRequest(request);
  // Abort request
  abort.abort(reason);

  // Throw "AbortError" with reason
  // Used for checking if request is queued or not
  let err = new Error();
  err.message = reason;
  err.name = "AbortError";
  err.stack = undefined;
  throw err;
};
const queueRequest = async (request: RequestExtendedInit): Promise<string> => {
  // Destructure to get pieces of request that could be the same
  const { signal, headers, ...req } = request;
  // Check if request is correctly set up for queueing
  if (!(headers as any)["x-thunkName"]) {
    return "Unfit";
  }
  // If request is query, abort immediately. No need to queue
  const { query } = JSON.parse(req.body?.toString() || "{}");
  if (!(query || "").includes("mutation")) {
    return "Query";
  }
  // Check if request is already queued (that includes the payload)
  let key = req?.variables?.jobId as string | undefined;
  if ((await getQueue(key)).some(({ request: { signal, headers, ...r } }) => isEqual(r, req))) {
    return "Duplicate";
  }
  // Push request to queue
  await pushToQueue(request as any);
  // Request queued
  return "Queued";
};
