import { ServiceJob } from "operations/schema/schema";
import { IntlShape } from "react-intl";
import { toDate } from "./date";
import { sortByProperty } from "./sortBy";

export type SortingOrder = "asc" | "desc";

// Defines which properties/keys are allowed to sort by
export const jobSortingKeys = ["plannedDate"] as const;
export const historySortingKeys = ["completedDate"] as const;
// Supported but unused keys
const unusedSortingKeys = ["customer", "postalCode"] as const;
// // // //
export type JobSortingKey = (typeof jobSortingKeys)[number];
export type HistorySortingKey = (typeof historySortingKeys)[number];
type UnusedSortingKeys = (typeof unusedSortingKeys)[number];
export type SortingKey = JobSortingKey | HistorySortingKey;

// Defines which pages can sort, and/or which type of sorting you want
// You have to set this in the AppBar
export type SortingPage = "job" | "planner" | "history" | "serviceHistory";

export interface Sorting<T extends JobSortingKey | HistorySortingKey> {
  key: T;
  order: SortingOrder;
}
type Sorters = {
  [x in SortingKey | UnusedSortingKeys]: (
    a: ServiceJob,
    b: ServiceJob,
    order?: SortingOrder
  ) => 1 | -1 | 0;
};
const sorters: Sorters = {
  plannedDate: (a, b, order) => {
    const aVal = toDate(a.plannedDate?.startTime || a.responseDate)?.getTime();
    const bVal = toDate(b.plannedDate?.startTime || b.responseDate)?.getTime();
    if (!aVal) return 1;
    if (!bVal) return -1;
    return sortByProperty(aVal, bVal, order);
  },
  completedDate: (a, b, order) => {
    const aVal = toDate(a.completedDate)?.getTime();
    const bVal = toDate(b.completedDate)?.getTime();
    if (!aVal) return 1;
    if (!bVal) return -1;
    return sortByProperty(aVal, bVal, order);
  },
  customer: (a, b, order) => {
    const aVal = a.customer?.name;
    const bVal = b.customer?.name;
    if (!aVal) return 1;
    if (!bVal) return -1;
    return sortByProperty(aVal, bVal, order);
  },
  postalCode: (a, b, order) => {
    const aVal = a.customer?.address?.postalCode;
    const bVal = b.customer?.address?.postalCode;
    if (!aVal) return 1;
    if (!bVal) return -1;
    return sortByProperty(aVal, bVal, order);
  },
};
export const getSorter = (key: SortingKey) => sorters[key];

export const sortingOptionText = (intl: IntlShape, key: SortingKey): string => {
  if (key === "plannedDate") return intl.formatMessage({ id: "sorting.plannedDate" });
  if (key === "completedDate") return intl.formatMessage({ id: "sorting.completedDate" });
  if (key === "customer") return intl.formatMessage({ id: "general.customer" });
  if (key === "postalCode") return intl.formatMessage({ id: "general.postalCode" });
  throw Error("invalid key");
};
