import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import GestureIcon from "@mui/icons-material/Gesture";
import PaletteIcon from "@mui/icons-material/Palette";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { fabric } from "fabric";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";
import { FileType } from "operations/schema/schema";

import { nanoid } from "nanoid";
import { initCanvas } from "./Canvas";
import { EditPhotoCloseDialog } from "./EditPhotoCloseDialog";
import { EditPhotoColorDialog } from "./EditPhotoColorDialog";
import { EditPhotoSaveDialog } from "./EditPhotoSaveDialog";
import { EditPhotoTextDialog } from "./EditPhotoTextDialog";

const PREFIX = "EditPhoto";

const classes = {
  bottomNavigation: `${PREFIX}-bottomNavigation`,
  bottomNavigationAction: `${PREFIX}-bottomNavigationAction`,
  canvasContainerWhenMovable: `${PREFIX}-canvasContainerWhenMovable`,
  canvasContainerWhenSelectable: `${PREFIX}-canvasContainerWhenSelectable`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.bottomNavigation}`]: {
    flexWrap: "wrap",
  },

  [`& .${classes.bottomNavigationAction}`]: {
    flex: "1 0 21%",
  },

  [`& .${classes.canvasContainerWhenMovable}`]: {
    overflow: "scroll",
    height: `calc(100vh - 236px)`,
    // @ts-ignore: Unreachable code error
    pointerEvents: "auto !important",
    "& .upper-canvas, .lower-canvas,": {
      pointerEvents: "none",
    },
  },

  [`& .${classes.canvasContainerWhenSelectable}`]: {
    overflow: "scroll",
    height: `calc(100vh - 236px)`,
    "& .upper-canvas, .lower-canvas,": {
      cursor: "move",
      pointerEvents: "auto",
    },
  },
}));

interface EditPhotoProps {
  setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
  currentPhoto: FileType | undefined;
  onSave: (result: FileType) => void;
  openCloseConfirmation: boolean;
  setOpenCloseConfirmation: Dispatch<SetStateAction<boolean>>;
}

export const EditPhoto: FC<EditPhotoProps> = (props) => {
  const {
    currentPhoto,
    openCloseConfirmation,
    setOpenCloseConfirmation,
    setOpenEditDialog,
    onSave,
  } = props;
  const intl = useIntl();
  const [value, setValue] = useState("move");
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const [color, setColor] = useState("#da1b1e");
  const [size, setSize] = useState(5);
  const [open, setOpen] = useState<boolean>(false);
  const [openColorPalette, setOpenColorPalette] = useState<boolean>(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isMovable, setIsMovable] = useState(true);
  const file = currentPhoto;

  useEffect(() => {
    setCanvas(initCanvas("canvas", file));
  }, [file]);

  useEffect(() => {
    if (canvas !== undefined) {
      canvas.freeDrawingBrush.width = size;
    }
  }, [canvas, size]);

  useEffect(() => {
    if (canvas !== undefined) {
      canvas.freeDrawingBrush.color = color;
    }
  }, [canvas, color]);

  if (file === undefined) {
    return (
      <Container>
        <Typography variant="body1">
          {intl.formatMessage({ id: "editPhoto.noFileSelected" })}
        </Typography>
      </Container>
    );
  }

  const handleChange = (_: any, newValue: string) => {
    setValue(newValue);
    disableDrawingMode();
    setIsMovable(false);

    switch (newValue) {
      case "move":
        setIsMovable(true);
        break;
      case "color":
        setOpenColorPalette(true);
        break;
      case "draw":
        enableDrawingMode();
        break;
      case "square":
        insertSquare();
        break;
      case "arrow":
        insertArrow();
        break;
      case "circle":
        insertCircle();
        break;
      case "text":
        setOpen(true);
        break;
      case "delete":
        deleteObject();
        break;
    }
  };

  const clear = () => {
    const objects = canvas?.getObjects();
    objects?.map((obj) => canvas?.remove(obj));
  };

  const save = () => {
    const canvasData = canvas?.toDataURL({ format: "jpeg" });
    if (!canvasData) throw new Error("No image data");
    let id = currentPhoto?.id || nanoid();
    const updatedPhoto = {
      ...currentPhoto,
      id: id,
      name: currentPhoto?.name || id,
      data: canvasData,
    };

    onSave(updatedPhoto);

    setConfirmationDialog(false);
    setOpenEditDialog(false);
  };

  const insertSquare = () => {
    canvas?.add(
      new fabric.Rect({
        top: 100,
        left: 100,
        width: 50,
        height: 50,
        stroke: color,
        strokeWidth: size,
        fill: "transparent",
      })
    );
  };

  const insertArrow = () => {
    const triangle = new fabric.Triangle({
      width: 10,
      height: 15,
      fill: color,
      left: 235,
      top: 65,
      angle: 90,
      strokeWidth: size,
    });

    const line = new fabric.Line([50, 100, 200, 100], {
      left: 75,
      top: 70,
      stroke: color,
      strokeWidth: size,
    });

    const objs = [line, triangle];

    const alltogetherObj = new fabric.Group(objs);
    canvas?.add(alltogetherObj);
  };

  const insertCircle = () => {
    canvas?.add(
      new fabric.Circle({
        top: 140,
        left: 230,
        radius: 75,
        stroke: color,
        strokeWidth: size,
        fill: "transparent",
      })
    );
  };

  const deleteObject = () => {
    const activeObjects = canvas?.getActiveObjects();
    activeObjects?.map((obj) => canvas?.remove(obj));
  };

  const enableDrawingMode = () => {
    canvas!.isDrawingMode = true;
  };
  const disableDrawingMode = () => {
    canvas!.isDrawingMode = false;
  };

  return (
    <StyledContainer data-testid="EditPhotoDialog">
      <EditPhotoTextDialog canvas={canvas} color={color} open={open} setOpen={setOpen} />
      <EditPhotoColorDialog
        color={color}
        size={size}
        openColorPalette={openColorPalette}
        setOpenColorPalette={setOpenColorPalette}
        setColor={setColor}
        setSize={setSize}
      />
      <EditPhotoSaveDialog
        confirmationDialog={confirmationDialog}
        setConfirmationDialog={setConfirmationDialog}
        save={save}
      />
      <EditPhotoCloseDialog
        openCloseConfirmation={openCloseConfirmation}
        setConfirmationDialog={setConfirmationDialog}
        setOpenCloseConfirmation={setOpenCloseConfirmation}
        setOpenEditDialog={setOpenEditDialog}
        save={save}
      />
      <Toolbar>
        <Grid container justifyContent="space-around">
          <Grid item>
            <StyledButtonPrimaryMini
              startIcon={<CheckIcon />}
              onClick={() => setConfirmationDialog(true)}
              data-testid="EditPhotoSaveButton"
            >
              {intl.formatMessage({ id: "general.save" })}
            </StyledButtonPrimaryMini>
          </Grid>
          <Grid item>
            <StyledButtonSecondaryGreyMini startIcon={<ClearIcon />} onClick={clear}>
              {intl.formatMessage({ id: "general.clear" })}
            </StyledButtonSecondaryGreyMini>
          </Grid>
        </Grid>
      </Toolbar>
      <div
        className={
          isMovable ? classes.canvasContainerWhenMovable : classes.canvasContainerWhenSelectable
        }
      >
        <canvas id="canvas" />
      </div>
      <BottomNavigation value={value} onChange={handleChange} className={classes.bottomNavigation}>
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Move"
          value="move"
          icon={<ControlCameraIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Color"
          value="color"
          icon={<PaletteIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Draw"
          value="draw"
          icon={<GestureIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Square"
          value="square"
          icon={<CheckBoxOutlineBlankIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Arrow"
          value="arrow"
          icon={<ArrowRightAltIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Circle"
          value="circle"
          icon={<RadioButtonUncheckedIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Text"
          value="text"
          icon={<TextFieldsIcon />}
        />
        <BottomNavigationAction
          className={classes.bottomNavigationAction}
          label="Delete"
          value="delete"
          icon={<DeleteIcon />}
        />
      </BottomNavigation>
    </StyledContainer>
  );
};
