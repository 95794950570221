import { Button, styled } from "@mui/material";

const StyledButtonSecondaryGreyMini = styled(Button)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.dark,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
  border: `1px solid ${theme.palette.secondary.dark}`,
  boxShadow: "none",
  "&:disabled": {
    border: "none",
  },
}));

export default StyledButtonSecondaryGreyMini;
