import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const NoMatchPage: FC = () => {
  const location = useLocation();

  return (
    <div style={{ flexGrow: 1 }} data-testid="NoMatchPage">
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "primary.dark",
        }}
      >
        <Toolbar>
          <Link
            to="/"
            style={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            <Button
              variant="text"
              disableRipple
              startIcon={<ArrowBackIcon />}
              sx={{ color: "primary.contrastText" }}
            >
              Go back
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Grid container direction="column" alignItems="center" mt={2.5}>
        <Grid item>
          <Typography variant="h1" color="primary">
            404
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            No match for <code>{location.pathname}</code>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
