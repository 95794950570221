import Grid from "@mui/material/Grid";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import { findMatchingCode } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

export const VisitDetailsTab: FC = () => {
  const { causes } = useAppSelector((s) => s.cache);
  const intl = useIntl();
  const job = useAppSelector(selectPageSelectedJob);

  const action1 = job?.actions?.find((a) => a?.sortId === "1");
  const action2 = job?.actions?.find((a) => a?.sortId === "2");
  const action3 = job?.actions?.find((a) => a?.sortId === "3");

  const cause = findMatchingCode(causes, job?.cause?.id!);

  return (
    <Grid container direction="column" spacing={1} data-testid="VisitDetailsTab" p={2}>
      {cause && (
        <Grid item>
          <StyledTextFieldReadOnly
            name="causeId"
            className="causeId"
            label={intl.formatMessage({ id: "job.cause" })}
            value={`${cause.code} - ${cause.name}`}
            disabled
          />
        </Grid>
      )}
      {action1 && (
        <Grid item>
          <StyledTextFieldReadOnly
            name="actionId1"
            className="actionId1"
            label={intl.formatMessage({ id: "job.action" })}
            value={`${action1.code} - ${action1.name}`}
            disabled
          />
        </Grid>
      )}
      {action2 && (
        <Grid item>
          <StyledTextFieldReadOnly
            name="actionId2"
            className="actionId2"
            label={`${intl.formatMessage({ id: "job.action" })} 2`}
            value={`${action2.code} - ${action2.name}`}
            disabled
          />
        </Grid>
      )}
      {action3 && (
        <Grid item>
          <StyledTextFieldReadOnly
            name="actionId3"
            className="actionId3"
            label={`${intl.formatMessage({ id: "job.action" })} 3`}
            value={`${action3.code} - ${action3.name}`}
            disabled
          />
        </Grid>
      )}
      <Grid item>
        <StyledTextFieldReadOnly
          id="solutionDescription"
          name="solutionDescription"
          label={intl.formatMessage({ id: "job.solutionDescription" })}
          value={job?.solutionDescription ?? " "}
          multiline
          rows={10}
          disabled
        />
      </Grid>
    </Grid>
  );
};
