import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  Input,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscLocale,
  Popup,
  localeDe,
  localeEn,
  localeEs,
  localeFr,
  localeNo,
  localeSv,
  luxonTimezone,
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { DateType } from "@mobiscroll/react/dist/src/core/util/datetime";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import PrimaryButton from "components/PrimaryButton";
import { addHours } from "date-fns";
import { calculateUrgencyColorHex, formatDate, isAbortError, isEmpty } from "helpers";
import * as luxon from "luxon";
import { ServiceJob, TimesType } from "operations/schema/schema";
import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setCalendarSelectedDate } from "store/slices/calendar.store";
import { setPlannedDate, updatePlannedDate } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { isFlagEnabled } from "store/slices/user.store";
import { JobCalendarEvent } from "./JobCalendarEvent";

luxonTimezone.luxon = luxon;
luxonTimezone.version = 3;

const PREFIX = "JobCalendarView";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
    flex: 1,
    position: "relative",
  },
}));
interface JobCalendarViewProps {
  jobs: ServiceJob[];
  toLink?: string;
  loading?: boolean;
  disabled?: boolean;
  refetchJobs?: any;
  plannerMode?: boolean;
}

export const JobCalendarView: FC<JobCalendarViewProps> = (props) => {
  const { jobs, toLink, loading = false, refetchJobs, plannerMode } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { languageVar } = useAppSelector((state) => state.user);
  const {
    selectedDate: lastSelectedDate,
    view: scheduleView,
    hideWeekends,
  } = useAppSelector((state) => state.calendar);
  const changePlannedDateEnabled =
    useAppSelector((s) => isFlagEnabled(s, "ChangePlannedDate")) && !plannerMode;
  const [selectedDate, setSelectedDate] = useState<DateType>(lastSelectedDate ?? new Date());
  const [isOpen, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<HTMLElement>();
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [listEvents, setListEvents] = useState<MbscCalendarEvent[]>([]);

  const jobLookup: { [key: string]: MbscCalendarEvent } = {};
  jobs.forEach((job) => {
    jobLookup[job.id!] = { ...job };
  });

  const events = useMemo<MbscCalendarEvent[]>(() => {
    return jobs
      .filter((job) => job.plannedDate?.startTime || job.responseDate)
      .map((job) => {
        const dateText = job.plannedDate?.startTime || job.responseDate;
        const urgencyColor = calculateUrgencyColorHex(dateText);

        let start = job.plannedDate?.startTime || job.responseDate;
        let end = job.plannedDate?.stopTime;
        if ((end === null || end === undefined) && job.responseDate) {
          end = addHours(new Date(job.responseDate), 2).toISOString();
        }

        let event: MbscCalendarEvent = {
          id: job.id || undefined,
          title: job.externalId || undefined,
          color: urgencyColor,
          job: job,
          start: start ? new Date(start).toISOString() : undefined,
          end: end ? new Date(end).toISOString() : undefined,
        };

        return event;
      });
  }, [jobs]);

  const invalidDates = [
    {
      recurring: {
        repeat: "weekly",
        weekDays: "SA,SU",
      },
    },
  ];

  const view = useMemo<MbscEventcalendarView>(() => {
    if (scheduleView === "month") {
      return {
        calendar: { type: "month", labels: true },
        agenda: { type: "month" },
      };
    } else {
      return {
        schedule: {
          type: scheduleView,
          startDay: hideWeekends ? 1 : 0,
          endDay: hideWeekends ? 5 : 6,
        },
      };
    }
  }, [scheduleView, hideWeekends]);
  const listView = useMemo<MbscEventcalendarView>(
    () => ({
      agenda: {
        type: "year",
        size: 5,
      },
    }),
    []
  );

  const i18nLangToCalendarLocaleMap: { [key: string]: MbscLocale } = {
    de: localeDe,
    en: localeEn,
    es: localeEs,
    fr: localeFr,
    nn: localeNo,
    no: localeNo,
    sv: localeSv,
  };
  const calendarLocale = i18nLangToCalendarLocaleMap[languageVar];

  const searchInputRef = useCallback((input: Input) => {
    setSearchInput(input && input.nativeElement);
  }, []);
  const handleInputChange = useCallback(
    (ev: any) => {
      const text = ev.target.value;

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (text.length > 0) {
          setListEvents(
            events.filter((x) => {
              return (
                x.job?.externalId?.includes(text) ||
                x.job?.customer?.name?.toLowerCase().includes(text.toLowerCase())
              );
            })
          );
          setOpen(true);
        } else {
          setOpen(false);
        }
      }, 200);
    },
    [events]
  );
  const handleInputFocus = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value.length > 0) {
      setOpen(true);
    }
  }, []);
  const renderHeader = useCallback(
    () => (
      <>
        <CalendarNav />
        <div className="md-seach-header-bar">
          <Input
            startIcon="material-search"
            ref={searchInputRef}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            inputStyle="box"
            placeholder={intl.formatMessage({ id: "calendar.search" })}
          />
        </div>
        <CalendarPrev />
        <CalendarToday />
        <CalendarNext />
      </>
    ),
    [handleInputChange, handleInputFocus, intl, searchInputRef]
  );

  const renderEvent = (data: MbscCalendarEventData) => {
    return (
      <>
        {data.original && (
          <JobCalendarEvent job={data.original.job} showEngineerName={plannerMode} />
        )}
      </>
    );
  };

  const renderPopupEvent = (data: MbscCalendarEventData) => {
    return (
      <>
        {data.original && (
          <JobCalendarEvent job={data.original.job} hideTimes showEngineerName={plannerMode} />
        )}
      </>
    );
  };

  const updatePlannedDateCb = useCallback(
    (jobId: string, plannedTimes: TimesType) => {
      dispatch(updatePlannedDate({ jobId: jobId, times: plannedTimes }))
        .then(unwrapResult)
        .then(({ queued }) => {
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-success" }));
          }
          dispatch(setPlannedDate({ jobId: jobId, times: plannedTimes }));
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "UpdatePlannedDate-fail" }));
        });
    },
    [dispatch]
  );

  const dispatchSelectedDate = (date: DateType) => {
    if (typeof date === "object") {
      dispatch(setCalendarSelectedDate({ date: formatDate(date as Date) }));
    } else {
      dispatch(setCalendarSelectedDate({ date: formatDate(date) }));
    }
  };

  return (
    <>
      {isEmpty(jobs) && !loading ? (
        <>
          <Grid container direction="column" alignItems="center" mt={2.5}>
            <Grid item>
              <Typography>
                <FormattedMessage id="general.noJobsFound" />.
              </Typography>
            </Grid>
            {refetchJobs && (
              <Grid item>
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  data-testid="refetchJobs"
                  onClick={() => {
                    refetchJobs(true);
                  }}
                >
                  <FormattedMessage id="general.refreshJobList" />
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Root>
          {loading && <LinearProgress />}
          <Eventcalendar
            theme="material"
            themeVariant="light"
            locale={calendarLocale}
            view={view}
            invalid={hideWeekends ? invalidDates : undefined}
            data={events}
            selectedDate={selectedDate}
            immutableData={true}
            dragToMove={changePlannedDateEnabled}
            dragToResize={changePlannedDateEnabled}
            dragTimeStep={15}
            timezonePlugin={luxonTimezone}
            dataTimezone="utc"
            displayTimezone="local"
            onSelectedDateChange={(event) => {
              setSelectedDate(event.date);
              dispatchSelectedDate(event.date);
            }}
            onRightClick={function (event: any) {
              event.domEvent.preventDefault();
            }}
            onCellClick={function (event) {
              event.domEvent.preventDefault();
            }}
            onLabelClick={function (event) {
              event.domEvent.preventDefault();
            }}
            onCellRightClick={function (event) {
              event.domEvent.preventDefault();
            }}
            onEventRightClick={function (event) {
              event.domEvent.preventDefault();
            }}
            onEventUpdate={(event) => {
              event.domEvent.preventDefault();

              const newEvent = event.event;
              let start;
              let end;

              if (newEvent.start instanceof Date) {
                start = newEvent.start.toISOString();
              } else if (typeof newEvent.start === "string") {
                start = newEvent.start;
              }

              if (newEvent.end instanceof Date) {
                end = newEvent.end.toISOString();
              } else if (typeof newEvent.end === "string") {
                end = newEvent.end;
              }

              if (start && end) {
                const plannedTimes: TimesType = {
                  startTime: new Date(start).toISOString(),
                  stopTime: new Date(end).toISOString(),
                };
                updatePlannedDateCb(newEvent.job.id, plannedTimes);
              }
            }}
            onEventClick={(event) => {
              event.domEvent.preventDefault();
              navigate(`${toLink}/${event.event.id}`);
            }}
            renderHeader={renderHeader}
            renderEventContent={renderEvent}
            renderScheduleEventContent={renderEvent}
          />
          <Popup
            className="md-search-popup"
            display="anchored"
            showArrow={false}
            showOverlay={false}
            scrollLock={false}
            contentPadding={false}
            focusOnOpen={false}
            focusOnClose={false}
            anchor={searchInput}
            focusElm={searchInput}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          >
            <Eventcalendar
              className="mbsc-popover-list"
              view={listView}
              data={listEvents}
              showControls={false}
              timezonePlugin={luxonTimezone}
              dataTimezone="utc"
              displayTimezone="local"
              onEventClick={(event) => {
                event.domEvent.preventDefault();
                dispatchSelectedDate(event.event.start!);
                navigate(`${toLink}/${event.event.id}`);
              }}
              renderEventContent={renderPopupEvent}
            />
          </Popup>
        </Root>
      )}
    </>
  );
};
