import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FilePreview } from "components/FilePreview";
import { StyledContainer } from "components/StyledContainer";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

export const FilesTab: FC = () => {
  const { files } = useAppSelector(selectPageSelectedJob);

  return (
    <>
      {/* {loading && <LinearProgress data-testid="FilesTabLoading" />} */}
      <StyledContainer data-testid="FilesTab">
        {!files.length && (
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <NoteAddIcon color="secondary" fontSize="large" />
            </Grid>
            <Grid item>
              <Typography color="secondary">
                <FormattedMessage id="file.noFilesSelected" />
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={1}>
          {files.map(
            (f, index) => f && <FilePreview key={index} file={f} downloadOnCardClick readonly />
          )}
        </Grid>
      </StyledContainer>
    </>
  );
};
