import Autocomplete from "@mui/material/Autocomplete";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import StyledTextField from "components/StyledTextField";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";

import { QuestionTypeProps } from "./QuestionTypeProps";

export const DropDownType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  return (
    <Autocomplete
      fullWidth
      value={question.answer || null}
      options={question.dropDownValues}
      onChange={(e, value) => {
        onChange(value);
      }}
      size="small"
      renderInput={(params) =>
        readonly ? (
          <StyledTextFieldReadOnly
            key={question.nodeKey}
            {...params}
            value={question.answer}
            disabled
          />
        ) : (
          <StyledTextField
            key={question.nodeKey}
            {...params}
            value={question.answer}
            required={question.isRequired}
            helperText={errorText}
            error={!!errorText}
          />
        )
      }
      disabled={readonly}
      data-testid="DropDownType-Autocomplete"
    />
  );
};
