import { Dispatch, FC, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Autocomplete, Container, Grid } from "@mui/material";

import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { AddPartFilter } from "models/AddPartFilter";
import { useAppSelector } from "store";
import { isFlagEnabled, isProductEvatic, isProductVantageOnline } from "store/slices/user.store";

type AddPartFilterDialogProps = {
  filter: AddPartFilter;
  setFilter: Dispatch<SetStateAction<AddPartFilter>>;
  onReset: () => void;
  onSubmit: () => void;
};

export const AddPartFilterDialog: FC<AddPartFilterDialogProps> = (props) => {
  const { filter, setFilter, onReset, onSubmit } = props;
  const intl = useIntl();
  const { manufacturers, partSortGroups, suppliers } = useAppSelector((state) => state.cache);
  const isVantageOnline = useAppSelector(isProductVantageOnline);
  const isEvatic = useAppSelector(isProductEvatic);
  const partsSupplierFilterEnabled = useAppSelector((s) => isFlagEnabled(s, "PartsSupplierFilter"));

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container spacing={1}>
        {isVantageOnline && (
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={manufacturers}
              getOptionLabel={(option) => `${option.name}`}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label={intl.formatMessage({ id: "part.manufacturers" })}
                />
              )}
              value={filter.selectedManufacturers}
              onChange={(_, value) => {
                setFilter({
                  ...filter,
                  selectedManufacturers: value,
                });
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              data-testid="AddPartFilterDialog-Manufacturer"
            />
          </Grid>
        )}
        {isEvatic && (
          <>
            {partsSupplierFilterEnabled && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={suppliers}
                  getOptionLabel={(option) => `${option.name}`}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label={intl.formatMessage({ id: "part.suppliers" })}
                    />
                  )}
                  value={filter.selectedSuppliers}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      selectedSuppliers: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={!suppliers.length}
                  data-testid="AddPartFilterDialog-Suppliers"
                />
              </Grid>
            )}

            {!!partSortGroups?.sortGroup1Values?.length && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={partSortGroups.sortGroup1Values}
                  getOptionLabel={(option) => `${option.description}`}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={partSortGroups?.sortGroup1?.description} />
                  )}
                  value={filter.sortGroup1}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      sortGroup1: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  data-testid="AddPartFilterDialog-SortGroup1"
                />
              </Grid>
            )}
            {!!partSortGroups?.sortGroup2Values?.length && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={partSortGroups.sortGroup2Values}
                  getOptionLabel={(option) => `${option.description}`}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={partSortGroups?.sortGroup2?.description} />
                  )}
                  value={filter.sortGroup2}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      sortGroup2: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  data-testid="AddPartFilterDialog-SortGroup2"
                />
              </Grid>
            )}
            {!!partSortGroups?.sortGroup3Values?.length && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={partSortGroups.sortGroup3Values}
                  getOptionLabel={(option) => `${option.description}`}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={partSortGroups?.sortGroup3?.description} />
                  )}
                  value={filter.sortGroup3}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      sortGroup3: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  data-testid="AddPartFilterDialog-SortGroup3"
                />
              </Grid>
            )}
            {!!partSortGroups?.sortGroup4Values?.length && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={partSortGroups.sortGroup4Values}
                  getOptionLabel={(option) => `${option.description}`}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={partSortGroups?.sortGroup4?.description} />
                  )}
                  value={filter.sortGroup4}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      sortGroup4: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  data-testid="AddPartFilterDialog-SortGroup4"
                />
              </Grid>
            )}
            {!!partSortGroups?.sortGroup5Values?.length && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={partSortGroups.sortGroup5Values}
                  getOptionLabel={(option) => `${option.description}`}
                  renderInput={(params) => (
                    <StyledTextField {...params} label={partSortGroups?.sortGroup5?.description} />
                  )}
                  value={filter.sortGroup5}
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      sortGroup5: value,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  data-testid="AddPartFilterDialog-SortGroup5"
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} mt={2}>
          <SecondaryButton onClick={onReset} data-testid="AddPartFilterDialog-ResetButton">
            <FormattedMessage id="filter.reset" />
          </SecondaryButton>
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton onClick={onSubmit}>
            <FormattedMessage id="part.viewParts" />
          </PrimaryButton>
        </Grid>
      </Grid>
    </Container>
  );
};
