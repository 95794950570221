import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";

interface StyledFabProps extends FabProps {
  component?: ElementType<any> | undefined;
}

export const StyledFabFixed = styled((props: StyledFabProps) => <Fab {...props} />)(
  ({ theme }) => ({
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  })
);

export default StyledFabFixed;
