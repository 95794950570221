import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { GetChecklistGroupsQueryVariables } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./visit.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

// Definition of actual actions
export const asyncQueries = {
  getContacts: createAppAsyncThunk(
    "visit/getContacts",
    async (_, { rejectWithValue, getState, extra: { sdk } }) => {
      const {
        visit: { selectedJobId },
        jobs: { jobs },
      } = getState();
      if (!selectedJobId) return rejectWithValue("No selected job");
      const { customer } = jobs[selectedJobId];
      if (!customer || !customer.id) return rejectWithValue("No customer"); // Y doe????
      const { data, errors } = await graphqlRequest(sdk.getContacts, {
        variables: {
          customerId: customer?.id,
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.contacts) return rejectWithValue("something went wrong");
      return data.contacts;
    }
  ),
  //AddChecklistDialog
  getChecklistGroups: createAppAsyncThunk(
    "jobs/getChecklistGroups",
    async (variables: GetChecklistGroupsQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getChecklistGroups, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.checklistGroups) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getContacts.rejected, (state, { payload: errors }) => {
    state.contactsLoading = false;
    return state;
  });
  builder.addCase(asyncQueries.getContacts.pending, (state) => {
    state.contactsLoading = true;
    return state;
  });
  builder.addCase(asyncQueries.getContacts.fulfilled, (state) => {
    state.contactsLoading = false;
    return state;
  });
  // AddChecklistDialog
  builder.addCase(asyncQueries.getChecklistGroups.pending, (state) => {
    state.addChecklist.loading = true;
    return state;
  });
  builder.addCase(asyncQueries.getChecklistGroups.rejected, (state) => {
    state.addChecklist.loading = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getChecklistGroups.fulfilled,
    (state, { payload: { checklistGroups } }) => {
      state.addChecklist.loading = false;
      state.addChecklist.checklists = [...checklistGroups];
      return state;
    }
  );
};
