import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { AsolviTimePicker } from "components/AsolviTimePicker";
import { formatTime } from "helpers";

import { QuestionTypeProps } from "./QuestionTypeProps";

export const TimeType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");
  const [value, setValue] = useState<Date | null>(null);

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  useEffect(() => {
    if (question.answer) {
      const tempDate = new Date();

      const answerSplit = question.answer.split(":");

      if (answerSplit.length === 2) {
        tempDate.setHours(parseInt(answerSplit[0]));
        tempDate.setMinutes(parseInt(answerSplit[1]));

        setValue(tempDate);
      }
    }
  }, [question.answer]);

  return (
    <AsolviTimePicker
      onChange={(value) => {
        onChange(formatTime(value));
      }}
      name={question.question}
      value={value}
      required={question.isRequired}
      error={errorText}
      size="small"
      disabled={readonly}
    />
  );
};
