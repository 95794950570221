import { NoMatchPage } from "components/NoMatchPage";
import { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import HistoryJobProvider from "context/jobProviders/HistoryJobProvider";
import { HistoryDetailsPage } from "./HistoryDetailsPage";
import { HistoryVisitsPage } from "./HistoryVisitsPage";
import { LegacyHistoryDetailsPage } from "./LegacyHistoryDetailsPage";

const Router: FC = () => {
  const { id } = useParams();
  return (
    <Routes>
      <Route element={<HistoryJobProvider jobId={id} />}>
        <Route path="/*" element={<HistoryDetailsPage />} />
        <Route path="/visits" element={<HistoryVisitsPage />} />
        <Route path="/visits/legacy" element={<LegacyHistoryDetailsPage />} />
      </Route>
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
