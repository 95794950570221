import { IntlShape } from "react-intl";
import {
  enqueueGeneralErrorMessage,
  SnackbarMessage,
  SnackbarMessageKey,
  SnackbarMessages,
} from "./SnackbarMessages";

export const getSnackbarMessage = (
  key: SnackbarMessageKey,
  argument: string | undefined,
  intl: IntlShape
): SnackbarMessage | undefined => {
  let messageFunc = isValidSnackbarKey(key) ? SnackbarMessages[key] : enqueueGeneralErrorMessage;

  return {
    ...messageFunc(intl, argument),
    key,
  };
};

export function isValidSnackbarKey(key: string): key is SnackbarMessageKey {
  return key in SnackbarMessages;
}
