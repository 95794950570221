import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import FullScreenDialog from "components/FullScreenDialog";
import { scanditLicense } from "env";

import { getSymbologies } from "./Symbologies";

import {
  Barcode,
  BarcodeCapture,
  BarcodeCaptureOverlay,
  BarcodeCaptureSession,
  BarcodeCaptureSettings,
  barcodeCaptureLoader,
} from "scandit-web-datacapture-barcode";
import * as Scandit from "scandit-web-datacapture-core";
import {
  Camera,
  CameraSwitchControl,
  DataCaptureContext,
  DataCaptureView,
  FrameSourceState,
  LaserlineViewfinder,
  LaserlineViewfinderStyle,
  Viewfinder,
} from "scandit-web-datacapture-core";

const PREFIX = "BarcodeScannerDialog";

const classes = {
  barCodeContainer: `${PREFIX}-barCodeContainer`,
};

const StyledFullScreenDialog = styled(FullScreenDialog)(() => ({
  [`& .${classes.barCodeContainer}`]: {
    marginTop: "15px",
    marginLeft: "3px",
  },
}));

interface BarcodeScannerDialogProps {
  submitValue: (value: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BarcodeScannerDialog = (props: BarcodeScannerDialogProps) => {
  const { open, setOpen, submitValue } = props;
  const [init, setInit] = useState<boolean>();
  const [isPermissionGranted, setIsPermissionGranted] = useState<boolean>();

  const [turnOffCamera, setTurnOffCamera] = useState<() => void>(() => () => {});

  const pageElements = {
    captureHost: document.getElementById("data-capture-view") as HTMLElement,
  };

  useEffect(() => {
    if (!open) return;
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((t) => {
          t.stop();
        });
        setIsPermissionGranted(true);
      });
  }, [open]);
  const scanditConfigure = useCallback(async () => {
    if (!isPermissionGranted) return;
    let configDone = Scandit.configurePhase === "done";
    if (!configDone) {
      await Scandit.configure({
        licenseKey: scanditLicense()!,
        moduleLoaders: [barcodeCaptureLoader({ highEndBlurryRecognition: false })],
        libraryLocation:
          "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/",
      }).then(() => {
        setInit(true);
      });
    } else {
      setInit(true);
    }
  }, [isPermissionGranted]);
  useEffect(() => {
    scanditConfigure();
  }, [isPermissionGranted, scanditConfigure]);

  const takePhoto = useCallback(async () => {
    let configDone = Scandit.configurePhase === "done";
    if (!init || !open || !configDone) return;
    // Create the data capture context.
    const context: DataCaptureContext = await DataCaptureContext.create();
    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    const camera = Camera.default;
    camera.setMirrorImageEnabled(false);
    context.setFrameSource(camera);
    // The barcode capturing process is configured through barcode capture settings,
    // they are then applied to the barcode capture instance that manages barcode recognition.
    const settings: BarcodeCaptureSettings = new BarcodeCaptureSettings();
    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies(getSymbologies());
    // Filter out duplicate barcodes for 1 second.
    settings.codeDuplicateFilter = -1;

    // Create a new barcode capture mode with the settings from above.
    const barcodeCapture = await BarcodeCapture.forContext(context, settings);

    // // Set feedback to null to avoid beeps, vibrations and/or screen flashes
    // const feedback = Scandit.BarcodeCaptureFeedback.default;
    // feedback.success = new Scandit.Feedback(null, null);
    // barcodeCapture.feedback = feedback;

    // Disable the barcode capture mode until the camera is accessed.
    barcodeCapture.setEnabled(false);
    // Register a listener to get informed whenever a new barcode got recognized.
    barcodeCapture.addListener({
      didScan: (barcodeCapture: BarcodeCapture, session: BarcodeCaptureSession) => {
        turnOffCamera();
        const barcode: Barcode = session.newlyRecognizedBarcodes[0];
        if (barcode && barcode.data) submitValue(barcode.data);
      },
      // // Possible to add an on update if necessary
      // didUpdateSession(barcodeCapture, session) {
      // },
    });

    // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    const view = await DataCaptureView.forContext(context);

    // Connect the data capture view to the HTML element.
    view.connectToElement(pageElements.captureHost);

    // Add a control to be able to switch cameras.
    view.addControl(new CameraSwitchControl());

    // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
    // the video preview. This is optional, but recommended for better visual feedback.
    const barcodeCaptureOverlay: BarcodeCaptureOverlay =
      await BarcodeCaptureOverlay.withBarcodeCaptureForView(barcodeCapture, view);
    const viewfinder: Viewfinder = new LaserlineViewfinder(LaserlineViewfinderStyle.Animated);
    barcodeCaptureOverlay.setViewfinder(viewfinder);

    // Switch the camera on to start streaming frames.
    let turnOffCamera = () => {
      barcodeCapture.setEnabled(false);
      camera.switchToDesiredState(FrameSourceState.Off);
      context.dispose();
      setInit(undefined);
      setIsPermissionGranted(undefined);
      setTurnOffCamera(() => () => {});
    };
    setTurnOffCamera(() => turnOffCamera);

    camera.switchToDesiredState(FrameSourceState.On).then(() => {
      barcodeCapture.setEnabled(true);
    });
  }, [init, open, pageElements.captureHost, submitValue]);

  useEffect(() => {
    takePhoto();
  }, [init, open, pageElements.captureHost, submitValue, setTurnOffCamera, takePhoto]);

  return (
    <StyledFullScreenDialog
      child={
        isPermissionGranted ? (
          <>
            <div className="wrapper">
              <div className="top">
                <div
                  style={{
                    display: "grid",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100vw",
                    height: "100vh",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    id="data-capture-view"
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : isPermissionGranted === false ? (
          <Container className={classes.barCodeContainer}>
            <Typography>
              <FormattedMessage id="barcode.allowCameraAccess" />!
            </Typography>
          </Container>
        ) : (
          <Container className={classes.barCodeContainer}>
            <Typography>Loading...</Typography>
          </Container>
        )
      }
      open={open}
      setOpen={(open: boolean) => {
        if (!open) turnOffCamera();
        setOpen(open);
      }}
    />
  );
};
