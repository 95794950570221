import { fabric } from "fabric";
import { FileType } from "operations/schema/schema";
export const initCanvas = (componentId: string, file: FileType | undefined) => {
  const image = new Image();
  image.src = file?.data || "";
  const canvas = new fabric.Canvas(componentId, {
    height: image.height,
    width: image.width,
    backgroundImage: new fabric.Image(image),
    selectionBorderColor: "black",
    selectionColor: "black",
  });
  return canvas;
};
