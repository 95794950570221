import { NoMatchPage } from "components/NoMatchPage";
import { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import JobProvider from "context/jobProviders/JobProvider";
import { JobDetailsPage } from "./JobDetailsPage";
import { JobVisitPage } from "./JobVisitPage";

const Router: FC = () => {
  const { id } = useParams();
  return (
    <Routes>
      <Route element={<JobProvider jobId={id} />}>
        <Route path="/*" element={<JobDetailsPage />} />
        <Route path="/visit" element={<JobVisitPage />} />
      </Route>
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
