import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const EE = () => {
  return (
    <StyledContainer maxWidth="xs">
      <h1>Smartypants 😉</h1>
      <img
        src="https://media.giphy.com/media/RlH4gZYWOsAgg/giphy.gif"
        alt="smartypants"
      />
      <h4>Try using only one, that works much better</h4>
    </StyledContainer>
  );
};

export default EE;
