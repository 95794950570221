import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

interface DetailAccordionProps {
  open?: boolean;
  content: JSX.Element | JSX.Element[] | string;
}

export const DetailAccordion: FC<DetailAccordionProps> = (props) => {
  const { content, open: propsOpen } = props;
  const intl = useIntl();
  const [open, setOpen] = useState(propsOpen ?? false);

  return (
    <>
      <Grid
        container
        onClick={() => {
          setOpen(!open);
        }}
        spacing={1}
        data-testid="DetailAccordion"
      >
        <Grid item>
          <Typography fontWeight={700} color="secondary.dark" mb={1}>
            {intl.formatMessage({ id: "general.viewMoreDetails" })}
          </Typography>
        </Grid>
        <Grid item>
          {open ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
        </Grid>
      </Grid>
      <Collapse in={open}>{content}</Collapse>
    </>
  );
};
