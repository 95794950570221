import { endOfDay } from "date-fns";
import { isEqual } from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Autocomplete, CircularProgress, Container, Grid, Typography } from "@mui/material";

import { useDebounce } from "hooks/useDebounce";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { DateFilterType, FilterDateSelect } from "components/FilterDateSelect";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { addressToPostalLocation } from "helpers";
import {
  PlannerFilter,
  PlannerFilterDateTypes,
  PlannerFilterStorageKey,
  defaultPlannerFilter,
} from "models/PlannerFilter";
import { useAppDispatch, useAppSelector } from "store";
import { getCustomers, resetPlannerFilter, setPlannerFilter } from "store/slices/planner.store";
import { isFlagEnabled, isProductEvatic, isProductVantageOnline } from "store/slices/user.store";

type PlannerFilterDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (dirty: boolean) => void;
};

export const PlannerFilterDialog: FC<PlannerFilterDialogProps> = (props) => {
  const { open, setOpen, onClose } = props;
  const intl = useIntl();
  const { plannerNodes, symptoms } = useAppSelector((state) => state.cache);
  const {
    plannerFilter: filter,
    customers,
    engineers,
    loadingCustomers,
  } = useAppSelector((s) => s.planner);
  const dispatch = useAppDispatch();
  const isEvatic = useAppSelector(isProductEvatic);
  const isVantage = useAppSelector(isProductVantageOnline);
  const [jobId, setJobId] = useState<String>("");
  const [customerSearch, setCustomerSearch] = useState<string>("");
  const nameFilter = useDebounce<string>(customerSearch, 500);
  const serviceRegionFilterEnabled = useAppSelector((s) => isFlagEnabled(s, "ServiceRegionFilter"));
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      dispatch(getCustomers({ nameFilter }));
    }
  }, [nameFilter, dispatch, open]);

  const engineersList = [
    {
      id: "Unassigned",
      name: intl.formatMessage({ id: "general.unassigned" }),
    },
    ...engineers,
  ];

  const dateOptions: DateFilterType[] = [
    {
      label: intl.formatMessage({ id: "filter.next7days" }),
      value: "week",
      class: "e2e-next-7-days-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.nextMonth" }),
      value: "1month",
      class: "e2e-next-month-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.next3months" }),
      value: "3month",
      class: "e2e-next-3-months-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.specificDate" }),
      value: "specificDate",
      class: "e2e-specific-date-filter",
    },
  ];

  const setFilter = (filter: PlannerFilter) => {
    setIsDirty(true);
    dispatch(setPlannerFilter({ plannerFilter: filter }));
  };

  const setDate = function (type: PlannerFilterDateTypes) {
    var specificDate = null;
    if (type === "specificDate") specificDate = filter.specificDate;
    setFilter({
      ...filter,
      typeDate: type,
      jobId: null,
      specificDate: specificDate,
    });
  };
  const setSpecificDate = function (value: Date | null) {
    setFilter({
      ...filter,
      specificDate: value ? endOfDay(value) : null,
      jobId: null,
      typeDate: "specificDate",
    });
  };

  const resetFilter = () => {
    if (!isEqual(filter, defaultPlannerFilter)) {
      setIsDirty(true);
    }
    dispatch(resetPlannerFilter());
  };

  const saveFilter = () => {
    localStorage.setItem(PlannerFilterStorageKey, JSON.stringify(filter));
  };

  useEffect(() => {
    setJobId(filter.jobId || "");
  }, [filter, setJobId]);

  const handleClose = () => {
    const dirty = isDirty;
    saveFilter();
    setOpen(false);
    setIsDirty(false);
    onClose(dirty);
  };

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: "filter.setFilters" })}
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      child={
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            {isEvatic && (
              <Grid item xs={12}>
                <Autocomplete
                  options={plannerNodes}
                  getOptionLabel={(option) => `${option.label}`}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label={intl.formatMessage({ id: "filter.folder" })}
                    />
                  )}
                  value={filter.plannerNode}
                  data-testid="PlannerFilterDialog-PlannerNodeFilter"
                  onChange={(_, value) => {
                    setFilter({
                      ...filter,
                      plannerNode: value,
                      jobId: null,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.nodeKey === value.nodeKey}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "filter.job" })}
                data-testid="PlannerFilterDialog-JobFilter"
                value={jobId}
                onChange={(e) => {
                  setFilter({
                    ...defaultPlannerFilter,
                    jobId: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={engineersList}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label={intl.formatMessage({ id: "filter.engineers" })}
                  />
                )}
                value={filter.selectedEngineers}
                data-testid="PlannerFilterDialog-EngineerFilter"
                onChange={(_, value) => {
                  setFilter({
                    ...filter,
                    selectedEngineers: value,
                    jobId: null,
                  });
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={symptoms}
                getOptionLabel={(option) => `${option.description}`}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label={intl.formatMessage({ id: "filter.symptoms" })}
                  />
                )}
                value={filter.selectedSymptoms}
                data-testid="PlannerFilterDialog-SymptomFilter"
                onChange={(_, value) => {
                  setFilter({
                    ...filter,
                    selectedSymptoms: value,
                    jobId: null,
                  });
                }}
                isOptionEqualToValue={(option, value) => option.code === value.code}
              />
            </Grid>
            {serviceRegionFilterEnabled && (
              <Grid item xs={12}>
                <StyledTextField
                  label={intl.formatMessage({ id: "job.customer.serviceRegion" })}
                  data-testid="PlannerFilterDialog-ServiceRegionFilter"
                  value={filter.serviceRegion || ""}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      serviceRegion: e.target.value,
                      jobId: null,
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                data-testid="PlannerFilterDialog-CustomerFilter"
                value={filter.customer}
                onChange={(_, value) => {
                  setFilter({
                    ...filter,
                    customer: value,
                    jobId: null,
                  });
                }}
                getOptionLabel={(o) => `${o.name}`}
                renderOption={(props, option) => (
                  <li {...props} key={`${option.id}-${option.name}`} style={{ display: "block" }}>
                    <Typography variant="body1">{option.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {addressToPostalLocation(option.address, isVantage)}
                    </Typography>
                  </li>
                )}
                inputValue={customerSearch}
                onInputChange={(_, value) => {
                  setCustomerSearch(value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(x) => x}
                options={customers}
                loading={loadingCustomers}
                renderInput={(params) => (
                  <StyledTextField
                    name="customer"
                    {...params}
                    label={intl.formatMessage({
                      id: "general.customer",
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "filter.city" })}
                data-testid="PlannerFilterDialog-CityFilter"
                value={filter.city || ""}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    city: e.target.value,
                    jobId: null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                label={intl.formatMessage({ id: "general.postalCode" })}
                data-testid="PlannerFilterDialog-PostalCodeFilter"
                value={filter.postalCode || ""}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    postalCode: e.target.value,
                    jobId: null,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterDateSelect value={filter.typeDate} options={dateOptions} onChange={setDate} />
            </Grid>
            {filter.typeDate === "specificDate" && (
              <Grid item xs={12}>
                <AsolviDatePicker
                  data-testid="PlannerFilterDialog-DatePickerFilter"
                  onChange={(value) => {
                    setSpecificDate(value);
                  }}
                  value={filter.specificDate}
                  label={intl.formatMessage({ id: "filter.specificDate" })}
                  clearable
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SecondaryButton onClick={resetFilter} data-testid="PlannerFilterDialog-ResetButton">
                <FormattedMessage id="filter.reset" />
              </SecondaryButton>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                data-testid="PlannerFilterDialog-SubmitButton"
                onClick={handleClose}
                sx={{ mt: 0 }}
                className="e2e-close-filter-button"
              >
                <FormattedMessage id="general.viewJobs" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
