import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Dialog, IconButton, List, Toolbar, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";
import { QueueHeader } from "./queue/QueueHeader";

import { Queue, getQueues } from "context/graphql/queue";
import { useAppDispatch, useAppSelector } from "store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";

interface QueueDialogProps {}

export const QueueDialog: FC<QueueDialogProps> = () => {
  const intl = useIntl();
  const [queue, setQueue] = useState<Queue>({});

  const dispatch = useAppDispatch();
  const { queue: open } = useAppSelector((state) => state.dialog);
  const setOpen = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "queue", open }));
  };

  useEffect(() => {
    if (open) getQueues().then((q) => setQueue(q));
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
      data-testid="queueDialog"
    >
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            <FormattedMessage id="queue.queuedOperations" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        <List>
          {Object.keys(queue).map((key) => (
            <QueueHeader key={key} queueKey={key} />
          ))}
        </List>
      </StyledContainer>
    </Dialog>
  );
};
