import { version } from "env";
import { ServiceHistoryFilterStorageKey } from "models/HistoryFilter";

// This can be reset after every "nuke"
export enum Versions {
  "1.7.0",
  "1.8.0",
}
type VersionString = keyof typeof Versions;

let cleanupPromise: Promise<void | undefined> = Promise.resolve(undefined);

export const versionCleanup = async () => {
  await startCleanup();
};

const startCleanup = (): Promise<void | undefined> => {
  cleanupPromise = cleanupPromise.then(cleanupCore);
  return cleanupPromise;
};

const cleanupCore = async () => {
  let storedVersion = localStorage.getItem("version") as VersionString | null | undefined;
  let currentVersion = version();
  if (storedVersion === currentVersion) return;
  if (storedVersion) {
    let version = Versions[storedVersion];
    let versions = Object.keys(cleanupActions) as VersionString[];
    let nextIndex = versions.findIndex((x) => Versions[x] > version);
    if (nextIndex !== -1) {
      for (let i = nextIndex; i < versions.length; i++) {
        await cleanupActions[versions[i]]();
      }
    }
  } else {
    localStorage.clear();
  }
  localStorage.setItem("version", currentVersion);
};

type CleanupActions = {
  [x: string]: (() => void) | (() => Promise<void>);
};

// This can be reset after every "nuke"
const cleanupActions: CleanupActions = {
  "1.7.0": () => {
    // Left as an example. Do necessary cleanup for this version in these
  },
  "1.8.0": () => {
    localStorage.removeItem(ServiceHistoryFilterStorageKey);
  },
};
