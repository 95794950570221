import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import { WorkTaskSwitch } from "components/WorkTaskSwitch";
import { toDateString } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { selectSelectedJobVisit, updateWorkTask } from "store/slices/visit.store";

import StyledTextField from "components/StyledTextField";
import { TabProps } from "./TabProps";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const TasksTab: FC<TabProps> = (props) => {
  const { setFab } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { workTasks } = useAppSelector(selectSelectedJobVisit);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fab = undefined;
    setFab(fab);
  }, [setFab]);

  return loading ? (
    <BackdropPrimaryMain open={loading} onClick={() => setLoading(false)}>
      <CircularProgress color="inherit" />
    </BackdropPrimaryMain>
  ) : (
    <StyledContainer data-testid="TasksTab">
      <Grid item container direction="column" rowSpacing={1}>
        {workTasks?.map((wt) => (
          <div key={wt.id}>
            <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
              <Grid item maxWidth="60%">
                <Typography>{wt.name}</Typography>
              </Grid>
              <Grid item>
                <WorkTaskSwitch
                  onChange={(_, checked) => {
                    let workTask = {
                      ...wt,
                      completedDate: checked ? toDateString(new Date()) : undefined,
                    };
                    dispatch(updateWorkTask({ workTask }));
                  }}
                  checked={!!wt.completedDate}
                />
              </Grid>
            </Grid>
            <Grid item mb={2}>
              <StyledTextField
                value={wt.description || ""}
                label={intl.formatMessage({ id: "general.comment" })}
                onChange={(e) => {
                  let workTask = { ...wt, description: e.target.value };
                  dispatch(updateWorkTask({ workTask }));
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </div>
        ))}
      </Grid>
    </StyledContainer>
  );
};
