import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { FeedbackMutationVariables } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./feedback.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

// Definition of actual actions
export const asyncMutations = {
  sendFeedback: createAppAsyncThunk(
    "user/sendFeedback",
    async (props: FeedbackMutationVariables, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.feedback, {
        thunkName: "user/sendFeedback",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.feedback) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.sendFeedback.pending, (state, { meta }) => {
    if (meta.queued) return state;
    return state;
  });
  builder.addCase(asyncMutations.sendFeedback.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    return state;
  });
  builder.addCase(asyncMutations.sendFeedback.fulfilled, (state) => {
    state.text = "";
    state.stars = null;
    state.open = false;
    return state;
  });
};
