export const languages = ["en", "sv", "nb", "nn", "de", "fr", "es"] as const;
export type Language = "en" | "sv" | "nb" | "nn" | "de" | "fr" | "es";

export const getUserSettingLanguage = (): Language | undefined => {
  const userLang = localStorage.getItem("language");
  return languages.find((lang) => lang === userLang);
};

export const getBrowserSetting = (): Language | undefined => {
  const nav = navigator.language;
  if (nav.startsWith("sv")) return "sv";
  if (nav.startsWith("no")) return "nb";
  if (nav.startsWith("nb")) return "nb";
  if (nav.startsWith("nn")) return "nn";
  if (nav.startsWith("de")) return "de";
  if (nav.startsWith("fr")) return "fr";
  if (nav.startsWith("es")) return "es";
};

export const getDefaultUserLanguage = (): Language => {
  const defaultLang = getUserSettingLanguage() || getBrowserSetting() || "en";
  localStorage.setItem("language", defaultLang);
  return defaultLang;
};
