import { Done as DoneIcon } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  Fade,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { FC, MutableRefObject, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import { JobCategoryType, ServiceLevelType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { isFlagEnabled, isProductEvatic, selectEngineerSettings } from "store/slices/user.store";
import { selectSelectedJobVisit, setVisitValue } from "store/slices/visit.store";

import { AsolviSwitch } from "components/AsolviSwitch";
import BackdropWhite from "components/BackdropWhite";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import { SymptomAutocomplete } from "components/SymptomAutocomplete";
import { EngineerSignoff } from "./EngineerSignoff";
import { JobVisitSummary } from "./JobVisitSummary";

interface SignoffDialogProps {
  handleSignature: (added: boolean) => void;
  disableNextButton: boolean;
  canvasRef: MutableRefObject<ReactSignatureCanvas | null>;
  loading: boolean;
  openCustomerReview: () => void;
}

export const EngineerSignoffComponent: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { handleSignature, canvasRef, disableNextButton, loading, openCustomerReview } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const {
    signoffErrors,
    solutionDescription,
    changeServiceTerms,
    serviceLevel,
    jobCategory,
    followUp,
  } = useAppSelector(selectSelectedJobVisit);
  const { jobCategories, symptoms, serviceLevels } = useAppSelector((state) => state.cache);
  const engineerSettings = useAppSelector(selectEngineerSettings);
  const changeServiceTermsEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangeServiceTerms"));
  const isEvatic = useAppSelector(isProductEvatic);

  const [symptomErrors, setSymptomErrors] = useState({ symptomCode1: null });
  const requireEngineerSignature = engineerSettings?.requireEngineerSignature ?? false;
  const canChangeServiceLevel =
    changeServiceTermsEnabled && (engineerSettings?.canChangeServiceLevel ?? false);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <JobVisitSummary isEngineerSummaryPage={true} />
      </Grid>
      {canChangeServiceLevel && (
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              <FormattedMessage id="visit.changeServiceTermsPrompt" />
            </Typography>
          </Grid>
          <Grid item>
            <AsolviSwitch
              checked={changeServiceTerms}
              onChange={() =>
                dispatch(
                  setVisitValue({
                    key: "changeServiceTerms",
                    value: !changeServiceTerms,
                  })
                )
              }
              data-testid="EngineerSignoffDialog-ChangeServiceTerms"
            />
          </Grid>
        </Grid>
      )}
      {changeServiceTerms && (
        <Fade in={changeServiceTerms}>
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item width="100%" marginTop="16px">
              <Autocomplete
                fullWidth
                options={serviceLevels || []}
                getOptionLabel={(serviceLevel: ServiceLevelType) => `${serviceLevel.name}`}
                getOptionDisabled={(option) => {
                  return option.id === serviceLevel?.id;
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value: ServiceLevelType | null) => {
                  dispatch(setVisitValue({ key: "serviceLevel", value }));
                }}
                value={serviceLevel}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    error={!!signoffErrors?.serviceLevel}
                    label={intl.formatMessage({ id: "visit.serviceTerms" })}
                  />
                )}
                data-testid="EngineerSignoffDialog-ServiceLevel"
              />
              <FormHelperText error>{signoffErrors?.serviceLevel}</FormHelperText>
            </Grid>
          </Grid>
        </Fade>
      )}
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="body2">
            <FormattedMessage id="visit.followUp.required" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            key={`${followUp.followUpDisabled}`}
            checked={followUp.followUpChecked}
            onChange={() =>
              dispatch(
                setVisitValue({
                  key: "followUp",
                  value: { ...followUp, followUpChecked: !followUp.followUpChecked },
                })
              )
            }
            disabled={followUp.followUpDisabled}
            data-testid="EngineerSignoffDialog-FollowUpButton"
          />
        </Grid>
      </Grid>
      {followUp.followUpChecked && (
        <Fade in={followUp.followUpChecked}>
          <Grid item container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="body2">
                <FormattedMessage id="visit.followUp.isFitParts" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key={`${followUp.followUpPartsDisabled}`}
                checked={followUp.followUpPartsChecked}
                disabled={followUp.followUpPartsDisabled}
                onChange={() =>
                  dispatch(
                    setVisitValue({
                      key: "followUp",
                      value: {
                        ...followUp,
                        followUpPartsChecked: !followUp.followUpPartsChecked,
                      },
                    })
                  )
                }
                data-testid="EngineerSignoffDialog-FitPartsButton"
              />
            </Grid>
            {isEvatic ? (
              <Grid item width="100%" marginTop="16px">
                <StyledTextField
                  id="reason"
                  name="reason"
                  label={intl.formatMessage({ id: "visit.followUp.reason" })}
                  value={solutionDescription}
                  onChange={({ target: { value } }) => {
                    dispatch(setVisitValue({ key: "solutionDescription", value }));
                  }}
                  multiline
                  rows={10}
                />
                {solutionDescription.length === 0 &&
                  engineerSettings?.requiredSolutionDescription && (
                    <FormHelperText error>
                      {intl.formatMessage({
                        id: "visit.followUp.reasonRequired",
                      })}
                    </FormHelperText>
                  )}
              </Grid>
            ) : (
              <Grid item width="100%" marginTop="16px">
                <Autocomplete
                  fullWidth
                  options={jobCategories || []}
                  getOptionLabel={(category: JobCategoryType) => `${category.text}`}
                  isOptionEqualToValue={(option, value) => option.typeId === value.typeId}
                  onChange={(_, value: JobCategoryType | null) => {
                    if (followUp.symptomCode1 && followUp.symptomCode1 !== "") {
                      const symptom = symptoms.find((x) => x.code === followUp.symptomCode1);
                      if (symptom?.typeId && symptom.typeId !== value?.typeId) {
                        dispatch(
                          setVisitValue({
                            key: "followUp",
                            value: { ...followUp, symptomCode1: "" },
                          })
                        );
                      }
                    }
                    dispatch(setVisitValue({ key: "jobCategory", value }));
                  }}
                  value={jobCategory}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      error={!!signoffErrors.jobCategory}
                      label={intl.formatMessage({ id: "job.category" })}
                    />
                  )}
                  data-testid="EngineerSignoffDialog-CategoryField"
                />
                {signoffErrors.jobCategory && (
                  <FormHelperText error>{signoffErrors.jobCategory}</FormHelperText>
                )}
                <Grid item width="100%" marginTop="16px">
                  <SymptomAutocomplete
                    symptoms={symptoms}
                    category={jobCategory}
                    symptomCode1={followUp.symptomCode1}
                    onChange={(field, value, shouldValidate) => {
                      let newFollowUp = { ...followUp };
                      newFollowUp[field] = value;
                      dispatch(
                        setVisitValue({
                          key: "followUp",
                          value: { ...followUp, [field]: value },
                        })
                      );
                      if (!value && shouldValidate) {
                        setSymptomErrors({
                          ...symptomErrors,
                          [field]: intl.formatMessage({
                            id: "visit.symptomRequired",
                          }),
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item width="100%" marginTop="16px">
                  <StyledTextField
                    id="reason"
                    name="reason"
                    label={intl.formatMessage({
                      id: "job.symptomDescription",
                    })}
                    value={followUp.symptomDescription}
                    error={followUp.symptomDescription?.length === 0}
                    onChange={(event) => {
                      dispatch(
                        setVisitValue({
                          key: "followUp",
                          value: { ...followUp, symptomDescription: event.target.value },
                        })
                      );
                    }}
                    multiline
                    rows={10}
                  />
                </Grid>
                {followUp.symptomDescription?.length === 0 && (
                  <FormHelperText error>
                    {intl.formatMessage({
                      id: "visit.symptomRequired",
                    })}
                  </FormHelperText>
                )}
              </Grid>
            )}
          </Grid>
        </Fade>
      )}

      {requireEngineerSignature && (
        <Grid item sx={{ width: "100%" }}>
          <EngineerSignoff canvasReference={canvasRef} handleSignature={handleSignature} />
        </Grid>
      )}
      <Grid item pb={1.5}>
        <BackdropWhite open={loading}>
          <CircularProgress color="inherit" />
        </BackdropWhite>

        <PrimaryButton
          key={`to-customer-summary-button`}
          startIcon={<DoneIcon />}
          variant="contained"
          onClick={openCustomerReview}
          fullWidth
          disabled={disableNextButton}
        >
          <FormattedMessage id="general.next" />
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
