export type AuthState = {
  email?: string;
  isLoggedIn?: boolean;
  message?: string; // TODO; Remove when local state implemented
  expiry?: string; // Needs to be string when stored in redux
  token?: string;
};

export const getInitialAuthState = (): AuthState => {
  const getDataFromLocalStorage = JSON.parse(
    localStorage.getItem("authState") || "{}"
  );
  return {
    ...getDataFromLocalStorage,
    isLoggedIn: getDataFromLocalStorage.isLoggedIn || false,
  };
};

/** 08.12.22
export type LoginResult = {
  __typename?: 'LoginResult';
  // The email of the logged in user
  email: Scalars['String'];
  // Token expiry
  expiry: Scalars['DateTime'];
  // The external GUID of the logged in user
  externalGuid?: Maybe<Scalars['ID']>;
  // The external id of the logged in user
  externalId: Scalars['String'];
  // The full name of the logged in user
  fullName?: Maybe<Scalars['String']>;
  // The id of the logged in user
  id: Scalars['String'];
  // The user prefered language
  languageCode?: Maybe<Scalars['String']>;
  // The product the user is assigned to
  product: Scalars['String'];
  // The engineer own stock id
  stockId?: Maybe<Scalars['String']>;
  // The default supplier stock for the user
  suppliedStoreId?: Maybe<Scalars['Int']>;
  // JWT
  token: Scalars['String'];
  // User's consents
  userConsents: Array<UserConsentType>;
};
*/
