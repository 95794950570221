import { Container, Grid } from "@mui/material";
import { endOfDay } from "date-fns";
import { isEqual } from "lodash";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  HistoryFilter,
  HistoryFilterDateTypes,
  HistoryFilterStorageKey,
  defaultHistoryFilter,
} from "models/HistoryFilter";
import { useAppDispatch, useAppSelector } from "store";
import {
  resetHistoryFilter,
  selectHistoryFilter,
  setHistoryFilter,
} from "store/slices/history.store";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { DateFilterType, FilterDateSelect } from "components/FilterDateSelect";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";

type HistoryFilterDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (dirty: boolean) => void;
};

export const HistoryFilterDialog: FC<HistoryFilterDialogProps> = (props) => {
  const { open, setOpen, onClose } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectHistoryFilter);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const dateOptions: DateFilterType[] = [
    {
      label: intl.formatMessage({ id: "filter.last7days" }),
      value: "week",
      class: "e2e-last-7-days-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.lastMonth" }),
      value: "1month",
      class: "e2e-last-month-filter",
    },
    {
      label: intl.formatMessage({ id: "filter.specificDate" }),
      value: "specificDate",
      class: "e2e-specific-date-filter",
    },
  ];

  const setDate = function (type: HistoryFilterDateTypes) {
    var specificDate = null;
    if (type === "specificDate") specificDate = filter.specificDate;
    setFilter({
      ...filter,
      typeDate: type,
      specificDate: specificDate,
    });
  };
  const setSpecificDate = function (value: Date | null) {
    setFilter({
      ...filter,
      specificDate: value ? endOfDay(value) : null,
      typeDate: "specificDate",
    });
  };

  const setFilter = (filter: HistoryFilter) => {
    setIsDirty(true);
    dispatch(setHistoryFilter({ historyFilter: filter }));
  };

  const resetFilter = () => {
    if (!isEqual(filter, defaultHistoryFilter)) {
      setIsDirty(true);
    }
    dispatch(resetHistoryFilter());
  };

  const saveFilter = () => {
    localStorage.setItem(HistoryFilterStorageKey, JSON.stringify(filter));
  };

  const handleClose = () => {
    const dirty = isDirty;
    saveFilter();
    setOpen(false);
    setIsDirty(false);
    onClose(dirty);
  };

  return (
    <FullScreenDialog
      title={intl.formatMessage({ id: "filter.setFilters" })}
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      child={
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FilterDateSelect value={filter.typeDate} options={dateOptions} onChange={setDate} />
            </Grid>
            {filter.typeDate === "specificDate" && (
              <Grid item xs={12}>
                <AsolviDatePicker
                  data-testid="HistoryFilterDialog-DatePickerFilter"
                  onChange={(value) => {
                    setSpecificDate(value);
                  }}
                  value={filter.specificDate}
                  label={intl.formatMessage({ id: "filter.specificDate" })}
                  clearable
                />
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <SecondaryButton onClick={resetFilter} data-testid="HistoryFilterDialog-ResetButton">
                <FormattedMessage id="filter.reset" />
              </SecondaryButton>
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                data-testid="HistoryFilterDialog-SubmitButton"
                onClick={handleClose}
                className="e2e-close-filter-button"
              >
                <FormattedMessage id={"general.viewJobs"} />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
