import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ChecklistTypeFormHelperText } from "./ChecklistTypeFormHelperText";
import { QuestionTypeProps } from "./QuestionTypeProps";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    border: `1px solid ${theme.palette.grey[400]} !important`,
    borderRadius: theme.shape.borderRadius,
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      marginLeft: "unset",
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(0.5),
    },
    "&:last-of-type": {
      marginLeft: theme.spacing(0.5),
    },
    "&.Mui-selected": {
      border: 0,
      color: theme.palette.common.white,
      "&.MuiToggleButton-success": {
        backgroundColor: theme.palette.success.main,
      },
      "&.Mui-error": {
        backgroundColor: theme.palette.error.main,
      },
      "&.MuiToggleButton-standard": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}));

export const BooleanType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  const iconStyle = { marginLeft: 1 };
  const errorStyle = {
    outlineColor: "red",
    outlineWidth: "1px",
    outlineStyle: "solid",
    margin: "2px",
  };

  const sx = !!errorText ? errorStyle : undefined;

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  return (
    <>
      <StyledToggleButtonGroup
        value={question.answer}
        onChange={(_, value) => {
          if (question.isRequired) {
            if (value !== null) {
              onChange(value);
            }
          } else {
            onChange(value);
          }
        }}
        exclusive
        fullWidth
        size="small"
        aria-describedby="bool-type-error-text"
        disabled={readonly}
        data-testid="BooleanTypeToggle"
      >
        <ToggleButton value="true" color="success" sx={sx}>
          <FormattedMessage id="general.yes" />
          <CheckIcon sx={iconStyle} />
        </ToggleButton>
        <ToggleButton value="false" color="error" sx={sx}>
          <FormattedMessage id="general.no" />
          <CloseIcon sx={iconStyle} />
        </ToggleButton>
        <ToggleButton value="N/A" sx={sx}>
          N/A
        </ToggleButton>
      </StyledToggleButtonGroup>
      <ChecklistTypeFormHelperText>{errorText}</ChecklistTypeFormHelperText>
    </>
  );
};
