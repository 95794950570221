import { FC, useState, PropsWithChildren } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { ChecklistQuestionType } from "operations/schema/schema";

interface GroupTypeProps extends PropsWithChildren {
  question: ChecklistQuestionType;
}

export const GroupType: FC<GroupTypeProps> = (props) => {
  const { question, children } = props;
  const [open, setOpen] = useState(false);

  return (
    <Grid item width="100%" mt={1} mb={1}>
      <Grid
        container
        onClick={() => {
          setOpen(!open);
        }}
        justifyContent="space-between"
        data-testid="GroupType"
      >
        <Grid item>
          <Typography fontWeight={700} fontSize="1.1rem" color="secondary.dark">
            {question.question}
          </Typography>
        </Grid>
        <Grid item>
          {open ? (
            <ExpandLess color="secondary" />
          ) : (
            <ExpandMore color="secondary" />
          )}
        </Grid>
      </Grid>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container direction="column" mt={1} mb={1}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};
