import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Dispatch, FC, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";
import StyledButtonSecondaryMini from "components/StyledButtonSecondaryMini";

interface EditPhotoCloseDialogProps {
  openCloseConfirmation: boolean;
  setConfirmationDialog: Dispatch<SetStateAction<boolean>>;
  setOpenCloseConfirmation: Dispatch<SetStateAction<boolean>>;
  setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
  save: () => void;
}

export const EditPhotoCloseDialog: FC<EditPhotoCloseDialogProps> = (props) => {
  const {
    openCloseConfirmation,
    setConfirmationDialog,
    setOpenCloseConfirmation,
    setOpenEditDialog,
    save,
  } = props;

  return (
    <Dialog
      open={openCloseConfirmation}
      onClose={() => setConfirmationDialog(false)}
      aria-labelledby="close-dialog-title"
      data-testid="EditPhotoCloseDialog-Dialog"
    >
      <DialogTitle id="close-dialog-title">
        <FormattedMessage id="editPhoto.closePrompt" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="editPhoto.savingWillOverride" />.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-around"
          direction="column"
          spacing={2}
          alignItems="center"
        >
          <Grid item width="80%">
            <StyledButtonPrimaryMini
              variant="contained"
              fullWidth
              data-testid="EditPhotoCloseDialog-SaveAndCloseButton"
              onClick={() => {
                save();
                setOpenCloseConfirmation(false);
              }}
              startIcon={<CheckIcon />}
            >
              <FormattedMessage id="editPhoto.saveAndClose" />
            </StyledButtonPrimaryMini>
          </Grid>
          <Grid item width="80%">
            <StyledButtonSecondaryGreyMini
              variant="outlined"
              fullWidth
              data-testid="EditPhotoCloseDialog-CloseButton"
              onClick={() => {
                setOpenEditDialog(false);
                setOpenCloseConfirmation(false);
              }}
              startIcon={<ClearIcon />}
            >
              <FormattedMessage id="general.close" />
            </StyledButtonSecondaryGreyMini>
          </Grid>
          <Grid item width="80%">
            <StyledButtonSecondaryMini
              onClick={() => setOpenCloseConfirmation(false)}
              fullWidth
              data-testid="EditPhotoCloseDialog-CancelButton"
              variant="outlined"
            >
              <FormattedMessage id="general.cancel" />
            </StyledButtonSecondaryMini>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
