import { ReactNode, useEffect } from "react";
import { IntlProvider, createIntl, createIntlCache } from "react-intl";

import messages from "lang";
import { Language, getDefaultUserLanguage } from "models/Setting";
import { useAppDispatch, useAppSelector } from "store";
import { getEngineerSettings, getFeatureFlags, getUserSettings } from "store/slices/user.store";

const cache = createIntlCache();
let currentLang: Language = getDefaultUserLanguage();
let initIntl = (lang: Language) =>
  createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache
  );
let intl = initIntl(currentLang);

export const getIntl = () => {
  if (!intl) {
    intl = initIntl(currentLang);
  }
  return intl;
};

export default function SettingsProvider({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const {
    authVar: { isLoggedIn },
    userVar,
    languageVar,
  } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserSettings());
    }
  }, [dispatch, isLoggedIn]);
  useEffect(() => {
    if (!!userVar?.externalId) {
      dispatch(getFeatureFlags());
      dispatch(getEngineerSettings());
    }
  }, [dispatch, userVar?.externalId]);
  useEffect(() => {
    if (languageVar !== currentLang) {
      currentLang = languageVar;
      intl = initIntl(currentLang);
    }
  }, [dispatch, languageVar]);
  return (
    <IntlProvider locale={languageVar} messages={messages[languageVar]}>
      {children}
    </IntlProvider>
  );
}
