import { PartStatus, PartType } from "operations/schema/schema";

export const isPartUsed = (part?: PartType) => part?.status === PartStatus.Used;

export const isPartRequested = (part?: PartType) =>
part?.status === PartStatus.Requested;

export const isPartRequestedUsed = (part?: PartType) => part?.status === PartStatus.RequestedUsed;

export const isPartReturnRequested = (part?: PartType) =>
  part?.status === PartStatus.RequestedReturned;
