import { isAfter, isValid } from "date-fns";
import jwtDecode from "jwt-decode";

import { backendUrl } from "env";
import { RequestMiddleware } from "graphql-request/build/esm/types";
import { DecodedToken, RefreshTokenResponse, Token } from "models/authToken";
import { getStore } from "store";
import { selectAuth, setAuth } from "store/slices/user.store";
import { abortAll } from "./client";

let refreshingPromise: Promise<Token | undefined> = Promise.resolve(undefined);
export const resetAuthRefresh = () => {
  refreshingPromise = Promise.resolve(undefined);
};

// /////////////////////////////////////////////////////
// // FOR TESTING REFRESH TOKEN
// /////////////////////////////////////////////////////
// let lastRefresh = new Date().valueOf() + 30000;
// /////////////////////////////////////////////////////

export const authMiddleware: RequestMiddleware = async (request) => {
  const authVar = selectAuth(getStore().getState());
  if (!authVar.isLoggedIn) return request;
  let token = await getToken();
  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${token}`,
    },
  };
};

const getToken = (): Promise<Token | undefined> => {
  refreshingPromise = refreshingPromise.then(refreshCore);
  return refreshingPromise;
};

const refreshCore = (token: Token | undefined) => {
  const authVar = selectAuth(getStore().getState());
  token = token || authVar.token;
  if (!isTokenExpired(token, "exp") && !isTokenExpired(token, "accExp")) {
    return token;
  }
  return refreshToken(token);
};

const isTokenExpired = (token: Token | undefined, expiryField: "exp" | "accExp") => {
  if (!token) return true;
  // /////////////////////////////////////////////////////
  // // FOR TESTING REFRESH TOKEN
  // /////////////////////////////////////////////////////
  // if (new Date().valueOf() - lastRefresh > 30000) {
  //   lastRefresh = new Date().valueOf();
  //   return true;
  // }
  // ///////////////////////////////////////////////////////
  let decodedToken = jwtDecode<DecodedToken>(token);
  let expiry = decodedToken[expiryField];
  expiry = typeof expiry === "string" ? Number.parseFloat(expiry) : expiry;
  if (!expiry) return true;
  if (!isValid(new Date(expiry))) return true;
  if (isAfter(new Date(Date.now()), new Date(expiry))) return true;
  return false;
};

const refreshToken = async (token: string | undefined): Promise<Token | undefined> => {
  const authVar = selectAuth(getStore().getState());
  try {
    if (!token) return;
    const auth = await fetch(`${backendUrl()}/refresh`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = (await res.json()) as RefreshTokenResponse;
      if (!res.ok || !data.auth) {
        const error = new Error(data?.message || res.statusText || "Error");
        return Promise.reject(error);
      }
      return data.auth;
    });
    getStore().dispatch(
      setAuth({
        ...authVar,
        token: auth?.token,
        expiry: new Date(auth?.expiry).toISOString(),
      })
    );
    localStorage.setItem("authState", JSON.stringify(auth));
    return auth.token;
  } catch (e) {
    abortAll("TOKEN_EXPIRED");
    getStore().dispatch(
      setAuth({
        ...authVar,
        isLoggedIn: false,
        message: "Your session has expired, please log in again",
      })
    );
  }
};
