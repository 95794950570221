import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { Version } from "./Version";

const PREFIX = "Login";
const classes = {
  paper: `${PREFIX}-paper`,
};
const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const LoginContainer: FC<PropsWithChildren> = (props) => {
  return (
    <StyledContainer maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h2" variant="h5">
          <FormattedMessage id="general.welcomeTo" />
        </Typography>
        <Typography component="h1" variant="h4" color="primary">
          Asolvi Anywhere
        </Typography>
        {props.children}
        <Version />
      </div>
    </StyledContainer>
  );
};
export default LoginContainer;
