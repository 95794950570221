import { graphqlUrl } from "env";
import { GraphQLClient } from "graphql-request";
import { RequestConfig } from "graphql-request/build/esm/types";
import { getSdk as getSchemaSdk } from "operations/schema/schema";
import { authMiddleware } from "./auth";
import { errorMiddleware } from "./error";
import { queueMiddleware } from "./queueMiddleware";

let client: GraphQLClient;
let abortController = new AbortController();
let requestConfig: RequestConfig = {
  requestMiddleware: async (req) => {
    req = await queueMiddleware(req);
    if (!req.signal?.aborted) {
      req = await authMiddleware(req);
    }
    return req;
  },
  responseMiddleware: errorMiddleware,
  signal: abortController.signal,
};
export const getClient = () => {
  if (!client) {
    client = new GraphQLClient(graphqlUrl(), requestConfig);
  }
  return client;
};
export const getSdk = () => {
  return getSchemaSdk(getClient());
};
export const abortAll = (reason: string | undefined) => {
  abortController.abort(reason);
  abortController = new AbortController();
  client.requestConfig.signal = abortController.signal;
};
