import { getStore } from "store";

import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js";
import { selectAuth0 } from "store/slices/user.store";

let auth0: Auth0Client | undefined = undefined;
let isAuth0: boolean = false;

export const getAuth0 = () => {
  return auth0;
};
export const getIsAuth0 = () => {
  return isAuth0;
};
export const initializeAuth0 = () => {
  if (auth0) return;
  let { domain: d, clientId: c, audience: a } = selectAuth0(getStore().getState());
  const cOptions: Auth0ClientOptions = {
    domain: d || "",
    client_id: c || "",
    redirect_uri: `${window.location.origin}/login/auth0callback/`,
    useRefreshTokens: true,
    audience: a || "",
    cacheLocation: "localstorage",
  };
  if (cOptions.domain && cOptions.client_id && cOptions.audience) {
    auth0 = new Auth0Client(cOptions);
    isAuth0 = true;
  }
};
