import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { NoMatchPage } from "components/NoMatchPage";
import { HistoryPage } from "./HistoryPage";
import HistoryDetails from "./historyDetails";
import { ServiceHistoryPage } from "./historyDetails/ServiceHistoryPage";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HistoryPage />} />
      <Route path=":id/*" element={<HistoryDetails />} />
      <Route path="/:type/:typeId" element={<ServiceHistoryPage />} />
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
