import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import Grid from "@mui/material/Grid";

import { StyledChip } from "components/StyledChip";
import { StyledChips } from "components/StyledChips";
import { ServiceHistory } from "operations/schema/schema";
import { intlFormatDateWithWeekday } from "helpers";
import { JobStyles } from "styles/JobStyles";

const classes = JobStyles.createClasses();
const StyledBox = JobStyles.defineStyles(classes);

interface HistoryProps {
  svcHistory: ServiceHistory;
  loading?: boolean;
  toLink?: string;
}

export const HistoryItem: FC<HistoryProps> = (props) => {
  const { svcHistory, loading, toLink } = props;
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <StyledBox borderLeft={0} borderColor="grey.500">
      <ListItem
        button
        divider
        component="div"
        onClick={() =>
          navigate(
            `${toLink}/${svcHistory.serviceJobId}${
              svcHistory.isLegacy ? "/legacy" : ""
            }`,
            { state: { svcHistory: svcHistory } }
          )
        }
        key={svcHistory.serviceJobId}
        className={loading ? "" : "e2e-svcHistory"}
        data-testid="HistoryItem"
      >
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item xs={11} width="100%">
            <Grid container>
              <Grid item container justifyContent="space-between">
                <Grid item className={classes.jobTime}>
                  <Grid item container direction="row" alignItems="center">
                    {
                      <Typography className={classes.date}>
                        {intlFormatDateWithWeekday(intl, svcHistory.completedDate)}
                      </Typography>
                    }
                    {
                      <Typography className={classes.legacy}>
                        {svcHistory?.isLegacy ? "(Legacy)" : ""}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="body1">
              <span>{svcHistory.engineerName}</span>
            </Typography>
            <Typography variant="body1">
              <span className="e2e-customerName">{svcHistory.externalId}</span>
            </Typography>
            <Typography
              variant="body1"
              component={"span"}
              className={`${classes.ellipsis} ${classes.paragraph}`}
              mt={1}
            >
              {svcHistory.actionCodes?.map((ac) => (
                <StyledChip key={ac} label={ac} />
              ))}
            </Typography>
            <StyledChips
              symptoms={svcHistory.symptoms}
              category={svcHistory.category}
              priority={svcHistory.priority}
              causeCode={svcHistory.causeName}
            />
          </Grid>

          <Grid item xs={1}>
            <ChevronRightIcon />
          </Grid>
        </Grid>
      </ListItem>
    </StyledBox>
  );
};
