import { PreLoginResult } from "operations/schema/schema";

export type Auth0State = PreLoginResult;
export const getInitialAuth0LoginState = (): PreLoginResult => {
  const getDataFromLocalStorage = JSON.parse(
    localStorage.getItem("auth0Props") || "{}"
  );
  return {
    ...getDataFromLocalStorage,
    email: getDataFromLocalStorage.email || "",
  };
};
