import { Grid } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";

import { formatTimeDiff } from "helpers";
import { useAppSelector } from "store";

import { selectSelectedJob } from "store/slices/history.store";
import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export const WorkTab: FC = () => {
  const intl = useIntl();
  const { workTimes } = useAppSelector(selectSelectedJob);

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {workTimes.map((times, index) => {
          return (
            <Grid key={index} item>
              <TimeBlockComponent
                times={times}
                id={index}
                timesLabel="workTimes"
                timesText={intl.formatMessage({ id: "times.workTime" })}
                totalTime={formatTimeDiff(
                  intl,
                  Math.floor(
                    (new Date(times.stopTime!).getTime() - new Date(times.startTime!).getTime()) /
                      1000 /
                      60
                  )
                )}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} ml={1}>
          <TotalTimeComponent timesMerged={workTimes} />
        </Grid>
      </Grid>
    </>
  );
};
