import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { fabric } from "fabric";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";
import StyledTextField from "components/StyledTextField";

interface EditPhotoTextDialogProps {
  canvas: fabric.Canvas | undefined;
  color: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditPhotoTextDialog: FC<EditPhotoTextDialogProps> = (props) => {
  const intl = useIntl();
  const [text, setText] = useState<string>("");

  const { canvas, color, open, setOpen } = props;

  const insertText = () => {
    if (text.length) {
      canvas?.add(
        new fabric.Text(text, {
          left: 200,
          top: 100,
          fill: color,
          fontFamily: "Sans-serif",
        })
      );
      setText("");
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="insert-text"
      data-testid="insert-text-dialog"
    >
      <DialogTitle id="insert-text">
        {intl.formatMessage({ id: "editPhoto.insertText" })}
      </DialogTitle>
      <DialogContent>
        <StyledTextField
          autoFocus
          margin="dense"
          id="text"
          label="Text"
          type="text"
          multiline
          value={text}
          onChange={(e) => setText(e.target.value)}
          data-testid="EditPhotoTextDialog-TextField"
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-evenly" }}>
        <StyledButtonPrimaryMini onClick={insertText} data-testid="EditPhototextDialog-AddButton">
          {intl.formatMessage({ id: "general.add" })}
        </StyledButtonPrimaryMini>
        <StyledButtonSecondaryGreyMini
          onClick={() => {
            setText("");
            setOpen(false);
          }}
        >
          {intl.formatMessage({ id: "general.cancel" })}
        </StyledButtonSecondaryGreyMini>
      </DialogActions>
    </Dialog>
  );
};
