import { nanoid } from "nanoid";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import ImageIcon from "@mui/icons-material/Image";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FilePreview } from "components/FilePreview";
import { resizeUploadFile } from "helpers";
import { FileType } from "operations/schema/schema";

import { ChecklistTypeFormHelperText } from "./ChecklistTypeFormHelperText";
import { QuestionTypeProps } from "./QuestionTypeProps";

type ImageData = {
  name: string;
  data: string;
};

export const ImageType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  const errorStyle = {
    outlineColor: "red",
    outlineWidth: "1px",
    outlineStyle: "solid",
    margin: "2px",
  };

  const handleFileInput = async (e: any) => {
    const file = e.target.files[0];

    if (file) {
      resizeUploadFile(file).then((data) => {
        const output = typeof data === "string" ? data : "";
        const newFile: FileType = {
          id: nanoid(),
          name: file.name,
          data: output,
          description: "",
          size: output.length,
        };

        const tempImageArray = question?.answer ? (JSON.parse(question.answer) as ImageData[]) : [];

        tempImageArray.push(newFile);

        onChange(JSON.stringify(tempImageArray));
      });
    }

    e.target.value = null;
  };

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  const images = question?.answer ? (JSON.parse(question.answer) as ImageData[]) : null;
  return (
    <Grid item container direction="column" data-testid="ImageType">
      <Grid item container spacing={1}>
        {!readonly && (
          <>
            <Grid item xs={4}>
              <label htmlFor={question.nodeKey!}>
                <input
                  accept="image/*"
                  id={question.nodeKey!}
                  type="file"
                  onChange={handleFileInput}
                  style={{ display: "none" }}
                  data-testid="ImageTypeFileInput"
                />
                <Button
                  variant="contained"
                  color="info"
                  component="span"
                  size="small"
                  sx={!!errorText ? errorStyle : undefined}
                  fullWidth
                >
                  <ImageIcon />
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <ChecklistTypeFormHelperText>{errorText}</ChecklistTypeFormHelperText>
            </Grid>
          </>
        )}
        {images?.map((image, index) => (
          <FilePreview
            key={index}
            file={image}
            readonly={readonly}
            onDelete={() => {
              const tempImageArray = JSON.parse(question?.answer!) as ImageData[];

              tempImageArray.splice(index, 1);

              if (tempImageArray?.length > 0) {
                onChange(JSON.stringify(tempImageArray));
              } else {
                onChange(null);
              }
            }}
          />
        ))}
        {!images?.length && (
          <Grid item>
            <Typography>
              <FormattedMessage id="checklist.noImageAdded" />
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
