import { isValid } from "date-fns";
import * as yup from "yup";

import { getIntl } from "context/SettingsProvider";
import { ValidationError } from "yup";

export const ExtraValidationSchema = () => {
  const intl = getIntl();

  return yup.object({
    id: yup.string(),
    isTime: yup.boolean(),
    quantity: yup
      .number()
      .nullable()
      .typeError(intl.formatMessage({ id: "validation.number" }))
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.extra.quantityRequired" }),
        test: function (value) {
          if (!this.parent.isTime) {
            return value === 0 || !!value;
          }
          return true;
        },
      }),
    startTime: yup
      .string()
      .nullable()
      .test({
        name: "required",
        test: function (value) {
          if (!value) {
            const errors = [
              new ValidationError(
                intl.formatMessage({ id: "times.startDateRequired" }),
                value,
                "startDate"
              ),
              new ValidationError(
                intl.formatMessage({ id: "times.startTimeRequired" }),
                value,
                "startTime"
              ),
            ];
            return this.createError({ message: () => errors });
          }
          return true;
        },
      })
      .test({
        name: "type",
        test: function (value) {
          if (value && !isValid(new Date(value))) {
            const errors = [
              new ValidationError(
                intl.formatMessage({ id: "validation.date" }),
                value,
                "startDate"
              ),
              new ValidationError(
                intl.formatMessage({ id: "validation.time" }),
                value,
                "startTime"
              ),
            ];
            return this.createError({ message: () => errors });
          }
          return true;
        },
      }),
    stopTime: yup
      .string()
      .nullable()
      .test({
        name: "required",
        test: function (value) {
          if (this.parent.isTime && !value) {
            const errors = [
              new ValidationError(
                intl.formatMessage({ id: "visit.endDateRequired" }),
                value,
                "stopDate"
              ),
              new ValidationError(
                intl.formatMessage({ id: "visit.endTimeRequired" }),
                value,
                "stopTime"
              ),
            ];
            return this.createError({ message: () => errors });
          }
          return true;
        },
      })
      .test({
        name: "type",
        message: intl.formatMessage({ id: "validation.time" }),
        test: function (value) {
          if (this.parent.isTime && value && !isValid(new Date(value))) {
            const errors = [
              new ValidationError(intl.formatMessage({ id: "validation.date" }), value, "stopDate"),
              new ValidationError(intl.formatMessage({ id: "validation.time" }), value, "stopTime"),
            ];
            return this.createError({ message: () => errors });
          }
          return true;
        },
      })
      .test({
        name: "min",
        test: function (value) {
          if (!!value && this.parent.isTime && new Date(value) < new Date(this.parent.startTime)) {
            var errors = [
              new ValidationError(
                intl.formatMessage({ id: "visit.endTimeAfterStartTime" }),
                value,
                "stopTime"
              ),
            ];

            const stopDateCopy = new Date(value);
            stopDateCopy.setHours(0, 0, 0, 0);
            const startDateCopy = new Date(this.parent.startTime);
            startDateCopy.setHours(0, 0, 0, 0);

            if (stopDateCopy < startDateCopy) {
              errors.push(
                new ValidationError(
                  intl.formatMessage({ id: "visit.endDateAfterStartDate" }),
                  value,
                  "stopDate"
                )
              );
            }

            return this.createError({ message: () => errors });
          }
          return true;
        },
      }),
  });
};
