import { LinearProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus, selectLoadingJob, selectPageVisits } from "store/root.store";
import { selectHistorySelectedJobId } from "store/slices/history.store";
import { setAppBar } from "store/slices/menu.store";

import { VisitList } from "components/job/visit/VisitList";

export const HistoryVisitsPage: FC = () => {
  const jobId = useAppSelector(selectHistorySelectedJobId);
  const visits = useAppSelector(selectPageVisits);
  const loading = useAppSelector(selectLoadingJob);
  const isOnline = useAppSelector(selectConnectionStatus);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({
          id: "general.visits",
        }),
        goingBack: true,
      })
    );
  }, [intl, dispatch]);

  useEffect(() => {
    if (!visits || visits?.length === 1) {
      navigate(`/history/${jobId}`, { replace: true });
    }
  }, [visits, jobId, navigate]);

  return (
    <>
      {loading && isOnline && <LinearProgress />}
      <VisitList visits={visits} />
    </>
  );
};
