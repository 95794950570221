import { FileInputType, FileType } from "operations/schema/schema";
import Resizer from "react-image-file-resizer";

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const downloadFile = (file: FileInputType | FileType) => {
  const link = document.createElement("a");
  link.download = file.name;
  link.href = `data:application/octet-stream;base64,${file.data}`;
  link.click();
};

export const resizeUploadFile = (file: any) => {
  return new Promise((resolve) => {
    if (file.type.includes("image/")) {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    }
  });
};
