import { WifiOff, WifiPassword } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { useAppSelector } from "store";
import { selectForcedOffline, selectQueueLength } from "store/root.store";

const PREFIX = "ConnectionStatusIcon";

const classes = {
  badgeStyle: `${PREFIX}-badgeStyle`,
  statusIcons: `${PREFIX}-statusIcons`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`& .${classes.badgeStyle}`]: {
    bottom: "0.25em",
    right: "0.5em",
    color: theme.palette.primary.dark,
    backgroundColor: "white",
    display: "inline-block",
    width: "24px",
    textAlign: "center",
    borderRadius: "50%",
    height: "24px",
    position: "relative",
    top: "0px",
  },
  [`&.${classes.statusIcons}`]: {
    display: "flex",
    flexFlow: "row",
  },
}));

interface ConnectionStatusIconProps {
  onClick?: () => void;
}

const ConnectionStatusIcon: FC<ConnectionStatusIconProps> = (props) => {
  const queueLength = useAppSelector(selectQueueLength);
  const forcedOffline = useAppSelector(selectForcedOffline);

  return (
    <IconButton color="inherit" data-testid="OfflineIcon" onClick={props.onClick}>
      <StyledTypography variant="button" className={classes.statusIcons}>
        <span className={classes.badgeStyle}>{queueLength}</span>
        {!forcedOffline ? <WifiOff /> : <WifiPassword />}
      </StyledTypography>
    </IconButton>
  );
};

export default ConnectionStatusIcon;
