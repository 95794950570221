import { ReactNode } from "react";
import { StyledSnackbarProvider } from "./notistackSnackbarProvider";
import SnackbarContextProvider from "./snackbarContext";

type SnackbarProviderProps = { children: ReactNode };

export default function SnackbarProvider(props: SnackbarProviderProps) {
  return (
    <StyledSnackbarProvider>
      <SnackbarContextProvider>{props.children}</SnackbarContextProvider>
    </StyledSnackbarProvider>
  );
}

export * from "./snackbarContext";
export * from "./notistackSnackbarProvider";
