import {
  ChevronRight as ChevronRightIcon,
  ShoppingCart as ShoppingCartIcon,
  SlowMotionVideo as SlowMotionVideoIcon,
} from "@mui/icons-material";
import { Grid, ListItemButton, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { EventDateIcon } from "components/EventDateIcon";
import { StyledChips } from "components/StyledChips";
import { calculateUrgencyColor, intlFormatTime, isEmpty, isProductEvatic, toDate } from "helpers";
import { JobStatus, ServiceJob } from "operations/schema/schema";
import { JobStyles } from "styles/JobStyles";

import { JobVisitDetails } from "./JobVisitDetails";

const classes = JobStyles.createClasses();
const StyledBox = JobStyles.defineStyles(classes);

interface JobProps {
  job: ServiceJob;
  showBorder?: boolean;
  toLink?: string;
  disabled?: boolean;
  showEngineerName?: boolean;
}

export const JobComponent: FC<JobProps> = (props: JobProps) => {
  const { job, showBorder, toLink, disabled = false, showEngineerName } = props;
  const intl = useIntl();
  const plannedEndText = job.plannedDate?.stopTime;
  const plannedDays =
    job.plannedDate?.stopTime && job.plannedDate?.startTime
      ? Math.floor(
          (toDate(job.plannedDate?.stopTime!).getTime() -
            toDate(job.plannedDate?.startTime!).getTime()) /
            1000 /
            60 /
            60 /
            24
        )
      : 0;
  const dateText = job.plannedDate?.startTime || job.responseDate;
  const borderColor = calculateUrgencyColor(dateText);

  const jobInProgress =
    job.status === JobStatus.InProgress ||
    (!isEmpty(job.workTimes) && job.workTimes[0].startTime !== null) ||
    (!isEmpty(job.travelTimes) && job.travelTimes[0].startTime != null);

  return (
    <StyledBox className="component-Job" borderLeft={showBorder ? 6 : 0} borderColor={borderColor}>
      <ListItemButton
        disableRipple={disabled}
        divider
        component={disabled ? "div" : Link}
        to={toLink ? `${toLink}/${job.id}` : undefined}
        key={job.id}
        className={disabled ? "should-be-a-div" : "e2e-job"}
        data-testid="Job-Button"
      >
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item minWidth="100%">
            <Grid container>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1" component="h4" color="primary">
                    <span className="e2e-externalId">{job.externalId}</span>
                  </Typography>
                </Grid>
                <Grid item className={classes.jobTime}>
                  <Grid item container direction="row" alignItems="center" spacing={1}>
                    {job.preOrderedParts && job.preOrderedParts.length > 0 && (
                      <Grid item>
                        <ShoppingCartIcon
                          fontSize="small"
                          color="primary"
                          className={classes.indicatorIcon}
                        />
                      </Grid>
                    )}
                    {jobInProgress && showBorder && (
                      <Grid item>
                        <SlowMotionVideoIcon
                          fontSize="small"
                          color="primary"
                          className={classes.indicatorIcon}
                        />
                      </Grid>
                    )}
                    {showBorder && (
                      <Grid item>
                        <EventDateIcon plannedDate={job.plannedDate?.startTime} />
                      </Grid>
                    )}
                    {dateText && (
                      <Grid item>
                        <Typography variant="body2" color="secondary">
                          {intlFormatTime(intl, dateText)}
                          {plannedDays < 1 && plannedEndText
                            ? ` - ${intlFormatTime(intl, plannedEndText)}`
                            : plannedDays === 1
                            ? ` (+1 ${intl.formatMessage({ id: "date.day" })})`
                            : plannedDays > 1 &&
                              ` (+${plannedDays} ${intl.formatMessage({ id: "date.days" })})`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <JobVisitDetails
              customerName={props.job.customer?.name}
              customerAddress={props.job.customer?.address}
              equipmentName={props.job.equipment?.name}
              serialNumber={props.job.equipment?.serialNumber}
              location={props.job.equipment?.location}
              symptomDescription={props.job.symptomDescription}
              classes={classes}
              engineerName={
                showEngineerName
                  ? props.job.engineerName ?? intl.formatMessage({ id: "general.unassigned" })
                  : ""
              }
              includeCountry={isProductEvatic(props.job.product)}
            />
            <StyledChips
              isOnStop={job.customer?.isOnStop}
              category={job.category}
              priority={job.priority}
              symptoms={job.symptoms}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={1}>
              <ChevronRightIcon />
            </Grid>
          )}
        </Grid>
      </ListItemButton>
    </StyledBox>
  );
};
