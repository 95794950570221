import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { ContactType, EquipmentInputType, JobStatus, TimesType } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./jobs.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  rejectJob: createAppAsyncThunk(
    "jobs/rejectJob",
    async (props: { jobId: string; rejectReason: string }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.rejectJobAssignment, {
        thunkName: "jobs/rejectJob",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.rejectJobAssignment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  acceptJob: createAppAsyncThunk(
    "jobs/acceptJob",
    async (props: { jobId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { jobId } = props;
      const { errors, queued } = await graphqlRequest(sdk.updateJobStatus, {
        thunkName: "jobs/acceptJob",
        thunkProps: props,
        variables: {
          jobId,
          status: JobStatus.Accepted,
        },
      });
      if (errors) return rejectWithValue(errors);
      return { queued };
    }
  ),
  //EditEquipmentDialog
  updateEquipment: createAppAsyncThunk(
    "jobs/updateEquipment",
    async (
      props: { jobId: string; equipment: EquipmentInputType },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updateEquipment, {
        thunkName: "jobs/updateEquipment",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateEquipment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  //ChangeJobEquipmentDialog
  changeJobEquipment: createAppAsyncThunk(
    "jobs/changeJobEquipment",
    async (props: { jobId: string; equipmentId: string }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.changeJobEquipment, {
        thunkName: "jobs/changeJobEquipment",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.changeJobEquipment) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  // EditContactDialog
  editContact: createAppAsyncThunk(
    "jobs/editContact",
    async (
      props: { jobId: string; customerId: string; equipmentId: string; contact: ContactType },
      { rejectWithValue, extra: { sdk } }
    ) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.editContact, {
        thunkName: "jobs/editContact",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateContact) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
  //EditPlannedDateDialog
  updatePlannedDate: createAppAsyncThunk(
    "jobs/updatePlannedDate",
    async (props: { jobId: string; times: TimesType }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updatePlannedDate, {
        thunkName: "jobs/updatePlannedDate",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updatePlannedDate) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.rejectJob.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.rejectJob.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.rejectJob.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.rejectJob.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.rejectJob.fulfilled, (state, { meta }) => {
    state.jobs[meta.arg.jobId].status = JobStatus.Rejected;
    state.rejectJob.loading = false;
    state.rejectJob.text = "";
    state.rejectJob.open = false;
    return state;
  });
  builder.addCase(asyncMutations.acceptJob.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.jobs[meta.arg.jobId].status = JobStatus.Assigned;
    return state;
  });
  builder.addCase(asyncMutations.acceptJob.fulfilled, (state, { meta }) => {
    state.jobs[meta.arg.jobId].status = JobStatus.Accepted;
    return state;
  });
  // EditEquipmentDialog
  builder.addCase(asyncMutations.updateEquipment.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.updateEquipment.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.updateEquipment.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.updateEquipment.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.updateEquipment.fulfilled, (state, { meta }) => {
    state.updateEquipment.loading = false;
    state.updateEquipment.open = false;
    const { jobId, equipment } = meta.arg;
    if (state.jobs[jobId].equipment) {
      state.jobs[jobId].equipment!.location = equipment.location;
    }
    return state;
  });
  // ChangeJobEquipmentDialog
  builder.addCase(asyncMutations.changeJobEquipment.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.changeJobEquipment.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.changeJobEquipment.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.changeJobEquipment.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.changeJobEquipment.fulfilled, (state) => {
    state.changeJobEquipment.loading = false;
    return state;
  });
  // EditContactDialog
  builder.addCase(asyncMutations.editContact.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.editContact.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.editContact.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.editContact.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.editContact.fulfilled, (state, { meta }) => {
    state.editContact.loading = false;
    const { jobId, contact } = meta.arg;
    state.jobs[jobId].preferredContact = {
      email: contact.email,
      firstName: contact.firstName,
      id: contact.id,
      lastName: contact.lastName,
      mobileNumber: contact.mobile,
      name: contact.name,
      phoneNumber: contact.phone,
    };

    return state;
  });
};
