import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import StyledTextField from "components/StyledTextField";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import { QuestionTypeProps } from "./QuestionTypeProps";

interface NumberTypeProps extends QuestionTypeProps {
  isInt: boolean;
}

export const NumberType: FC<NumberTypeProps> = (props) => {
  const regExp = new RegExp(`^-?\\d*$`);
  const intl = useIntl();
  const { question, onChange, isInt, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  return readonly ? (
    <StyledTextFieldReadOnly
      value={question.answer}
      type="number"
      size="small"
      data-testid="NumberTypeField"
      disabled
    />
  ) : (
    <StyledTextField
      value={question.answer}
      type="number"
      onChange={(event) => {
        const newValue = event.target.value;
        if (isInt) {
          if (regExp.test(newValue)) {
            onChange(newValue);
          }
        } else {
          onChange(newValue);
        }
      }}
      inputProps={{
        min: question.minValue,
        max: question.maxValue,
      }}
      required={question.isRequired}
      helperText={errorText}
      error={!!errorText}
      size="small"
      data-testid="NumberTypeField"
    />
  );
};
