import { Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { useIntl } from "react-intl";

import StyledTextFieldReadyOnly from "components/StyledTextFieldReadOnly";
import { WorkTaskSwitch } from "components/WorkTaskSwitch";
import { WorkTaskType } from "operations/schema/schema";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

type TasksTabProps = {
  workTasks: WorkTaskType[] | null | undefined;
};

export const TasksTab: FC<TasksTabProps> = (props) => {
  const { workTasks } = props;
  const intl = useIntl();

  return (
    <StyledContainer data-testid="TasksTab">
      <Grid item container direction="column" rowSpacing={1}>
        {workTasks?.map(({ id, name, completedDate, description }) => (
          <div key={id}>
            <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
              <Grid item maxWidth="60%">
                <Typography>{name}</Typography>
              </Grid>
              <Grid item>
                <WorkTaskSwitch checked={!!completedDate} disabled />
              </Grid>
            </Grid>
            <Grid item mb={2}>
              <StyledTextFieldReadyOnly
                value={description || ""}
                label={intl.formatMessage({ id: "general.comment" })}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </div>
        ))}
      </Grid>
    </StyledContainer>
  );
};
