import { Autocomplete, Container, Grid, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";

import { nanoid } from "nanoid";
import { PartStatus } from "operations/schema/schema";

import { UsedPart } from "models/usedPart";
import { useAppDispatch, useAppSelector } from "store";
import { addParts, selectSelectedJob } from "store/slices/visit.store";

import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";

type ReturnPartsDialogDialogProps = {
  preOrderedParts: UsedPart[];
  setOpenReturnPartsDialog: Dispatch<SetStateAction<boolean>>;
};

type Stock = {
  id: string;
  name: string;
};

export const ReturnPartsDialog: FC<ReturnPartsDialogDialogProps> = (props) => {
  const { setOpenReturnPartsDialog, preOrderedParts } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const stockList: Stock[] = [
    {
      id: job.engineerId as string,
      name: intl.formatMessage({ id: "general.engineer" }).toUpperCase(),
    },
    {
      id: job.customer?.id as string,
      name: intl.formatMessage({ id: "general.customer" }).toUpperCase(),
    },
    {
      id: "supplying_stock",
      name: intl.formatMessage({ id: "part.supplyingStore" }),
    },
  ];

  const [selectedStock, setSelectedStock] = useState<Stock | null>(stockList[0]);

  return (
    <Container sx={{ mt: 2 }} data-testid="ReturnPartsDialog">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={stockList}
            className="e2e-select-part"
            getOptionLabel={(stock: Stock) => stock.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedStock}
            onChange={(e, value) => setSelectedStock(value)}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                label={intl.formatMessage({
                  id: "visit.returnParts.chooseLocation",
                })}
              />
            )}
            renderOption={(props, stock: Stock) => {
              return (
                <li {...props} key={nanoid()} style={{ display: "block" }}>
                  <Typography variant="body1">{stock.name}</Typography>
                </li>
              );
            }}
            data-testid="ReturnPartsDialog-ReturnLocation"
          />
        </Grid>
        <Grid item alignItems="center" xs={12}>
          <PrimaryButton
            fullWidth
            disabled={!selectedStock}
            variant="contained"
            onClick={() => {
              preOrderedParts.forEach((p) => {
                if (p.part) {
                  p.part.returnStockId =
                    selectedStock?.id === "supplying_stock" ? p.part.stockId : selectedStock?.id;
                  p.part.status = PartStatus.RequestedReturned;
                  p.part.storeLabel = selectedStock?.name;
                }
              });
              dispatch(addParts({ parts: preOrderedParts }));
              setTimeout(() => setOpenReturnPartsDialog(false), 300);
            }}
            className="e2e-add-pre-ordered-parts-button"
            data-testid="ReturnPartsDialog-ConfirmButton"
          >
            {intl.formatMessage({
              id: "general.confirm",
            })}
          </PrimaryButton>
        </Grid>
      </Grid>
    </Container>
  );
};
