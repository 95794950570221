import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FC, ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Transition from "./Transition";

interface FullScreenDialogProps {
  child: ReactElement;
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: string;
  disableCloseIcon?: boolean;
  goingBack?: boolean | null;
  centerTitle?: boolean;
  onClose?: () => void;
}

const createTitleBlock = function (title: string, centered?: boolean): JSX.Element {
  if (centered) {
    return (
      <Typography className="AppBar-label" paddingRight="36px">
        {title}
      </Typography>
    );
  } else {
    return (
      <Typography marginLeft="16px" flex={1}>
        {title}
      </Typography>
    );
  }
};

const FullScreenDialog: FC<FullScreenDialogProps> = (props) => {
  const {
    child,
    open,
    setOpen,
    title,
    disableCloseIcon,
    goingBack,
    centerTitle,
    onClose = () => {},
  } = props;
  const intl = useIntl();
  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>
      <AppBar position="fixed">
        <Toolbar>
          {!disableCloseIcon && !goingBack && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
              aria-label={intl.formatMessage({ id: "general.close" })}
              size="large"
              data-testid="IconButtonClose"
            >
              <CloseIcon />
            </IconButton>
          )}
          {!disableCloseIcon && goingBack && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
              aria-label={intl.formatMessage({ id: "general.back" })}
              size="large"
              data-testid="IconButtonClose"
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          {title ? (
            createTitleBlock(title, centerTitle)
          ) : (
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
              data-testid="ButtonClose"
            >
              <FormattedMessage id="general.close" />
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {child}
    </Dialog>
  );
};

export default FullScreenDialog;
