import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { RefObject } from "react";
import { FormattedMessage } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import { AsolviSignoff } from "components/AsolviSignoff";
import { useAppSelector } from "store";
import { selectSelectedJob } from "store/slices/visit.store";

interface EngineerSignoffProps {
  canvasReference: RefObject<ReactSignatureCanvas>;
  handleSignature: (added: boolean) => void;
}

export const EngineerSignoff = (props: EngineerSignoffProps) => {
  const { canvasReference, handleSignature } = props;
  const labelText = <FormattedMessage id="visit.engineerSignature" />;
  const job = useAppSelector(selectSelectedJob);

  return (
    <Card elevation={1}>
      <CardContent>
        <AsolviSignoff
          canvasReference={canvasReference}
          handleSignature={handleSignature}
          labelText={labelText}
          signatureImageData={job.engineerSignatureImageData}
        />
      </CardContent>
    </Card>
  );
};
