import { PartType } from "operations/schema/schema";

export const filterPartList = (
  parts: PartType[],
  filter: string,
  splice: number = 300
) => {
  const searchTerms = filter.toLocaleLowerCase().split(" ");

  return parts
    .filter((part: PartType) => {
      const description = part.description?.toLocaleLowerCase();
      const partNumber = part.partNumber?.toLocaleLowerCase();
      const barcode = part.barcode?.toLocaleLowerCase();
      return (
        0 <=
        searchTerms.reduce(
          (sum, term) =>
            //min-max search hit pattern filter
            Math.min(
              sum,
              Math.max(
                description?.indexOf(term) ?? -1,
                partNumber?.indexOf(term) ?? -1,
                barcode ? barcode.indexOf(term) : -1
              )
            ),
          0 //start with lowest inclusive value
        )
      );
    })
    .slice(0, splice); // limit to render 300 options at once
};
