import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { toDateString } from "helpers";
import { AppAsyncThunkConfig } from "store";
import { State } from "./note.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  addWorkNote: createAppAsyncThunk(
    "note/addWorkNote",
    async (
      props: { jobId: string; text: string; isAlert: boolean },
      { getState, rejectWithValue, extra: { sdk } }
    ) => {
      const { jobId, text, isAlert } = props;
      const {
        user: { userVar },
      } = getState();
      const { data, errors, queued } = await graphqlRequest(sdk.addWorkNote, {
        thunkName: "note/addWorkNote",
        thunkProps: props,
        variables: {
          jobId,
          workNote: {
            text: text,
            isAlert: isAlert,
            loggedBy: userVar?.fullName || "",
            loggedDate: toDateString(new Date()),
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.addWorkNote) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
};

// Need to import State from related slice
// Also, ActionReducerMapBuilder is removed because of auto-format, so re-import this
// ⬇ Commented out because of error until you import State
export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.addWorkNote.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.loading = true;
    return state;
  });
  builder.addCase(asyncMutations.addWorkNote.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.loading = false;
    return state;
  });
  builder.addCase(asyncMutations.addWorkNote.fulfilled, (state, { payload }) => {
    state.loading = false;
    state.open = false;
    state.text = "";
    state.isAlert = false;
    return state;
  });
};
