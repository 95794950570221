import { Build as BuildIcon } from "@mui/icons-material";
import { Container, Grid, LinearProgress, List, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import { sortByProperty } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  getServiceHistory,
  selectServiceHistoryFilterCount,
  setServiceHistoryFilter,
} from "store/slices/history.store";
import { setAppBar } from "store/slices/menu.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

import { JobListSkeleton } from "components/job/JobListSkeleton";

import { HistoryItem } from "../HistoryItem";

import { FilterAppBar } from "components/FilterAppBar";
import { useEffectOnce } from "helpers/useEffectOnce";
import {
  HistoryFilter,
  ServiceHistoryFilterStorageKey,
  defaultServiceHistoryFilter,
} from "models/HistoryFilter";
import { ServiceHistoryFilterDialog } from "./ServiceHistoryFilterDialog";

const sortingType = "serviceHistory";

export const ServiceHistoryPage: FC = () => {
  const { typeId, type } = useParams();
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const { serviceHistory, loadingServiceHistory } = useAppSelector((s) => s.history);
  const filterCount = useAppSelector(selectServiceHistoryFilterCount);
  const { sortingVar } = useAppSelector((s) => s.menu);
  const { sorting } = sortingVar[sortingType];
  const location = useLocation();
  const { state }: any = useLocation();
  const openFilter: boolean = state?.openFilter ?? false;
  const [filterDialogOpen, setFilterDialogOpen] = useState(openFilter);

  useEffectOnce(() => {
    const historyFilterString = localStorage.getItem(ServiceHistoryFilterStorageKey);
    let storedHistoryFilter: HistoryFilter | null = null;
    if (historyFilterString) {
      storedHistoryFilter = JSON.parse(historyFilterString) as HistoryFilter;
      if (storedHistoryFilter.specificDate)
        storedHistoryFilter.specificDate = new Date(storedHistoryFilter.specificDate);
    }

    if (storedHistoryFilter != null) {
      dispatch(setServiceHistoryFilter({ serviceHistoryFilter: storedHistoryFilter }));
    } else {
      dispatch(setServiceHistoryFilter({ serviceHistoryFilter: defaultServiceHistoryFilter }));
    }
  });

  const getServiceHistoryCb = useCallback(() => {
    dispatch(getServiceHistory({ type, typeId })).catch(() => {
      dispatch(addSnackbarMessage({ key: "GetJob-fail" }));
    });
  }, [dispatch, type, typeId]);

  useEffect(() => {
    if (!filterDialogOpen) {
      getServiceHistoryCb();
    }
  }, [filterDialogOpen, getServiceHistoryCb]);

  const sortedHistory = serviceHistory
    .slice()
    .sort((a, b) =>
      sortByProperty(
        new Date(a.completedDate).getTime(),
        new Date(b.completedDate).getTime(),
        sorting.order
      )
    );

  useEffect(() => {
    localStorage.setItem(`${sortingType}Sorting`, JSON.stringify(sorting));
  }, [sorting]);

  useEffect(() => {
    dispatch(
      setAppBar({
        title: state?.navTitle ?? "",
        goingBack: true,
        sortingType,
      })
    );
  }, [location, dispatch, state?.navTitle]);

  const ServiceHistoryList = function () {
    let shArray = sortedHistory || [];
    if (shArray.length) {
      return shArray.map((x) => {
        return (
          <List dense key={x.externalId}>
            <HistoryItem svcHistory={x} toLink="/history" />
          </List>
        );
      });
    }
    return (
      <Grid container direction="column" alignItems="center" mt={2.5}>
        <Grid item>
          <BuildIcon color="secondary" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography color="secondary">
            <FormattedMessage id="history.noServiceHistory" />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return loadingServiceHistory ? (
    <>
      {loadingServiceHistory && isOnline && <LinearProgress />}
      <JobListSkeleton showBorder />
    </>
  ) : (
    <Container data-testid="EngineerHistoryPage-Container">
      <FilterAppBar
        onClick={setFilterDialogOpen}
        filterCount={filterCount}
        foundCount={serviceHistory.length}
        type="jobs"
      />

      {!loadingServiceHistory && ServiceHistoryList()}

      <ServiceHistoryFilterDialog open={filterDialogOpen} setOpen={setFilterDialogOpen} />
    </Container>
  );
};
