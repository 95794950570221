import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";

import { ConsentObject, formatDateTime, getTrackingConsent, isAbortError } from "helpers";

import { useAppDispatch, useAppSelector } from "store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";
import {
  updateUserConsent as dUpdateUserConsent,
  updateEngineerMetadata,
} from "store/slices/user.store";

import { UserConsentType, UserConsentTypeEnum } from "operations/schema/schema";

import { unwrapResult } from "@reduxjs/toolkit";
import { AsolviSwitch } from "components/AsolviSwitch";
import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const PREFIX = "LocationSettingsDialog";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

interface LocationSettingsProps {}

export const LocationSettingsDialog: FC<LocationSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const { userVar: userData, languageVar } = useAppSelector((state) => state.user);
  const { locationSettings: open } = useAppSelector((state) => state.dialog);
  const setOpen = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "locationSettings", open }));
  };
  const intl = useIntl();
  const [trackingConsentAccepted, setTrackingConsentAccepted] = useState(
    userData?.userConsents?.filter((c) => c?.type === UserConsentTypeEnum.Tracking)?.[0]
      ?.accepted ?? false
  );
  const [consentObject, setConsentObject] = useState<ConsentObject>();

  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition | null>(
    JSON.parse(localStorage.getItem("userLocation") || "null") || null
  );
  const [position, setPosition] = useState({
    longitude: 0.0,
    latitude: 0.0,
    timestamp: "",
  });

  useEffect(() => {
    getTrackingConsent(languageVar).then((c) => setConsentObject(c));
  }, [languageVar]);
  useEffect(() => {
    let position = {
      longitude: currentPosition?.coords?.longitude ?? 0,
      latitude: currentPosition?.coords?.latitude ?? 0,
      timestamp: formatDateTime(currentPosition?.timestamp) || "",
    };
    setPosition(position);
  }, [currentPosition]);

  const checkCurrentPosition = useCallback(
    (consentAccepted: boolean) => {
      if (consentAccepted && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (postion: any) => setCurrentPosition(postion),
          (error: any) => {
            localStorage.removeItem("userLocation");
            dispatch(
              addSnackbarMessage({
                key: `AddUserConsent-tracking-failure${error.code === 1 ? `-${error.code}` : ""}`,
              })
            );
          },
          {
            enableHighAccuracy: false,
            timeout: 15000,
            maximumAge: 0,
          }
        );
      }
    },
    [dispatch]
  );

  const updateUserConsent = useCallback(async () => {
    let consent: UserConsentType = {
      accepted: !trackingConsentAccepted,
      version: consentObject!.version,
      type: UserConsentTypeEnum.Tracking,
    };
    dispatch(dUpdateUserConsent({ consent }))
      .then(unwrapResult)
      .then(() => {
        setTrackingConsentAccepted(consent.accepted);
        checkCurrentPosition(consent.accepted);
        dispatch(addSnackbarMessage({ key: "AddUserConsent-tracking-success" }));
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "AddUserConsent-fail" }));
      });
  }, [checkCurrentPosition, consentObject, dispatch, trackingConsentAccepted]);

  if (!consentObject) {
    return null;
  }
  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            aria-label={intl.formatMessage({ id: "general.close" })}
            className={classes.menuButton}
            onClick={() => setOpen(false)}
            data-testid="LocationSettingsDialog-CloseButton"
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography flexGrow={1}>
            <FormattedMessage id="menu.location" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <StyledContainer>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Grid item>
            <Typography variant="body1">
              <FormattedMessage id="trackingConsent.toggleText" />
            </Typography>
          </Grid>
          <Grid item>
            <AsolviSwitch
              key="trackingConsent"
              checked={trackingConsentAccepted}
              onClick={() => {
                updateUserConsent();
              }}
              data-testid="LocationSettingsDialog-Toggle"
            />
          </Grid>
        </Grid>
        {!trackingConsentAccepted ? (
          <>
            <Grid container alignItems="center" justifyContent="center">
              <Typography component="div" whiteSpace="pre-line">
                <ReactMarkdown className="markdown-text" children={consentObject.text} />
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="body1">
                  <FormattedMessage id="locationSettings.location" />
                  {position.latitude && position.longitude
                    ? position.latitude + ", " + position.longitude
                    : intl.formatMessage({ id: "general.unknown" })}
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sx={{ paddingTop: "10px" }}>
                <Typography variant="body1">
                  <FormattedMessage id="locationSettings.updateTime" />
                  {position.timestamp
                    ? position.timestamp
                    : intl.formatMessage({ id: "general.unknown" })}
                </Typography>
              </Grid>
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <PrimaryButton
                type="submit"
                variant="contained"
                fullWidth
                onClick={() => {
                  checkCurrentPosition(trackingConsentAccepted);
                  dispatch(updateEngineerMetadata());
                }}
                data-testid="LocationSettingsDialog-UpdateLocation"
              >
                <FormattedMessage id="trackingConsent.updateLocation" />
              </PrimaryButton>
            </Grid>
          </>
        )}
      </StyledContainer>
    </StyledDialog>
  );
};
