import { toDateString } from "helpers";
import { User } from "models/user";
import {
  CoordinatesInputType,
  MetadataInputType,
  UserConsentTypeEnum,
} from "operations/schema/schema";

const getLocation = async (): Promise<CoordinatesInputType | undefined> => {
  let userCache: User = JSON.parse(localStorage.getItem("currentUser") || "{}");

  const trackingConsent = userCache?.userConsents?.filter(
    (c) => c?.type === UserConsentTypeEnum.Tracking && c?.accepted
  )[0];

  let coordinates = undefined;
  if (navigator.geolocation && trackingConsent) {
    coordinates = await new Promise<CoordinatesInputType | undefined>((res) => {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          let pos = {
            latitude: position?.coords?.latitude,
            longitude: position?.coords?.longitude,
          };
          res(pos);
        },
        () => {
          res(undefined); // Error handler, important
        }
      );
    });
  }
  return coordinates;
};

const setLocalStorage = ({ coordinates, timestamp }: MetadataInputType) => {
  localStorage.setItem("userLocation", JSON.stringify({ coords: coordinates, timestamp }));
};

export const getMetadata = async () => {
  let metadata: MetadataInputType = {};

  let coordinates = await getLocation();
  if (coordinates) metadata.coordinates = coordinates;
  metadata.timestamp = toDateString(Date.now());

  setLocalStorage(metadata);

  return metadata;
};
