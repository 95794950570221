export const HistoryFilterStorageKey = "historyFilter";
export const ServiceHistoryFilterStorageKey = "serviceHistoryFilter";

const StorageKeys = [HistoryFilterStorageKey, ServiceHistoryFilterStorageKey] as const;
export type HistoryFilterStorageTypes = (typeof StorageKeys)[number];

export type HistoryFilterDateTypes =
  | "week"
  | "1month"
  | "12month"
  | "18month"
  | "last5"
  | "specificDate";
export type HistoryFilter = {
  typeDate: HistoryFilterDateTypes;
  specificDate: Date | null;
};

export const defaultHistoryFilter: HistoryFilter = {
  typeDate: "week",
  specificDate: null,
};

export const defaultServiceHistoryFilter: HistoryFilter = {
  typeDate: "12month",
  specificDate: null,
};

const keysToExclude = ["specificDate", "typeDate"] as const;
type FilterKeys = (keyof Omit<typeof defaultHistoryFilter, (typeof keysToExclude)[number]>)[];
export const HistoryFilterKeys: FilterKeys = Object.keys(defaultHistoryFilter).filter(
  (x) => !keysToExclude.includes(x as any)
) as FilterKeys;
export const ServiceHistoryFilterKeys: FilterKeys = Object.keys(defaultServiceHistoryFilter).filter(
  (x) => !keysToExclude.includes(x as any)
) as FilterKeys;
