import {
  Lock as LockIcon,
  Note as NoteIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { formatDateTime, isEmpty } from "helpers";
import { WorkNoteType } from "operations/schema/schema";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store";
import { selectPageWorkNotes } from "store/root.store";
import { isProductEvatic, isProductVantageOnline } from "store/slices/user.store";

export const NotesTab: FC = () => {
  const workNotes = useAppSelector(selectPageWorkNotes, shallowEqual);
  const isVantageOnline = useAppSelector(isProductVantageOnline);
  const isEvatic = useAppSelector(isProductEvatic);

  const getCardHeader = (wn: WorkNoteType) => {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          {wn.isAlert && <PriorityHighIcon color="error" />}
          {wn.isInternal && <LockIcon color="error" />}
        </Grid>
        {!wn.hideHeader && (
          <Grid item>
            <Typography fontWeight={700}>{wn.loggedBy}</Typography>
            <Typography color="text.secondary">{formatDateTime(wn.loggedDate)}</Typography>
          </Grid>
        )}
        {/* for later */}
        {/* <Grid item ml="auto">
          <IconButton size="large">
            <EditIcon color="action" />
          </IconButton>
          <IconButton size="large" sx={{ pr: 0 }}>
            <DeleteIcon color="error" />
          </IconButton>
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Grid container direction="column" data-testid="NotesTab">
      {!isEmpty(workNotes) ? (
        workNotes.map((wn, index) => {
          let replacedText = "";
          let wasReplaced = false;
          if (wn?.text && isEvatic && wn?.loggedBy !== "") {
            replacedText = wn.text.replaceAll(wn.loggedBy, "**" + wn.loggedBy + "** \n");
            wasReplaced = true;
          }

          return (
            wn && (
              <Card key={`${index}-${wn.loggedBy}-${wn.loggedDate}`} sx={{ width: "100%" }}>
                {isVantageOnline && (
                  <CardHeader
                    title={getCardHeader(wn)}
                    sx={{ pb: 0 }}
                    titleTypographyProps={{ fontSize: 17 }}
                  />
                )}
                <CardContent sx={{ pl: 3, pr: 3, pt: 1, pb: "1 !important" }}>
                  <Typography align="justify" component="div" whiteSpace="pre-line">
                    <ReactMarkdown
                      children={wasReplaced ? replacedText : wn.text}
                      className="markdown-text"
                    />
                  </Typography>
                </CardContent>
              </Card>
            )
          );
        })
      ) : (
        <Grid container item direction="column" alignItems="center" mt={2.5}>
          <Grid item>
            <NoteIcon color="secondary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography color="secondary">
              <FormattedMessage id="note.noNotesFound" />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
