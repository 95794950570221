import { Wifi } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC } from "react";

const PREFIX = "RefreshSessionIcon";

const classes = {
  badgeStyle: `${PREFIX}-badgeStyle`,
  statusIcons: `${PREFIX}-statusIcons`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`& .${classes.badgeStyle}`]: {
    bottom: "0.25em",
    right: "0.5em",
    color: theme.palette.primary.dark,
    backgroundColor: "white",
    display: "inline-block",
    width: "24px",
    textAlign: "center",
    borderRadius: "50%",
    height: "24px",
    position: "relative",
    top: "0px",
  },
  [`&.${classes.statusIcons}`]: {
    display: "flex",
    flexFlow: "row",
  },
}));

interface RefreshSessionIconProps {
  onClick?: () => void;
}

const RefreshSessionIcon: FC<RefreshSessionIconProps> = (props) => {
  return (
    <IconButton color="inherit" onClick={props.onClick}>
      <StyledTypography variant="button" className={classes.statusIcons}>
        <Wifi />
      </StyledTypography>
    </IconButton>
  );
};

export default RefreshSessionIcon;
