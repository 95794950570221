import { FC, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  AppBar,
  CircularProgress,
  Dialog,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import { isAbortError } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import {
  addChecklist,
  getChecklistGroups,
  handleCloseAddChecklist,
  handleCloseAddChecklistSelect,
  selectSelectedJob,
  selectSelectedJobVisit,
} from "store/slices/visit.store";

export const AddChecklistSelectDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const currentEquipment = job?.equipment;
  const {
    openSelect: open,
    loading,
    checklists,
    useEquipment,
    addLoading,
  } = useAppSelector((state) => state.visit.addChecklist);
  const { checklists: currentChecklists } = useAppSelector(selectSelectedJobVisit);
  const currentCodes = currentChecklists.map((c) => c.checklist.checklistCode);

  const addChecklistCb = useCallback(
    (checklistCode: string) => {
      dispatch(addChecklist({ jobId: job.id, checklistCode }))
        .then(unwrapResult)
        .then(() => {
          dispatch(handleCloseAddChecklistSelect());
          dispatch(handleCloseAddChecklist());
          dispatch(addSnackbarMessage({ key: "AddChecklist-success" }));
        })
        .catch((error: any) => {
          if (isAbortError(error)) return;
          dispatch(addSnackbarMessage({ key: "AddChecklist-fail" }));
        });
    },
    [dispatch, job.id]
  );

  const handleClose = () => {
    dispatch(handleCloseAddChecklistSelect());
  };

  useEffect(() => {
    if (open) {
      dispatch(getChecklistGroups({ equipmentId: useEquipment ? currentEquipment?.id : null }));
    }
  }, [currentEquipment?.id, dispatch, open, useEquipment]);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} data-testid="addChecklistSelectDialog">
      <BackdropPrimaryMain open={loading || addLoading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
            data-testid="addChecklistSelectCloseButton"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" marginLeft="16px" flex={1}>
            {`${job?.externalId} `} / <FormattedMessage id="visit.addChecklist" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <List dense>
        {checklists.map((c) => (
          <ListItemButton
            divider
            key={c.code}
            disabled={currentCodes.some((x) => x === c.code)}
            onClick={() => {
              if (!c.code) return;
              addChecklistCb(c.code);
            }}
          >
            <ListItemText primary={`${c.code}: ${c.description}`} />
          </ListItemButton>
        ))}
      </List>
    </Dialog>
  );
};
