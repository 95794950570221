import { NoMatchPage } from "components/NoMatchPage";
import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { PlannerPage } from "./PlannerPage";
import PlannerDetails from "./plannerDetails";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<PlannerPage />} />
      <Route path=":id/*" element={<PlannerDetails />} />
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
