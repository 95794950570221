import { Action, createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RootState } from "store";
import { asyncMutations, mutationBuilder } from "./feedback.mutations";

// Interface for state
export interface State {
  open: boolean;
  text: string;
  stars: number | null;
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  setOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setText: (state: State, action: PayloadAction<{ text: string }>) => State;
  setStars: (state: State, action: PayloadAction<{ stars: number | null }>) => State;
  handleClose: (state: State, action: Action) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  selectOpen: (state: RootState) => boolean;
}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  open: false,
  text: "",
  stars: null,
};
/** Example state
 * feedback: false
 */

// Definition of actual actions
const actions: Actions = {
  setOpen: (state, { payload: { open } }) => {
    state.open = open;
    return state;
  },
  setText: (state, { payload: { text } }) => {
    state.text = text;
    return state;
  },
  setStars: (state, { payload: { stars } }) => {
    state.stars = stars;
    return state;
  },
  handleClose: (state) => {
    state.text = "";
    state.stars = null;
    state.open = false;
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  selectOpen: ({ feedback }) => {
    return feedback.open;
  },
};
/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

const storeBase = createSlice<State, Actions>({
  name: "feedback",
  initialState,
  reducers: actions,
  extraReducers: (b) => {
    mutationBuilder(b);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;

// Export all actions created in store, to be used in components
// I.e, if using setOpen defined in examples above, it would look like this
// export const { setOpen } = storeBase.actions;
export const { setOpen, setStars, setText } = storeBase.actions;

export const { sendFeedback } = asyncMutations;

// Export all selectors created in store, to be used in components
// I.e, if using selectFeedback defined in examples above, it would look like this
// export const { selectFeedback } = selectors;
export const { selectOpen } = selectors;
