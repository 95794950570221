import { EquipmentType } from "operations/schema/schema";

export const filterEquipmentList = (
  equipments: EquipmentType[],
  filter: string,
  splice: number = 300
) => {
  const searchTerms = filter.toLocaleLowerCase().split(" ");

  return equipments
    .filter((equipment: EquipmentType) => {
      const serialNumber = equipment.serialNumber?.toLocaleLowerCase();
      const name = equipment.name?.toLocaleLowerCase();
      const location = equipment.location?.toLocaleLowerCase();
      return (
        0 <=
        searchTerms.reduce(
          (sum, term) =>
            //min-max search hit pattern filter
            Math.min(
              sum,
              Math.max(
                location?.indexOf(term) ?? -1,
                name?.indexOf(term) ?? -1,
                serialNumber ? serialNumber.indexOf(term) : -1
              )
            ),
          0 //start with lowest inclusive value
        )
      );
    })
    .slice(0, splice); // limit to render 300 options at once
};
