import { ActionType, CauseType } from "operations/schema/schema";

export const findMatchingCode = <T extends ActionType | CauseType>(
  candidates: T[] | undefined,
  codeId: string | undefined
): T | null => {
  if (!candidates || !candidates.length || !codeId) {
    return null;
  }
  const match = candidates.find((c) => c.id === codeId);
  return match ? match : null;
};
