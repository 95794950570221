import { AppBar, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { TabPanel } from "components/TabPanel";
import TabToolbarPlaceholder from "components/TabToolbarPlaceholder";
import { isEmpty } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { setAppBar } from "store/slices/menu.store";

import { ChecklistTab } from "components/job/checklist/ChecklistTab";
import { JobDetailTab } from "components/job/details/JobDetailTab";
import { FilesTab } from "components/job/files/FilesTab";
import { MeterTab } from "components/job/meter/MeterTab";
import { NotesTab } from "components/job/notes/NotesTab";
import { PartTab } from "components/job/parts/PartTab";
import { TasksTab } from "components/job/tasks/TasksTab";

import { VisitDetailsTab } from "components/job/visit/VisitDetailsTab";
import { selectPageSelectedJob } from "store/root.store";
import { TimesTab } from "./times/TimesTab";

export const HistoryJobDetails: FC = () => {
  const job = useAppSelector(selectPageSelectedJob);
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [tabValue, setTabValue] = useState("details");

  useEffect(() => {
    dispatch(
      setAppBar({
        title: job?.externalId ?? "",
        goingBack: true,
      })
    );
  }, [dispatch, job]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          mt: 7,
          backgroundColor: `secondary.main`,
        }}
      >
        <Tabs
          scrollButtons
          value={tabValue}
          onChange={(_, newValue: string) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          textColor="inherit"
        >
          <Tab
            label={intl.formatMessage({ id: "general.details" })}
            className="e2e-details-tab"
            value="details"
          />
          <Tab
            label={intl.formatMessage({ id: "general.visit" })}
            className="e2e-visit-tab"
            value="visit"
          />
          <Tab
            label={intl.formatMessage({ id: "job.times" })}
            className="e2e-times-tab"
            value="times"
          />
          {!isEmpty(job.checklists) && (
            <Tab
              label={intl.formatMessage({ id: "job.checklists" })}
              className="e2e-checklists-tab"
              value="checklists"
            />
          )}
          <Tab
            label={intl.formatMessage({ id: "job.parts" })}
            className="e2e-parts-tab"
            value="parts"
          />
          <Tab
            label={intl.formatMessage({ id: "general.files" })}
            className="e2e-files-tab"
            value="files"
          />

          <Tab label={intl.formatMessage({ id: "job.notes" })} value="notes" />

          {!isEmpty(job.meters) && (
            <Tab
              label={intl.formatMessage({ id: "job.meters" })}
              className="e2e-meters-tab"
              value="meters"
            />
          )}
          {!isEmpty(job.tasks) && (
            <Tab label={intl.formatMessage({ id: "job.tasks" })} value="tasks" />
          )}
        </Tabs>
      </AppBar>
      <TabToolbarPlaceholder />

      <TabPanel index="details" value={tabValue}>
        <JobDetailTab readonly={true} />
      </TabPanel>
      <TabPanel index="visit" value={tabValue}>
        <VisitDetailsTab />
      </TabPanel>
      <TabPanel index="times" value={tabValue}>
        <TimesTab />
      </TabPanel>
      <TabPanel index="parts" value={tabValue}>
        <PartTab />
      </TabPanel>
      <TabPanel index="files" value={tabValue}>
        <FilesTab />
      </TabPanel>
      <TabPanel index="notes" value={tabValue}>
        <NotesTab />
      </TabPanel>
      <TabPanel index="meters" value={tabValue}>
        <MeterTab />
      </TabPanel>
      <TabPanel index="tasks" value={tabValue}>
        <TasksTab workTasks={job.tasks} />
      </TabPanel>
      <TabPanel index="checklists" value={tabValue}>
        <ChecklistTab />
      </TabPanel>
    </>
  );
};
