import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useSnackbar as useNotistackSnackbar } from "notistack";
import React, { createContext, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { removeSnackbarMessage, selectSnackbarMessage } from "store/slices/snackbar.store";
import { SnackbarMessage, SnackbarMessageKey } from "./SnackbarMessages";
import { getSnackbarMessage, isValidSnackbarKey } from "./getSnackbarMessage";

/**
 * A wrapper around notistack useSnackbar, to ensure that we can display
 * the same messages for the persisted queue.
 */

type ProviderProps = { children: React.ReactNode };

interface SnackbarContextType {
  enqueueCustomSnackbar: (key: SnackbarMessageKey, argument?: string) => void;
  enqueueDynamicCustomSnackbar: (key: string, argument?: string) => void;
}
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export default function SnackbarContextProvider({ children }: ProviderProps) {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const message = useAppSelector(selectSnackbarMessage);
  /**
   * TODO
   * After making sure that the new redux-snackbar works fine
   * we should move over to just using that instead of both the new and old one
   * Reson is that with both of them the messages might end up coming out of order
   * Minor issue, but still worth noting
   */

  const enqueue = useCallback(
    ({ key, text, variant }: SnackbarMessage) => {
      enqueueSnackbar(text, {
        variant,
        action: (key) => (
          <IconButton
            aria-label="dismiss notification"
            component="span"
            onClick={() => {
              closeSnackbar();
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ),
      });
      dispatch(removeSnackbarMessage({ key }));
    },
    [closeSnackbar, dispatch, enqueueSnackbar]
  );

  const enqueueCustomSnackbar = useCallback(
    (key: SnackbarMessageKey, argument?: string) => {
      argument = argument === undefined ? "" : argument;
      let message = getSnackbarMessage(key, argument, intl);
      if (message) enqueue(message);
    },
    [enqueue, intl]
  );

  const enqueueDynamicCustomSnackbar = useCallback(
    (key: string, argument?: string) => {
      if (isValidSnackbarKey(key)) {
        enqueueCustomSnackbar(key, argument);
      }
    },
    [enqueueCustomSnackbar]
  );

  useEffect(() => {
    if (message) {
      enqueue(message);
    }
  }, [enqueue, message]);

  const contextValue: SnackbarContextType = {
    enqueueCustomSnackbar,
    enqueueDynamicCustomSnackbar,
  };

  return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
}
export function useSnackbar() {
  const context = React.useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarContext");
  }
  return context;
}
