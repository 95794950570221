import { styled } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.info.main,
  border: `1px solid ${theme.palette.info.main}`,
}));

const IncreaseButton = (props: IconButtonProps) => {
  return (
    <StyledIconButton {...props} size="large">
      <AddIcon />
    </StyledIconButton>
  );
};

export default IncreaseButton;
