import { styled } from "@mui/material/styles";
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";

export const StyledSnackbarProvider = styled(NotistackSnackbarProvider)(
  ({ theme }) => ({
    "&.MuiSnackbar-root": {
      "& .MuiCollapse-wrapperInner": {
        width: "100%",
      },
    },

    "&.SnackbarItem-variantError": {
      backgroundColor: `${theme.palette.error.light} !important`,
      "& svg": { color: theme.palette.error.main },
      color: `${theme.palette.error.dark} !important`,
    },

    "&.SnackbarItem-variantSuccess": {
      backgroundColor: `${theme.palette.success.light} !important`,
      "& svg": { color: theme.palette.success.main },
      color: `${theme.palette.success.dark} !important`,
    },

    "&.SnackbarItem-variantWarning": {
      backgroundColor: `${theme.palette.warning.light} !important`,
      "& svg": { color: theme.palette.warning.main },
      color: `${theme.palette.warning.dark} !important`,
    },

    "&.SnackbarItem-variantInfo": {
      backgroundColor: `${theme.palette.info.light} !important`,
      "& svg": { color: theme.palette.info.main },
      color: `${theme.palette.info.dark} !important`,
    },
  })
);
