import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { AppAsyncThunkConfig } from "store";
import { State } from "./bookInParts.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  updateTransitParts: createAppAsyncThunk(
    "bookInParts/updateTransitParts",
    async (props: { partIds: string[] }, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.updateTransitParts, {
        thunkName: "bookInParts/updateTransitParts",
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.updateTransitParts) return rejectWithValue("something went wrong");
      return { queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.updateTransitParts.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.loadingUpdate = true;
    return state;
  });
  builder.addCase(asyncMutations.updateTransitParts.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.loadingUpdate = false;
    return state;
  });
  builder.addCase(asyncMutations.updateTransitParts.fulfilled, (state) => {
    state.loadingUpdate = false;
    return state;
  });
};
