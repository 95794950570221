import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Build as BuildIcon,
  CalendarMonth as CalendarIcon,
  Close as CloseIcon,
  DeleteOutline as DeleteOutlineIcon,
  ExitToApp as ExitToAppIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Feedback as FeedbackIcon,
  History as HistoryIcon,
  Info as InfoIcon,
  InsertInvitation as InsertInvitationIcon,
  Inventory as InventoryIcon,
  Language as LanguageIcon,
  Link as LinkIcon,
  LocationOn as LocationOnIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
  ToggleOff as ToggleOffIcon,
  ToggleOn as ToggleOnIcon,
} from "@mui/icons-material";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { PromptDialog } from "components/PromptDialog";
import { gitSha, version } from "env";
import { countryToFlag, formatDateTime, productsPretty } from "helpers";
import { MenuItem } from "models/MenuItem";
import { Language } from "models/Setting";
// import { Endpoint } from "operations/schema/schema"; // WIP change endpoint while logged in

import { logoutUser } from "context/login/logout";
import { StockStore } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { selectForcedOffline, selectQueueLength, setForcedOffline } from "store/root.store";
import { setOpen as setBookInPartsOpen } from "store/slices/bookInParts.store";
import { getCachePrefill, getPartsEngineer, getRequestableParts } from "store/slices/cache.store";
import { setCalendarSelectedDate } from "store/slices/calendar.store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";
import { setOpen as setFeedbackOpen } from "store/slices/dialogs/feedback.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import {
  isFlagEnabled,
  selectEngineerSettings,
  selectUserSettings,
  setLanguage as setLanguageVar,
  updateUserSettings,
} from "store/slices/user.store";

const PREFIX = "MenuDrawer";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  footer: `${PREFIX}-footer`,
  header: `${PREFIX}-header`,
  clickable: `${PREFIX}-clickable`,
  notClickable: `${PREFIX}-notClickable`,
  childList: `${PREFIX}-childList`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    width: drawerWidth,
    [theme.breakpoints.between(0, "md")]: {
      width: "100vw",
    },
    flexShrink: 0,
  },
  [`& .${classes.drawerPaper}`]: {
    width: drawerWidth,
    [theme.breakpoints.between(0, "md")]: {
      width: "100vw",
    },
  },
  [`& .${classes.footer}`]: {
    marginTop: "auto",
  },
  "& svg": {
    fontSize: "2rem",
  },
  [`& .${classes.header}`]: {
    minHeight: theme.spacing(7),
  },
  [`& .${classes.clickable}`]: {
    cursor: "pointer",
  },
  [`& .${classes.notClickable}`]: {
    cursor: "default",
  },
  [`& .${classes.childList}`]: {
    marginLeft: "15px",
  },
}));

const drawerWidth = 350;

interface MenuAppBarProps {
  openMenu: boolean;
  setOpenMenu: (open: boolean) => void;
}

export const MenuDrawer: FC<MenuAppBarProps> = ({ openMenu, setOpenMenu }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const forcedOffline = useAppSelector(selectForcedOffline);
  const userSettings = useAppSelector(selectUserSettings);
  const engineerSettings = useAppSelector(selectEngineerSettings);
  const queueLength = useAppSelector(selectQueueLength);
  const transitPartsEnabled = useAppSelector((s) => isFlagEnabled(s, "TransitParts"));
  const { lastLoaded } = useAppSelector((s) => s.cache);
  const { userVar: userData } = useAppSelector((s) => s.user);

  const [expandLanguage, setExpandLanguage] = useState<boolean>(false);
  const [expandSettings, setExpandSettings] = useState<boolean>(false);
  const [expandAbout, setExpandAbout] = useState<boolean>(false);
  const [expandEndpointsMenu, setExpandEndpointsMenu] = useState<boolean>(false);
  const [expandEndpoints, setExpandEndpoints] = useState<boolean>(false);
  const [openClearCacheDialog, setOpenClearCacheDialog] = useState<boolean>(false);
  const [openClearStorageDialog, setOpenClearStorageDialog] = useState<boolean>(false);

  const { authVar: loginState, languageVar } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const openFeedback = (open: boolean) => {
    dispatch(setFeedbackOpen({ open }));
  };
  const openBookInParts = (open: boolean) => {
    dispatch(setBookInPartsOpen({ open }));
  };
  const openLocationSettings = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "locationSettings", open }));
  };
  const openSignout = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "signout", open }));
  };

  const setLanguage = (languageVar: Language) => {
    localStorage.setItem("language", languageVar);
    dispatch(setLanguageVar(languageVar));
  };

  const toggleUseMultipleEndpoints = () => {
    if (!userSettings) return;
    let settings = { ...userSettings };
    settings.useMultipleEndpoints = !userSettings?.useMultipleEndpoints;
    dispatch(updateUserSettings(settings));
  };
  const mapUserEndpoints = () => {
    if (!userSettings || !userSettings.userEndpoints) return [];
    return userSettings.userEndpoints.map((e) => {
      return {
        text: e!.apiUrl,
        secondaryText: `${e!.product ? productsPretty(e!.product) : ""}${
          e!.apiVersion ? ` - ${e!.apiVersion}` : ""
        }`,
        selected: e!.id === userSettings.lastEndpoint!.id,
        // onClick: () => changeEndpoint(e!), // WIP change endpoint while logged in
      };
    });
  };

  const refreshCachedData = () => {
    dispatch(getCachePrefill({ force: true }));
    dispatch(
      getRequestableParts({
        location: {
          locationId: userData?.stockId,
          stockStore: StockStore.Engineer,
        },
        force: true,
      })
    );
    dispatch(getPartsEngineer({ force: true }));
  };

  // // WIP change endpoint while logged in
  // const changeEndpoint = (e: Endpoint) => {
  //   console.log(e);
  // };

  const menuList = [
    {
      text: intl.formatMessage({ id: "menu.myJobs" }),
      icon: <BuildIcon />,
      onClick: () => {
        navigate("/jobs");
        setOpenMenu(false);
      },
      className: "e2e-engineer-jobs",
    },
    {
      text: intl.formatMessage({ id: "menu.myCalendar" }),
      icon: <CalendarIcon />,
      onClick: () => {
        dispatch(setCalendarSelectedDate({ date: null }));
        navigate("/jobs/schedule");
        setOpenMenu(false);
      },
      className: "e2e-engineer-schedule",
    },
    {
      text: intl.formatMessage({ id: "menu.viewMyJobsHistory" }),
      icon: <HistoryIcon />,
      onClick: () => {
        navigate("/history");
        setOpenMenu(false);
      },
      className: "e2e-engineer-history",
    },
    {
      text: intl.formatMessage({ id: "menu.planner" }),
      icon: <InsertInvitationIcon />,
      onClick: () => {
        navigate("/planner", { state: { openFilter: true } });
        setOpenMenu(false);
      },
      className: "e2e-engineer-planner",
      hide: !engineerSettings?.isSupervisor,
    },
    {
      text: intl.formatMessage({ id: "menu.bookInParts" }),
      icon: <AssignmentTurnedInIcon />,
      onClick: () => {
        openBookInParts(true);
        setOpenMenu(false);
      },
      hide: !transitPartsEnabled,
    },
    {
      text: intl.formatMessage({ id: "menu.stockEnquiry" }),
      icon: <InventoryIcon />,
      onClick: () => {
        navigate("/stock");
        setOpenMenu(false);
      },
      className: "e2e-engineer-stock",
    },
    {
      text: intl.formatMessage({ id: "menu.about" }),
      icon: <InfoIcon />,
      onClick: () => setExpandAbout(!expandAbout),
      expand: expandAbout,
      disableInset: true,
      childItems: [
        {
          text: `${intl.formatMessage({ id: "menu.version" })}: ${version()}`,
          secondaryText: gitSha(),
        },
      ],
    },
    {
      text: intl.formatMessage({ id: "menu.sendFeedback" }),
      icon: <FeedbackIcon />,
      onClick: () => {
        openFeedback(true);
        setOpenMenu(false);
      },
    },
    {
      text: intl.formatMessage({ id: "menu.settings" }),
      icon: <SettingsIcon />,
      onClick: () => {
        setExpandSettings(!expandSettings);
      },
      expand: expandSettings,
      hide: false,
      childItems: [
        {
          text: intl.formatMessage({ id: "menu.clearCache" }),
          icon: <DeleteOutlineIcon />,
          onClick: () => setOpenClearCacheDialog(true),
        },
        {
          text: intl.formatMessage({ id: "menu.clearLocalStorage" }),
          icon: <DeleteOutlineIcon />,
          onClick: () => setOpenClearStorageDialog(true),
        },
        {
          text: intl.formatMessage({ id: "menu.location" }),
          icon: <LocationOnIcon />,
          onClick: () => {
            openLocationSettings(true);
            setOpenMenu(false);
          },
        },
        {
          text: intl.formatMessage({ id: "menu.language" }),
          icon: <LanguageIcon />,
          onClick: () => setExpandLanguage(!expandLanguage),
          expand: expandLanguage,
          disableInset: true,
          childItems: [
            {
              text: `${countryToFlag("GB")} ${intl.formatMessage({
                id: "menu.language.en",
              })}`,
              selected: languageVar === "en",
              onClick: () => setLanguage("en"),
            },
            {
              text: `${countryToFlag("SE")} ${intl.formatMessage({
                id: "menu.language.sv",
              })}`,
              selected: languageVar === "sv",
              onClick: () => setLanguage("sv"),
            },
            {
              text: `${countryToFlag("NO")} ${intl.formatMessage({
                id: "menu.language.nb",
              })}`,
              selected: languageVar === "nb",
              onClick: () => setLanguage("nb"),
            },
            {
              text: `${countryToFlag("NO")} ${intl.formatMessage({
                id: "menu.language.nn",
              })}`,
              selected: languageVar === "nn",
              onClick: () => setLanguage("nn"),
            },
            {
              text: `${countryToFlag("DE")} ${intl.formatMessage({
                id: "menu.language.de",
              })}`,
              selected: languageVar === "de",
              onClick: () => setLanguage("de"),
            },
            {
              text: `${countryToFlag("FR")} ${intl.formatMessage({
                id: "menu.language.fr",
              })}`,
              selected: languageVar === "fr",
              onClick: () => setLanguage("fr"),
            },
            {
              text: `${countryToFlag("ES")} ${intl.formatMessage({
                id: "menu.language.es",
              })}`,
              selected: languageVar === "es",
              onClick: () => setLanguage("es"),
            },
          ],
        },
        {
          text: intl.formatMessage({ id: "menu.forceOffline" }),
          icon: forcedOffline ? <ToggleOnIcon color="info" /> : <ToggleOffIcon color="secondary" />,
          onClick: () => dispatch(setForcedOffline({ status: !forcedOffline })),
        },
        {
          text: intl.formatMessage({ id: "menu.endpoints" }),
          icon: <LinkIcon />,
          onClick: () => setExpandEndpointsMenu(!expandEndpointsMenu),
          expand: expandEndpointsMenu,
          childItems: [
            {
              text: userSettings?.useMultipleEndpoints
                ? intl.formatMessage({ id: "menu.endpoints.multipleEnabled" })
                : intl.formatMessage({ id: "menu.endpoints.multipleDisabled" }),
              icon: userSettings?.useMultipleEndpoints ? (
                <ToggleOnIcon color="info" />
              ) : (
                <ToggleOffIcon color="secondary" />
              ),
              onClick: () => toggleUseMultipleEndpoints(),
            },
            {
              text: intl.formatMessage({ id: "menu.endpoints.endpointList" }),
              hide: !userSettings?.useMultipleEndpoints,
              onClick: () => setExpandEndpoints(!expandEndpoints),
              expand: expandEndpoints,
              childItems: [
                ...mapUserEndpoints(),
                {
                  text: intl.formatMessage({ id: "menu.endpoints.addNew" }),
                  hide: true, //Hide while WIP change endpoint while logged in
                  secondaryText: "Work in progress. For now, sign out and add via login screen.",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      text: intl.formatMessage({ id: "menu.signOut" }),
      icon: <ExitToAppIcon />,
      onClick: () => {
        if (queueLength === 0) {
          logoutUser();
        } else {
          openSignout(true);
        }
      },
    },
  ];

  function CreateMenuList({
    text,
    secondaryText,
    icon,
    onClick,
    childItems,
    expand,
    selected,
    className,
    disableInset,
  }: MenuItem) {
    return (
      <>
        <ListItem
          key={text}
          onClick={onClick}
          selected={selected}
          className={className}
          sx={{ mb: 1 }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={text}
            secondary={secondaryText}
            className={onClick ? classes.clickable : classes.notClickable}
          />
          {childItems &&
            (expand ? (
              <ExpandLessIcon className={classes.clickable} />
            ) : (
              <ExpandMoreIcon className={classes.clickable} />
            ))}
        </ListItem>
        {childItems && !childItems.every((menuItem) => menuItem.hide) && (
          <Collapse in={expand} timeout="auto" unmountOnExit key={`Collapse-${text}`}>
            <List disablePadding className={!disableInset ? classes.childList : ""}>
              {childItems!.map((menuItem) => {
                if (!menuItem.hide) return <CreateMenuList key={menuItem.text} {...menuItem} />;
                return null;
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <StyledDrawer
      className={classes.drawer}
      variant="temporary"
      onClose={() => setOpenMenu(false)}
      anchor="left"
      open={openMenu}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <ListItem className={classes.header}>
        <ListItemIcon onClick={() => setOpenMenu(false)}>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText
          className="truncated"
          primary={loginState.email}
          secondary={
            intl.formatMessage({ id: "menu.lastSync" }) + ": " + formatDateTime(lastLoaded)
          }
        />
        <ListItemIcon onClick={() => refreshCachedData()}>
          <RefreshIcon />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <List>
        {menuList
          .filter((x) => !x.hide)
          .map((menuItem) => (
            <CreateMenuList key={menuItem.text} {...menuItem} />
          ))}
      </List>
      <PromptDialog
        open={openClearCacheDialog}
        setOpen={setOpenClearCacheDialog}
        onOk={() => {
          // TODO cacheContext?.clearCache();
          dispatch(addSnackbarMessage({ key: "ClearCache-success" }));
        }}
        promptContent={<FormattedMessage id="general.prompt" />}
      />
      <PromptDialog
        open={openClearStorageDialog}
        setOpen={setOpenClearStorageDialog}
        onOk={() => {
          logoutUser();
          localStorage.clear();
          dispatch(addSnackbarMessage({ key: "ClearLocalStorage-success" }));
        }}
        promptContent={
          <Typography>
            <FormattedMessage id="general.prompt" />
            <br />
            <FormattedMessage id="general.willResultInLogout" />
          </Typography>
        }
      />
    </StyledDrawer>
  );
};
