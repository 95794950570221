import { AddressType, Maybe } from "operations/schema/schema";

export const addressToString = (
  address: Maybe<AddressType> | null | undefined,
  includeCountry: Boolean = false
) => {
  const { name, address1, address2, address3, postalCode, postalPlace, country, city } =
    address || {};

  const parts = [name, address1, address2, address3, city, postalCode, postalPlace];
  if (includeCountry) parts.push(country);
  return parts.filter(Boolean).join(" ").trim();
};

export const addressToPostalLocation = (
  address: Maybe<AddressType> | null | undefined,
  vantage: Boolean = false
) => {
  const { postalCode, postalPlace, city } = address || {};

  const parts = [postalCode];
  if (vantage) parts.push(city);
  else parts.push(postalPlace);
  return parts.filter(Boolean).join(" ").trim();
};

export const addressToGoogleString = (
  address: Maybe<AddressType> | null | undefined,
  includeCountry: Boolean = false
) => {
  const { address1, address2, address3, postalCode, postalPlace, country, city } = address || {};

  const parts = [address1, address2, address3, city, postalCode, postalPlace];
  if (includeCountry) parts.push(country);
  return parts.filter(Boolean).join(" ").trim();
};
