import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { unwrapResult } from "@reduxjs/toolkit";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import { GraphQLError } from "graphql";
import { isAbortError } from "helpers";
import { LoginMutationVariables, Product } from "operations/schema/schema";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { login } from "store/slices/user.store";

type LoginFormProps = {
  classes: any;
  email: string | undefined;
  loginLoading: boolean;
  setLoginLoading: (state: boolean) => void;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
  url: string;
  product: Product | undefined;
  skipNavigate?: boolean;
};

export const UsernamePasswordLoginForm: FC<LoginFormProps> = ({
  classes,
  email,
  loginLoading,
  setLoginLoading,
  setEmail,
  url,
  product,
  skipNavigate = false,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { authVar } = useAppSelector((s) => s.user);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<string | GraphQLError[]>();

  const loginCallback = useCallback(
    async (variables: LoginMutationVariables) => {
      await dispatch(login(variables))
        .then(unwrapResult)
        .then((login) => {
          setLoginLoading(false);
          if (!skipNavigate) navigate("/jobs");
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          setLoginLoading(false);
          setLoginError(e);
        });
    },
    [dispatch, navigate, setLoginLoading, skipNavigate]
  );

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        if (!email || !password || !url || !product) return;
        e.preventDefault();
        setLoginLoading(true);
        loginCallback({
          email: email,
          password: password,
          url: url,
          product: product,
        });
        setPassword("");
      }}
      noValidate
    >
      <StyledTextField
        margin="normal"
        required
        id="email"
        label={intl.formatMessage({ id: "general.email" })}
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <StyledTextField
        margin="normal"
        required
        id="password"
        label={intl.formatMessage({ id: "login.password" })}
        name="password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        inputProps={{
          "data-testid": "passwordInput",
        }}
      />
      {loginError && (
        <Alert severity="error" data-testid="loginResult">
          <AlertTitle>
            <FormattedMessage id="login.error" />
          </AlertTitle>
          <FormattedMessage id="login.invalidCredentials" /> —{" "}
          <strong>
            <FormattedMessage id="login.doubleCheckSpelling" />
          </strong>
        </Alert>
      )}
      {authVar.message && !(authVar.message === "Auth0 failed") && (
        <Alert severity="info">
          <AlertTitle>
            <FormattedMessage id="logout.loggedOut" />
          </AlertTitle>
          {authVar.message}
        </Alert>
      )}
      <div>
        <PrimaryButton
          key={`${loginLoading}`}
          type="submit"
          disabled={loginLoading}
          className={classes.submit}
          data-testid="loginButton"
        >
          <FormattedMessage id="login.signIn" />
        </PrimaryButton>
        {loginLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </form>
  );
};
