import {
  Build as BuildIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Note as NoteIcon,
  Print as PrintIcon,
  SlowMotionVideo as SlowMotionVideoIcon,
} from "@mui/icons-material";
import { CircularProgress, SwipeableDrawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { isAbortError, isEmpty } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  selectCreateJobOpen,
  setOpen as setOpenCreateJobDialog,
} from "store/slices/dialogs/createJob.store";
import {
  selectNoteDialogOpen,
  setOpen as setOpenNoteDialog,
} from "store/slices/dialogs/note.store";
import {
  getJob,
  setRejectJobOpen,
  setUpdateEquipmentOpen,
  setUpdatePlannedDateOpen,
} from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { isFlagEnabled, selectEngineerSettings } from "store/slices/user.store";

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import PrimaryButton from "components/PrimaryButton";
import { TravelDialog } from "components/job/travel/TravelDialog";

import StyledFabFixed from "components/StyledFabFixed";
import { ChangeJobEquipmentDialog } from "pages/jobs/dialogs/ChangeJobEquipmentDialog";
import { CreateJobDialog } from "pages/jobs/dialogs/CreateJobDialog";
import { EquipmentEditDialog } from "pages/jobs/dialogs/EquipmentEditDialog";
import { NoteDialog } from "pages/jobs/dialogs/NoteDialog";
import { PlannedDateEditDialog } from "pages/jobs/dialogs/PlannedDateEditDialog";
import {
  selectIsJobInProgress,
  selectSelectedJob,
  selectSelectedJobVisit,
} from "store/slices/visit.store";
import { RejectJobDialog } from "./RejectJobDialog";
import { TravelButton } from "./visit/TravelButton";

const StyledContainer = styled("div")(() => ({
  zIndex: "9001",
}));

export const JobDetailsActions: FC = () => {
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const job = useAppSelector(selectSelectedJob);
  const { checklists } = useAppSelector(selectSelectedJobVisit);
  const openCreateJobDialog = useAppSelector(selectCreateJobOpen);
  const openNoteDialog = useAppSelector(selectNoteDialogOpen);
  const engineerSettings = useAppSelector(selectEngineerSettings);
  const changeJobEquipmentEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangeJobEquipment"));
  const changePlannedDateEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangePlannedDate"));
  const navigate = useNavigate();

  const canCreateJob = engineerSettings?.canCreateJob && isOnline;
  const canChangeEquipment =
    engineerSettings?.canChangeEquipmentOnTicket && isOnline && changeJobEquipmentEnabled;
  const [loading] = useState(false);
  const [openChangeJobEquipmentDialog, setOpenChangeJobEquipmentDialog] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openTravel, setOpenTravel] = useState(false);
  const jobInProgress = useAppSelector(selectIsJobInProgress);

  const refetch = () => {
    dispatch(getJob())
      .then(unwrapResult)
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "GetJob-fail" }));
      });
  };

  const jobHasIncompletePreWorkChecklists =
    !isEmpty(checklists) &&
    checklists.some(
      (c) =>
        c.hasPreStart &&
        c.checklist.questions
          .filter((x) => x.isRequiredPreStart)
          .some((q) => q.answer === null || q.answer === "0")
    );

  return (
    <StyledContainer data-testid="jobDetailsActionsContainer">
      <BackdropPrimaryMain open={loading}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <StyledFabFixed
        className="e2e-details-fab-button"
        disabled={loading}
        onClick={() => setOpenDrawer(true)}
        data-testid="jobDetailsActionsOpenButton"
      >
        <MoreVertIcon />
      </StyledFabFixed>
      <SwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
      >
        <div style={{ margin: "0px 16px" }} data-testid="jobDetailsActionsDrawer">
          {!jobInProgress && (
            <PrimaryButton
              data-testid="rejectJobButton"
              onClick={() => dispatch(setRejectJobOpen({ open: true }))}
              startIcon={<CancelIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="general.rejectJob" />
            </PrimaryButton>
          )}
          {canCreateJob && (
            <PrimaryButton
              className="e2e-create-job-button"
              data-testid="createJobButton"
              onClick={() => dispatch(setOpenCreateJobDialog({ open: true }))}
              startIcon={<BuildIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="menu.createNewJob" />
            </PrimaryButton>
          )}
          {canChangeEquipment && (
            <PrimaryButton
              className="e2e-change-machine-button"
              data-testid="changeJobEquipmentButton"
              onClick={() => setOpenChangeJobEquipmentDialog(true)}
              startIcon={<EditIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="visit.changeMachine" />
            </PrimaryButton>
          )}
          {changePlannedDateEnabled && (
            <PrimaryButton
              data-testid="editPlannedButton"
              onClick={() => dispatch(setUpdatePlannedDateOpen({ open: true }))}
              startIcon={<AccessAlarmIcon />}
              style={{ textTransform: "none" }}
            >
              {job.plannedDate?.startTime || job.plannedDate?.stopTime ? (
                <FormattedMessage id="visit.changePlannedTimes" />
              ) : (
                <FormattedMessage id="visit.addPlannedTimes" />
              )}
            </PrimaryButton>
          )}
          <PrimaryButton
            data-testid="openWorkNoteButton"
            onClick={() => dispatch(setOpenNoteDialog({ open: true }))}
            startIcon={<NoteIcon />}
            style={{ textTransform: "none" }}
          >
            <FormattedMessage id="visit.addNote" />
          </PrimaryButton>
          {jobInProgress && job.equipment?.id && (
            <PrimaryButton
              className="e2e-edit-machine-location-button"
              data-testid="equipmentEditButton"
              onClick={() => dispatch(setUpdateEquipmentOpen({ open: true }))}
              startIcon={<PrintIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="visit.editMachineLocation" />
            </PrimaryButton>
          )}
          {job?.canTravel && <TravelButton onClick={() => setOpenTravel(true)} />}
          <PrimaryButton
            className="e2e-details-visit-button"
            data-testid="visitButton"
            onClick={() => navigate(`/jobs/${job.id}/visit`)}
            startIcon={jobInProgress ? <SlowMotionVideoIcon /> : <BuildIcon />}
            style={{ textTransform: "none" }}
            disabled={jobHasIncompletePreWorkChecklists}
          >
            {jobInProgress ? (
              <FormattedMessage id="visit.inProgress" />
            ) : (
              <FormattedMessage id="visit.addVisit" />
            )}
          </PrimaryButton>
        </div>
      </SwipeableDrawer>
      {canCreateJob && openCreateJobDialog && <CreateJobDialog customer={job.customer} />}
      {canChangeEquipment && (
        <ChangeJobEquipmentDialog
          open={openChangeJobEquipmentDialog}
          handleClose={() => setOpenChangeJobEquipmentDialog(false)}
          handleFinished={refetch}
        />
      )}
      {changePlannedDateEnabled && <PlannedDateEditDialog />}
      <TravelDialog open={openTravel} handleClose={() => setOpenTravel(false)} />
      {job.equipment && <EquipmentEditDialog />}
      {openNoteDialog && <NoteDialog />}
      <RejectJobDialog />
    </StyledContainer>
  );
};
