import { Typography } from "@mui/material";
import { gitSha, version } from "env";
import { FormattedMessage } from "react-intl";

export const Version = () => {
  return (
    <>
      <Typography variant="body1" color="textSecondary">
        <FormattedMessage id="menu.version" />: {version()}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {gitSha()}
      </Typography>
    </>
  );
};
