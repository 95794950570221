import { AnyAction, Middleware } from "@reduxjs/toolkit";
import { getQueueOpen } from "context/graphql/queue";

export const queueMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action: AnyAction) => {
    if (!getQueueOpen()) {
      if (!action.meta) action.meta = {};
      action.meta.queued = true;
    }
    return next(action);
  };
