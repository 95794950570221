import { styled } from "@mui/material/styles";

const FileTabDetails = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  flexGrow: 1,
}));

export default FileTabDetails;
