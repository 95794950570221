import { gql } from "graphql-request";
import { mergeDateTimeString } from "helpers";
import { Job } from "models/Job";
import { MeterReadingInputType } from "operations/schema/schema";
import { JobVisitType } from "store/slices/visit.store";
import {
  CompleteVisitWithPartsMutationVariables,
  FileInputType,
  PartType,
  StockStore,
} from "../schema/schema";

export const COMPLETE_VISIT_WITH_PARTS = gql`
  mutation completeVisitWithParts(
    $metadata: MetadataInputType
    $jobId: String!
    $visit: JobVisitInput!
    $followUp: FollowUpInputType!
    $parts: [PartInputType!]!
    $extras: [ExtraInputType!]!
    $files: [FileInputType!]!
    $workTasks: [WorkTaskInputType!]!
    $newWorkUnitId: Int
  ) {
    completeVisitWithParts(
      metadata: $metadata
      jobId: $jobId
      visit: $visit
      followUp: $followUp
      parts: $parts
      extras: $extras
      files: $files
      workTasks: $workTasks
      newWorkUnitId: $newWorkUnitId
    ) {
      id
      externalId
    }
  }
`;

export const createCompleteVisitVars = (
  jobId: any,
  storeId: any,
  {
    workTimes,
    causeId,
    actionId1,
    actionId2,
    actionId3,
    solutionDescription,
    skipSignature,
    customerSignerName,
    jobCategory,
    serviceReportEmail,
    changeServiceTerms,
    serviceLevel,
    followUp,
    usedParts,
    extras,
    files,
    signatureCustomer,
    signatureEngineer,
    workTasks,
    checklists,
    newWorkUnitId,
  }: JobVisitType
): CompleteVisitWithPartsMutationVariables => {
  let mappedFiles: Array<FileInputType> = [];

  if (files !== undefined && files?.length > 0) {
    mappedFiles = files.map((file) => {
      return {
        data: file.data ?? "",
        description: file.description ?? "",
        name: file.name ?? "",
      };
    });
  }
  const [partsAdded, partsRequested] = usedParts
    .filter((p) => !p.uploaded)
    .map((p) => p.part)
    .reduce(
      ([added, requested], p) => {
        return p.stockStore !== StockStore.Other
          ? [[...added, p], requested]
          : [added, [...requested, p]];
      },
      [[], []] as Array<PartType[]>
    );

  return {
    jobId: jobId,
    visit: {
      workTimes: workTimes.map((time) => ({
        startTime: mergeDateTimeString(time.startDate, time.startTime),
        stopTime: mergeDateTimeString(time.stopDate!, time.stopTime!),
      })),
      causeId: `${causeId}`,
      actionId1: actionId1,
      actionId2: actionId2,
      actionId3: actionId3,
      solutionDescription: solutionDescription,
      customerSignerName: skipSignature ? null : customerSignerName,
      customerSignatureImageData: signatureCustomer,
      engineerSignatureImageData: signatureEngineer,
      categoryId: jobCategory?.id,
      typeId: jobCategory?.typeId,
      checklists: checklists.map((cl) => cl.checklist),
      serviceReportEmail: serviceReportEmail,
      newServiceLevelId: changeServiceTerms ? serviceLevel?.id : null,
    },
    followUp: {
      followUpChecked: followUp.followUpChecked,
      followUpPartsChecked: followUp.followUpPartsChecked,
      engineerId: followUp.engineerId,
      symptomDescription: followUp.symptomDescription,
      symptomCodeId1: followUp.symptomCode1,
      symptomCodeId2: followUp.symptomCode2,
      symptomCodeId3: followUp.symptomCode3,
    },
    parts: [
      ...partsAdded.map((p) => {
        return {
          id: p.id || "",
          partNumber: p.partNumber || "",
          quantity: p.quantity,
          stockId: `${p.stockId}`,
          stockStore: p.stockStore || StockStore.Other,
          isNonStock: p.isNonStock || undefined,
          status: p.status || undefined,
          returnStockId: p.returnStockId || undefined,
          salesPrice: p.salesPrice || undefined,
          salesPriceChangedOnClient: p.salesPriceChangedOnClient,
        };
      }),
      ...partsRequested.map((p) => {
        return {
          id: p.id || "",
          partNumber: p.partNumber || "",
          quantity: p.quantity,
          stockId: `${p.stockId}`,
          stockStore: p.stockStore || StockStore.Other,
          isNonStock: p.isNonStock || undefined,
          status: p.status,
          requestStockId: storeId, //  || undefined,
        };
      }),
    ],
    extras: extras,
    files: mappedFiles,
    workTasks: workTasks.map((wt) => {
      return {
        id: wt.id,
        description: wt.description,
        completedDate: wt.completedDate,
      };
    }),
    newWorkUnitId: newWorkUnitId,
  };
};

export const meterReadingsNotRequiredOrFilled = (
  cachedReadings: MeterReadingInputType[],
  job: Job
) => {
  return (
    !job.requireMeterReading ||
    !job.meters.length ||
    (job.requireMeterReading && cachedReadings.length > 0)
  );
};
