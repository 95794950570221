import { FC, useState } from "react";
import { useIntl } from "react-intl";

import { Grid, Typography } from "@mui/material";
import StyledTextField from "components/StyledTextField";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import { ExpressionParameterType } from "operations/schema/schema";
import { QuestionTypeProps } from "./QuestionTypeProps";

type ErrorObject = {
  [x: string]: string | undefined | null;
};

export const ExpressionType: FC<QuestionTypeProps> = (props) => {
  const regExp = new RegExp(`^-?\\d*.+\\d*$`);
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorObject, setErrorObject] = useState<ErrorObject>({});

  const params = question.expressionParams.map((ep) => ep.key);
  // eslint-disable-next-line
  const fn = new Function(...params, question.expression!);

  function round(num: string, decimalPlaces = 3) {
    var p = Math.pow(10, decimalPlaces);
    var n = +num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
  }

  const onValueChange = (param: ExpressionParameterType) => {
    question.expressionParams = question.expressionParams.map((ep) =>
      ep.key === param.key ? param : ep
    );
    question.answer = round(
      fn(...question.expressionParams.map((ep) => ep.value)).toString()
    ).toString();
    onChange(question);
  };

  return (
    <Grid item container direction="column" width="100%">
      <Grid item key={question.nodeKey}>
        <Typography>{question.answer}</Typography>
      </Grid>
      {question.expressionParams.map((ep) => (
        <div key={ep.key}>
          <Grid item>
            <Typography>{ep.description}</Typography>
          </Grid>
          <Grid item width="100%">
            {readonly ? (
              <StyledTextFieldReadOnly
                value={ep.value}
                type="number"
                size="small"
                data-testid="ExpressionTypeField"
                disabled
              />
            ) : (
              <StyledTextField
                value={ep.value}
                type="number"
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (!newValue && question.isRequired) {
                    setErrorObject({
                      ...errorObject,
                      [ep.key]: intl.formatMessage({ id: "general.required" }),
                    });
                    onValueChange({ ...ep, value: null });
                    return;
                  }
                  if (!regExp.test(newValue) || !Number(newValue)) {
                    setErrorObject({
                      ...errorObject,
                      [ep.key]: intl.formatMessage({ id: "general.enterValidNumber" }),
                    });
                    onValueChange({ ...ep, value: null });
                    return;
                  }
                  setErrorObject({ ...errorObject, [ep.key]: undefined });
                  onValueChange({ ...ep, value: Number(newValue) });
                }}
                inputProps={{
                  min: question.minValue,
                  max: question.maxValue,
                  step: "any",
                }}
                required={question.isRequired}
                helperText={errorObject[ep.key]}
                error={!!errorObject[ep.key]}
                size="small"
                data-testid={`ExpressionTypeField-${ep.key}`}
              />
            )}
          </Grid>
        </div>
      ))}
    </Grid>
  );
};
