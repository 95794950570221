import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useIntl } from "react-intl";

interface StyledSwitchProps extends SwitchProps {
  switchedontext: string;
  switchedofftext: string;
}

const PREFIX = "AsolviSwitch";

const classes = {
  root: `${PREFIX}-root`,
  switchBase: `${PREFIX}-switchBase`,
  thumb: `${PREFIX}-thumb`,
  track: `${PREFIX}-track`,
  checked: `${PREFIX}-checked`,
};

const StyledSwitch = styled(Switch)<StyledSwitchProps>((props) => ({
  [`& .${classes.track}`]: {
    border: `1px solid ${props.theme.palette.grey[500]}`,
    borderRadius: 11,
    "&:before, &:after": {
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: 11,
      position: "absolute",
      color: props.theme.palette.common.white,
    },
    "&:before": {
      content: `'${props.switchedontext}'`,
      left: 5,
    },
    "&:after": {
      content: `'${props.switchedofftext}'`,
      right: 6,
    },
  },

  [`& .${classes.checked}`]: {
    transform: "translateX(25px) !important",
  },

  [`& .${classes.thumb}`]: {
    width: 20,
    height: 20,
    boxShadow: "none",
    margin: 1,
  },

  [`&.${classes.root}`]: {
    width: 50,
    height: 26,
    padding: 0,
    borderRadius: 15,
  },

  [`& .${classes.switchBase}`]: {
    padding: 2,
    "&.Mui-checked": {
      color: props.theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: props.theme.palette.info.main,
        borderColor: props.theme.palette.info.main,
      },
    },
    "&.Mui-checked.Mui-disabled": {
      color: props.theme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: props.theme.palette.secondary.light,
        borderColor: props.theme.palette.secondary.light,
      },
    },
  },
}));

export const AsolviSwitch = (props: SwitchProps) => {
  const intl = useIntl();

  return (
    <StyledSwitch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
      switchedontext={intl.formatMessage({
        id: "general.yes",
      })}
      switchedofftext={intl.formatMessage({
        id: "general.no",
      })}
    />
  );
};
