import { CalendarMonth as CalendarIcon, Edit as EditIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, styled } from "@mui/material";
import {
  DateField,
  DatePickerToolbar,
  DatePickerToolbarProps,
  DateView,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { localeMap } from "lang";
import { useState } from "react";
import { useIntl } from "react-intl";

const StyledMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.info.main,
  "& label.Mui-focused": {
    color: theme.palette.info.main,
  },
  "& label.Mui-error": {
    color: theme.palette.error.main,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: theme.palette.info.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.info.main,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.error.main,
    },
  },
}));

const StyledMobileDatePickerReadOnly = styled(MobileDatePicker)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  "& label.Mui-focused": {
    color: theme.palette.secondary.dark,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: theme.palette.secondary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
    },
  },
  userSelect: "none",
  pointerEvents: "none",
}));

export interface AsolviDatePickerProps {
  onChange: (date: Date) => void | (() => void);
  label?: string;
  value: Date | null;
  disabled?: boolean;
  disableFuture?: boolean;
  required?: boolean;
  id?: string;
  name?: string;
  error?: string;
  readOnly?: boolean;
  size?: "small" | "medium" | undefined;
  clearable?: boolean;
  orientation?: "portrait" | "landscape";
}

export const AsolviDatePicker = (props: AsolviDatePickerProps) => {
  const intl = useIntl();

  const LayoutWithKeyboardView = (pickerProps: PickersLayoutProps<any, Date, DateView>) => {
    const { value, onChange } = pickerProps;
    const [showKeyboardView, setShowKeyboardView] = useState(false);

    const { toolbar, tabs, content, actionBar } = usePickerLayout({
      ...pickerProps,
      slotProps: {
        ...pickerProps.slotProps,
        toolbar: {
          ...pickerProps.slotProps?.toolbar,
          //@ts-ignore
          showKeyboardView,
          setShowKeyboardView,
        },
      },
    });

    return (
      <PickersLayoutRoot ownerState={pickerProps}>
        {toolbar}
        {actionBar}
        <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
          {tabs}
          {showKeyboardView ? (
            <Box sx={{ mx: 3, my: 2, width: 272 }}>
              <DateField
                value={value}
                onChange={onChange}
                sx={{ width: "100%" }}
                format={localeMap(intl.locale).format}
                data-testid="AsolviDatePicker-KeyboardTextField"
              />
            </Box>
          ) : (
            content
          )}
        </PickersLayoutContentWrapper>
      </PickersLayoutRoot>
    );
  };

  const ToolbarWithKeyboardViewSwitch = (
    props: DatePickerToolbarProps<any> & {
      showKeyboardView?: boolean;
      setShowKeyboardView?: React.Dispatch<React.SetStateAction<boolean>>;
    }
  ) => {
    const { showKeyboardView, setShowKeyboardView, ...other } = props;

    return (
      <Stack
        spacing={2}
        direction={other.isLandscape ? "column" : "row"}
        alignItems="center"
        sx={
          other.isLandscape
            ? {
                gridColumn: 1,
                gridRow: "1 / 3",
              }
            : { gridColumn: "1 / 4", gridRow: 1, mr: 1 }
        }
      >
        <DatePickerToolbar {...other} sx={{ flex: "1 1 100%" }} />
        <IconButton
          color="inherit"
          onClick={() => setShowKeyboardView!((prev) => !prev)}
          data-testid="AsolviDatePicker-KeyboardViewSwitch"
        >
          {showKeyboardView ? <CalendarIcon /> : <EditIcon />}
        </IconButton>
      </Stack>
    );
  };
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap(intl.locale).locale}
    >
      {props.disabled || props.readOnly ? (
        <StyledMobileDatePickerReadOnly
          label={props.label}
          value={props.value}
          format={localeMap(intl.locale).format}
        />
      ) : (
        <StyledMobileDatePicker
          localeText={{
            cancelButtonLabel: intl.formatMessage({
              id: "general.cancel",
              defaultMessage: "Cancel",
            }),
            todayButtonLabel: intl.formatMessage({
              id: "date.today",
              defaultMessage: "Today",
            }),
            okButtonLabel: intl.formatMessage({
              id: "general.ok",
              defaultMessage: "Ok",
            }),
          }}
          disableFuture={props.disableFuture}
          label={props.label}
          value={props.value}
          orientation={props.orientation}
          onAccept={(date: any) => {
            props.onChange(date as Date);
          }}
          slots={{
            layout: LayoutWithKeyboardView,
            toolbar: ToolbarWithKeyboardViewSwitch,
          }}
          slotProps={{
            actionBar: {
              actions: props.clearable
                ? ["clear", "today", "cancel", "accept"]
                : ["today", "cancel", "accept"],
            },
            textField: {
              id: props.id,
              name: props.name,
              variant: "outlined",
              required: props.required,
              fullWidth: true,
              error: !!props.error,
              helperText: props.error,
              size: props.size,
            },
          }}
          closeOnSelect={false}
          format={localeMap(intl.locale).format}
        />
      )}
    </LocalizationProvider>
  );
};
