import { FilterAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC } from "react";

const PREFIX = "FilterIcon";

const classes = {
  badgeStyle: `${PREFIX}-badgeStyle`,
  statusIcons: `${PREFIX}-statusIcons`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`& .${classes.badgeStyle}`]: {
    bottom: "0.25em",
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.secondary.light,
    display: "inline-block",
    width: "24px",
    textAlign: "center",
    borderRadius: "50%",
    height: "24px",
    position: "relative",
    top: "0px",
  },
  [`&.${classes.statusIcons}`]: {
    display: "flex",
    flexFlow: "row",
  },
}));

type FilterIconProps = { value: number };

export const FilterIcon: FC<FilterIconProps> = (props) => {
  return (
    <IconButton className="e2e-filter-button">
      <StyledTypography variant="button" className={classes.statusIcons}>
        {props.value > 0 && <span className={classes.badgeStyle}>{props.value}</span>}
        <FilterAlt />
      </StyledTypography>
    </IconButton>
  );
};
