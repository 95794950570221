import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { Typography } from "@mui/material";
import { AsolviSwitch } from "components/AsolviSwitch";
import PrimaryButton from "components/PrimaryButton";
import StyledTextField from "components/StyledTextField";
import { Stock } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { getStockList } from "store/slices/stock.store";

type EngineerStockFilterDialogProps = {
  setSelectedStock: Dispatch<SetStateAction<Stock>>;
  selectedStock: Stock;
  setShowOtherStockLocations: Dispatch<SetStateAction<boolean>>;
  showOtherStockLocations: boolean;
  setHideOutOfStock: Dispatch<SetStateAction<boolean>>;
  hideOutOfStock: boolean;
  onSubmit: () => void;
};

export const StockFilterDialog: FC<EngineerStockFilterDialogProps> = (props) => {
  const intl = useIntl();

  const {
    setSelectedStock,
    selectedStock,
    onSubmit,
    showOtherStockLocations,
    setShowOtherStockLocations,
    hideOutOfStock,
    setHideOutOfStock,
  } = props;

  const dispatch = useAppDispatch();
  const { stockList } = useAppSelector((s) => s.stock);

  useEffect(() => {
    dispatch(getStockList());
  }, [dispatch]);

  const allStock: Stock = {
    id: "",
    name: intl.formatMessage({ id: "stock.allStocks" }),
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: "10px" }}>
        <Grid item>
          <Typography variant="body1">
            <FormattedMessage id="stock.checkOtherLocations" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            key="mystockonly"
            checked={showOtherStockLocations}
            onClick={() => {
              if (!showOtherStockLocations) {
                setHideOutOfStock(true);
              }

              setShowOtherStockLocations(!showOtherStockLocations);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: "20px", pb: "20px" }}
      >
        <Grid item>
          <Typography variant="body1">
            <FormattedMessage id="stock.showAvailableOnly" />
          </Typography>
        </Grid>
        <Grid item>
          <AsolviSwitch
            disabled={showOtherStockLocations}
            key="hideamountonstock"
            checked={hideOutOfStock}
            onClick={() => {
              setHideOutOfStock(!hideOutOfStock);
            }}
          />
        </Grid>
      </Grid>
      {showOtherStockLocations && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                options={[allStock].concat(stockList)}
                getOptionLabel={(option) => `${option?.name}`}
                renderInput={(params) => (
                  <StyledTextField {...params} label={intl.formatMessage({ id: "stock.stocks" })} />
                )}
                value={selectedStock}
                onChange={(_, value) => {
                  if (value) {
                    setSelectedStock(value);
                  } else {
                    setSelectedStock({
                      id: "",
                      name: "",
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={12} mt={2}>
        <PrimaryButton onClick={onSubmit} fullWidth sx={{ mt: 0 }}>
          <FormattedMessage id="part.viewParts" />
        </PrimaryButton>
      </Grid>
    </Container>
  );
};
