import { CustomerContactType, Maybe } from "operations/schema/schema";

export const chooseContact = (
  preferredContact: Maybe<CustomerContactType> | null | undefined,
  contacts: Maybe<CustomerContactType>[] | null | undefined
) =>
  preferredContact
    ? preferredContact
    : contacts && contacts.length > 0
    ? contacts[0]
    : undefined;
