import {
  Build as BuildIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Note as NoteIcon,
  Print as PrintIcon,
} from "@mui/icons-material";
import { Container, SwipeableDrawer } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";
import {
  selectCreateJobOpen,
  setOpen as setOpenCreateJobDialog,
} from "store/slices/dialogs/createJob.store";
import {
  selectNoteDialogOpen,
  setOpen as setOpenNoteDialog,
} from "store/slices/dialogs/note.store";
import { setUpdateEquipmentOpen } from "store/slices/jobs.store";
import { isFlagEnabled, selectEngineerSettings } from "store/slices/user.store";
import { selectSelectedJob } from "store/slices/visit.store";

import PrimaryButton from "components/PrimaryButton";
import { StyledFab } from "components/StyledFab";
import { TravelDialog } from "components/job/travel/TravelDialog";
import { ChangeJobEquipmentDialog } from "pages/jobs/dialogs/ChangeJobEquipmentDialog";
import { CreateJobDialog } from "pages/jobs/dialogs/CreateJobDialog";
import { EquipmentEditDialog } from "pages/jobs/dialogs/EquipmentEditDialog";
import { NoteDialog } from "pages/jobs/dialogs/NoteDialog";

import { TabProps } from "./TabProps";
import { TravelButton } from "./TravelButton";
import { VisitDetails } from "./VisitDetails";

interface VisitTabProps extends TabProps {
  handleFinished: () => void;
}

export const VisitTab: FC<VisitTabProps> = (props) => {
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(selectConnectionStatus);
  const openCreateJobDialog = useAppSelector(selectCreateJobOpen);
  const openNoteDialog = useAppSelector(selectNoteDialogOpen);
  const engineerSettings = useAppSelector(selectEngineerSettings);
  const changeJobEquipmentEnabled = useAppSelector((s) => isFlagEnabled(s, "ChangeJobEquipment"));
  const canCreateJob = engineerSettings?.canCreateJob && isOnline;
  const canChangeEquipment =
    engineerSettings?.canChangeEquipmentOnTicket && isOnline && changeJobEquipmentEnabled;
  const job = useAppSelector(selectSelectedJob);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openTravel, setOpenTravel] = useState(false);
  const [openChangeJobEquipmentDialog, setOpenChangeJobEquipmentDialog] = useState(false);

  const { handleFinished, setFab } = props;

  useEffect(() => {
    const fab = (
      <StyledFab
        className="e2e-visit-fab-button"
        onClick={() => setOpenDrawer(true)}
        data-testid="VisitTabOpenButton"
      >
        <MoreVertIcon />
      </StyledFab>
    );
    setFab(fab);
  }, [setFab]);

  return (
    <>
      <Container data-testid="VisitTabContainer">
        <VisitDetails />
        <SwipeableDrawer
          anchor="bottom"
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          data-testid="VisitTabDrawer"
        >
          <div style={{ margin: "0px 16px" }}>
            {canCreateJob && (
              <PrimaryButton
                className="e2e-create-job-button"
                data-testid="createJobButton"
                onClick={() => dispatch(setOpenCreateJobDialog({ open: true }))}
                startIcon={<BuildIcon />}
                style={{ textTransform: "none" }}
              >
                <FormattedMessage id="menu.createNewJob" />
              </PrimaryButton>
            )}
            {canChangeEquipment && (
              <PrimaryButton
                className="e2e-change-machine-button"
                data-testid="changeJobEquipmentButton"
                onClick={() => setOpenChangeJobEquipmentDialog(true)}
                startIcon={<EditIcon />}
                style={{ textTransform: "none" }}
              >
                <FormattedMessage id="visit.changeMachine" />
              </PrimaryButton>
            )}
            <PrimaryButton
              data-testid="openWorkNoteButton"
              onClick={() => dispatch(setOpenNoteDialog({ open: true }))}
              startIcon={<NoteIcon />}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="visit.addNote" />
            </PrimaryButton>
            {job?.equipment?.id && (
              <PrimaryButton
                data-testid="equipmentEditButton"
                onClick={() => dispatch(setUpdateEquipmentOpen({ open: true }))}
                startIcon={<PrintIcon />}
                style={{ textTransform: "none" }}
              >
                <FormattedMessage id="visit.editMachineLocation" />
              </PrimaryButton>
            )}
            {job?.canTravel && <TravelButton onClick={() => setOpenTravel(true)} />}
          </div>
        </SwipeableDrawer>
      </Container>
      <TravelDialog open={openTravel} handleClose={() => setOpenTravel(false)} />
      {canCreateJob && openCreateJobDialog && <CreateJobDialog customer={job?.customer} />}
      {canChangeEquipment && (
        <ChangeJobEquipmentDialog
          open={openChangeJobEquipmentDialog}
          handleClose={() => setOpenChangeJobEquipmentDialog(false)}
          handleFinished={handleFinished}
        />
      )}
      {job?.equipment && <EquipmentEditDialog />}
      {openNoteDialog && <NoteDialog />}
    </>
  );
};
