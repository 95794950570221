import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";

import { PartType, Stock } from "operations/schema/schema";
import { RootState } from "store";
import { asyncQueries, queryBuilder } from "./stock.queries";

export interface State {
  stockEnquiry: PartType[];
  stockList: Stock[];
  loadingStockEnquiry: boolean;
}

interface Actions extends SliceCaseReducers<State> {}

interface Selectors {
  selectStockList: (state: RootState) => Stock[];
}

export const initialState: State = {
  stockEnquiry: [],
  stockList: [],
  loadingStockEnquiry: false,
};

const actions: Actions = {};

const selectors: Selectors = {
  selectStockList({ stock: { stockList } }) {
    return stockList;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "stock",
  initialState,
  reducers: actions,
  extraReducers: queryBuilder,
});

export default storeBase.reducer;
export const { selectStockList } = selectors;
export const { getStockEnquiry, getStockList } = asyncQueries;
