import { Variables } from "graphql-request/build/esm/types";
import { getStore } from "store";
import { addQueueLength, selectConnectionStatus, subtractQueueLength } from "store/root.store";
import { getSdk } from "./client";
import { QueueEntry, RequestExtendedInit, getDb } from "./db";

export type { QueueEntry, RequestExtendedInit }; // Re-export to avoid importing from "./db"

type Sdk = ReturnType<typeof getSdk>;

export type Queue = {
  [x: string]: QueueEntry[];
};

export let queueOpen: boolean = true;

export const getQueueOpen = () => {
  return queueOpen;
};
export const closeQueue = () => {
  queueOpen = false;
};
export const openQueue = async () => {
  if (!selectConnectionStatus(getStore().getState())) return;
  queueOpen = true;
  executeQueue();
};

export const getQueues = async () => {
  let db = await getDb();
  let entries = await db.getAll("queue");
  let queue = entries.reduce((queue: Queue, q) => {
    queue[q.collection] = [...(queue[q.collection] || []), q];
    return queue;
  }, {});
  return queue;
};
export const getQueue = async (key: string = "other") => {
  let db = await getDb();
  let entries = await db.getAll("queue");
  let queue = entries.filter((q) => q.collection === key);
  return queue;
};
export const deleteQueueEntry = async (id: number | undefined) => {
  if (!id) return;
  (await getDb()).delete("queue", id);
};
export const clearQueue = async () => {
  (await getDb()).clear("queue");
};

export const pushToQueue = async (request: RequestExtendedInit<Variables>) => {
  let db = await getDb();
  let collection: string;
  let { variables } = request;
  if (variables && variables.jobId) {
    collection = variables.jobId as string;
  } else {
    collection = "other";
  }
  delete request.signal; // AbortSignal not serializable
  db.put("queue", { collection, request });
  getStore().dispatch(addQueueLength());
};

const executeQueue = async () => {
  let keys = Object.keys(await getQueues()).filter((k) => k !== "other");
  let currentKey: string | undefined = "other";
  while (getQueueOpen() && Object.entries(await getQueues()).length) {
    if (currentKey && !(await getQueue(currentKey)).length) currentKey = keys.shift();
    if (!currentKey) continue;

    let q = await getQueue(currentKey);
    const queueEntry = q.shift();
    if (!queueEntry) continue;
    const { request } = queueEntry;
    if (!request) continue;

    const { operationName, variables, headers } = request;
    if (!operationName) continue;

    const operation = getSdk()[operationName as keyof Sdk];
    let thunkName = headers["x-thunkName"];
    let thunkProps = headers["x-thunkProps"];
    let onError = headers["x-onError"];
    let onCompleted = headers["x-onCompleted"];

    try {
      await executeOperation(operation, variables as any, headers);
      for (let action of onCompleted) {
        await getStore().dispatch(action);
      }
    } catch (e: any) {
      let errors: any = undefined;
      if (e && !(e instanceof Error) && e.errors) {
        errors = e.errors;
      } else if (e && e.response && e.response.errors) {
        errors = e.response.errors;
      } else {
        console.log(e);
      }
      let rejectedAction = {
        type: `${thunkName}/rejected`,
        payload: errors,
        meta: {
          requestStatus: "rejected",
          arg: thunkProps,
          aborted: false,
          queued: false,
        },
      };
      await getStore().dispatch(rejectedAction);
      for (let action of onError) {
        await getStore().dispatch(action);
      }
    } finally {
      await getStore().dispatch(subtractQueueLength());
      if (!queueEntry.id) continue;
      (await getDb()).delete("queue", queueEntry.id);
    }
  }
};

const executeOperation = async (
  operation: Sdk[keyof Sdk],
  variables: any,
  headers: any,
  attempt: number = 0
): Promise<ReturnType<Sdk[keyof Sdk]>> => {
  try {
    let res = await operation(variables, headers);
    return res;
  } catch (e) {
    if (attempt < 2) {
      return executeOperation(operation, variables, headers, attempt + 1);
    } else {
      throw e;
    }
  }
};
