import React, { useCallback, useEffect, useState } from "react";

import { getStore } from "store";

import { consentVersion } from "helpers";

import { BasicUserConsentDialog } from "pages/consent/BasicUserConsentDialog";
import { TrackingUserConsentDialog } from "pages/consent/TrackingUserConsentDialog";
import { selectUser } from "store/slices/user.store";

type ConsentProviderProps = {
  children: React.ReactNode;
};

interface ConsentContextType {}

const ConsentContext = React.createContext<ConsentContextType | undefined>(undefined);

export default function ConsentProvider(props: ConsentProviderProps) {
  const [consentsAccepted, setConsentsAccepted] = useState(false);
  const [openBasic, setOpenBasic] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);

  const checkMissingConsent = useCallback(() => {
    let consentKeys = Object.keys(consentVersion);
    const user = selectUser(getStore().getState());
    const userConsents = user?.userConsents || [];
    for (let consent of userConsents) {
      let key = consent.type.toLowerCase() as keyof typeof consentVersion;
      let current = consentVersion[key];
      if (current.required && consent.accepted && current.version === consent.version) {
        consentKeys.splice(consentKeys.indexOf(key), 1);
      } else if (!current.required && current.version === consent.version) {
        consentKeys.splice(consentKeys.indexOf(key), 1);
      }
    }
    setConsentsAccepted(!consentKeys.length);
    if (consentKeys.includes("basic")) {
      // Open basic first
      setOpenBasic(true);
      return;
    }
    // Might need a way to do this where we separate optional and required
    // Do required first, then optional
    for (let key of consentKeys) {
      // Open one at a time
      if (key === "tracking") {
        setOpenTracking(true);
        return;
      }
    }
  }, []);

  useEffect(() => {
    checkMissingConsent();
  }, [checkMissingConsent]);

  return (
    <ConsentContext.Provider value>
      <BasicUserConsentDialog
        open={openBasic}
        setOpen={(open: boolean) => {
          setOpenBasic(open);
          checkMissingConsent();
        }}
      />
      <TrackingUserConsentDialog
        open={openTracking}
        setOpen={(open: boolean) => {
          setOpenTracking(open);
          checkMissingConsent();
        }}
      />
      {consentsAccepted ? props.children : null}
    </ConsentContext.Provider>
  );
}

export function useConsent() {
  const context = React.useContext(ConsentContext);
  if (context === undefined) {
    throw new Error("useConsents must be used within a ConsentContext");
  }
  return context;
}
