import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import FullScreenDialog from "components/FullScreenDialog";

import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";
import { ChecklistTypeFormHelperText } from "./ChecklistTypeFormHelperText";
import { QuestionTypeProps } from "./QuestionTypeProps";
import { SignatureDialog } from "./SignatureDialog";

export const SignatureType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const job = useAppSelector(selectPageSelectedJob);
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [imageWidth, setImageWidth] = useState(window.innerWidth - 50);

  const errorStyle = {
    outlineColor: "red",
    outlineWidth: "1px",
    outlineStyle: "solid",
    margin: "2px",
  };

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  useEffect(() => {
    const resizeImage = () => {
      setImageWidth(window.innerWidth - 50);
    };

    resizeImage();

    window.addEventListener("resize", resizeImage);
    return () => window.removeEventListener("resize", resizeImage);
  }, []);

  const handleSignatureInput = async (value: string) => {
    const signature = value;

    onChange(JSON.stringify([{ name: `${job.id}_signature.png`, data: signature }]));
  };

  return (
    <Grid item container direction="column" spacing={1} data-testid="SignatureType">
      {!readonly && (
        <Grid item container>
          <Grid item xs={4}>
            <Button
              onClick={() => {
                setSignatureDialogOpen(true);
              }}
              variant="contained"
              color="info"
              size="small"
              fullWidth
              sx={!!errorText ? errorStyle : undefined}
              data-testid="SignatureTypeDialogOpenButton"
            >
              <CreateIcon />
            </Button>
          </Grid>

          {question.answer && !question.isRequired && (
            <Grid item xs={4}>
              <Button
                onClick={() => {
                  onChange(null);
                }}
                color="error"
                variant="contained"
                size="small"
                fullWidth
                sx={{ ml: 1 }}
                data-testid="SignatureTypeDeleteButton"
              >
                <DeleteIcon />
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <ChecklistTypeFormHelperText>{errorText}</ChecklistTypeFormHelperText>
          </Grid>
        </Grid>
      )}

      {question.answer ? (
        <Grid item>
          <img
            src={JSON.parse(question.answer)[0].data}
            alt="signature preview"
            style={{
              maxWidth: imageWidth,
              maxHeight: "150px",
              width: "auto",
              height: "auto",
            }}
          />
        </Grid>
      ) : (
        <Grid item>
          <Typography>
            <FormattedMessage id="checklist.noSignatureAdded" />
          </Typography>
        </Grid>
      )}
      <FullScreenDialog
        open={signatureDialogOpen}
        setOpen={setSignatureDialogOpen}
        title={`${job?.externalId} / ${intl.formatMessage({ id: "general.signature" })}`}
        centerTitle
        goingBack
        child={
          <SignatureDialog
            onSave={handleSignatureInput}
            handleClose={() => {
              setSignatureDialogOpen(false);
            }}
          />
        }
      />
    </Grid>
  );
};
