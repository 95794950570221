import { styled } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
  border: `1px solid ${theme.palette.error.main}`,
}));

const DecreaseButton = (props: IconButtonProps) => {
  return (
    <StyledIconButton {...props} size="large">
      <RemoveIcon />
    </StyledIconButton>
  );
};

export default DecreaseButton;
