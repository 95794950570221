import { PlaylistAdd as PlaylistAddIcon } from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import StyledCard from "components/StyledCard";
import { StyledContainer } from "components/StyledContainer";
import { isPartUsed } from "helpers";
import { StockStore } from "operations/schema/schema";
import { useAppSelector } from "store";
import { selectPageSelectedJob } from "store/root.store";

export const PartTab: FC = () => {
  const intl = useIntl();
  const { usedParts: parts } = useAppSelector(selectPageSelectedJob);
  const usedParts = parts.filter((part) => isPartUsed(part!));

  const partsGrid = usedParts?.length ? (
    usedParts.map((usedPart) => {
      const stockStoreTranslatable =
        usedPart.stockStore === StockStore.Customer
          ? intl.formatMessage({ id: "general.customer" })
          : usedPart.stockStore === StockStore.Engineer
          ? intl.formatMessage({ id: "general.engineer" })
          : usedPart.stockStore === StockStore.Locational
          ? intl.formatMessage({ id: "part.locational" })
          : usedPart.stockStore === StockStore.Nonstock
          ? intl.formatMessage({ id: "part.nonStock" })
          : usedPart.stockStore === StockStore.Other
          ? intl.formatMessage({ id: "part.other" })
          : usedPart.stockStore;

      return usedPart ? (
        <Grid item xs={12} sm={6} lg={4} key={usedPart.id}>
          <StyledCard key={usedPart.id}>
            <CardContent sx={{ width: "100%" }}>
              <Typography variant="body1" component="h3">
                {usedPart.description}
              </Typography>
              <Typography color="textSecondary">{usedPart.partNumber}</Typography>
              <Typography color="textSecondary">{stockStoreTranslatable?.toUpperCase()}</Typography>
              <Typography variant="body2" component="p">
                <FormattedMessage id="general.quantity" />: {usedPart.quantity}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      ) : null;
    })
  ) : (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <PlaylistAddIcon color="secondary" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography color="secondary">
          <FormattedMessage id="part.noPartsUsed" />
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <StyledContainer data-testid="PartTab">
      <Grid container spacing={1}>
        {partsGrid}
      </Grid>
    </StyledContainer>
  );
};
