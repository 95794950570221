import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";

import { WorkTaskSwitch } from "components/WorkTaskSwitch";

import { QuestionTypeProps } from "./QuestionTypeProps";

export const ConditionalGroupType: FC<QuestionTypeProps> = (props) => {
  const { question, onChange, children, readonly } = props;
  const [checked, setChecked] = useState(!!question.answer);

  return (
    <Grid item width="100%" data-testid="ConditionalGroupType" marginTop="5px" marginBottom="5px">
      <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography>{question.question}</Typography>
        </Grid>
        <Grid item>
          <WorkTaskSwitch
            checked={checked}
            onChange={(_, checked) => {
              if (!readonly) {
                setChecked(checked);
                onChange(checked ? "true" : "false");
              }
            }}
            disabled={readonly}
            data-testid="WorkTaskSwitch"
          />
        </Grid>
      </Grid>
      <Collapse in={checked === question.showCondition}>
        <Grid container direction="column" spacing={1} marginTop="5px">
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};
