import { Delete, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Fade, Grid, IconButton, ListItem, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { PromptDialog } from "components/PromptDialog";
import { QueueEntry, deleteQueueEntry } from "context/graphql/queue";

interface QueueItemProps {
  queueEntry: QueueEntry;
  onAbort: () => void;
}

const removeBase64Fields = (orgObj: any): any => {
  if (orgObj === null) return null;
  let obj = JSON.parse(JSON.stringify(orgObj));

  if (typeof obj === "string") {
    if (obj.startsWith("<?xml")) return obj.replace(/\r|\n|\t/g, " ");
    if (obj.length > 1000) return "<binary data>";
    return obj;
  }

  if (!Array.isArray(obj) && typeof obj !== "object") return obj;

  for (let key in obj) {
    obj[key] = removeBase64Fields(obj[key]);
  }
  return obj;
};

export const QueueItem = (props: QueueItemProps) => {
  const { queueEntry, onAbort } = props;
  const intl = useIntl();
  const { operationName, variables } = queueEntry.request;
  const { jobId: _, ...restVariables } = variables || {};
  const [isOpen, setIsOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const mappedVariables = removeBase64Fields(restVariables);

  const abortOperation = () => {
    deleteQueueEntry(queueEntry.id);
    onAbort();
  };

  return (
    <ListItem divider component="div">
      <Grid
        container
        direction="row"
        alignItems="start"
        wrap="nowrap"
        justifyContent="space-between"
        item
        xs={12}
      >
        <Grid
          container
          direction="column"
          margin="auto"
          alignItems="start"
          justifyContent="space-between"
          item
        >
          <Grid container item spacing={1}>
            <Typography variant="body2" color="secondary">
              {`${intl.formatMessage({
                id: "queue.operation",
              })}: ${operationName}`}
            </Typography>
          </Grid>
          {isOpen && (
            <Grid container item spacing={1}>
              <Fade in={isOpen}>
                <Typography variant="body2" color="secondary" component="pre">
                  {JSON.stringify(mappedVariables, null, 2)}
                </Typography>
              </Fade>
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" item xs={1}>
          {/**
           * TODO:
           * Delete-button commented out until
           * we are sure it does not cause concurrency issues
           **/}
          {false && (
            <Grid item>
              <IconButton
                aria-label={intl.formatMessage({ id: "general.delete" })}
                onClick={() => setConfirmationDialog(true)}
                size="large"
              >
                <Delete color="error" />
              </IconButton>
            </Grid>
          )}
          <Grid item>
            {!isOpen ? (
              <IconButton
                aria-label={intl.formatMessage({ id: "general.details" })}
                onClick={() => setIsOpen(!isOpen)}
                size="large"
              >
                <ExpandMore color="secondary" />
              </IconButton>
            ) : (
              <IconButton
                aria-label={intl.formatMessage({ id: "general.close" })}
                onClick={() => setIsOpen(!isOpen)}
                size="large"
              >
                <ExpandLess color="secondary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <PromptDialog
        aria-labelledby="form-dialog-title"
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        okText={intl.formatMessage({ id: "general.confirm" })}
        onOk={abortOperation}
        title={<FormattedMessage id="queue.cancelOperation" />}
        promptContent={<FormattedMessage id="queue.cancelPrompt" />}
      />
    </ListItem>
  );
};
