import { isMobileSafari } from "helpers";
import useShouldShowPrompt from "./useShouldShowPrompt";

const iosInstallPromptedAt = "iosInstallPromptedAt";

const useIosInstallPrompt = (): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt);

  return [
    isMobileSafari() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};

export default useIosInstallPrompt;
