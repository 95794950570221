import DoneIcon from "@mui/icons-material/Done";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, MutableRefObject } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";

import { isAbortError } from "helpers";
import { JobStatus } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { filterNewJobIds, getJobs, setJobStatus } from "store/slices/jobs.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";
import { completeVisit, selectSelectedJob, setSignatureCustomer } from "store/slices/visit.store";

import BackdropWhite from "components/BackdropWhite";
import PrimaryButton from "components/PrimaryButton";
import { JobSignoff } from "./JobSignoff";
import { JobVisitSummary } from "./JobVisitSummary";

interface SignoffDialogProps {
  canvasRef: MutableRefObject<ReactSignatureCanvas | null>;
  disableCompleteVisitButton: boolean;
  loading: boolean;
  handleSignature: (added: boolean) => void;
  handleClose: () => void;
}

export const CustomerSignoffComponent: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { handleClose, handleSignature, disableCompleteVisitButton, canvasRef, loading } = props;
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const navigate = useNavigate();
  const submitForm = () => {
    dispatch(
      setSignatureCustomer({
        signatureData: canvasRef.current?.getTrimmedCanvas().toDataURL(),
      })
    );
    dispatch(completeVisit({ jobId: job.id }))
      .then(unwrapResult)
      .then(({ queued, followUp }) => {
        dispatch(setJobStatus({ jobId: job.id, status: JobStatus.Completed }));
        dispatch(getJobs({ force: true }));
        dispatch(filterNewJobIds({ jobId: job.id }));
        if (queued) {
          dispatch(addSnackbarMessage({ key: "CompleteVisitWithParts-stored" }));
        } else {
          if (followUp) {
            dispatch(addSnackbarMessage({ key: "CompleteVisitWithParts-returned" }));
          } else {
            dispatch(addSnackbarMessage({ key: "CompleteVisitWithParts-success" }));
          }
        }
        handleClose();
        navigate("/jobs");
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "CompleteVisitWithParts-fail" }));
      });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item sx={{ width: "100%" }}>
        <JobVisitSummary />
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <JobSignoff canvasReference={canvasRef} handleSignature={handleSignature} />
      </Grid>
      <Grid item pb={1.5}>
        <BackdropWhite open={loading}>
          <CircularProgress color="inherit" />
        </BackdropWhite>

        <PrimaryButton
          key={`${disableCompleteVisitButton}`}
          startIcon={<DoneIcon />}
          variant="contained"
          onClick={submitForm}
          fullWidth
          disabled={disableCompleteVisitButton}
          className="e2e-complete-visit-button-final"
        >
          <FormattedMessage id="visit.complete" />
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};
