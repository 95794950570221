import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PullToRefresh from "react-simple-pull-to-refresh";

import PrimaryButton from "components/PrimaryButton";
import StyledCard from "components/StyledCard";
import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";

import { CircularProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import BackdropPrimaryMain from "components/BackdropPrimaryMain";
import { GraphQLError } from "graphql";
import { isAbortError } from "helpers";
import { TransitPartType } from "models/TransitPartType";
import { useAppDispatch, useAppSelector } from "store";
import {
  getTransitParts,
  selectBookInPartsDialogOpen,
  setOpen,
  togglePartChecked,
  updateTransitParts,
} from "store/slices/bookInParts.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const PREFIX = "BookInPartsDialog";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

export const BookInPartsDialog: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { transitParts, loadingParts, loadingUpdate } = useAppSelector(
    (state) => state.bookInParts
  );
  const open = useAppSelector(selectBookInPartsDialogOpen);

  const getTransitPartsCb = useCallback(() => {
    dispatch(getTransitParts());
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      getTransitPartsCb();
    }
  }, [open, getTransitPartsCb]);

  const updateTransitPartsCb = useCallback(
    async (partIds: string[]) => {
      await dispatch(updateTransitParts({ partIds }))
        .then(unwrapResult)
        .then(({ queued }) => {
          dispatch(setOpen({ open: false }));
          if (queued) {
            dispatch(addSnackbarMessage({ key: "UpdateTransitParts-stored" }));
          } else {
            dispatch(addSnackbarMessage({ key: "UpdateTransitParts-success" }));
          }
        })
        .catch((error: GraphQLError[] | Error) => {
          if (isAbortError(error)) return;
          if (Array.isArray(error) && error.some((e) => e.message.includes("403 Forbidden"))) {
            dispatch(addSnackbarMessage({ key: "UpdateTransitParts-forbidden" }));
          }
          dispatch(addSnackbarMessage({ key: "UpdateTransitParts-fail" }));
        });
      getTransitPartsCb();
    },
    [dispatch, getTransitPartsCb]
  );

  const toggleChecked = async (part: TransitPartType) => {
    await dispatch(togglePartChecked({ part }));
  };

  const disabled = !transitParts.find((p) => p.checked === true);

  const partsGrid = transitParts.length ? (
    <Grid
      container
      spacing={1}
      key="transitPartsGridContainer"
      data-testid="BookInPartsDialog-PartsGrid"
    >
      <Grid item xs={12}>
        <Typography variant="h5">
          {intl.formatMessage({
            id: "bookInParts.confirmPartsReceived",
          })}
        </Typography>
      </Grid>
      {transitParts.map((part) => {
        return (
          <Grid item xs={12} key={part.id + "-gridItem"}>
            <StyledCard key={part.id}>
              <CardContent sx={{ width: "100%" }}>
                <Grid container justifyContent="space-between" direction="row" alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="body1" component="h3">
                      {part.description}
                    </Typography>
                    <Typography color="textSecondary">{part.partNumber}</Typography>
                    <Typography variant="body2" component="p">
                      <FormattedMessage id="general.quantity" />: {part.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="right">
                    <Checkbox
                      name={part.description || ""}
                      color="primary"
                      checked={part.checked}
                      onChange={() => {
                        toggleChecked(part);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <PrimaryButton
          key={`book-in-parts`}
          type="submit"
          variant="contained"
          fullWidth
          disabled={disabled || loadingUpdate || loadingParts}
          onClick={() => {
            const ids = transitParts
              .filter((part) => part.checked)
              .map((part) => part.id) as string[];
            updateTransitPartsCb(ids);
          }}
        >
          <FormattedMessage id="general.finish" />
        </PrimaryButton>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <AssignmentTurnedInIcon color="secondary" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography color="secondary" data-testid="BookInPartsDialog-NoPartsText">
          <FormattedMessage id="bookInPart.noPartsToBookIn" />
        </Typography>
      </Grid>
      <Grid item>
        <PrimaryButton
          type="submit"
          variant="contained"
          onClick={() => getTransitPartsCb()}
          data-testid="BookInPartsDialog-RefetchButton"
        >
          <FormattedMessage id="bookInParts.refreshPartsList" />
        </PrimaryButton>
      </Grid>
    </Grid>
  );

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={() => dispatch(setOpen({ open: false }))}
      TransitionComponent={Transition}
    >
      <BackdropPrimaryMain open={loadingUpdate}>
        <CircularProgress color="inherit" />
      </BackdropPrimaryMain>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          {loadingParts && <LinearProgress />}
          <IconButton
            aria-label={intl.formatMessage({ id: "general.close" })}
            className={classes.menuButton}
            onClick={() => dispatch(setOpen({ open: false }))}
            data-testid="BookInPartsDialog-CloseButton"
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography flexGrow={1}>
            <FormattedMessage id="menu.bookInParts" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <StyledContainer>
        <PullToRefresh onRefresh={async () => await getTransitPartsCb()}>{partsGrid}</PullToRefresh>
      </StyledContainer>
    </StyledDialog>
  );
};
