import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { CardContent, FormHelperText, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidationError } from "yup";

import { ExtraInputType } from "operations/schema/schema";

import { useAppDispatch } from "store";
import { removeExtra, updateExtra } from "store/slices/visit.store";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { AsolviTimePicker } from "components/AsolviTimePicker";
import { PromptDialog } from "components/PromptDialog";
import StyledCard from "components/StyledCard";
import { StyledCardActions } from "components/StyledCardActions";
import StyledTextField from "components/StyledTextField";
import { intlFormatDateNumeric, intlFormatDateTimeNumeric, toDate, toDateString } from "helpers";
import { ExtraValidationSchema } from "models/ExtraValidationSchema";

const PREFIX = "ExtraCard";

const classes = {
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  error: `${PREFIX}-error`,
  buttonDisabled: `${PREFIX}-buttonDisabled`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: "100%",

  [`& .${classes.buttonWrapper}`]: {
    marginTop: "10px",
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.buttonDisabled}`]: {
    border: `1px solid rgba(0, 0, 0, 0.26)`,
  },
}));

interface ExtraCardProps {
  extra: ExtraInputType;
  setCountEditItems: (amount: number) => void;
  index: number;
}

export const ExtraCardComponent: FC<ExtraCardProps> = (props) => {
  const { extra, setCountEditItems, index } = props;

  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [openEdit, setOpenEdit] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const validationSchema = ExtraValidationSchema();

  const editJobPart = () => {
    setCountEditItems(!openEdit ? +1 : -1);
    setErrors({});
    setOpenEdit(!openEdit);
  };

  const removeExtraFromVisit = () => {
    dispatch(removeExtra({ index: index }));
  };

  const editExtra = (extra: ExtraInputType) => {
    const isValid = validationSchema.isValidSync(extra);
    if (isValid) {
      dispatch(updateExtra({ extra: extra }));
      setErrors({});
    } else {
      try {
        validationSchema.validateSync(extra, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        setErrors({ ...errors });
      }
    }
  };

  return (
    <StyledGrid item xs={12} sm={6} lg={4}>
      <StyledCard key={extra.id}>
        <CardContent sx={{ width: "100%" }}>
          <Typography variant="body1" component="h3">
            {extra.type}
          </Typography>
          {extra.isTime ? (
            !openEdit ? (
              <>
                <Typography variant="body2" component="p">
                  <FormattedMessage id="times.startTime" />:{" "}
                  {intlFormatDateTimeNumeric(intl, extra.startTime)}
                </Typography>
                <Typography variant="body2" component="p">
                  <FormattedMessage id="visit.endTime" />:{" "}
                  {extra.stopTime && intlFormatDateTimeNumeric(intl, extra.stopTime)}
                </Typography>
              </>
            ) : (
              <Grid container spacing={1} mt={1}>
                <Grid item xs={6}>
                  <AsolviDatePicker
                    error={errors?.startDate}
                    name={`startDate`}
                    label={intl.formatMessage({ id: "times.startDate" })}
                    required
                    value={toDate(extra.startTime)}
                    onChange={(value: Date) => {
                      const changedExtra = {
                        ...extra,
                        startTime: toDateString(value),
                      };
                      editExtra(changedExtra);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AsolviTimePicker
                    error={errors?.startTime}
                    name={`startTime`}
                    label={intl.formatMessage({ id: "times.startTime" })}
                    required
                    value={toDate(extra.startTime)}
                    onChange={(value: Date) => {
                      const changedExtra = {
                        ...extra,
                        startTime: toDateString(value),
                      };
                      editExtra(changedExtra);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AsolviDatePicker
                    error={errors?.stopDate}
                    name={`stopDate`}
                    label={intl.formatMessage({ id: "visit.endDate" })}
                    required
                    value={toDate(extra.stopTime)}
                    onChange={(value: Date) => {
                      const changedExtra = {
                        ...extra,
                        stopTime: toDateString(value),
                      };
                      editExtra(changedExtra);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <AsolviTimePicker
                    error={errors?.stopTime}
                    name={`stopTime`}
                    label={intl.formatMessage({ id: "visit.endTime" })}
                    required
                    value={toDate(extra.stopTime)}
                    onChange={(value: Date) => {
                      const changedExtra = {
                        ...extra,
                        stopTime: toDateString(value),
                      };
                      editExtra(changedExtra);
                    }}
                  />
                </Grid>
              </Grid>
            )
          ) : !openEdit ? (
            <>
              <Typography variant="body2" component="p">
                <FormattedMessage id="times.startTime" />:{" "}
                {intlFormatDateNumeric(intl, extra.startTime)}
              </Typography>
              <Typography variant="body2" component="p">
                <FormattedMessage id="general.quantity" />: {extra.quantity}
              </Typography>
            </>
          ) : (
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <AsolviDatePicker
                  error={errors?.startDate}
                  name={`startDate`}
                  label={intl.formatMessage({ id: "times.startDate" })}
                  required
                  value={toDate(extra.startTime)}
                  onChange={(value: Date) => {
                    const changedExtra = {
                      ...extra,
                      startTime: toDateString(value),
                    };
                    editExtra(changedExtra);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  error={!!errors?.Quantity}
                  inputProps={{ inputMode: "numeric", pattern: "d*" }}
                  id="total-miles"
                  name="quantity"
                  label={intl.formatMessage({ id: "general.quantity" })}
                  value={extra.quantity}
                  style={{ marginTop: "8px" }}
                  onChange={(e: any) => {
                    const { value } = e.target;

                    if (/^\d+$/.test(value) || value === "") {
                      const changedExtra = {
                        ...extra,
                        quantity: Number(value),
                      };
                      editExtra(changedExtra);
                    }
                  }}
                />
                <FormHelperText error>{errors?.Quantity}</FormHelperText>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <StyledCardActions>
          <IconButton
            aria-label={intl.formatMessage({ id: "general.delete" })}
            onClick={() => setConfirmationDialog(true)}
            size="large"
          >
            <DeleteIcon color="error" />
          </IconButton>
          {openEdit ? (
            <IconButton
              aria-label={intl.formatMessage({ id: "general.save" })}
              onClick={editJobPart}
              size="large"
            >
              <CheckIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton
              aria-label={intl.formatMessage({ id: "general.edit" })}
              onClick={editJobPart}
              size="large"
            >
              <EditIcon color="action" />
            </IconButton>
          )}
        </StyledCardActions>
      </StyledCard>
      <PromptDialog
        aria-labelledby="form-dialog-title"
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        okText={intl.formatMessage({ id: "general.confirm" })}
        onOk={removeExtraFromVisit}
        title={<FormattedMessage id="visit.extra.remove" />}
        promptContent={<FormattedMessage id="visit.extra.removePrompt" />}
      />
    </StyledGrid>
  );
};
