import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { AssignTaskMutationVariables } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./planner.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  assignTask: createAppAsyncThunk(
    "planner/assignTask",
    async (props: AssignTaskMutationVariables, { rejectWithValue, extra: { sdk } }) => {
      const { ...variables } = props;
      const { data, errors, queued } = await graphqlRequest(sdk.assignTask, {
        thunkName: "planner/assignTask", // TODO: Not necessary? Maybe not allowed in offline?
        thunkProps: props,
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.assignTask) return rejectWithValue("something went wrong");
      return { data, queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.assignTask.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.loadingAssignTask = true;
    return state;
  });
  builder.addCase(asyncMutations.assignTask.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.loadingAssignTask = false;
    return state;
  });
  builder.addCase(asyncMutations.assignTask.fulfilled, (state) => {
    state.loadingAssignTask = false;
    return state;
  });
};
