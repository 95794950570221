import capitalize from "@mui/material/utils/capitalize";
import {
  addDays,
  addMonths,
  endOfDay,
  format,
  isValid,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { IntlShape } from "react-intl";

export type DefinedDate = Date | string | number;
export type MaybeDate = Date | string | number | undefined | null;

function toDate(isoDate: DefinedDate): Date;
function toDate(isoDate: MaybeDate): Date | null;
function toDate(isoDate: DefinedDate | MaybeDate) {
  if (isoDate && isValid(new Date(isoDate))) return new Date(isoDate);
  else return null;
}
export { toDate };

function toDateString(isoDate: DefinedDate): string;
function toDateString(isoDate: MaybeDate): string | null;
function toDateString(isoDate: DefinedDate | MaybeDate) {
  let date = toDate(isoDate);
  if (date) return date.toISOString();
  else return null;
}
export { toDateString };

export const formatDate = (isoDate: MaybeDate) => {
  let date = toDate(isoDate);
  if (date) return format(date, "yyyy-MM-dd");
  else return null;
};

export const formatTime = (isoDate: MaybeDate) => {
  let date = toDate(isoDate);
  if (date) return format(date, "HH:mm");
  else return null;
};

export const formatDateTime = (isoDate: MaybeDate) => {
  let date = toDate(isoDate);
  if (date) return format(date, "yyyy/MM/dd HH:mm:ss");
  else return null;
};

export const formatServiceDate = (date: string | undefined | null) => {
  if (!!date) {
    const dateString = date.split("-").reverse().join("-"); // dd-mm-yyyy to yyyy-mm-dd
    return formatDate(dateString);
  } else {
    return null;
  }
};

export const mergeDateTime = (date: DefinedDate, time: DefinedDate) => {
  const dateCopy = toDate(date);
  const timeCopy = toDate(time);
  if (dateCopy && timeCopy) {
    const hour = timeCopy.getHours();
    const minutes = timeCopy.getMinutes();
    dateCopy.setHours(hour, minutes, 0, 0);
    return toDate(dateCopy);
  }
  return toDate(date);
};
export const mergeDateTimeString = (date: DefinedDate, time: DefinedDate) => {
  return toDateString(mergeDateTime(date, time));
};

export type Period =
  | "week"
  | "1month"
  | "3month"
  | "12month"
  | "18month"
  | "last5"
  | "5year"
  | "specificDate"
  | null;
export const addPeriod = (type: Period): Date | null => {
  var toDate: Date | null = null;
  var date = endOfDay(Date.now());
  switch (type) {
    case "week":
      toDate = addDays(date, 7);
      break;
    case "1month":
      toDate = addMonths(date, 1);
      break;
    case "3month":
      toDate = addMonths(date, 3);
      break;
    case "12month":
      toDate = addMonths(date, 12);
      break;
    case "18month":
      toDate = addMonths(date, 18);
      break;
  }
  return toDate;
};
export const subPeriod = (type: Period): Date | null => {
  var fromDate: Date | null = null;
  var date = endOfDay(Date.now());
  switch (type) {
    case "week":
      fromDate = subDays(date, 7);
      break;
    case "1month":
      fromDate = subMonths(date, 1);
      break;
    case "3month":
      fromDate = subMonths(date, 3);
      break;
    case "12month":
      fromDate = subMonths(date, 12);
      break;
    case "18month":
      fromDate = subMonths(date, 18);
      break;
    case "5year":
      fromDate = subYears(date, 5);
      break;
    case "last5":
      fromDate = null;
      break;
  }
  return fromDate;
};

export const intlFormatDate = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return capitalize(
    intl.formatDate(dateCopy!, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );
};

export const intlFormatDateNumeric = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return capitalize(
    intl.formatDate(dateCopy!, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );
};

export const intlFormatDateTimeNumeric = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return capitalize(
    intl.formatDate(dateCopy!, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })
  );
};

export const intlFormatDateWithWeekday = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return capitalize(
    intl.formatDate(dateCopy!, {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    })
  );
};

export const intlFormatDateTimeWithWeekday = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return capitalize(
    intl.formatDate(dateCopy!, {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })
  );
};

export const intlFormatTime = (intl: IntlShape, date: DefinedDate) => {
  const dateCopy = toDate(date);
  return intl.formatTime(dateCopy!, {
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
};

export const formatTimeDiff = (intl: IntlShape, totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes - hours * 60;
  return (
    (hours === 1
      ? hours + " " + intl.formatMessage({ id: "date.hour" }) + " "
      : hours > 1
      ? hours + " " + intl.formatMessage({ id: "date.hours" }) + " "
      : "") +
    (minutes === 1
      ? minutes + " " + intl.formatMessage({ id: "date.minute" })
      : minutes + " " + intl.formatMessage({ id: "date.minutes" }))
  );
};

// @ts-ignore
export const reduceError = (obj: any, pathKeys: Array<string | number>) => {
  if (pathKeys.length > 1) {
    if (obj[pathKeys[0]]) {
      return reduceError(obj[pathKeys[0]], pathKeys.slice(1));
    } else {
      return null;
    }
  }
  if (pathKeys.length === 1) {
    return obj[pathKeys[0]];
  } else {
    return obj;
  }
};
