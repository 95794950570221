import {
  Close as CloseIcon,
  NotificationImportant as NotificationImportantIcon,
} from "@mui/icons-material";
import { Alert, Link } from "@mui/material";
import { isEmpty } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { clearNewJobIds, selectNewJobIds } from "store/slices/jobs.store";

export const NewJobsAlert: FC = () => {
  const dispatch = useAppDispatch();
  const newJobIds = useAppSelector(selectNewJobIds);
  const intl = useIntl();
  const navigate = useNavigate();

  const onDimiss = () => {
    dispatch(clearNewJobIds());
  };

  return (
    <>
      {!isEmpty(newJobIds) && (
        <Link
          component="button"
          onClick={() => {
            navigate("/jobs/new");
          }}
          sx={{
            width: 1,
          }}
          data-testid="JobDetails-RelatedJobs"
        >
          <Alert
            sx={{
              width: 1,
              borderRadius: 0,
            }}
            variant="filled"
            severity="info"
            icon={<NotificationImportantIcon fontSize="inherit" />}
            action={
              <div
                style={{ margin: "auto", display: "flex", alignItems: "center" }}
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  onDimiss();
                }}
              >
                {intl.formatMessage({ id: "newJobs.alert.dismiss" })}
                <CloseIcon />
              </div>
            }
          >
            {intl.formatMessage(
              { id: "newJobs.countAvailable" },
              { newJobIdsCount: newJobIds.length }
            )}
          </Alert>
        </Link>
      )}
    </>
  );
};
