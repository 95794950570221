import ListSubheader from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: "inherit",
  color: theme.palette.primary.dark,
  fontWeight: 700,
  top: 56,
  "@media (min-width:0px) and (orientation: landscape)": {
    top: 48,
  },
  "@media (min-width:600px)": {
    top: 64,
  },
}));

export default StyledListSubheader;
