import { SharedProps } from "notistack";
import { IntlShape } from "react-intl";

export interface SnackbarMessage {
  key?: SnackbarMessageKey;
  text: string;
  variant: SharedProps["variant"];
}

export const enqueueGeneralErrorMessage = (intl: IntlShape): SnackbarMessage => {
  return {
    text: intl.formatMessage({
      id: "snackbar.generalError",
    }),
    variant: "error",
  };
};

export const SnackbarMessages = {
  GeneralError: enqueueGeneralErrorMessage,
  "Feedback-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.feedback.success",
    }),
    variant: "success",
  }),
  "Feedback-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.feedback.fail",
    }),
    variant: "error",
  }),
  "StartTravel-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.startTravel.fail",
    }),
    variant: "error",
  }),
  "StartTravel-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.startTravel.success",
    }),
    variant: "success",
  }),
  "UpdateTravel-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTravel.fail",
    }),
    variant: "error",
  }),
  "UpdateTravel-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTravel.success",
    }),
    variant: "success",
  }),
  "UpdateTravel-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTravel.stored",
    }),
    variant: "info",
  }),
  "StartVisitWork-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.startVisitWork.success",
    }),
    variant: "info",
  }),
  "StartVisitWork-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.startVisitWork.stored",
    }),
    variant: "info",
  }),
  "StartVisitWork-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.startVisitWork.fail",
    }),
    variant: "error",
  }),
  "UpdateEquipment-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipment.success",
    }),
    variant: "success",
  }),
  "UpdateEquipment-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipment.stored",
    }),
    variant: "info",
  }),
  "UpdateEquipment-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipment.fail",
    }),
    variant: "error",
  }),
  "UpdatePlannedDate-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updatePlannedDate.success",
    }),
    variant: "success",
  }),
  "UpdatePlannedDate-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updatePlannedDate.stored",
    }),
    variant: "info",
  }),
  "UpdatePlannedDate-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updatePlannedDate.fail",
    }),
    variant: "error",
  }),
  "AddPart-success": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.addPart.success",
      }) +
      ":\n" +
      argument,
    variant: "success",
  }),
  "RequestPart-success": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.requestPart.success",
      }) +
      ":\n" +
      argument,
    variant: "success",
  }),
  "CompleteVisitWithParts-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.completeVisitWithParts.fail",
    }),
    variant: "error",
  }),
  "CompleteVisitWithParts-fail-meters": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.completeVisitWithParts.fail.meters",
    }),
    variant: "error",
  }),
  "CompleteVisitWithParts-returned": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.completeVisitWithParts.returned",
      }) + argument,
    variant: "success",
  }),
  "CompleteVisitWithParts-success": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.completeVisitWithParts.success",
      }) + argument,
    variant: "success",
  }),
  "GetJob-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.getJob.fail",
    }),
    variant: "error",
  }),
  "GetJobs-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.getJobs.fail",
    }),
    variant: "error",
  }),
  "GetJobVisits-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.getJobVisits.fail",
    }),
    variant: "error",
  }),
  "No-barcode-match": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.noBarcodeMatch",
      }) + argument,
    variant: "info",
  }),
  "No-end-travel": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.travelNotEnded",
    }),
    variant: "error",
  }),
  "Meters-Updated": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.meters.updated",
    }),
    variant: "info",
  }),
  "UpdateTransitParts-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTransitParts.fail",
    }),
    variant: "error",
  }),
  "UpdateTransitParts-forbidden": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTransitParts.forbidden",
    }),
    variant: "error",
  }),
  "UpdateTransitParts-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTransitParts.success",
    }),
    variant: "success",
  }),
  "UpdateTransitParts-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateTransitParts.stored",
    }),
    variant: "info",
  }),
  "CreateJob-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.createJob.fail",
    }),
    variant: "error",
  }),
  "CreateJob-success": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text: intl.formatMessage(
      {
        id: "snackbar.createJob.success",
      },
      {
        jobId: argument,
      }
    ),
    variant: "success",
  }),
  "CreateJob-stored": (intl: IntlShape, argument?: string): SnackbarMessage => ({
    text:
      intl.formatMessage({
        id: "snackbar.createJob.stored",
      }) +
      " " +
      argument,
    variant: "info",
  }),
  "AddWorkNote-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addWorkNote.fail",
    }),
    variant: "error",
  }),
  "AddWorkNote-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addWorkNote.success",
    }),
    variant: "success",
  }),
  "AddWorkNote-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addWorkNote.stored",
    }),
    variant: "info",
  }),
  "Login-failed": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.login.fail",
    }),
    variant: "error",
  }),
  "ReissueLogin-failed": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.reissueLogin.fail",
    }),
    variant: "error",
  }),
  "AssignTask-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.assignTask.fail",
    }),
    variant: "error",
  }),
  "AssignTask-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.assignTask.success",
    }),
    variant: "success",
  }),
  "AssignTask-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.assignTask.stored",
    }),
    variant: "info",
  }),
  "AssignTask-forbidden": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.assignTask.forbidden",
    }),
    variant: "error",
  }),
  "AddUserConsent-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addUserConsent.fail",
    }),
    variant: "error",
  }),
  "ClearCache-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.clearCache.success",
    }),
    variant: "success",
  }),
  "ClearLocalStorage-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.clearLocalStorage.success",
    }),
    variant: "success",
  }),
  "RejectJob-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.rejectJob.fail",
    }),
    variant: "error",
  }),
  "RejectJob-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.rejectJob.success",
    }),
    variant: "success",
  }),
  "RejectJob-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.rejectJob.stored",
    }),
    variant: "info",
  }),
  "UpdateVisitInformation-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateVisitInformation.fail",
    }),
    variant: "error",
  }),
  "AddUserConsent-tracking-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addUserConsent.tracking.success",
    }),
    variant: "success",
  }),
  "AddUserConsent-tracking-failure": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addUserConsent.tracking.fail",
    }),
    variant: "info",
  }),
  "AddUserConsent-tracking-failure-1": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addUserConsent.tracking.denied",
    }),
    variant: "error",
  }),
  "UpdateEquipmentCustomProps-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipmentCustomProps.fail",
    }),
    variant: "error",
  }),
  "UpdateEquipmentCustomProps-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipmentCustomProps.success",
    }),
    variant: "success",
  }),
  "UpdateEquipmentCustomProps-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.updateEquipmentCustomProps.stored",
    }),
    variant: "info",
  }),
  "TokenRefresh-failed": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.tokenRefresh.fail",
    }),
    variant: "error",
  }),
  "TokenRefresh-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.tokenRefresh.success",
    }),
    variant: "success",
  }),
  "ChangeJobEquipment-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.changeJobEquipment.success",
    }),
    variant: "success",
  }),
  "ChangeJobEquipment-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.changeJobEquipment.fail",
    }),
    variant: "error",
  }),
  "ChangeJobEquipment-forbidden": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.changeJobEquipment.forbidden",
    }),
    variant: "error",
  }),
  "EditContact-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.editContact.success",
    }),
    variant: "success",
  }),
  "EditContact-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.editContact.stored",
    }),
    variant: "info",
  }),
  "EditContact-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.editContact.fail",
    }),
    variant: "error",
  }),
  "EditContact-forbidden": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.editContact.forbidden",
    }),
    variant: "error",
  }),
  "AddChecklist-success": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addChecklist.success",
    }),
    variant: "success",
  }),
  "AddChecklist-stored": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addChecklist.stored",
    }),
    variant: "info",
  }),
  "AddChecklist-fail": (intl: IntlShape): SnackbarMessage => ({
    text: intl.formatMessage({
      id: "snackbar.addChecklist.fail",
    }),
    variant: "error",
  }),
};

export type SnackbarMessageKey = Extract<keyof typeof SnackbarMessages, string>;
