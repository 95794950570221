import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, Grid, IconButton, Rating, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import PrimaryButton from "components/PrimaryButton";
import { StyledContainer } from "components/StyledContainer";
import StyledTextField from "components/StyledTextField";
import Transition from "components/Transition";
import { gitSha, version } from "env";

import { unwrapResult } from "@reduxjs/toolkit";
import { isAbortError } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectOpen,
  sendFeedback,
  setOpen,
  setStars,
  setText,
} from "store/slices/dialogs/feedback.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

const PREFIX = "FeedbackDialog";

const classes = {
  menuButton: `${PREFIX}-menuButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
}));

interface FeedbackProps {}

export const FeedbackDialog: FC<FeedbackProps> = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const open = useAppSelector(selectOpen);
  const { text, stars } = useAppSelector((s) => s.feedback);

  const close = () => {
    dispatch(setOpen({ open: false }));
  };

  const feedback = useCallback(() => {
    dispatch(
      sendFeedback({
        feedback: text,
        rate: stars,
        version: `${version()}-${gitSha()}`,
      })
    )
      .then(unwrapResult)
      .then(({ queued }) => {
        if (!queued) {
          dispatch(addSnackbarMessage({ key: "Feedback-success" }));
        }
      })
      .catch((e) => {
        if (isAbortError(e)) return;
        dispatch(addSnackbarMessage({ key: "Feedback-fail" }));
      });
  }, [dispatch, stars, text]);

  return (
    <StyledDialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            aria-label={intl.formatMessage({ id: "general.delete" })}
            className={classes.menuButton}
            onClick={close}
            size="large"
            data-testid="closeButton"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <Typography flexGrow={1}>
            <FormattedMessage id="menu.sendFeedback" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />

      <StyledContainer>
        <Grid container direction="column">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              feedback();
            }}
          >
            <Grid item>
              <Typography>
                <FormattedMessage id="feedback.first" />. <FormattedMessage id="feedback.second" />.{" "}
                <FormattedMessage id="feedback.third" />
                . <FormattedMessage id="feedback.thankYou" />.
              </Typography>
            </Grid>

            <Grid item>
              <StyledTextField
                id="feedback-freetext"
                label={intl.formatMessage({ id: "feedback" })}
                multiline
                rows={4}
                margin="normal"
                value={text}
                onChange={(event) => {
                  dispatch(setText({ text: event.target.value }));
                }}
                data-testid="feedbackInput"
              />
            </Grid>
            <Grid item>
              <FormattedMessage id="feedback.overall" />
              :
              <br />
              <Rating
                name="size-large"
                value={stars}
                size="large"
                onChange={(_event, val) => dispatch(setStars({ stars: val }))}
                data-testid="rating"
              />
            </Grid>
            <Grid item>
              <PrimaryButton
                key="sendFeedbackButton"
                type="submit"
                variant="contained"
                fullWidth
                disabled={!!text && !stars}
                data-testid="sendFeedbackButton"
              >
                <FormattedMessage id="menu.sendFeedback" />
              </PrimaryButton>
            </Grid>
          </form>
        </Grid>
      </StyledContainer>
    </StyledDialog>
  );
};
