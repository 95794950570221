import { useCallback, useEffect } from "react";

import { ServiceJob } from "operations/schema/schema";

import { JobDetailsSkeleton } from "components/job/JobDetailsSkeleton";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { setPage, setSelectedJob } from "store/root.store";
import {
  getFiles,
  getNotes,
  getPlannerJob,
  getRelatedJobs,
  getVisits,
  selectJobLoaded,
  setPlannerSelectedJob,
} from "store/slices/planner.store";
import { addSnackbarMessage } from "store/slices/snackbar.store";

interface JobProviderProps {
  jobId: string | undefined;
  onCompleted?: (job: ServiceJob) => void;
  onError?: (e: Error) => void;
}

export default function PlannerJobProvider({ jobId, onError, onCompleted }: JobProviderProps) {
  const dispatch = useAppDispatch();
  const jobLoaded = useAppSelector(selectJobLoaded);

  const initializeJob = useCallback(async () => {
    await dispatch(getPlannerJob(onCompleted)).catch(() => {
      dispatch(addSnackbarMessage({ key: "GetJob-fail" }));
    });
    dispatch(getFiles());
    dispatch(getNotes());
    dispatch(getRelatedJobs());
    dispatch(getVisits()).catch(() => {
      dispatch(addSnackbarMessage({ key: "GetJobVisits-fail" }));
    });
  }, [dispatch, onCompleted]);

  useEffect(() => {
    if (!jobId) return;
    dispatch(setPage({ page: "planner" }));
    dispatch(setPlannerSelectedJob({ jobId }));
    dispatch(setSelectedJob({ jobId }));
    initializeJob();
  }, [dispatch, initializeJob, jobId]);

  if (!jobLoaded) {
    return <JobDetailsSkeleton />;
  }

  return <Outlet />;
}
