import { AnyAction } from "@reduxjs/toolkit";
import { GraphQLError } from "graphql";
import { RemoveIndex } from "graphql-request/build/esm/helpers";
import { nanoid } from "nanoid";
import { getSdk } from "./client";
import { getMetadata } from "./metadata";

/**
 * @type {Sdk} Reveals all methods available when using 'getSdk'
 */
type Sdk = ReturnType<typeof getSdk>;

/**
 * @type {GraphqlRequestOptions<T, V>} Definition for option object
 * @template T - method in getSdk()/Sdk. Here used for fetching definition for requestHeaders
 * @template V - variables (if any) used in `T`
 * @prop {object} [requestHeaders] - (Optional) RequestHeaders used in the actual http-request
 * @prop {string} thunkName - (Optional) Name of thunk. Used if it should be queued in case of broken connection
 * @prop {any} thunkProps - (Optional) Required if `thunkName` defined. Properties used in original thunk. Used to handle method properly if queued
 * @prop {AnyAction} [onError] - (Optional) Array of actions that should be triggered if queued operation fails
 * @prop {AnyAction} [onCompleted] - (Optional) Array of actions that should be triggered if queued operation succeedes
 * @prop {object} variables - Variables used in query/mutation. Optional or required depending on request being used
 */
type GraphqlRequestOptions<T extends Sdk[keyof Sdk], V = Parameters<T>[0]> = {
  requestHeaders?: Parameters<T>[1];
  thunkName?: string;
  thunkProps?: any;
  onError?: AnyAction[];
  onCompleted?: AnyAction[];
} & (V extends Record<any, never>
  ? { variables?: V }
  : keyof RemoveIndex<V> extends never
  ? { variables?: V }
  : { variables: V });

/**
 * @type {GraphqlRequestArgs<T, V>} Definition for arguments used in `graphqlRequest` method
 * @template T - method in getSdk()/Sdk
 * @template V - variables (if any) used in `T`
 * @prop {T} action - Request that is being used
 * @prop {GraphqlRequestOptions<T, V>} - Options object. Optional or required depending on `action` being used
 */
type GraphqlRequestArgs<T extends Sdk[keyof Sdk], V = Parameters<T>[0]> = V extends Record<
  any,
  never
>
  ? [action: T, options?: GraphqlRequestOptions<T, V>]
  : keyof RemoveIndex<V> extends never
  ? [action: T, options?: GraphqlRequestOptions<T, V>]
  : [action: T, options: GraphqlRequestOptions<T, V>];

/**
 * @type {GraphqlRequestResponse} Definition of response returned from `graphqlRequest`
 * @template D - type of data returned by request
 * @prop {D} [data] - data returned by request. Undefined if errors
 * @prop {GraphQLError[]} errors - array of graphql errors
 * @prop {boolean} queued - flag set if operation has been queued
 * @throws {Error} - Generic error if something went wrong not related to graphql specifically
 */
type GraphqlRequestResponse<D> =
  | {
      data: Awaited<D>;
      errors?: undefined;
      queued?: boolean;
    }
  | {
      data?: undefined;
      errors: GraphQLError[];
      queued?: boolean;
    }
  | {
      data?: undefined;
      errors?: GraphQLError[];
      queued: boolean;
    };

/**
 * Wrapper function used for handling queueing
 * @param {GraphqlRequestArgs<T>} args - Consists of action and options
 * @param {T} args.action  - Request that is being used
 * @param {GraphqlRequestOptions<T>} args.options - Request options. Optional if request variables is optional
 * @returns {GraphqlRequestResponse<ReturnType<T>>} - Result of request or array of errors
 */
export const graphqlRequest = async <T extends Sdk[keyof Sdk]>(
  ...args: GraphqlRequestArgs<T>
): Promise<GraphqlRequestResponse<ReturnType<T>>> => {
  let [action, options] = args;
  let { variables, requestHeaders, onError, onCompleted, thunkName, thunkProps } = options ?? {};
  let queueId = nanoid();
  requestHeaders = {
    ...requestHeaders,
    "x-queueId": queueId,
  };
  requestHeaders = {
    ...requestHeaders,
    "x-onError": onError || [],
  };
  requestHeaders = {
    ...requestHeaders,
    "x-onCompleted": onCompleted || [],
  };
  if (thunkName) {
    requestHeaders = {
      ...requestHeaders,
      "x-thunkName": thunkName,
    };
  }
  if (thunkProps) {
    requestHeaders = {
      ...requestHeaders,
      "x-thunkProps": thunkProps,
    };
  }
  variables = {
    ...variables,
    metadata: await getMetadata(),
  };
  try {
    const data = (await action(variables as any, requestHeaders)) as any;
    return { data };
  } catch (e: any) {
    if (e instanceof Error && e.name === "AbortError" && e.message === "Queued") {
      return { queued: true };
    }
    if (e && !(e instanceof Error) && e.errors) {
      return { errors: e.errors };
    } else if (e && e.response && e.response.errors) {
      return { errors: e.response.errors };
    } else {
      throw e;
    }
  }
};
