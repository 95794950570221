import { Container, Grid, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";

import { useAppDispatch, useAppSelector } from "store";
import { updateUserConsent as dUpdateUserConsent } from "store/slices/user.store";

import { logoutUser } from "context/login/logout";
import { ConsentObject, getTrackingConsent, isAbortError } from "helpers";
import { UserConsentType, UserConsentTypeEnum } from "operations/schema/schema";

import { unwrapResult } from "@reduxjs/toolkit";
import { AsolviSwitch } from "components/AsolviSwitch";
import FullScreenDialog from "components/FullScreenDialog";
import PrimaryButton from "components/PrimaryButton";
import { addSnackbarMessage } from "store/slices/snackbar.store";

interface UserConsentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const TrackingUserConsentDialog: FC<UserConsentProps> = (props) => {
  const { open, setOpen } = props;
  const intl = useIntl();

  const dispatch = useAppDispatch();
  const { languageVar } = useAppSelector((state) => state.user);

  const [consentAccepted, setConsentAccepted] = useState(false);
  const [consentObject, setConsentObject] = useState<ConsentObject>();

  useEffect(() => {
    getTrackingConsent(languageVar).then((c) => setConsentObject(c));
  }, [languageVar]);

  const addUserConsent = useCallback(
    async (accepted: boolean = false) => {
      let consent: UserConsentType = {
        accepted: accepted,
        version: consentObject!.version,
        type: UserConsentTypeEnum.Tracking,
      };
      dispatch(dUpdateUserConsent({ consent }))
        .then(unwrapResult)
        .then(() => {
          setOpen(false);
        })
        .catch((e) => {
          if (isAbortError(e)) return;
          dispatch(addSnackbarMessage({ key: "AddUserConsent-fail" }));
        });
    },
    [consentObject, dispatch, setOpen]
  );

  if (!consentObject) {
    return null;
  }
  return (
    <FullScreenDialog
      open={open}
      setOpen={setOpen}
      title={intl.formatMessage({ id: "trackingConsent.dialogTitle" })}
      onClose={() => {
        addUserConsent();
        if (consentObject.required) {
          logoutUser();
        }
      }}
      child={
        <Container>
          <Grid container alignItems="center" justifyContent="center">
            <Typography
              component="div"
              whiteSpace="pre-line"
              sx={{ paddingBottom: "20px", paddingTop: "20px" }}
            >
              <ReactMarkdown className="markdown-text" children={consentObject.text} />
            </Typography>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="body2">
                <FormattedMessage id="trackingConsent.toggleText" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="trackingConsent"
                checked={consentAccepted}
                onChange={() => {
                  setConsentAccepted(!consentAccepted);
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center" sx={{ paddingTop: "20px" }}>
            <Grid item alignItems="center" xs={12}>
              <PrimaryButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={!consentAccepted && consentObject.required}
                onClick={() => {
                  addUserConsent(consentAccepted);
                }}
              >
                <FormattedMessage id="trackingConsent.saveAndContinue" />
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      }
    />
  );
};
