import { Tab, Tabs, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

import { TabPanel } from "components/TabPanel";
import { TravelTab } from "./TravelTab";
import { WorkTab } from "./WorkTab";

const StyledToolbarPlaceholder = styled(Toolbar)(() => ({
  padding: 0,
  zIndex: -1,
}));

export const TimesTab: FC = () => {
  const intl = useIntl();
  const [tabValue, setTabValue] = useState("work");

  return (
    <>
      <Tabs
        scrollButtons
        value={tabValue}
        onChange={(_, newValue: string) => {
          setTabValue(newValue);
        }}
        variant="fullWidth"
        textColor="inherit"
        style={{
          position: "fixed",
          background: "white",
          width: "100%",
          zIndex: 10,
        }}
      >
        <Tab label={intl.formatMessage({ id: "visit.travel" })} value="travel" />
        <Tab label={intl.formatMessage({ id: "visit.work" })} value="work" />
      </Tabs>
      <StyledToolbarPlaceholder />
      <TabPanel index="travel" value={tabValue}>
        <TravelTab />
      </TabPanel>
      <TabPanel index="work" value={tabValue}>
        <WorkTab />
      </TabPanel>
    </>
  );
};
