import {
  ChevronRight as ChevronRightIcon,
  SlowMotionVideo as SlowMotionVideoIcon,
} from "@mui/icons-material";
import { Grid, ListItem, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { EventDateIcon } from "components/EventDateIcon";
import { StyledChips } from "components/StyledChips";
import { calculateUrgencyColor, intlFormatDateWithWeekday, isEmpty, isProductEvatic } from "helpers";
import { JobStatus, ServiceJob } from "operations/schema/schema";
import { JobStyles } from "styles/JobStyles";

import { JobSkeleton } from "../JobSkeleton";
import { JobVisitDetails } from "../JobVisitDetails";

const classes = JobStyles.createClasses();
const StyledBox = JobStyles.defineStyles(classes);

interface JobProps {
  job: ServiceJob;
  loading?: boolean;
  showBorder?: boolean;
  toLink?: string;
}

export const Visit: FC<JobProps> = (props: JobProps) => {
  const { job, loading, showBorder, toLink } = props;
  const intl = useIntl();
  const dateText = job.plannedDate?.startTime || job.responseDate;
  const borderColor = calculateUrgencyColor(dateText);

  const jobInProgress =
    job.status === JobStatus.InProgress ||
    (!isEmpty(job.workTimes) && job.workTimes[0].startTime !== null) ||
    (!isEmpty(job.travelTimes) && job.travelTimes[0].startTime != null);

  const jobDate = job.workTimes[0]?.stopTime || job.plannedDate?.startTime || job.responseDate || undefined;

  return (
    <StyledBox borderLeft={showBorder ? 6 : 0} borderColor={borderColor}>
      <ListItem
        button
        divider
        component={loading ? "div" : Link}
        to={`${toLink}/${job.id}`}
        key={job.id}
        className={loading ? "" : "e2e-job"}
      >
        <Grid container alignItems="center" wrap="nowrap">
          {loading ? (
            <JobSkeleton />
          ) : (
            <>
              <Grid item xs={11} width="100%">
                <Grid container>
                  <Grid item container justifyContent="space-between">
                    <Grid item className={classes.jobTime}>
                      <Grid item container direction="row" alignItems="center">
                        {jobInProgress && showBorder && (
                          <Grid item>
                            <SlowMotionVideoIcon
                              fontSize="small"
                              color="primary"
                              className={classes.indicatorIcon}
                            />
                          </Grid>
                        )}
                        {showBorder && <EventDateIcon plannedDate={job.plannedDate?.startTime} />}
                        <Typography className={classes.date}>
                          {jobDate ? intlFormatDateWithWeekday(intl, jobDate) : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <JobVisitDetails
                  equipmentName={job.equipment?.name}
                  serialNumber={job.equipment?.serialNumber}
                  location={job.equipment?.location}
                  classes={classes}
                  includeCountry={isProductEvatic(job.product)}
                />
                <StyledChips
                  isOnStop={job.customer?.isOnStop}
                  priority={job.priority}
                  symptoms={job.symptoms}
                  category={job.category}
                />
              </Grid>
              <Grid item xs={1}>
                <ChevronRightIcon />
              </Grid>
            </>
          )}
        </Grid>
      </ListItem>
    </StyledBox>
  );
};
