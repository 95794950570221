import { AlertColor } from "@mui/material/Alert";
import theme from "theme";
import { MaybeDate, toDate } from "./date";
type Urgency = "imminent" | "soon" | "later" | undefined;

export const calculateUrgency = (dateString: MaybeDate): Urgency => {
  const hours = timeDifference(dateString);

  if (!hours) {
    return undefined;
  }
  if (hours <= 1) {
    return "imminent";
  }
  if (hours <= 4) {
    return "soon";
  }
  if (hours > 4) {
    return "later";
  }
};

export const calculateUrgencyLevel = (dateString: MaybeDate): AlertColor | undefined => {
  const urgency = calculateUrgency(dateString);

  switch (urgency) {
    case "imminent":
      return "error";
    case "soon":
      return "warning";
    case "later":
      return "info";
    default:
      return undefined;
  }
};

export const calculateUrgencyColor = (dateString: MaybeDate): string => {
  const urgency = calculateUrgency(dateString);

  switch (urgency) {
    case "imminent":
      return "error.main";
    case "soon":
      return "warning.main";
    case "later":
      return "info.main";
    default:
      return "grey.500";
  }
};

export const calculateUrgencyColorHex = (dateString: MaybeDate): string => {
  const urgency = calculateUrgency(dateString);

  switch (urgency) {
    case "imminent":
      return theme.palette.error.main;
    case "soon":
      return theme.palette.warning.main;
    case "later":
      return theme.palette.info.main;
    default:
      return theme.palette.grey["500"];
  }
};

const timeDifference = (date1?: MaybeDate, date2?: MaybeDate): number => {
  if (!date1) return 0;
  const dt1 = toDate(date1).getTime();
  const dt2 = (toDate(date2) || new Date()).getTime();
  return (dt1 - dt2) / (60 * 60 * 1000);
};
