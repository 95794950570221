import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { getChecklistStatus, isEmpty, toDateString } from "helpers";
import { cloneDeep } from "lodash";
import { Checklist } from "models/checklist";
import { EngineerSignoffValidationSchema } from "models/EngineerSignoffValidationSchema";
import { FollowUp } from "models/followUp";
import { Job } from "models/Job";
import { initialVisitFormValues } from "models/jobVisitForm";
import { MeterReadingInputType } from "operations/schema/schema";
import { Times } from "models/travelTimes";
import { TravelValidationSchema } from "models/TravelValidationSchema";
import { UsedPart } from "models/usedPart";
import { User } from "models/user";
import { VisitValidationSchema } from "models/VisitValidationSchema";
import {
  ChecklistGroupsType,
  ChecklistType,
  ExtraInputType,
  FileType,
  JobCategoryType,
  JobStatus,
  JobSymptomType,
  PartStatus,
  QuestionDataType,
  ServiceLevelType,
  StockStore,
  WorkNoteType,
  WorkNoteTypeEnum,
  WorkTaskType,
} from "operations/schema/schema";
import { RootState } from "store";
import { ValidationError } from "yup";
import { asyncMutations, mutationBuilder } from "./visit.mutations";
import { asyncQueries, queryBuilder } from "./visit.queries";

export type JobVisitType = {
  actionId1: string;
  actionId2?: string;
  actionId3?: string;
  autoEndTime: boolean;
  causeId: string;
  changeServiceTerms: boolean;
  checklists: Checklist[];
  customerSignerName: string;
  errors: any;
  signoffErrors: any;
  extras: ExtraInputType[];
  files: FileType[];
  followUp: FollowUp;
  hasCauses: boolean;
  hasCategories: boolean;
  jobCategory?: JobCategoryType | null;
  meterReadings?: MeterReadingInputType[];
  newWorkUnitId: number | undefined;
  serviceLevel: ServiceLevelType | null;
  serviceReportEmail: string | null;
  signatureCustomer: string | undefined;
  signatureEngineer: string | undefined;
  skipSignature: boolean;
  solutionDescription: string;
  travelEta: string | undefined;
  travelMileage: number;
  travelTimes: Times[];
  usedParts: UsedPart[];
  workTasks: WorkTaskType[];
  workTimes: Times[];
  submitLoading: boolean;
  metersNotSaved: boolean;
  metersUseCurrent: boolean;
};

// Interface for state
export interface State {
  jobVisits: {
    [x: string]: JobVisitType;
  };
  selectedJobId: string | undefined;
  machineProperties: {
    open: boolean;
    loading: boolean;
  };
  equipmentSpecifications: {
    open: boolean;
    loading: boolean;
  };
  contactsLoading: boolean;
  addChecklist: {
    open: boolean;
    openSelect: boolean;
    loading: boolean;
    useEquipment: boolean;
    addLoading: boolean;
    checklists: ChecklistGroupsType[];
  };
}

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  initializeVisit: (
    state: State,
    action: PayloadAction<{
      job: Job;
      jobCategories: JobCategoryType[];
      symptoms: JobSymptomType[];
      userVar: User;
      hasCauses: boolean;
    }>
  ) => State;
  setVisitCompleted: (state: State, action: Action) => State;
  setTravelTimes: (state: State, action: PayloadAction<{ times: Times[] }>) => State;
  setTravelEta: (state: State, action: PayloadAction<{ eta: string }>) => State;
  setTravelMileage: (state: State, action: PayloadAction<{ mileage: number }>) => State;
  setSelectedJob: (state: State, action: PayloadAction<{ jobId: string | undefined }>) => State;
  setAutoEndTime: (state: State, action: PayloadAction<{ autoEndTime: boolean }>) => State;
  // Form
  setVisitValue: <K extends Extract<keyof JobVisitType, string>>(
    state: State,
    action: PayloadAction<{ key: K; value: JobVisitType[K]; shouldValidate?: boolean }>
  ) => State;
  validateVisit: (state: State, action: Action) => State;
  // Checklists
  updateChecklist: (
    state: State,
    action: PayloadAction<{ checklist: ChecklistType; index: number }>
  ) => State;
  uploadedChecklists: (state: State, action: Action) => State;
  // Files
  addFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  removeFile: (state: State, action: PayloadAction<{ id: string }>) => State;
  updateFile: (state: State, action: PayloadAction<{ file: FileType }>) => State;
  uploadedFiles: (state: State, action: Action) => State;
  // MeterReadings
  updateMeterReadings: (
    state: State,
    action: PayloadAction<{ readings: MeterReadingInputType[] }>
  ) => State;
  setMetersNotSaved: (state: State, action: PayloadAction<{ unSavedChanges: boolean }>) => State;
  setMetersUseCurrent: (state: State, action: PayloadAction<{ useCurrent: boolean }>) => State;
  // Extras
  addExtra: (state: State, action: PayloadAction<{ extra: ExtraInputType }>) => State;
  removeExtra: (state: State, action: PayloadAction<{ index: number }>) => State;
  updateExtra: (state: State, action: PayloadAction<{ extra: ExtraInputType }>) => State;
  // Parts
  addPart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  addParts: (state: State, action: PayloadAction<{ parts: UsedPart[] }>) => State;
  removePart: (
    state: State,
    action: PayloadAction<{ id: string; stockStore: StockStore }>
  ) => State;
  updatePart: (state: State, action: PayloadAction<{ part: UsedPart }>) => State;
  uploadedAddedParts: (state: State, action: PayloadAction<{ addedParts: string[] }>) => State;
  uploadedRequestedParts: (state: State, action: Action) => State;
  // Signature
  setSignatureCustomer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  setSignatureEngineer: (
    state: State,
    action: PayloadAction<{ signatureData: string | undefined }>
  ) => State;
  // WorkTasks
  updateWorkTask: (state: State, action: PayloadAction<{ workTask: WorkTaskType }>) => State;
  addTravel: (state: State) => State;
  stopTravel: (state: State) => State;
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state: State, actions: PayloadAction<{ open: boolean }>) => State;
  //EquipmentSpecificationsDialog
  setEquipmentSpecificationsOpen: (
    state: State,
    actions: PayloadAction<{ open: boolean }>
  ) => State;
  handleCloseAddChecklist: (state: State, action: Action) => State;
  handleCloseAddChecklistSelect: (state: State, action: Action) => State;
  setAddChecklistOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setAddChecklistSelectOpen: (
    state: State,
    action: PayloadAction<{ open: boolean; useEquipment: boolean }>
  ) => State;
}

// Interface for store selectors (if necessary)
interface Selectors {
  selectVisitLoaded: (state: RootState) => boolean;
  selectTravelTimes: (state: RootState) => Times[];
  selectSelectedJob: (state: RootState) => Job;
  selectSelectedJobVisit: (state: RootState) => JobVisitType;
  selectWorkNotes: (state: RootState) => WorkNoteType[];
  selectVisitSelectedJobId: (state: RootState) => string | undefined;
  selectMachinePropertiesOpen: (state: RootState) => boolean;
  selectMachinePropertiesLoading: (state: RootState) => boolean;
  selectEquipmentSpecificationsOpen: (state: RootState) => boolean;
  selectEquipmentSpecificationsLoading: (state: RootState) => boolean;
  selectTravelTabInvalid: (state: RootState) => boolean;
  selectChecklistsIncomplete: (state: RootState) => boolean;
  selectVisitCanComplete: (state: RootState) => boolean;
  selectIsJobInProgress: (state: RootState) => boolean;
  selectVisitStarted: (state: RootState) => boolean;
  selectContactsLoading: (state: RootState) => boolean;
  selectAddChecklistOpen: (state: RootState) => boolean;
}

// Definition of actual (initial) state
export const initialState: State = {
  jobVisits: {},
  selectedJobId: undefined,
  machineProperties: {
    open: false,
    loading: false,
  },
  equipmentSpecifications: {
    open: false,
    loading: false,
  },
  contactsLoading: false,
  addChecklist: {
    open: false,
    openSelect: false,
    loading: false,
    useEquipment: false,
    addLoading: false,
    checklists: [],
  },
};

// Definition of actual actions
const actions: Actions = {
  initializeVisit: (state, { payload: { job, jobCategories, symptoms, hasCauses, userVar } }) => {
    let { selectedJobId, jobVisits } = state;
    if (selectedJobId && !jobVisits[selectedJobId]) {
      const travelTimes = job.travelTimes.filter((tt) => tt.startTime || tt.stopTime);

      state.jobVisits[selectedJobId] = {
        checklists: [
          ...job.checklists.map((cl) => ({
            checklist: cl,
            uploaded: false,
            hasPreStart: cl.questions.some((q) => q.isRequiredPreStart),
          })),
        ],
        files: [],
        extras: [],
        meterReadings: [
          ...job.meters.map(
            (mr) =>
              ({
                currentReading: undefined,
                currentReadingDate: undefined,
                typeId: mr.typeId,
              } as MeterReadingInputType)
          ),
        ],
        usedParts: [], // TODO; Initialize
        workTasks: [...job.tasks],
        travelTimes: (travelTimes || []).map((tt) => ({
          startDate: toDateString(tt.startTime!),
          startTime: toDateString(tt.startTime!),
          stopDate: toDateString(tt.stopTime) || undefined,
          stopTime: toDateString(tt.stopTime) || undefined,
        })),
        travelEta: toDateString(job.travelETA) || undefined,
        travelMileage: job.totalMileage || 0,
        followUp: (() => {
          let bool = !!job.preOrderedParts.length;
          return {
            engineerId: userVar ? parseInt(userVar?.externalId!) : 0,
            symptomDescription: job.symptomDescription || "",
            symptomCode1: symptoms.find((x) => job.symptoms[0] === x.description)?.code || "",
            symptomCode2: "",
            symptomCode3: "",
            followUpChecked: bool,
            followUpDisabled: bool,
            followUpPartsChecked: bool,
            followUpPartsDisabled: bool,
          };
        })(),
        signatureCustomer: undefined,
        signatureEngineer: undefined,
        newWorkUnitId: undefined,
        autoEndTime: true,
        ...initialVisitFormValues(job, jobCategories),
        errors: {},
        signoffErrors: {},
        submitLoading: false,
        metersNotSaved: job.requireMeterReading === true && job.meters.length > 0,
        metersUseCurrent: false,
        hasCategories: !!jobCategories.length,
        hasCauses: hasCauses,
      };
    }
    return state;
  },
  setVisitCompleted: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    // TODO; Merge data into jobs.store
    delete jobVisits[selectedJobId];
    return state;
  },
  setTravelTimes: (state, { payload: { times } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId) return state;
    let visit = jobVisits[selectedJobId];
    jobVisits[selectedJobId].travelTimes = [...times];
    const validationSchema = TravelValidationSchema();
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }
    return state;
  },
  setTravelEta: (state, { payload: { eta } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].travelEta = eta;
    return state;
  },
  setTravelMileage: (state, { payload: { mileage } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].travelMileage = mileage;
    return state;
  },
  setSelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setAutoEndTime: (state, { payload: { autoEndTime } }) => {
    if (!state.selectedJobId) return state;
    state.jobVisits[state.selectedJobId].autoEndTime = autoEndTime;
    return state;
  },

  // Form
  setVisitValue: (state, { payload: { key, value, shouldValidate = true } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    visit[key] = value;
    if (shouldValidate) {
      const validationSchema = VisitValidationSchema(visit.autoEndTime, visit.hasCauses);
      const isValid = validationSchema.isValidSync(visit);
      if (!isValid) {
        try {
          validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.errors = { ...errors };
        }
      } else {
        visit.errors = {};
      }

      const signoffValidation = EngineerSignoffValidationSchema(
        visit.hasCategories,
        visit.followUp.followUpChecked
      );
      const isSignoffValid = signoffValidation.isValidSync(visit);
      if (!isSignoffValid) {
        try {
          signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
        } catch (e: any) {
          let { inner } = e as ValidationError;
          let errors: any = {};
          for (let error of inner) {
            if (!error.path) continue;
            errors[error.path] = error.message;
          }
          visit.signoffErrors = { ...errors };
        }
      } else {
        visit.signoffErrors = {};
      }
    }
    return state;
  },
  validateVisit: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    // const visitForm = visit.form;
    const validationSchema = VisitValidationSchema(visit.autoEndTime, visit.hasCauses);
    const isValid = validationSchema.isValidSync(visit);
    if (!isValid) {
      try {
        validationSchema.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.errors = { ...errors };
      }
    } else {
      visit.errors = {};
    }

    const signoffValidation = EngineerSignoffValidationSchema(
      visit.hasCategories,
      visit.followUp.followUpChecked
    );
    const isSignoffValid = signoffValidation.isValidSync(visit);
    if (!isSignoffValid) {
      try {
        signoffValidation.validateSync({ ...visit }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        visit.signoffErrors = { ...errors };
      }
    } else {
      visit.signoffErrors = {};
    }
    // state.jobVisits[selectedJobId].form = visitForm;
    // yup.validate();
    return state;
  },
  // Checklists
  updateChecklist: (state, { payload: { checklist, index } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { checklists } = jobVisits[selectedJobId];
    checklist.questions = checklist.questions.map((q) => {
      if (
        q.dataType === QuestionDataType.DateTime ||
        q.dataType === QuestionDataType.Date ||
        q.dataType === QuestionDataType.TimeOfDay
      ) {
        q.answer = toDateString(q.answer);
      }
      return q;
    });
    checklists[index] = {
      uploaded: false,
      checklist,
      hasPreStart: checklist.questions.some((q) => q.isRequiredPreStart),
    };
    jobVisits[selectedJobId].checklists = [...checklists];
    return state;
  },
  uploadedChecklists: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { checklists } = jobVisits[selectedJobId];
    checklists.map((cl) => (cl.uploaded = true));
    jobVisits[selectedJobId].checklists = [...checklists];
    return state;
  },
  // Files
  addFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].files = [file, ...files];
    return state;
  },
  removeFile: (state, { payload: { id } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    files = files.filter((f) => f.id !== id);
    jobVisits[selectedJobId].files = [...files];
    return state;
  },
  updateFile: (state, { payload: { file } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { files } = jobVisits[selectedJobId];
    let index = files.findIndex((f) => f.id === file.id);
    files[index] = file;
    jobVisits[selectedJobId].files = [...files];
    return state;
  },
  uploadedFiles: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].files = [];
    return state;
  },
  // MeterReadings
  updateMeterReadings: (state, { payload: { readings } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].meterReadings = [...readings];
    return state;
  },
  setMetersNotSaved: (state, { payload: { unSavedChanges } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].metersNotSaved = unSavedChanges;
    return state;
  },
  setMetersUseCurrent: (state, { payload: { useCurrent } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    jobVisits[selectedJobId].metersUseCurrent = useCurrent;
    return state;
  },
  // Extras
  addExtra: (state, { payload: { extra } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].extras = [extra, ...extras];
    return state;
  },
  removeExtra: (state, { payload: { index } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    extras.splice(index, 1);
    jobVisits[selectedJobId].extras = [...extras];
    return state;
  },
  updateExtra: (state, { payload: { extra } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { extras } = jobVisits[selectedJobId];
    let index = extras.findIndex((f) => f.id === extra.id);
    extras[index] = extra;
    jobVisits[selectedJobId].extras = [...extras];
    return state;
  },
  // Parts
  addPart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [part, ...usedParts];
    if (part.part.status === PartStatus.Requested) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    }
    return state;
  },
  addParts: (state, { payload: { parts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    jobVisits[selectedJobId].usedParts = [...parts, ...usedParts];
    if (parts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    } else if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    }
    return state;
  },
  removePart: (state, { payload: { id, stockStore } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    usedParts = usedParts.filter((p) => !(p.part.id === id && p.part.stockStore === stockStore));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    if (!usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: false,
        followUpDisabled: false,
        followUpPartsChecked: false,
        followUpPartsDisabled: false,
      };
    } else if (usedParts.some((part) => part.part.status === PartStatus.Requested)) {
      jobVisits[selectedJobId].followUp = {
        ...jobVisits[selectedJobId].followUp,
        followUpChecked: true,
        followUpDisabled: true,
        followUpPartsChecked: true,
        followUpPartsDisabled: true,
      };
    }
    return state;
  },
  updatePart: (state, { payload: { part } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { usedParts } = jobVisits[selectedJobId];
    let index = usedParts.findIndex((p) => p.part.id === part.part.id);
    usedParts[index] = part;
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  uploadedAddedParts: (state, { payload: { addedParts } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) =>
      p.part.stockStore !== StockStore.Other && addedParts.includes(p.part.id!)
        ? (p.uploaded = true)
        : p
    );
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  uploadedRequestedParts: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const { usedParts } = jobVisits[selectedJobId];
    usedParts.map((p) => (p.part.stockStore === StockStore.Other ? (p.uploaded = true) : p));
    jobVisits[selectedJobId].usedParts = [...usedParts];
    return state;
  },
  // Signature
  setSignatureCustomer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureCustomer = signatureData;
    return state;
  },
  setSignatureEngineer: (state, { payload: { signatureData } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    state.jobVisits[selectedJobId].signatureEngineer = signatureData;
    return state;
  },
  // WorkTasks
  updateWorkTask: (state, { payload: { workTask } }) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    let { workTasks } = jobVisits[selectedJobId];
    let index = workTasks.findIndex((wt) => wt.id === workTask.id);
    workTasks[index] = workTask;
    jobVisits[selectedJobId].workTasks = [...workTasks];
    return state;
  },
  addTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const newTravelTime = {
      startDate: toDateString(Date.now()),
      startTime: toDateString(Date.now()),
    };
    if (jobVisits[selectedJobId].travelTimes.length === 0) {
      jobVisits[selectedJobId].travelTimes = cloneDeep([newTravelTime]);
    } else {
      jobVisits[selectedJobId].travelTimes = [
        ...jobVisits[selectedJobId].travelTimes,
        newTravelTime,
      ];
    }

    return state;
  },
  stopTravel: (state) => {
    const { selectedJobId, jobVisits } = state;
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    const lastIndex = jobVisits[selectedJobId].travelTimes.length - 1;
    if (lastIndex !== -1) {
      jobVisits[selectedJobId].travelTimes[lastIndex].stopDate = toDateString(Date.now());
      jobVisits[selectedJobId].travelTimes[lastIndex].stopTime = toDateString(Date.now());
    }
    return state;
  },
  //EquipmentPropsDialog
  setMachinePropertiesOpen: (state, { payload: { open } }) => {
    state.machineProperties.open = open;
    return state;
  },
  //EquipmentSpecificationsDialog
  setEquipmentSpecificationsOpen: (state, { payload: { open } }) => {
    state.equipmentSpecifications.open = open;
    return state;
  },
  // AddChecklistDialog
  handleCloseAddChecklist: (state) => {
    state.addChecklist.open = false;
    return state;
  },
  handleCloseAddChecklistSelect: (state) => {
    state.addChecklist.openSelect = false;
    return state;
  },
  setAddChecklistOpen: (state, { payload: { open } }) => {
    state.addChecklist.open = open;
    return state;
  },
  setAddChecklistSelectOpen: (state, { payload: { open, useEquipment } }) => {
    state.addChecklist.openSelect = open;
    state.addChecklist.useEquipment = useEquipment;
    return state;
  },
};

// Definition of actual selectors
const selectors: Selectors = {
  selectVisitLoaded({ visit: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      return false;
    }
    return true;
  },
  selectTravelTimes({ jobs: { jobs }, visit: { jobVisits, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId].travelTimes;
  },
  selectSelectedJob({ jobs: { jobs }, visit: { selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId];
  },
  selectSelectedJobVisit({ visit: { selectedJobId, jobVisits } }) {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("JobVisit not initialized or unavailable. Should not be possible to trigger");
    }
    return jobVisits[selectedJobId];
  },
  selectWorkNotes({ jobs: { jobs }, visit: { selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId].workNotes.filter((wn) => wn.type === WorkNoteTypeEnum.Ticket);
  },
  selectVisitSelectedJobId({ visit: { selectedJobId } }) {
    return selectedJobId;
  },
  selectMachinePropertiesOpen: ({ visit: { machineProperties } }) => {
    return machineProperties.open;
  },
  selectMachinePropertiesLoading: ({ visit: { machineProperties } }) => {
    return machineProperties.loading;
  },
  selectEquipmentSpecificationsOpen: ({ visit: { equipmentSpecifications } }) => {
    return equipmentSpecifications.open;
  },
  selectEquipmentSpecificationsLoading: ({ visit: { equipmentSpecifications } }) => {
    return equipmentSpecifications.loading;
  },
  selectTravelTabInvalid: ({ visit: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return !travelHasMatchingStopTimes;
  },
  selectChecklistsIncomplete: ({ visit: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    return !checklistsComplete;
  },
  selectVisitCanComplete: ({ visit: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const visit = jobVisits[selectedJobId];
    const isValid = !Object.keys(visit.errors).length;
    const hasAction = (visit.actionId1?.length || 0) > 0;
    const meterReadingsComplete = !visit.metersNotSaved;

    let checklistsComplete = true;
    if (visit.checklists.length > 0) {
      checklistsComplete = visit.checklists.every(({ checklist }) => {
        const { isComplete } = getChecklistStatus(checklist);

        return isComplete;
      });
    }

    // Actual validation issues will be caught via visit.errors check
    // but unmatched pairs do not validate correctly
    let travelHasMatchingStopTimes = true;
    if (visit.travelTimes.length > 0) {
      travelHasMatchingStopTimes = visit.travelTimes.every(({ stopDate, stopTime }) => {
        return !!stopDate && !!stopTime;
      });
    }
    return (
      isValid &&
      hasAction &&
      checklistsComplete &&
      meterReadingsComplete &&
      travelHasMatchingStopTimes
    );
  },
  selectIsJobInProgress: ({ jobs: { jobs }, visit: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null) ||
      (!isEmpty(visit.travelTimes) && visit.travelTimes[0].startTime != null)
    );
  },
  selectVisitStarted: ({ jobs: { jobs }, visit: { selectedJobId, jobVisits } }) => {
    if (!selectedJobId || !jobs[selectedJobId] || !jobVisits[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    const job = jobs[selectedJobId];
    const visit = jobVisits[selectedJobId];

    return (
      job.status === JobStatus.InProgress ||
      (!isEmpty(visit.workTimes) && visit.workTimes[0].startTime !== null)
    );
  },
  selectContactsLoading({ visit: { contactsLoading } }) {
    return contactsLoading;
  },
  selectAddChecklistOpen: ({ visit: { addChecklist } }) => {
    return addChecklist.open;
  },
};

// * visit: Name of store with lowercase letters
const storeBase = createSlice<State, Actions>({
  name: "visit",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;

// Export all actions created in store, to be used in components
export const {
  addExtra,
  addFile,
  addPart,
  addParts,
  initializeVisit,
  removeExtra,
  removeFile,
  removePart,
  setSelectedJob,
  setTravelTimes,
  setTravelEta,
  setTravelMileage,
  setVisitCompleted,
  setVisitValue,
  updateChecklist,
  updateExtra,
  updateFile,
  updateMeterReadings,
  setMetersNotSaved,
  setMetersUseCurrent,
  updatePart,
  updateWorkTask,
  uploadedAddedParts,
  uploadedChecklists,
  uploadedExtras,
  uploadedFiles,
  uploadedRequestedParts,
  validateVisit,
  setSignatureCustomer,
  setSignatureEngineer,
  setAutoEndTime,
  addTravel,
  stopTravel,
  setMachinePropertiesOpen,
  setEquipmentSpecificationsOpen,
  handleCloseAddChecklist,
  handleCloseAddChecklistSelect,
  setAddChecklistOpen,
  setAddChecklistSelectOpen,
} = storeBase.actions;

export const {
  completeVisit,
  startVisitTravel,
  startVisitWork,
  updateETA,
  updateVisitInformation,
  updateVisitTravel,
  updateMachineCustomProps,
  updateMeters,
  addChecklist,
} = asyncMutations;
export const { getContacts, getChecklistGroups } = asyncQueries;

// Export all selectors created in store, to be used in components
export const {
  selectVisitLoaded,
  selectTravelTimes,
  selectSelectedJob,
  selectSelectedJobVisit,
  selectWorkNotes,
  selectVisitSelectedJobId,
  selectMachinePropertiesOpen,
  selectMachinePropertiesLoading,
  selectEquipmentSpecificationsOpen,
  selectEquipmentSpecificationsLoading,
  selectTravelTabInvalid,
  selectChecklistsIncomplete,
  selectVisitCanComplete,
  selectIsJobInProgress,
  selectVisitStarted,
  selectContactsLoading,
  selectAddChecklistOpen,
} = selectors;
