import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { AsolviDateTimePicker } from "components/AsolviDateTimePicker";

import { QuestionTypeProps } from "./QuestionTypeProps";

export const DateTimeType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  return (
    <AsolviDateTimePicker
      onChange={(value) => {
        onChange(value);
      }}
      name={question.question}
      value={question.answer ? new Date(question.answer) : null}
      required={question.isRequired}
      error={errorText}
      size="small"
      disabled={readonly}
    />
  );
};
