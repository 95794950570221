import { Container, LinearProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { selectJobs, selectLoadingJobs, selectNewJobIds } from "store/slices/jobs.store";

import { JobList } from "components/job/JobList";
import { JobListSkeleton } from "components/job/JobListSkeleton";
import { setAppBar } from "store/slices/menu.store";

const sortingType = "job";

export const NewJobsPage: FC = () => {
  const dispatch = useAppDispatch();
  const newJobIds = useAppSelector(selectNewJobIds);
  const loadingJobs = useAppSelector(selectLoadingJobs);
  const jobs = useAppSelector(selectJobs);
  const intl = useIntl();

  useEffect(() => {
    dispatch(
      setAppBar({
        title: intl.formatMessage({ id: "newJobs" }),
        sortingType,
        goingBack: true,
        backAction: "ClearNewJobIds",
      })
    );
  }, [dispatch, intl]);

  return loadingJobs ? (
    <Container data-testid="newJobsPageContainer">
      <LinearProgress />
      <JobListSkeleton showBorder />
    </Container>
  ) : (
    <Container data-testid="newJobsPageContainer" sx={{ mt: 1 }}>
      <JobList
        jobs={jobs.filter((x) => newJobIds.includes(x.id))}
        loading={loadingJobs}
        toLink="/jobs"
        sortingType={sortingType}
        showBorder
      />
    </Container>
  );
};
