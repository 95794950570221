import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import StyledTextField from "components/StyledTextField";
import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";

import { QuestionTypeProps } from "./QuestionTypeProps";

export const StringType: FC<QuestionTypeProps> = (props) => {
  const intl = useIntl();
  const { question, onChange, readonly } = props;
  const [errorText, setErrorText] = useState<string>("");

  useEffect(() => {
    if (question.isRequired && !question.answer) {
      setErrorText(intl.formatMessage({ id: "general.required" }));
    } else {
      setErrorText("");
    }
  }, [intl, question.isRequired, question.answer]);

  return readonly ? (
    <StyledTextFieldReadOnly
      value={question.answer}
      size="small"
      data-testid="StringTypeField"
      disabled
    />
  ) : (
    <StyledTextField
      value={question.answer}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      required={question.isRequired}
      helperText={errorText}
      error={!!errorText}
      size="small"
      data-testid="StringTypeField"
    />
  );
};
