import { styled } from "@mui/material/styles";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const PREFIX = "EventDateIcon";

const classes = {
  jobTimeIcon: `${PREFIX}-jobTimeIcon`,
};

const StyledAccessAlarmIcon = styled(AccessAlarmIcon)(({theme}) => ({
  [`& .${classes.jobTimeIcon}`]: {
    marginRight: theme.spacing(0.5),
    verticalAlign: "middle",
  },
}));

export const EventDateIcon = ({ plannedDate }: any) => {
  if (plannedDate) {
    return (
      <StyledAccessAlarmIcon
        fontSize="small"
        color="primary"
        className={classes.jobTimeIcon}
      />
    );
  } else {
    return null;
  }
};
