import { getIntl } from "context/SettingsProvider";
import * as yup from "yup";

export const CreateJobValidationSchema = (
  hasCategories: boolean,
  isEquipmentMandatory: boolean
) => {
  const intl = getIntl();

  return yup.object({
    customer: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "createJob.customerRequired" }),
        test: (value) => {
          return !!value?.id;
        },
      }),
    symptomCode1: yup
      .string()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.symptomRequired" }),
        test: (value) => {
          return !!value;
        },
      }),
    category: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.categoryRequired" }),
        test: function (value) {
          return hasCategories ? !!value?.id : true;
        },
      }),
    description: yup.string().required(intl.formatMessage({ id: "createJob.descriptionRequired" })),
    equipment: yup
      .object()
      .nullable()
      .test("required", intl.formatMessage({ id: "createJob.equipmentRequired" }), (value) =>
        isEquipmentMandatory ? !!value?.id : true
      ),
  });
};
