import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Dispatch, FC, SetStateAction } from "react";
import { useIntl } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";

interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

interface EditPhotoColorDialogProps {
  color: string;
  size: number;
  openColorPalette: boolean;
  setOpenColorPalette: Dispatch<SetStateAction<boolean>>;
  setColor: Dispatch<SetStateAction<string>>;
  setSize: Dispatch<SetStateAction<number>>;
}

export const EditPhotoColorDialog: FC<EditPhotoColorDialogProps> = (props) => {
  const intl = useIntl();

  const { color, size, openColorPalette, setOpenColorPalette, setColor, setSize } = props;

  return (
    <Dialog
      data-testid="edit-photo-color-dialog"
      open={openColorPalette}
      onClose={() => setOpenColorPalette(false)}
      fullScreen
      aria-labelledby="select-color"
    >
      <DialogTitle>{intl.formatMessage({ id: "editPhoto.changeColorAndLineWidth" })}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
          }}
        >
          <FormControl
            sx={{
              mt: 2,
              minWidth: 120,
            }}
          >
            <Typography id="change-color" gutterBottom>
              {intl.formatMessage({ id: "editPhoto.selectColor" })}
            </Typography>
            <input
              id="select-color-form"
              data-testid="select-color-form"
              name="color"
              value={color}
              type="color"
              onChange={(e) => setColor(e.target.value)}
              style={{
                width: 66,
                height: 36,
              }}
            />
            <Typography id="change-line-width" gutterBottom sx={{ mt: 4 }}>
              {intl.formatMessage({ id: "editPhoto.changeLineWidth" })}
            </Typography>
            <Slider
              id="change-line-width"
              data-testid="change-line-width-slider"
              components={{
                ValueLabel: (props: ValueLabelProps) => {
                  const { children, open, value } = props;
                  return (
                    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
                      {children}
                    </Tooltip>
                  );
                },
              }}
              valueLabelDisplay="auto"
              aria-label="stroke-width"
              defaultValue={size}
              value={size}
              min={1}
              max={50}
              onChange={(e: any) => {
                setSize(e.target.value);
              }}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <StyledButtonPrimaryMini onClick={() => setOpenColorPalette(false)} color="primary">
          {intl.formatMessage({ id: "general.save" })}
        </StyledButtonPrimaryMini>
      </DialogActions>
    </Dialog>
  );
};
