import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const SecondaryGreyButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.dark,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
  border: `1px solid ${theme.palette.secondary.dark}`,
  boxShadow: "none",
  "&:disabled": {
    border: "none",
  },
  padding: "12px 16px",
  width: "100%",
}));

export default SecondaryGreyButton;
