import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import StyledCard from "components/StyledCard";
import { PartType } from "operations/schema/schema";

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

type RequestedPartsTabProps = {
  parts: PartType[] | null | undefined;
};

export const RequestedPartsTab: FC<RequestedPartsTabProps> = (props) => {
  const intl = useIntl();
  const parts = props.parts || [];

  return (
    <StyledContainer data-testid="RequestedPartsTab">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">
            {intl.formatMessage({
              id: "part.previouslyRequested",
            })}
          </Typography>
        </Grid>
        {parts.map((part) => {
          return (
            <Grid key={part.id} item xs={12} sm={6} lg={4}>
              <StyledCard>
                <CardContent sx={{ width: "100%" }}>
                  <Typography variant="body1" component="h3">
                    {part.description}
                  </Typography>
                  <Typography color="textSecondary">{part.partNumber}</Typography>
                  <Typography variant="body2" component="p">
                    <FormattedMessage id="general.quantity" />: {part.quantity}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          );
        })}
      </Grid>
    </StyledContainer>
  );
};
