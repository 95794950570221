import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";

import { TransitPartType } from "models/TransitPartType";
import { RootState } from "store";
import { asyncMutations } from "./bookInParts.mutations";
import { asyncQueries, queryBuilder } from "./bookInParts.queries";

export interface State {
  open: boolean;
  loadingUpdate: boolean;
  loadingParts: boolean;
  transitParts: TransitPartType[];
}

interface Actions extends SliceCaseReducers<State> {
  setOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  togglePartChecked: (state: State, action: PayloadAction<{ part: TransitPartType }>) => State;
}

interface Selectors {
  selectTransitParts: (state: RootState) => TransitPartType[];
  selectBookInPartsDialogOpen: (state: RootState) => boolean;
  selectUpdateTransitPartsLoading: (state: RootState) => boolean;
}

export const initialState: State = {
  open: false,
  loadingUpdate: false,
  loadingParts: false,
  transitParts: [],
};

const actions: Actions = {
  setOpen: (state, { payload: { open } }) => {
    state.open = open;
    return state;
  },
  togglePartChecked(state, { payload: { part } }) {
    let index = state.transitParts.findIndex((p) => p.id === part.id);
    state.transitParts[index].checked = !state.transitParts[index].checked;
    return state;
  },
};

const selectors: Selectors = {
  selectTransitParts({ bookInParts: { transitParts } }) {
    return transitParts;
  },
  selectBookInPartsDialogOpen: ({ bookInParts }) => {
    return bookInParts.open;
  },
  selectUpdateTransitPartsLoading: ({ bookInParts }) => {
    return bookInParts.loadingUpdate;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "bookInParts",
  initialState,
  reducers: actions,
  extraReducers: queryBuilder,
});

export default storeBase.reducer;
export const { setOpen, togglePartChecked } = storeBase.actions;
export const { selectTransitParts, selectBookInPartsDialogOpen, selectUpdateTransitPartsLoading } =
  selectors;
export const { getTransitParts } = asyncQueries;
export const { updateTransitParts } = asyncMutations;
