import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";

const TabToolbarPlaceholder = styled(Toolbar)(({ theme }) => ({
  zIndex: -1,
  marginBottom: "-8px",
  [theme.breakpoints.up("sm")]: {
    marginBottom: "-24px",
  },
}));

export default TabToolbarPlaceholder;
