import WarningIcon from "@mui/icons-material/Warning";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactMarkdown from "react-markdown";

import { CollapsibleNote } from "components/CollapsibleNote";
import { PromptDialog } from "components/PromptDialog";
import { formatDateTime, groupByProperty } from "helpers";
import { WorkNoteType } from "operations/schema/schema";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "store";
import { selectPageAlertNotes } from "store/root.store";

export const AlertNotesDialog: FC = () => {
  const intl = useIntl();
  const alertNotes = useAppSelector(selectPageAlertNotes, shallowEqual);
  const [openPrompt, setOpenPrompt] = useState(false);

  const groupedAlertNotes = groupByProperty(alertNotes, (x) => x?.type!, "");
  const alertNotesString = JSON.stringify(alertNotes);

  useEffect(() => {
    if (!!alertNotes?.length) {
      setOpenPrompt(true);
    }
  }, [alertNotesString, alertNotes?.length]);

  return (
    <PromptDialog
      open={openPrompt}
      setOpen={setOpenPrompt}
      showCancel={false}
      okText={intl.formatMessage({ id: "note.okText" })}
      title={
        <Typography variant="h5" display="flex" alignItems="end" component="p">
          <WarningIcon color="warning" sx={{ mr: 1.5 }} fontSize="large" />
          <FormattedMessage id="note.alert" />
        </Typography>
      }
      promptContent={
        <List>
          {groupedAlertNotes &&
            Object.entries(groupedAlertNotes).map((entry) => {
              const [label, notes] = entry;

              const noteCards = notes.map(
                (wn: WorkNoteType, index: number, { length }: { length: number }) => (
                  <Grid
                    key={`${index}-${wn.loggedDate}-${wn.loggedBy}`}
                    item
                    container
                    mb={index + 1 !== length ? 4 : 2}
                    direction="column"
                  >
                    <Grid item>
                      <Typography align="justify" component="div">
                        <ReactMarkdown children={wn.text} className="markdown-text" />
                      </Typography>
                    </Grid>
                    <Grid item mt={1}>
                      <Typography fontWeight={700} display="inline" mr={1}>
                        {wn.loggedBy}
                      </Typography>
                      <Typography color="text.secondary" display="inline">
                        {formatDateTime(wn?.loggedDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              );

              return (
                <CollapsibleNote
                  key={label}
                  label={label}
                  content={noteCards}
                  open={notes.length === 1}
                />
              );
            })}
        </List>
      }
    />
  );
};
