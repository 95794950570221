import { Alert } from "@mui/material";
import { isLocal } from "env";
import { Component, ReactNode } from "react";

class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: !isLocal() };
  }

  render() {
    if (this.state.hasError) {
      return <Alert severity="error">Something went wrong</Alert>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
