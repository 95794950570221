import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FC } from "react";

import { NoMatchPage } from "components/NoMatchPage";
import { PrivateRoute } from "pages/PrivateRoute";
import StockRouter from "pages/stock";
import JobsRouter from "pages/jobs";
import HistoryRouter from "pages/history";
import PlannerRouter from "pages/planner";
import LoginRouter from "pages/login";

// "index" page to avoid issues with router
import { JobsPage } from "pages/jobs/JobsPage";

const AppRouter: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="login/*" element={<LoginRouter />} />
        <Route
          path="history/*"
          element={<PrivateRoute component={HistoryRouter} />}
        />
        <Route
          path="jobs/*"
          element={<PrivateRoute component={JobsRouter} />}
        />
        <Route
          path="planner/*"
          element={<PrivateRoute component={PlannerRouter} />}
        />
        <Route
          path="stock/*"
          element={<PrivateRoute component={StockRouter} />}
        />
        <Route path="" element={<PrivateRoute component={JobsPage} />} />
        <Route path="*" element={<NoMatchPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
