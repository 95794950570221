import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AsolviDatePicker } from "components/AsolviDatePicker";
import { AsolviTimePicker } from "components/AsolviTimePicker";
import { toDate } from "helpers";
import { TimesType } from "operations/schema/schema";
import { FC } from "react";
import { useIntl } from "react-intl";

const PREFIX = "TimeBlock";
const classes = {
  field: `${PREFIX}-field`,
};
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.field}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

interface TimeBlockComponentProps {
  times: TimesType;
  id: number;
  timesLabel: "workTimes" | "travelTimes";
  timesText: string;
  totalTime: string;
}

const TimeBlockComponent: FC<TimeBlockComponentProps> = (props) => {
  const { times, id, timesLabel, timesText, totalTime } = props;
  const intl = useIntl();

  return (
    <StyledGrid container spacing={1}>
      <Grid item container direction="row" spacing={1} wrap="nowrap" justifyContent="space-between">
        <Grid item ml={1}>
          <Typography variant="body1">
            {timesText}: {totalTime}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={1} wrap="nowrap">
        <Grid item xs={6}>
          <AsolviDatePicker
            name={`${timesLabel}[${id}].startDate`}
            label={intl.formatMessage({ id: "times.startDate" })}
            value={toDate(times.startTime)}
            onChange={() => {}}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <AsolviTimePicker
            name={`${timesLabel}[${id}].startTime`}
            label={intl.formatMessage({ id: "times.startTime" })}
            value={toDate(times.startTime)}
            onChange={() => {}}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={1} wrap="nowrap">
        <Grid item xs={6}>
          <AsolviDatePicker
            name={`${timesLabel}[${id}].stopDate`}
            label={intl.formatMessage({ id: "visit.endDate" })}
            value={toDate(times.stopTime)}
            onChange={() => {}}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <AsolviTimePicker
            name={`${timesLabel}[${id}].stopTime`}
            label={intl.formatMessage({ id: "visit.endTime" })}
            value={toDate(times.stopTime)}
            onChange={() => {}}
            disabled
          />
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default TimeBlockComponent;
