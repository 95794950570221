import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  "& label.Mui-focused": {
    color: theme.palette.info.main,
  },
  "& label.Mui-error": {
    color: theme.palette.error.main,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: theme.palette.info.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.info.main,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.error.main,
    },
  },
}));

export default StyledTextField;
