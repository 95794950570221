import { VisitList } from "components/job/visit/VisitList";
import { FC } from "react";
import { useAppSelector } from "store";
import { selectPageVisits } from "store/root.store";

export const VisitsTab: FC = () => {
  const visits = useAppSelector(selectPageVisits);

  return (
    <>
      <VisitList visits={visits} />
    </>
  );
};
