const env = process.env;

export const isLocal = () => {
  let nodeEnv = env.NODE_ENV;
  return nodeEnv !== "production";
};

export const publicUrl = () => {
  return env.PUBLIC_URL;
};

export const graphqlUrl = () => {
  let localUrl = "http://localhost:5000/graphql";
  // let localUrl = "https://anywhere-dev-webapp.azurewebsites.net/graphql";
  return env.REACT_APP_GRAPHQL_URL || localUrl;
};
export const backendUrl = () => {
  return graphqlUrl().replace("/graphql", "");
};

export const version = () => {
  let version = env.REACT_APP_APP_VERSION || "";
  return isLocal() ? "Local" : version;
};
export const scanditLicense = () => {
  return env.REACT_APP_SCANDIT_LICENSE;
};

export const gitSha = () => {
  let sha = env.REACT_APP_GIT_SHA;
  return sha ? `${sha}` : "Local";
};

type reactAppStage = "dev" | "qa" | "prod" | undefined;
export const appEnvironment = () => {
  let stage = (env.REACT_APP_STAGE as reactAppStage) || ("local" as const);
  return stage;
};
