import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Dispatch, FC, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";

import StyledButtonPrimaryMini from "components/StyledButtonPrimaryMini";
import StyledButtonSecondaryGreyMini from "components/StyledButtonSecondaryGreyMini";

interface EditPhotoSaveDialogProps {
  confirmationDialog: boolean;
  setConfirmationDialog: Dispatch<SetStateAction<boolean>>;
  save: () => void;
}

export const EditPhotoSaveDialog: FC<EditPhotoSaveDialogProps> = (props) => {
  const { confirmationDialog, setConfirmationDialog, save } = props;

  return (
    <Dialog
      open={confirmationDialog}
      onClose={() => setConfirmationDialog(false)}
      data-testid="EditPhotoSaveDialog-Dialog"
      aria-labelledby="save-dialog-title"
    >
      <DialogTitle id="save-dialog-title">
        <FormattedMessage id="general.save" />?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="editPhoto.savingWillOverride" />.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-around">
          <Grid item>
            <StyledButtonPrimaryMini
              onClick={save}
              startIcon={<CheckIcon />}
              data-testid="EditPhotoSaveDialog-SaveButton"
            >
              <FormattedMessage id="general.save" />
            </StyledButtonPrimaryMini>
          </Grid>
          <Grid item>
            <StyledButtonSecondaryGreyMini
              onClick={() => setConfirmationDialog(false)}
              startIcon={<ClearIcon />}
              data-testid="EditPhotoSaveDialog-CancelButton"
            >
              <FormattedMessage id="general.cancel" />
            </StyledButtonSecondaryGreyMini>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
