import { alpha, Button, styled } from "@mui/material";

const StyledButtonPrimaryMini = styled(Button)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.common.white,
  border: `1px solid ${theme.palette.info.main}`,
  "&:hover, &:active": {
    backgroundColor: theme.palette.info.light,
    border: theme.palette.info.light,
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.common.black, 0.24),
    border: alpha(theme.palette.common.black, 0.24),
    color: alpha(theme.palette.common.black, 0.52),
  },
}));

export default StyledButtonPrimaryMini;
