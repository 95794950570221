import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { GetStockEnquiryQueryVariables } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./stock.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getStockEnquiry: createAppAsyncThunk(
    "stock/getStockEnquiry",
    async (variables: GetStockEnquiryQueryVariables, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getStockEnquiry, {
        variables,
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.stockEnquiry) return rejectWithValue("something went wrong");
      return data;
    }
  ),
  getStockList: createAppAsyncThunk(
    "stock/getStockList",
    async (_, { rejectWithValue, extra: { sdk } }) => {
      const { data, errors } = await graphqlRequest(sdk.getStockList);
      if (errors) return rejectWithValue(errors);
      if (!data?.stockList) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getStockEnquiry.pending, (state) => {
    state.loadingStockEnquiry = true;
    return state;
  });
  builder.addCase(asyncQueries.getStockEnquiry.rejected, (state) => {
    state.loadingStockEnquiry = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getStockEnquiry.fulfilled,
    (state, { payload: { stockEnquiry } }) => {
      state.loadingStockEnquiry = false;
      state.stockEnquiry = [...stockEnquiry];
      return state;
    }
  );
  builder.addCase(asyncQueries.getStockList.fulfilled, (state, { payload: { stockList } }) => {
    state.stockList = [...stockList];
    return state;
  });
};
