export const sortByProperty = (
  propA: string | number,
  propB: string | number,
  order: "asc" | "desc" = "asc"
) => {
  if (propA < propB) {
    return order === "asc" ? -1 : 1;
  } else if (propA > propB) {
    return order === "asc" ? 1 : -1;
  } else {
    return 0;
  }
};
