import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const SecondaryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.info.main,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
  },
  border: `1px solid ${theme.palette.info.main}`,
  boxShadow: "none",
  "&:disabled": {
    border: "none",
  },
  padding: "12px 16px",
  width: "100%",
})) as typeof Button;

export default SecondaryButton;
