import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import { FC } from "react";

import { FileInputType, FileType } from "operations/schema/schema";

const PREFIX = "RenderFile";

const classes = {
  cover: `${PREFIX}-cover`,
};

const StyledCardMedia = styled(CardMedia)(() => ({
  [`&.${classes.cover}`]: {
    minWidth: 120,
    minHeight: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface RenderFileProps {
  file: FileType | FileInputType;
}

export const RenderFile: FC<RenderFileProps> = (props) => {
  const { file } = props;
  const fileName = file.name ?? "";
  if (!fileName) {
    return null;
  }

  // Do not need fileName
  // eslint-disable-next-line
  const [_, ext] = fileName?.split(".");
  const extension = ext || "jpeg";

  if (["png", "jpg", "jpeg", "bmp", "gif", "tif", "tiff"].includes(extension)) {
    const data = file.data?.startsWith("data:image/")
      ? file.data
      : `data:image/${extension};base64,${file.data}`;

    return <StyledCardMedia className={classes.cover} image={data} title={fileName} />;
  } else if (extension === "pdf") {
    return (
      <StyledCardMedia className={classes.cover} title={fileName}>
        <PictureAsPdfIcon fontSize="large" color="error" />
      </StyledCardMedia>
    );
  } else if (extension === "doc" || extension === "docx") {
    return (
      <StyledCardMedia className={classes.cover} title={fileName}>
        <DescriptionIcon fontSize="large" color="info" />
      </StyledCardMedia>
    );
  } else if (extension === "xls" || extension === "xlsx") {
    return (
      <StyledCardMedia className={classes.cover} title={fileName}>
        <DescriptionIcon fontSize="large" color="success" />
      </StyledCardMedia>
    );
  } else {
    return (
      <StyledCardMedia className={classes.cover} title={fileName}>
        <DescriptionIcon fontSize="large" color="action" />
      </StyledCardMedia>
    );
  }
};
