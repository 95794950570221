import { Container, LinearProgress, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ServiceJob } from "operations/schema/schema";

import { isEmpty } from "helpers";
import { useAppSelector } from "store";
import { selectConnectionStatus } from "store/root.store";

import { TabPanel } from "components/TabPanel";
import { JobList } from "components/job/JobList";
import { JobListSkeleton } from "components/job/JobListSkeleton";

type RelatedJobsProps = {
  jobs: ServiceJob[];
  loading: boolean | true;
  hideNotification: any;
  customerId?: string | undefined;
  equipmentId?: string | undefined;
};

export const RelatedJobsTab: FC<RelatedJobsProps> = (props) => {
  const { jobs, loading, customerId, equipmentId, hideNotification } = props;
  const customerJobs = customerId ? jobs.filter((j) => j?.customer?.id === customerId) : [];
  const customerCount = customerJobs.length;
  const equipmentJobs = equipmentId ? jobs.filter((j) => j?.equipment?.id === equipmentId) : [];
  const equipmentCount = equipmentJobs.length;
  const [tabIndex, setTabIndex] = useState(0);
  const intl = useIntl();
  const isOnline = useAppSelector(selectConnectionStatus);

  const customerLabel =
    intl.formatMessage({ id: "general.customer" }) + (customerCount ? ` (${customerCount})` : "");
  const equipmentLabel =
    intl.formatMessage({ id: "general.equipment" }) +
    (equipmentCount ? ` (${equipmentCount})` : "");

  useEffect(() => {
    hideNotification(true);
  }, [hideNotification]);

  const MainTemplate = () => {
    return (
      <>
        <Tabs
          value={tabIndex}
          onChange={(_, newValue: number) => {
            setTabIndex(newValue);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          data-testid="RelatedJobsTabs"
        >
          <Tab label={customerLabel} disabled={!customerCount} />
          <Tab label={equipmentLabel} disabled={!equipmentCount} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <JobList jobs={customerJobs} disabled showEngineerName />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <JobList jobs={equipmentJobs} disabled showEngineerName />
        </TabPanel>
      </>
    );
  };

  return (
    <Container data-testid="RelatedJobsTab">
      {loading && isOnline && <LinearProgress />}
      {loading && isEmpty(jobs) && <JobListSkeleton showBorder />}
      {!isEmpty(jobs) && <MainTemplate />}
    </Container>
  );
};
