import { NoMatchPage } from "components/NoMatchPage";
import { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import PlannerJobProvider from "context/jobProviders/PlannerJobProvider";
import { PlannerDetailsPage } from "./PlannerDetailsPage";

const Router: FC = () => {
  const { id } = useParams();
  return (
    <Routes>
      <Route element={<PlannerJobProvider jobId={id} />}>
        <Route path="/*" element={<PlannerDetailsPage />} />
      </Route>
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
