import * as yup from "yup";

import { getIntl } from "context/SettingsProvider";

export const EngineerSignoffValidationSchema = (
  hasCategories: boolean,
  followUpChecked: boolean
) => {
  const intl = getIntl();
  return yup.object({
    solutionDescription: yup.string(),
    skipSignature: yup.boolean(),
    customerSignerName: yup.string(),
    engineerSignature: yup.string(),
    changeServiceTerms: yup.boolean(),
    serviceLevel: yup
      .object()
      .nullable()
      .when("changeServiceTerms", {
        is: true,
        then: yup
          .object()
          .typeError(intl.formatMessage({ id: "visit.signoff.serviceTermRequired" }))
          .required(intl.formatMessage({ id: "visit.signoff.serviceTermRequired" })),
      }),
    jobCategory: yup
      .object()
      .nullable()
      .test({
        name: "required",
        message: intl.formatMessage({ id: "visit.categoryRequired" }),
        test: function (value) {
          return hasCategories && followUpChecked ? !!value?.id : true;
        },
      }),
  });
};
