import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";

import { isEmpty } from "helpers";
import { fromLocal } from "helpers/fromLocal";
import { jobsDictionaryToArray } from "helpers/jobsDictionaryToArray";
import { isEqual } from "lodash";
import {
  defaultHistoryFilter,
  defaultServiceHistoryFilter,
  HistoryFilter,
  HistoryFilterKeys,
  HistoryFilterStorageKey,
  ServiceHistoryFilterKeys,
  ServiceHistoryFilterStorageKey,
} from "models/HistoryFilter";
import { Job, Jobs } from "models/Job";
import { ServiceHistory } from "operations/schema/schema";
import { RootState } from "store";
import { asyncQueries, queryBuilder } from "./history.queries";

export interface State {
  selectedJobId: string | undefined;
  jobs: Jobs;
  historyFilter: HistoryFilter;
  serviceHistoryFilter: HistoryFilter;
  serviceHistory: ServiceHistory[];
  loadingJobs: boolean;
  loadingJob: boolean;
  loadingServiceHistory: boolean;
  loadingRelatedJobs: boolean;
}

interface Actions extends SliceCaseReducers<State> {
  clearHistoryState: (state: State) => State;
  setHistorySelectedJob: (
    state: State,
    action: PayloadAction<{ jobId: string | undefined }>
  ) => State;
  setHistoryFilter: (
    state: State,
    action: PayloadAction<{ historyFilter: HistoryFilter }>
  ) => State;
  resetHistoryFilter: (state: State) => State;
  setServiceHistoryFilter: (
    state: State,
    action: PayloadAction<{ serviceHistoryFilter: HistoryFilter }>
  ) => State;
  resetServiceHistoryFilter: (state: State) => State;
}

interface Selectors {
  selectJobLoaded: (state: RootState) => boolean;
  selectJobs: (state: RootState) => Job[];
  selectLoadingJobs: (state: RootState) => boolean;
  selectSelectedJob: (state: RootState) => Job;
  selectHistorySelectedJobId: (state: RootState) => string | undefined;
  selectHistoryFilter: (state: RootState) => HistoryFilter;
  selectServiceHistoryFilter: (state: RootState) => HistoryFilter;
  selectHistoryFilterCount: (state: RootState) => number;
  selectServiceHistoryFilterCount: (state: RootState) => number;
}

export const initialState: State = {
  selectedJobId: undefined,
  jobs: {},
  historyFilter: fromLocal<HistoryFilter>(HistoryFilterStorageKey) || defaultHistoryFilter,
  serviceHistoryFilter:
    fromLocal<HistoryFilter>(ServiceHistoryFilterStorageKey) || defaultServiceHistoryFilter,
  serviceHistory: [],
  loadingJobs: false,
  loadingJob: false,
  loadingServiceHistory: false,
  loadingRelatedJobs: false,
};

const actions: Actions = {
  clearHistoryState(state) {
    state = { ...initialState };
    state.historyFilter = fromLocal<HistoryFilter>(HistoryFilterStorageKey) || defaultHistoryFilter;
    state.serviceHistoryFilter =
      fromLocal<HistoryFilter>(ServiceHistoryFilterStorageKey) || defaultServiceHistoryFilter;
    return state;
  },
  setHistorySelectedJob: (state, { payload: { jobId } }) => {
    state.selectedJobId = jobId;
    return state;
  },
  setHistoryFilter(state, { payload: { historyFilter } }) {
    state.historyFilter = historyFilter;
    return state;
  },
  resetHistoryFilter(state) {
    state.historyFilter = { ...defaultHistoryFilter };
    return state;
  },
  setServiceHistoryFilter(state, { payload: { serviceHistoryFilter } }) {
    state.serviceHistoryFilter = serviceHistoryFilter;
    return state;
  },
  resetServiceHistoryFilter(state) {
    state.serviceHistoryFilter = { ...defaultServiceHistoryFilter };
    return state;
  },
};

const selectors: Selectors = {
  selectJobLoaded({ history: { selectedJobId, jobs } }) {
    if (selectedJobId && jobs[selectedJobId]) {
      return true;
    }
    return false;
  },
  selectJobs({ history }) {
    return jobsDictionaryToArray(history.jobs);
  },
  selectLoadingJobs({ history: { loadingJobs } }) {
    return !!loadingJobs;
  },
  selectSelectedJob({ history: { jobs, selectedJobId } }) {
    if (!selectedJobId || !jobs[selectedJobId]) {
      throw new Error("SelectedJob unavailable. Should not be possible to trigger");
    }
    return jobs[selectedJobId];
  },
  selectHistorySelectedJobId({ history: { selectedJobId } }) {
    return selectedJobId;
  },
  selectHistoryFilter({ history: { historyFilter } }) {
    return historyFilter;
  },
  selectServiceHistoryFilter({ history: { serviceHistoryFilter } }) {
    return serviceHistoryFilter;
  },
  selectHistoryFilterCount: ({ history: { historyFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of HistoryFilterKeys) {
      if (isEqual(defaultHistoryFilter[k], historyFilter[k]) || isEmpty(historyFilter[k])) continue;
      count++;
    }
    return count;
  },
  selectServiceHistoryFilterCount: ({ history: { serviceHistoryFilter } }) => {
    let count = 1; // Start at 1 as we always date filter
    for (const k of ServiceHistoryFilterKeys) {
      if (
        isEqual(defaultServiceHistoryFilter[k], serviceHistoryFilter[k]) ||
        isEmpty(serviceHistoryFilter[k])
      )
        continue;
      count++;
    }
    return count;
  },
};

const storeBase = createSlice<State, Actions>({
  name: "history",
  initialState,
  reducers: actions,
  extraReducers: queryBuilder,
});

export default storeBase.reducer;
export const {
  clearHistoryState,
  setHistorySelectedJob,
  setHistoryFilter,
  resetHistoryFilter,
  setServiceHistoryFilter,
  resetServiceHistoryFilter,
} = storeBase.actions;
export const {
  selectJobLoaded,
  selectJobs,
  selectLoadingJobs,
  selectSelectedJob,
  selectHistorySelectedJobId,
  selectHistoryFilter,
  selectServiceHistoryFilter,
  selectHistoryFilterCount,
  selectServiceHistoryFilterCount,
} = selectors;
export const {
  getHistoryJob,
  getEngineerHistory,
  getServiceHistory,
  getFiles,
  getNotes,
  getRelatedJobs,
  getVisits,
} = asyncQueries;
