import CheckIcon from "@mui/icons-material/Check";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useIntl } from "react-intl";

import { ListItemButton } from "@mui/material";
import { Group, GroupingKey, groupOptionText } from "helpers/groupers";
import { Sorting, SortingKey, SortingPage, sortingOptionText } from "helpers/sorters";
import { MenuItem } from "models/MenuItem";
import { useAppDispatch, useAppSelector } from "store";
import { setGrouping as dSetGroup, setSorting as dSetSorting } from "store/slices/menu.store";

export interface SortingListProps {
  sortingType: SortingPage;
}

export const SortingList = (props: SortingListProps) => {
  const { sortingType } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { sortingKeys, groupingKeys, sortingVar, groupByOrderEnabled } = useAppSelector(
    (s) => s.menu
  );
  const sortKeys = sortingKeys[sortingType];
  const groupKeys = groupingKeys[sortingType];
  const { sorting, grouping } = sortingVar[sortingType];

  const setSorting = (sorting: Sorting<SortingKey>) => {
    localStorage.setItem(`${sortingType}Sorting`, JSON.stringify(sorting));
    dispatch(dSetSorting({ type: sortingType, sorting }));
  };
  const setGroup = (grouping: Group<GroupingKey>) => {
    localStorage.setItem(`${sortingType}Grouping`, JSON.stringify(grouping));
    dispatch(dSetGroup({ type: sortingType, grouping }));
  };

  const sortingList: MenuItem[] = [
    {
      text: intl.formatMessage({ id: "sorting.sortBy" }),
      childItems: [
        ...sortKeys.map((key) => ({
          text: sortingOptionText(intl, key),
          selected: sorting.key === key,
          onClick: () => {
            setSorting({
              ...sorting,
              key: key,
            });
          },
          testId: `sort-${key}`,
        })),
      ],
    },
    {
      text: intl.formatMessage({ id: "sorting.sortOrder" }),
      childItems: [
        {
          text: intl.formatMessage({ id: "sorting.ascending" }),
          selected: sorting.order === "asc",
          onClick: () => {
            setSorting({
              ...sorting,
              order: "asc",
            });
          },
          testId: "sort-asc",
        },
        {
          text: intl.formatMessage({ id: "sorting.descending" }),
          selected: sorting.order === "desc",
          onClick: () => {
            setSorting({
              ...sorting,
              order: "desc",
            });
          },
          testId: "sort-desc",
        },
      ],
    },
    {
      text: intl.formatMessage({ id: "sorting.groupBy" }),
      childItems: [
        ...groupKeys.map((key) => ({
          text: groupOptionText(intl, key),
          selected: grouping.key === key,
          onClick: () => {
            setGroup({
              ...grouping,
              key: key,
            });
          },
          testId: `group-${key}`,
        })),
      ],
    },
  ];
  if (groupByOrderEnabled) {
    sortingList.push({
      text: intl.formatMessage({ id: "sorting.groupOrder" }),
      childItems: [
        {
          text: intl.formatMessage({ id: "sorting.ascending" }),
          selected: grouping.order === "asc",
          onClick: () => {
            setGroup({
              ...grouping,
              order: "asc",
            });
          },
          testId: "group-asc",
        },
        {
          text: intl.formatMessage({ id: "sorting.descending" }),
          selected: grouping.order === "desc",
          onClick: () => {
            setGroup({
              ...grouping,
              order: "desc",
            });
          },
          testId: "group-desc",
        },
      ],
    });
  }

  return (
    <div data-testid="sortingList">
      {sortingList.map(({ text, childItems }) => (
        <div key={text + "listItem"}>
          <ListItem dense>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ style: { fontWeight: "bold" } }}
            />
          </ListItem>
          <List disablePadding>
            {childItems?.map(
              ({ text: subListText, testId, selected, onClick, className }: MenuItem) => (
                <ListItemButton
                  key={subListText + "item"}
                  dense
                  onClick={onClick}
                  className={className}
                  data-testid={`SortingList-Button${testId ? `-${testId}` : ""}`}
                >
                  {selected && <CheckIcon color="primary" />}
                  <ListItemText
                    primary={subListText}
                    primaryTypographyProps={{
                      style: { marginLeft: selected ? "16px" : "40px" },
                    }}
                  />
                </ListItemButton>
              )
            )}
          </List>
        </div>
      ))}
    </div>
  );
};
