import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useIntl } from "react-intl";

import StyledTextFieldReadOnly from "components/StyledTextFieldReadOnly";
import { intlFormatDateWithWeekday } from "helpers";
import { Maybe, MeterType } from "operations/schema/schema";

interface MeterReadingProps {
  meter: Maybe<MeterType>;
}

export const MeterReadingComponent: FC<MeterReadingProps> = (props: MeterReadingProps) => {
  const { meter } = props;
  const intl = useIntl();
  const currentReading = meter?.currentReading;

  return (
    <div style={{ marginTop: "16px" }}>
      <Typography variant="body1" paragraph>
        {meter?.name}
      </Typography>
      {currentReading ? (
        <Typography variant="subtitle2" color="textPrimary" paragraph>
          {intl.formatMessage({ id: "visit.meters.current" })} |
          {` ${intlFormatDateWithWeekday(intl, currentReading.readingDate)}`}
        </Typography>
      ) : null}
      <StyledTextFieldReadOnly
        name="currentReading"
        type="number"
        inputProps={{ min: 0 }}
        label={intl.formatMessage({ id: "visit.meters.newReading" })}
        InputLabelProps={{
          shrink: true,
        }}
        value={currentReading?.reading}
        size="small"
        disabled
      />
    </div>
  );
};
