import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { useIntl } from "react-intl";

import FileTabCard from "components/FileTabCard";
import FileTabDetails from "components/FileTabDetails";
import { RenderFile } from "components/RenderFile";
import { downloadFile, formatBytes } from "helpers";
import { FileInputType, FileType } from "operations/schema/schema";

export interface FilePreviewProps {
  file: FileInputType | FileType;
  onDelete?: () => void;
  onEdit?: () => void;
  downloadOnCardClick?: boolean;
  readonly?: boolean;
  disableEdit?: boolean;
}

export const FilePreview: FC<FilePreviewProps> = (props) => {
  const { file, onDelete, onEdit, downloadOnCardClick, readonly, disableEdit } = props;
  const intl = useIntl();

  return (
    <Grid item xs={12} width="100%">
      <FileTabCard
        key={file.name}
        onClick={() => {
          if (downloadOnCardClick) {
            downloadFile(file);
          }
        }}
        sx={{
          cursor: "pointer",
        }}
        data-testid={`FilePreview-FileTabCard-${file.name}`}
      >
        <RenderFile file={file} />
        <FileTabDetails>
          <CardContent>
            <Typography
              component="p"
              variant="body2"
              sx={{
                wordWrap: "break-word",
              }}
            >
              {file.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {file?.data?.length && formatBytes(file.data.length)}
            </Typography>
          </CardContent>
        </FileTabDetails>
        {!readonly && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            {onDelete && (
              <IconButton
                aria-label={intl.formatMessage({ id: "general.delete" })}
                onClick={onDelete}
                data-testid="FilePreview-DeleteButton"
                size="large"
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
            {file.data?.startsWith("data:image/") && onEdit && !disableEdit && (
              <IconButton
                aria-label={intl.formatMessage({ id: "general.edit" })}
                onClick={onEdit}
                data-testid="FilePreview-EditButton"
                size="large"
              >
                <EditIcon color="action" />
              </IconButton>
            )}
          </div>
        )}
      </FileTabCard>
    </Grid>
  );
};
