import { GraphQLClientResponse, GraphQLError } from "graphql-request/build/esm/types";
import { getStore } from "store";
import { selectAuth, setAuth } from "store/slices/user.store";
import { abortAll } from "./client";

const backendErrors = {
  MISSING_BEARER_TOKEN: "MISSING_BEARER_TOKEN",
  INVALID_BEARER_TOKEN: "INVALID_BEARER_TOKEN",
};

type ResponseMiddleware = (response: GraphQLClientResponse<unknown> | Error) => void;

export const errorMiddleware: ResponseMiddleware = (res) => {
  let response = (res as any).response as GraphQLClientResponse<unknown>;
  if (response) {
    let errors: GraphQLError[] | undefined = undefined;
    if (!(response instanceof Error) && response.errors) {
      errors = response.errors;
    } else if ((response as any).errors) {
      errors = (response as any).errors as GraphQLError[];
    }
    if (!errors) return;
    const traceId = response.headers.get(`x-b3-trace-id`) || `unknown`;
    console.error(
      `[${traceId}] Request error:
      status ${response.status}
      details: ${errors.map((_) => _.message).join(`, `)}`
    );
    const redirectError = errors.some(
      (error) =>
        error.extensions?.code === backendErrors.MISSING_BEARER_TOKEN ||
        error.extensions?.code === backendErrors.INVALID_BEARER_TOKEN
    );
    if (redirectError) {
      abortAll("UNAUTHORIZED");
      const authVar = selectAuth(getStore().getState());
      getStore().dispatch(
        setAuth({
          ...authVar,
          isLoggedIn: false,
          message: "Your session has expired, please log in again",
        })
      );
    }
  }
};
