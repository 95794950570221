import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { formatTimeDiff, mergeDateTime, toDate } from "helpers";
import { Times } from "models/travelTimes";
import { TimesType } from "operations/schema/schema";

interface TotalTimeComponentProps {
  times?: Times[];
  timesMerged?: TimesType[];
}

export const TotalTimeComponent: FC<TotalTimeComponentProps> = (props) => {
  const { times, timesMerged } = props;
  const intl = useIntl();
  const [totalMinutes, setTotalMinutes] = useState(0);

  useEffect(() => {
    let minutes = 0;
    if (times) {
      times.forEach((time) => {
        if (time.stopTime && time.stopDate) {
          minutes += Math.floor(
            (mergeDateTime(time.stopDate, time.stopTime).getTime() -
              mergeDateTime(time.startDate, time.startTime).getTime()) /
              1000 /
              60
          );
        }
      });
    } else {
      timesMerged?.forEach(({ stopTime, startTime }) => {
        if (stopTime && startTime) {
          minutes += Math.floor(
            (toDate(stopTime).getTime() - toDate(startTime).getTime()) / 1000 / 60
          );
        }
      });
    }
    setTotalMinutes(minutes);
  }, [times, timesMerged, setTotalMinutes]);

  return (
    <Grid item xs={12}>
      <Typography fontWeight={700} display="inline">
        {intl.formatMessage({ id: "times.totalTime" }) + ": "}
      </Typography>
      <Typography variant="body1" display="inline">
        {formatTimeDiff(intl, totalMinutes)}
      </Typography>
    </Grid>
  );
};
