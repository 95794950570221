import { Grid, LinearProgress, List, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import PrimaryButton from "components/PrimaryButton";
import { StyledListSubheader } from "components/StyledListSubheader";
import { intlFormatDateWithWeekday, isEmpty, toDate } from "helpers";
import { ServiceJob } from "operations/schema/schema";

import { getGroupBy } from "helpers/groupers";
import { SortingPage, getSorter } from "helpers/sorters";
import { useAppSelector } from "store";
import { JobComponent } from "./JobComponent";

const PREFIX = "JobList";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(() => ({
  [`& .${classes.root}`]: {
    width: "100%",
    flex: 1,
    position: "relative",
  },
}));

interface JobListProps {
  jobs: ServiceJob[];
  toLink?: string;
  loading?: boolean;
  disabled?: boolean;
  showBorder?: boolean;
  showEngineerName?: boolean;
  refetchJobs?: any;
  sortingType?: SortingPage;
}

export const JobList: FC<JobListProps> = (props) => {
  const {
    jobs,
    toLink,
    loading = false,
    disabled = false,
    showBorder = false,
    showEngineerName = false,
    refetchJobs,
    sortingType = "job",
  } = props;

  const intl = useIntl();
  const { sortingVar, groupByOrderEnabled } = useAppSelector((s) => s.menu);
  const {
    sorting: { key: sortingKey, order: sortingOrder },
    grouping: { key: groupKey, order: groupOrder },
  } = sortingVar[sortingType];

  const sorter = getSorter(sortingKey);
  const groupBy = getGroupBy(groupKey);

  const sortedJobs = jobs.slice().sort((a, b) => sorter(a, b, sortingOrder));
  let groupByOrder = groupByOrderEnabled ? groupOrder : sortingOrder;
  const { grouped: groupedJobs } = groupBy(sortedJobs, intl, groupByOrder);

  return (
    <>
      {isEmpty(groupedJobs) && !loading ? (
        <>
          <Grid container direction="column" alignItems="center" mt={2.5}>
            <Grid item>
              <Typography>
                <FormattedMessage id="general.noJobsFound" />.
              </Typography>
            </Grid>
            {refetchJobs && (
              <Grid item>
                <PrimaryButton
                  type="submit"
                  variant="contained"
                  data-testid="refetchJobs"
                  onClick={() => {
                    refetchJobs(true);
                  }}
                >
                  <FormattedMessage id="general.refreshJobList" />
                </PrimaryButton>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Root>
          {loading && <LinearProgress />}
          <List className={classes.root}>
            {Object.keys(groupedJobs).map((key) => {
              const jobCards = groupedJobs[key].map((job: ServiceJob) => (
                <List dense key={job.id}>
                  <JobComponent
                    job={job}
                    toLink={toLink}
                    disabled={disabled}
                    showBorder={showBorder}
                    showEngineerName={showEngineerName}
                  />
                </List>
              ));

              const groupLabel =
                groupKey.includes("Date") && toDate(key)
                  ? intlFormatDateWithWeekday(intl, key)
                  : key;

              if (isEmpty(jobCards)) return null;
              return (
                <li
                  key={key}
                  style={{
                    backgroundColor: "inherit",
                  }}
                >
                  <ul
                    style={{
                      backgroundColor: "inherit",
                      padding: 0,
                    }}
                  >
                    <StyledListSubheader disableSticky>{groupLabel}</StyledListSubheader>
                    {jobCards}
                  </ul>
                </li>
              );
            })}
          </List>
        </Root>
      )}
    </>
  );
};
