import RefreshIcon from "@mui/icons-material/Refresh";
import { Snackbar, SnackbarContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import StyledButtonSecondaryMini from "components/StyledButtonSecondaryMini";
import { logoutUser } from "context/login/logout";
import { gitSha } from "env";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

type Settings = {
  pwaVersion: string;
};

const PREFIX = "ServiceWorkerWrapper";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

// Display a message to the user when a new version is available
const ServiceWorkerWrapper: FC = () => {
  const intl = useIntl();
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = useState(false);

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = useCallback(async () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    await logoutUser();
    window.location.reload();
  }, [waitingWorker]);

  // register the service worker
  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
      },
    });

    // iOS workaround
    if (navigator.vendor === "Apple Computer, Inc.") {
      const interval = setInterval(async () => {
        const response = await fetch("/settings.json", {
          method: "GET",
          headers: new Headers({
            pragma: "no-cache",
            "cache-control": "no-cache",
          }),
          cache: "no-store",
        });

        const json: Settings = await response.json();

        if (json.pwaVersion !== gitSha()) {
          setShowReload(true);
        }
      }, 30000);

      return () => clearInterval(interval);
    }
  }, []);

  return (
    <StyledSnackbar
      open={showReload}
      onClick={reloadPage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <SnackbarContent
        className={classes.root}
        message={intl.formatMessage({ id: "general.newVersion" })}
        action={
          <StyledButtonSecondaryMini size="small" onClick={reloadPage} startIcon={<RefreshIcon />}>
            {intl.formatMessage({ id: "general.reload" })}
          </StyledButtonSecondaryMini>
        }
      />
    </StyledSnackbar>
  );
};

export default ServiceWorkerWrapper;
