import Fab, { FabProps } from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";

interface StyledFabProps extends FabProps {
  component?: ElementType<any> | undefined;
}

export const StyledFab = styled((props: StyledFabProps) => <Fab {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.info.main,
  },
}));

export default StyledFab;
