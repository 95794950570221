import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const PrimaryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.common.white,
  "&:hover, &:active": {
    backgroundColor: theme.palette.info.light,
  },
  "&:focus": {
    backgroundColor: theme.palette.info.main,
  },
  "&.Mui-disabled": {
    backgroundColor: alpha(theme.palette.common.black, 0.24),
    color: alpha(theme.palette.common.black, 0.52),
  },
  width: "100%",
  padding: "12px 16px",
}));

export default PrimaryButton;
