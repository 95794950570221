import { FormattedMessage, useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "store";
import { setOpen as setDialogOpen } from "store/slices/dialog.store";

import { PromptDialog } from "components/PromptDialog";
import { logoutUser } from "context/login/logout";

export const SignoutDialog = () => {
  const intl = useIntl();

  const dispatch = useAppDispatch();
  const { signout: open } = useAppSelector((state) => state.dialog);
  const setOpen = (open: boolean) => {
    dispatch(setDialogOpen({ dialogName: "signout", open }));
  };

  return (
    <PromptDialog
      aria-labelledby="alert-dialog-title"
      aria-describedby={intl.formatMessage({
        id: "logout.dataLossWarning",
      })}
      data-testid="signoutDialog"
      open={open}
      setOpen={setOpen}
      okText={intl.formatMessage({ id: "general.yes" })}
      cancelText={intl.formatMessage({ id: "general.no" })}
      onOk={() => {
        logoutUser();
      }}
      title={<FormattedMessage id="offline.unsavedChanges" />}
      promptContent={
        <>
          <FormattedMessage id="logout.signoutDataLossWarning" />.{" "}
          <FormattedMessage id="logout.prompt" />
        </>
      }
    />
  );
};
