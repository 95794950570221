import { Route, Routes } from "react-router-dom";
import { FC } from "react";
import { NoMatchPage } from "components/NoMatchPage";

import { LoginPage } from "./LoginPage";
import { Auth0Login } from "./Auth0Login";
import { Auth0Callback } from "./Auth0Callback";

const Router: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="auth0login" element={<Auth0Login />} />
      <Route path="auth0callback" element={<Auth0Callback />} />
      <Route path="*" element={<NoMatchPage />} />
    </Routes>
  );
};
export default Router;
