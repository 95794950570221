import { FC } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";

import { StyledListSubheader } from "components/StyledListSubheader";

import { JobSkeleton } from "./JobSkeleton";

const StyledList = styled(List)(() => ({
  width: "100%",
  position: "relative",
  paddingLeft: 16,
}));

type JobListSkeletonProps = {
  showBorder?: boolean;
};

export const JobListSkeleton: FC<JobListSkeletonProps> = ({ showBorder }) => {
  return (
    <StyledList>
      <li
        style={{
          backgroundColor: "inherit",
        }}
      >
        <ul
          style={{
            backgroundColor: "inherit",
            padding: 0,
          }}
        >
          <StyledListSubheader>
            <Skeleton variant="text" />
          </StyledListSubheader>
          <List dense>
            <JobSkeleton showBorder={showBorder} />
          </List>
          <List dense>
            <JobSkeleton showBorder={showBorder} />
          </List>
          <List dense>
            <JobSkeleton showBorder={showBorder} />
          </List>
        </ul>
      </li>
    </StyledList>
  );
};
