import { AnyAction } from "@reduxjs/toolkit";
import { Variables } from "graphql-request";
import { GraphQLClientRequestHeaders } from "graphql-request/build/esm/types";
import { DBSchema, IDBPDatabase, openDB } from "idb";

export type RequestExtendedInit<V extends Variables = Variables> = RequestInit & {
  url: string;
  operationName?: string;
  variables?: V;
  headers: GraphQLClientRequestHeaders & {
    ["x-queueId"]?: string;
    ["x-thunkName"]: string;
    ["x-thunkProps"]: any;
    ["x-onError"]: AnyAction[];
    ["x-onCompleted"]: AnyAction[];
  };
};

export interface Queue extends DBSchema {
  queue: {
    value: QueueEntry;
    key: number;
  };
}

export type QueueEntry = {
  id?: number; // Generic unique id
  collection: string; // Denotes which collection queue item is part of (jobId/other/etc)
  request: RequestExtendedInit<Variables>; // The request itself
};

let db: IDBPDatabase<Queue>;
const initDb = async () => {
  db = await openDB<Queue>("queue-db", 1, {
    upgrade: (db) => {
      db.createObjectStore("queue", {
        keyPath: "id",
        autoIncrement: true,
      });
    },
  });
};

export const getDb = async () => {
  if (!db) {
    await initDb();
  }
  return db;
};
