import { ReactNode, createContext, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import {
  selectConnectionStatus,
  setConnectionStatus as setRootConnectionStatus,
} from "store/root.store";
import { closeQueue, openQueue } from "./graphql/queue";

type ConnectionStatusProviderProps = { children: ReactNode };

const ConnectionStatusContext = createContext<boolean | undefined>(undefined);

export default function ConnectionStatusProvider(props: ConnectionStatusProviderProps) {
  const dispatch = useAppDispatch();
  const connectionStatus = useAppSelector(selectConnectionStatus);

  useEffect(() => {
    if (connectionStatus) {
      openQueue();
    } else {
      closeQueue();
    }
  }, [connectionStatus]);

  useEffect(() => {
    const isReachable = () => {
      return fetch("/", { method: "HEAD", mode: "no-cors" })
        .then(() => {
          dispatch(setRootConnectionStatus({ status: true }));
        })
        .catch(() => {
          dispatch(setRootConnectionStatus({ status: false }));
        });
    };

    const onOffline = () => {
      dispatch(setRootConnectionStatus({ status: false }));
    };

    const onOnline = () => {
      isReachable();
    };

    /**
     * Check if the user is online when we start the application.
     * This is needed because the user can be logged in when starting the application,
     * and the event listener does not respond to events while the application is closed.
     */
    if (navigator.onLine === true) {
      onOnline();
    } else {
      onOffline();
    }

    window.addEventListener("online", onOnline);

    window.addEventListener("offline", onOffline);

    return function cleanup() {
      window.removeEventListener("offline", onOffline);

      window.removeEventListener("online", onOnline);
    };
  }, [dispatch]);

  return (
    <ConnectionStatusContext.Provider value={connectionStatus}>
      {props.children}
    </ConnectionStatusContext.Provider>
  );
}

export function useConnectionStatus() {
  const context = useContext(ConnectionStatusContext);
  if (context === undefined) {
    throw new Error("useConnectionStatus must be used within a ConnectionStatusContext");
  }
  return context;
}
