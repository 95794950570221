import { ChecklistType, QuestionDataType } from "operations/schema/schema";

export const getChecklistStatus = (checklist: ChecklistType) => {
  const visibleQuestions =
    checklist.questions?.filter((q, _, array) => {
      if (q.dataType === QuestionDataType.Group) return false;

      const parent = array.find((x) => x?.nodeKey === q?.parent);

      return parent?.dataType === QuestionDataType.ConditionalGroup
        ? parent.showCondition === !!parent.answer
        : true;
    }) ?? [];

  const questionsCount = visibleQuestions.length;

  const questionsAnswered = visibleQuestions.filter((q) => !!q?.answer).length;

  const requiredQuestions = visibleQuestions.filter((q) => q?.isRequired);

  const requiredQuestionsAnswered = requiredQuestions?.filter((q) => !!q?.answer).length;

  return {
    isComplete:
      questionsCount === 0 ||
      requiredQuestions.length === requiredQuestionsAnswered ||
      questionsCount === questionsAnswered,
    questionsCount: questionsCount,
    questionsAnswered: questionsAnswered,
  };
};
