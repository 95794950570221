import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { StockStore } from "operations/schema/schema";
import { AppAsyncThunkConfig } from "store";
import { State } from "./bookInParts.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncQueries = {
  getTransitParts: createAppAsyncThunk(
    "bookInParts/getTransitParts",
    async (_, { getState, rejectWithValue, extra: { sdk } }) => {
      const stockId = getState().user.userVar?.stockId;
      const { data, errors } = await graphqlRequest(sdk.getTransitParts, {
        variables: {
          location: {
            locationId: stockId,
            stockStore: StockStore.Engineer,
          },
        },
      });
      if (errors) return rejectWithValue(errors);
      if (!data?.transitParts) return rejectWithValue("something went wrong");
      return data;
    }
  ),
};

export const queryBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncQueries.getTransitParts.pending, (state) => {
    state.loadingParts = true;
    return state;
  });
  builder.addCase(asyncQueries.getTransitParts.rejected, (state) => {
    state.loadingParts = false;
    return state;
  });
  builder.addCase(
    asyncQueries.getTransitParts.fulfilled,
    (state, { payload: { transitParts } }) => {
      state.loadingParts = false;
      const newParts = transitParts.map((p) => {
        return {
          description: p.description,
          id: p.id,
          partNumber: p.partNumber,
          quantity: p.quantity,
          checked: false,
        };
      });
      state.transitParts = [...newParts];
      return state;
    }
  );
};
