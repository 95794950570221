import { getAuth0, getIsAuth0 } from "context/auth0";
import { resetAuthRefresh } from "context/graphql/auth";
import { abortAll } from "context/graphql/client";
import { getStore } from "store";
import { setForcedOffline } from "store/root.store";
import { logout } from "store/slices/user.store";

export const logoutUser = async () => {
  const dispatch = getStore().dispatch;
  abortAll("LOGOUT");
  resetAuthRefresh();
  dispatch(setForcedOffline({ status: false }));
  setTimeout(() => {
    dispatch(logout()).then(() => {
      let isAuth0 = getIsAuth0();
      let auth0 = getAuth0();
      if (isAuth0 && auth0) {
        auth0.logout({ returnTo: `${window.location.origin}/login/` });
      }
    });
  }, 300);
};
