import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const StyledTextFieldReadOnly = styled(TextField)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  '& label.Mui-focused': {
    color: theme.palette.secondary.dark,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.secondary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
    },
  },
  userSelect: 'none',
  pointerEvents: 'none',
}));

export default StyledTextFieldReadOnly;