import { FC } from "react";

import { AlertNotesDialog } from "components/AlertNotesDialog";

import { PlannerActions } from "./PlannerActions";
import { PlannerJobDetails } from "./PlannerJobDetails";

export const PlannerDetailsPage: FC = () => {
  return (
    <>
      <PlannerJobDetails />
      <PlannerActions />
      <AlertNotesDialog />
    </>
  );
};
