import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { graphqlRequest } from "context/graphql/functions";
import { toDateString } from "helpers";
import { AppAsyncThunkConfig } from "store";
import { State } from "./createJob.store";

export const createAppAsyncThunk = createAsyncThunk.withTypes<AppAsyncThunkConfig>();

export const asyncMutations = {
  createJob: createAppAsyncThunk(
    "createJob/createJob",
    async (_, { rejectWithValue, getState, extra: { sdk } }) => {
      const {
        createJob: { formValues, categories },
        user,
      } = getState();
      const hasCategories = !!categories.length;
      if (
        !(formValues.customer && formValues.description && formValues.symptomCode1) ||
        !(hasCategories ? !!formValues.category?.id : true)
      ) {
        return rejectWithValue("Missing values when creating job");
      }
      const { data, errors, queued } = await graphqlRequest(sdk.createJob, {
        thunkName: "createJob/createJob",
        variables: {
          jobDetails: {
            assignedEngineers: formValues.assignToMe
              ? [
                  {
                    engineerId: user.userVar?.externalId,
                    engineerGuid: user.userVar?.externalGuid,
                  },
                ]
              : [],
            categoryId: formValues.category?.id,
            customerId: formValues.customer?.id,
            description: formValues.description,
            equipmentId: formValues.equipment?.id,
            symptomCodeId1: formValues.symptomCode1,
            symptomCodeId2: formValues.symptomCode2,
            symptomCodeId3: formValues.symptomCode3,
            itemId: formValues.equipment?.itemId,
            receivedDate: toDateString(new Date()),
            warrantyServiceLevelId: formValues.equipment?.warrantyServiceLevelId,
            warrantyStartDate: formValues.equipment?.warrantyStartDate,
            warrantyEndDate: formValues.equipment?.warrantyEndDate,
          },
          files: formValues.files.map(({ name, data, description }) => ({
            name,
            data,
            description,
          })),
        },
      });
      if (errors) return rejectWithValue(errors);
      if (data && !data.createServiceJob?.jobId) return rejectWithValue("something went wrong");
      return { jobId: data?.createServiceJob?.jobId, queued };
    }
  ),
};

export const mutationBuilder = (builder: ActionReducerMapBuilder<State>) => {
  builder.addCase(asyncMutations.createJob.pending, (state, { meta }) => {
    if (meta.queued) return state;
    state.createJobLoading = true;
    state.isDirty = false;
    return state;
  });
  builder.addCase(asyncMutations.createJob.rejected, (state, { meta }) => {
    if (meta.aborted) return state;
    state.createJobLoading = false;
    return state;
  });
  builder.addCase(asyncMutations.createJob.fulfilled, (state, { payload: jobId }) => {
    state.createJobLoading = false;
    // Do stuff
    return state;
  });
};
