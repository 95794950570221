import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export interface JobStylesClasses {
  paragraph: string;
  jobTime: string;
  indicatorIcon: string;
  ellipsis: string;
  date: string;
  legacy: string
}

export const JobStyles = {
  createClasses: function () {
    const PREFIX = "Job";
    let classes: JobStylesClasses = {
      paragraph: `${PREFIX}-paragraph`,
      jobTime: `${PREFIX}-jobTime`,
      indicatorIcon: `${PREFIX}-indicatorIcon`,
      ellipsis: `${PREFIX}-ellipsis`,
      date: `${PREFIX}-date`,
      legacy: `${PREFIX}-legacy`
    };
    return classes;
  },

  defineStyles: function (classes: JobStylesClasses) {
    return styled(Box)(({ theme }) => ({
      [`& .${classes.paragraph}`]: {
        marginBottom: theme.spacing(1),
      },

      [`& .${classes.jobTime}`]: {
        alignSelf: "center",
        display: "flex",
      },

      [`& .${classes.indicatorIcon}`]: {
        marginRight: theme.spacing(0.5),
        verticalAlign: "middle",
      },

      [`& .${classes.ellipsis}`]: {
        maxHeight: "3em",
        display: "-webkit-box",
        lineClamp: 2,
        WebkitLineClamp: 2,
        boxOrient: "vertical",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },

      [`& .${classes.date}`]: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        paddingBottom: theme.spacing(1),
      },

      [`& .${classes.legacy}`]: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1)
      },
    }));
  },
};
