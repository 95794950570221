import { FC } from "react";
import { Skeleton, ListItemButton, Grid } from "@mui/material";

import { JobStyles } from "styles/JobStyles";

type JobSkeletonProps = {
  showBorder?: boolean;
};
export const JobSkeleton: FC<JobSkeletonProps> = ({ showBorder }) => {
  const classes = JobStyles.createClasses();
  const StyledBox = JobStyles.defineStyles(classes);

  return (
    <StyledBox
      className="component-Job"
      borderLeft={showBorder ? 6 : 0}
      borderColor="grey.500"
    >
      <ListItemButton disableRipple divider component="div">
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item xs={11} width="100%" data-testid="JobSkeleton">
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton width="40%" />
            <Skeleton width="100%" />
            <Skeleton width="80%" />
            <Skeleton width="40%" />
            <Skeleton width="50%" />
            <Skeleton width="100%" />
            <Skeleton width={60} height={18} variant="rectangular" />
          </Grid>
        </Grid>
      </ListItemButton>
    </StyledBox>
  );
};
