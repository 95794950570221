import {
  Action,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { CreateJobForm } from "models/createJobForm";
import { CreateJobValidationSchema } from "models/CreateJobValidationSchema";
import {
  ContractStatus,
  CustomerType,
  EquipmentType,
  JobCategoryType,
  JobSymptomType,
  WorkNoteType,
} from "operations/schema/schema";
import { RootState } from "store";
import { ValidationError } from "yup";
import { asyncMutations, mutationBuilder } from "./createJob.mutations";
import { asyncQueries, queryBuilder } from "./createJob.queries";

// Interface for state
export interface State {
  createJobLoading: boolean;
  initLoading: boolean;
  isDirty: boolean;
  isEquipmentMandatory: boolean;
  isValid: boolean;
  open: boolean;
  alertNotes: WorkNoteType[];
  alertNotesLoading: boolean;
  categories: JobCategoryType[];
  customers: CustomerType[];
  customersLoading: boolean;
  equipments: EquipmentType[];
  equipmentsLoading: boolean;
  errors: any; // TODO: Find type
  symptoms: JobSymptomType[];
  selectedContract: ContractStatus | undefined;
  formValues: CreateJobForm;
}
/** Example state interface
 * feedback: boolean
 */

// Interface for store actions
interface Actions extends SliceCaseReducers<State> {
  setOpen: (state: State, action: PayloadAction<{ open: boolean }>) => State;
  setEquipmentMandatory: (
    state: State,
    action: PayloadAction<{ isEquipmentMandatory: boolean }>
  ) => State;
  validateForm: (state: State, action: Action) => State;
  setValue: <K extends Extract<keyof CreateJobForm, string>>(
    state: State,
    action: PayloadAction<{
      field: K;
      value: CreateJobForm[K];
      shouldValidate?: boolean;
    }>
  ) => State;
  handleClose: (state: State, action: Action) => State;
}
/** Example function interface
 * setOpen: (
 *    state: State,
 *    action: PayloadAction<{ dialogName: string; open: boolean }>
 * ) => State;
 */

// Interface for store selectors (if necessary)
interface Selectors {
  selectCreateJobOpen: (state: RootState) => boolean;
  selectCreateJobOptions: (state: RootState) => State;
  selectCreateJobForm: (state: RootState) => CreateJobForm;
}
/** Example function interface
 * selectFeedback: (
 *    state: RootState
 * ) => boolean;
 */

// Definition of actual (initial) state
export const initialState: State = {
  initLoading: false,
  createJobLoading: false,
  isValid: true,
  isDirty: false,
  open: false,
  isEquipmentMandatory: false,
  alertNotes: [],
  alertNotesLoading: false,
  categories: [],
  customers: [],
  customersLoading: false,
  equipments: [],
  equipmentsLoading: false,
  errors: {},
  symptoms: [],
  selectedContract: undefined,
  formValues: {
    assignToMe: false,
    category: null,
    customer: null,
    description: "",
    equipment: null,
    files: [],
    symptomCode1: null,
  },
};
/** Example state
 * feedback: false
 */

// Definition of actual actions
const actions: Actions = {
  createJobOnQueued: (state, { payload }) => {},
  setEquipmentMandatory: (state, { payload: { isEquipmentMandatory } }) => {
    state.isEquipmentMandatory = isEquipmentMandatory;
    return state;
  },
  setOpen: (state, { payload: { open } }) => {
    state.open = open;
    return state;
  },
  setValue: (state, { payload: { field, value, shouldValidate } }) => {
    const { isEquipmentMandatory, isValid } = state;
    state.formValues[field] = value;
    state.isDirty = true;

    if (field === "customer") {
      let customer = value as CustomerType;
      if (!customer) {
        state.alertNotes = [];
        state.selectedContract = undefined;
      } else {
        state.equipments = state.equipments.filter((e) => e.customerId === customer.id);
        if (state.formValues.equipment?.customerId !== customer.id) {
          state.formValues.equipment = null;
        }
      }
    }

    if (!shouldValidate && isValid) return state;
    const validationSchema = CreateJobValidationSchema(
      !!state.categories.length,
      isEquipmentMandatory
    );
    const isValidated = validationSchema.isValidSync(state.formValues);
    if (!isValidated) {
      try {
        validationSchema.validateSync({ ...state.formValues }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        state.errors = { ...errors };
      }
      state.isValid = false;
    } else {
      state.isValid = true;
      state.errors = {};
    }
    return state;
  },
  validateForm: (state) => {
    const { isEquipmentMandatory } = state;
    const validationSchema = CreateJobValidationSchema(
      !!state.categories.length,
      isEquipmentMandatory
    );
    const isValidated = validationSchema.isValidSync(state.formValues);
    if (!isValidated) {
      try {
        validationSchema.validateSync({ ...state.formValues }, { strict: true, abortEarly: false });
      } catch (e: any) {
        let { inner } = e as ValidationError;
        let errors: any = {};
        for (let error of inner) {
          if (!error.path) continue;
          errors[error.path] = error.message;
        }
        state.errors = { ...errors };
      }
      state.isValid = false;
    } else {
      state.isValid = true;
      state.errors = {};
    }
    return state;
  },
  handleClose: (state) => {
    state.open = false;
    state.initLoading = false;
    state.createJobLoading = false;
    state.isValid = true;
    state.isDirty = false;
    state.selectedContract = undefined;
    state.alertNotes = [];
    state.errors = {};
    state.formValues = {
      assignToMe: false,
      category: null,
      customer: null,
      description: "",
      equipment: null,
      files: [],
      symptomCode1: null,
    };
    return state;
  },
};
/** Example function
 * setOpen: (state, { payload: { dialogName, open } }) => {
 *    state[dialogName] = open;
 *    return state;
 * },
 */

// Definition of actual selectors
const selectors: Selectors = {
  selectCreateJobOpen: ({ createJob }) => {
    return createJob.open;
  },
  selectCreateJobOptions: ({ createJob }) => {
    return createJob;
  },
  selectCreateJobForm: ({ createJob }) => {
    return createJob.formValues;
  },
};
/** Example function
 * selectFeedback: ({dialog}) => dialog.feedback
 */

const storeBase = createSlice<State, Actions>({
  name: "createJob",
  initialState,
  reducers: actions,
  extraReducers: (builder: ActionReducerMapBuilder<State>) => {
    queryBuilder(builder);
    mutationBuilder(builder);
  },
});

// To be imported and added in store/reducers
export default storeBase.reducer;

// Export all actions created in store, to be used in components
// I.e, if using setOpen defined in examples above, it would look like this
// export const { setOpen } = storeBase.actions;
export const { setEquipmentMandatory, setOpen, setValue, validateForm, handleClose } =
  storeBase.actions;

export const {
  getAlertNotes,
  getCreateJobOptions,
  getCustomer,
  getCustomers,
  getEquipment,
  getEquipmentContractStatus,
} = asyncQueries;

export const { createJob } = asyncMutations;

// Export all selectors created in store, to be used in components
// I.e, if using selectFeedback defined in examples above, it would look like this
// export const { selectFeedback } = selectors;
export const { selectCreateJobOpen, selectCreateJobOptions, selectCreateJobForm } = selectors;
