import { Symbology } from "scandit-web-datacapture-barcode";

export const getSymbologies = () => [
  Symbology.DataMatrix,
  Symbology.QR,
  Symbology.Code128,
  Symbology.Code39,
  Symbology.InterleavedTwoOfFive,
  Symbology.GS1Databar,
  Symbology.GS1DatabarExpanded,
  Symbology.GS1DatabarLimited,
  Symbology.EAN13UPCA,
  Symbology.EAN8,
  Symbology.UPCE,
];
