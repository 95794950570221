import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

interface CollapsibleNoteProps {
  label: string;
  content: JSX.Element | JSX.Element[];
  open?: boolean;
}

export const CollapsibleNote: FC<CollapsibleNoteProps> = (props) => {
  const { label, content, open: propsOpen } = props;
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(propsOpen ?? false);

  const getLabelKey = (label: string) => {
    switch (label) {
      case "Ticket":
        return "note.ticket";
      case "Customer":
        return "note.customer";
      case "Contract":
        return "note.contract";
      case "Equipment":
        return "note.equipment";
      default:
        return "";
    }
  };

  return (
    <Grid mt={1} mb={1}>
      <Grid
        container
        onClick={() => {
          setOpen(!open);
        }}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography fontWeight={700} fontSize="1.15rem" color="secondary.dark" mb={1}>
            {intl.formatMessage({ id: getLabelKey(label) })}
          </Typography>
        </Grid>
        <Grid item>
          {open ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
        </Grid>
      </Grid>
      <Collapse in={open}>{content}</Collapse>
    </Grid>
  );
};
