import { Grid } from "@mui/material";
import { formatTimeDiff, toDate } from "helpers";
import { FC } from "react";
import { useIntl } from "react-intl";

import { Times } from "models/travelTimes";
import { useAppSelector } from "store";
import { selectSelectedJob } from "store/slices/history.store";
import { isFlagEnabled } from "store/slices/user.store";

import { AsolviTimePicker } from "components/AsolviTimePicker";
import StyledTextField from "components/StyledTextField";
import TimeBlockComponent from "./TimeBlockComponent";
import { TotalTimeComponent } from "./TotalTimeComponent";

export type TravelType = {
  travelTimes: Times[];
  eta?: Date | null;
  mileage?: number;
};

export const TravelTab: FC = () => {
  const intl = useIntl();
  const {
    travelTimes,
    travelETA: travelEta,
    totalMileage: travelMileage,
  } = useAppSelector(selectSelectedJob);
  const showETAandMileage = useAppSelector((s) => isFlagEnabled(s, "ETAandMileage"));

  return (
    <>
      <Grid container direction="column" spacing={1} width="100%" pl={1} pr={1}>
        {travelTimes.map((times, index) => {
          return (
            <div key={`${times.startTime}`}>
              {times.startTime !== null && (
                <Grid item>
                  <TimeBlockComponent
                    times={times}
                    id={index}
                    timesLabel="travelTimes"
                    timesText={intl.formatMessage({
                      id: "times.travelTime",
                    })}
                    totalTime={formatTimeDiff(
                      intl,
                      Math.floor(
                        (new Date(times.stopTime!).getTime() -
                          new Date(times.startTime!).getTime()) /
                          1000 /
                          60
                      )
                    )}
                  />
                </Grid>
              )}
            </div>
          );
        })}
        {showETAandMileage && (
          <Grid
            item
            container
            justifyContent="space-between"
            direction="column"
            width="100%"
            spacing={1}
            wrap="nowrap"
            sx={{
              mt: 1,
              ml: 0,
              pl: "0 !important",
            }}
          >
            <Grid item container spacing={1} sx={{ pl: "0 !important" }} wrap="nowrap">
              <Grid item xs={12}>
                <AsolviTimePicker
                  name={`eta`}
                  label={intl.formatMessage({ id: "visit.eta" })}
                  value={toDate(travelEta)}
                  onChange={() => {}}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} sx={{ pl: "0 !important" }} wrap="nowrap">
              <Grid item xs={12}>
                <StyledTextField
                  inputProps={{ inputMode: "numeric", pattern: "d*" }}
                  id="total-miles"
                  name="mileage"
                  label={intl.formatMessage({ id: "visit.totalMiles" })}
                  value={travelMileage}
                  disabled={true}
                  onChange={() => {}}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} ml={1}>
          <TotalTimeComponent timesMerged={travelTimes} />
        </Grid>
      </Grid>
    </>
  );
};
