import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

const FileTabCard = styled(Card)(() => ({
  display: "flex",
  marginBottom: 10,
  minWidth: "100%",
}));

export default FileTabCard;
